import { createFeature, createReducer, on } from '@ngrx/store';
import { DocumentationFileStructureModel } from 'src/app/core/models/doc-file-structure.model';
import { DocumentationFileModel } from 'src/app/core/models/doc-file.model';
import * as docsApiActions from './actions/docs-api.actions';

export interface DocsState {
    documentationFile: DocumentationFileModel | null;
    documentationStructure: DocumentationFileStructureModel | null;
    detailLoading: boolean;
}

export const initialState: DocsState = {
    documentationFile: null,
    documentationStructure: null,
    detailLoading: false
};

export const documentationFeature = createFeature({
    name: 'documentation',
    reducer: createReducer(
        initialState,
        on(docsApiActions.getDocumentationStructure, (state) => ({
            ...state,
            documentationStructure: null
        })),
        on(
            docsApiActions.getDocumentationStructureSuccess,
            (state, { documentationStructure }) => ({
                ...state,
                documentationStructure
            })
        ),
        on(docsApiActions.getDocByPath, (state) => ({
            ...state,
            documentationFile: null,
            detailLoading: true
        })),
        on(docsApiActions.getDocByPathSuccess, (state, { documentationFile }) => ({
            ...state,
            documentationFile,
            detailLoading: false
        }))
    )
});
