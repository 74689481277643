<div
    class="container"
    [class.selected]="selected"
    (mouseleave)="deleteAllowed && onShowDeleteButton(false)"
    (mouseenter)="deleteAllowed && onShowDeleteButton(true)"
>
    <div class="item-title" [class.selected]="selected">
        {{ versionItemTitle }}
        <mat-icon *ngIf="showDelete" class="delete" (click)="onDeleteSelected($event)"
            >delete</mat-icon
        >
    </div>
    <div class="info">
        <div>
            <app-icon-text icon="person-outline" iconWidth="20px" iconHeight="20px">
                <app-user-name [id]="userId"></app-user-name>
            </app-icon-text>
            <app-icon-text icon="calendar" iconWidth="20px" iconHeight="20px">
                <span>{{ exportedAt | customDate: dateString }}</span>
            </app-icon-text>
            <app-icon-text
                *ngIf="secondText"
                icon="computer"
                iconWidth="20px"
                iconHeight="20px"
            >
                {{ secondText }}</app-icon-text
            >
        </div>
    </div>
</div>
