<app-account-result-page>
    <div id="result-subtitle">Your account has been successfully activated</div>
    <div id="result-text">You can now log in to WorkRoads.</div>
    <div id="result-image">
        <img
            src="/assets/setting-up-account-successfully.svg"
            alt="setting up account success logo"
        />
    </div>
    <div>
        <app-custom-button
            text="Login"
            color="primary"
            type="raised"
            size="large"
            (buttonClick)="onButtonClick()"
        >
        </app-custom-button>
    </div>
</app-account-result-page>
