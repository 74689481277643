import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { ApplicationInsightsService } from '../services/application-insights.service';
import { Injector } from '@angular/core';
import {
    AngularPlugin,
    ApplicationinsightsAngularpluginErrorService
} from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ApplicationInsightsServiceMock } from '../mocks/application-insights.service.mock';

export function loggingServiceFactory(store: Store, router: Router) {
    if (!environment.configuration.saveInsights)
        return new ApplicationInsightsServiceMock();

    return new ApplicationInsightsService(store, router);
}

export function createAppInsight(connectionString: string) {
    if (!connectionString) return null;

    const myinjector = Injector.create({
        providers: [
            {
                provide: ApplicationinsightsAngularpluginErrorService,
                useClass: ApplicationinsightsAngularpluginErrorService
            }
        ]
    });
    const angularPlugin = new AngularPlugin(myinjector);

    return new ApplicationInsights({
        config: {
            connectionString: connectionString,
            extensions: [angularPlugin]
        }
    });
}
