import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-user-menu[userName][organization]',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {
    public show = false;
    @Input() userName!: string;
    @Input() organization!: string;

    clickOnComponent(event: boolean) {
        this.show = this.show ? !this.show : event;
    }
}
