import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DATE_HOUR } from '../../pipes/custom-date.pipe';

@Component({
    selector: 'app-version-history-item[versionItemTitle]',
    templateUrl: './version-history-item.component.html',
    styleUrls: ['./version-history-item.component.scss']
})
export class VersionHistoryItemComponent {
    @Input() versionItemTitle!: string;
    @Input() userId = '';
    @Input() secondText = '';
    @Input() exportedAt = new Date();
    @Input() deleteAllowed = false;
    @Input() selected = false;
    @Output() delete = new EventEmitter();

    dateString = DATE_HOUR;
    showDelete = false;

    onShowDeleteButton(show: boolean): void {
        this.showDelete = show;
    }

    onDeleteSelected(event: any): void {
        event.stopPropagation();
        this.delete.emit();
    }
}
