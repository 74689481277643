import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackBarDataInterface } from 'src/app/core/interfaces/custom-snackbar-data.interface';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {
    constructor(private snackBar: MatSnackBar) {}

    info(message: string): void {
        this.snackBar.open(message, '', {
            duration: 3000,
            panelClass: ['info-notification']
        });
    }
    success(message: string): void {
        this.snackBar.open(message, '', {
            duration: 3000,
            panelClass: ['success-notification']
        });
    }
    warning(message: string): void {
        this.snackBar.open(message, '', {
            duration: 3000,
            panelClass: ['warning-notification']
        });
    }
    error(message: string): void {
        this.snackBar.open(message, '', {
            duration: 3000,
            panelClass: ['error-notification']
        });
    }

    openFromComponent(componentToUse: any, data: CustomSnackBarDataInterface): void {
        if (data.messages.length) {
            this.snackBar.openFromComponent(componentToUse, {
                data: {
                    messages: data.messages,
                    success: data.success
                },
                duration: 3000
            });
        } else {
            this.snackBar.dismiss();
        }
    }

    dismissSnackBar(): void {
        this.snackBar.dismiss();
    }
}
