import { createAction, props } from '@ngrx/store';
import { LoginResponse, UserInfo } from 'src/app/core/definitions/interfaces';

export const externalLoginRequest = createAction(
    '[Authentication] External login request',
    props<{ email: string; password: string; callback: string }>()
);
export const externalLoginFirstStepSuccess = createAction(
    '[Authentication] External login first step success',
    props<{ data: LoginResponse }>()
);
export const externalLoginSecondStepSuccess = createAction(
    '[Authentication] External login second step success'
);
export const externalLoginFailure = createAction(
    '[Authentication] External login first step failure',
    props<{ message: string }>()
);

export const loginRequest = createAction(
    '[Authentication] Login request',
    props<{ email: string; password: string; returnUrl: string }>()
);
export const loginSuccess = createAction(
    '[Authentication] Login success',
    props<{ returnUrl: string; user: UserInfo }>()
);
export const loginFailure = createAction(
    '[Authentication] Login failure',
    props<{ message: string }>()
);
