<div class="details-container">
    <div class="tree-layout-container">
        <div class="title">
            <div class="img-container autocad">
                <ng-content select="#first-title"></ng-content>
            </div>
            <div class="img-container workroads">
                <ng-content select="#second-title"></ng-content>
            </div>
        </div>
        <div class="tree-container scroll">
            <div class="tree">
                <ng-content></ng-content>
            </div>
        </div>
    </div>

    <div class="code-editor-container">
        <ng-content select="#global-editor"></ng-content>
    </div>
</div>
