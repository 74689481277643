/**
 * Remove spaces and use PascalCase
 * @param input
 */
export function toPascalCase(input: string): string {
    const words = input.trim().split(' ');
    const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return capitalizedWords.join('');
}

export function replaceNewlinesWithInvisibleToken(text: string) {
    if (text == null) return '';
    return text.replace(/(\r\n|\n|\r)/gm, '\u200B');
}

export function replaceInvisibleTokenWithNewlines(text: string) {
    if (text == null) return '';
    return text.replace(/\u200B/gm, '\n');
}
