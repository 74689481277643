<div class="container-properties">
    <div class="properties">
        <div class="personal-list">
            <app-list-item
                *ngFor="let page of pages"
                [title]="page?.data?.title"
                height="20px"
                [routerLink]="'/app/personal/' + page.path"
                [routerLinkActive]="'selected'"
            >
            </app-list-item>
        </div>
    </div>
</div>
