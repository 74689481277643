<div class="icon-text-container">
    <mat-icon
        [svgIcon]="icon"
        [style.width]="iconWidth"
        [style.height]="iconHeight"
    ></mat-icon>
    <div class="showed-text">
        <ng-content></ng-content>
    </div>
</div>
