import { createFeature, createReducer, on } from '@ngrx/store';
import { RoleInfoInterface } from 'src/app/core/interfaces/roles-info.interface';
import { UserInfoInterface } from 'src/app/core/interfaces/user-info.interface';
import * as actions from './organization.actions';

export interface OrganizationState {
    users: UserInfoInterface[];
    roles: RoleInfoInterface[];
    loading: boolean;
}

export const initialOrganizationState: OrganizationState = {
    users: [],
    roles: [],
    loading: false
};

export const organizationFeature = createFeature({
    name: 'organization',
    reducer: createReducer(
        initialOrganizationState,
        on(actions.getRoles, (state) => ({ ...state, roles: [], loading: true })),
        on(actions.getRolesSuccess, (state, { roles }) => ({
            ...state,
            roles
        })),
        on(actions.getRolesFail, (state) => ({ ...state, loading: false })),
        on(actions.getUsers, (state) => ({
            ...state,
            users: [],
            loading: true
        })),
        on(actions.getUsersSuccess, (state, { users }) => ({
            ...state,
            users,
            loading: false
        })),
        on(actions.getUsersFail, (state) => ({ ...state, loading: false })),
        on(actions.modifyUserRoles, (state) => ({ ...state, loading: true })),

        on(actions.modifyUserRolesFail, (state) => ({
            ...state,
            loading: false
        })),
        on(actions.inviteUser, (state) => ({
            ...state,
            loading: true
        })),
        on(actions.inviteUserSuccess, (state, { invite }) => {
            const newUserList = [...state.users];
            const inviteRolesNames = invite.roles.map(
                (roleId) => state.roles.find((r) => r.id === roleId)?.name
            );
            newUserList.unshift({
                email: invite.email,
                roles: inviteRolesNames
            } as UserInfoInterface);

            return {
                ...state,
                users: newUserList
            };
        }),
        on(actions.inviteUserFail, (state) => ({
            ...state,
            loading: false
        })),
        on(actions.deleteAccount, (state) => ({ ...state, loading: true })),
        on(actions.deleteAccountFail, (state) => ({
            ...state,
            loading: false
        }))
    )
});
