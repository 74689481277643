import { FilterBase } from '../../../../shared/components/flow-filter-builder/filter-base';
import { Option } from '../../../../shared/components/custom-select/custom-select.component';
import { FieldDataType } from 'src/app/core/definitions/enums';

// https://angular.io/guide/dynamic-form#define-control-classes
export class PropertyFilter extends FilterBase<any> {
    initialValue: any = null;
    type = '';
    key;
    controlType = '';
    label;
    options: Option[] = [];

    dataType: FieldDataType;

    constructor(
        fieldCodeName: string,
        fieldName: string,
        dataType: FieldDataType,
        options: Option[]
    ) {
        super();
        this.key = fieldCodeName;
        this.label = fieldName;
        this.options = options;
        this.dataType = dataType;
        this.setTypes(dataType);
    }

    setTypes(dataType: FieldDataType) {
        switch (dataType) {
            case FieldDataType.Number:
                this.initialValue = {
                    number: 0,
                    operator: '='
                };
                this.controlType = 'number-comparison';
                break;
            case FieldDataType.Text:
                this.initialValue = '';
                this.controlType = 'select';
                this.type = 'multiple';
                break;
            case FieldDataType.Boolean:
                this.controlType = 'select';
                break;
            default:
                this.controlType = 'input';
        }
    }

    getQueryFromValue(value: any): string {
        if (this.controlType === 'number-comparison') {
            return `${this.key} ${value.operator} ${value.number}`;
        }
        if (this.controlType === 'select' && this.type === 'multiple') {
            return (value as string[])
                .map((value) => `( ${this.key} = "${value}" )`)
                .join(' || ');
        }
        return `${this.key} = ${value}`;
    }
}
