<div
    class="body"
    appAutoScrollToSelected
    appScrollShadows
    [selectedId]="selectedId"
    [items$]="combined$"
>
    <app-list-item
        *ngFor="let view of draftViews$ | async"
        [id]="view.id!"
        [selected]="selectedId === view.id"
        [isDraft]="true"
        [title]="view.title"
        [subtitle]="view.description"
        (click)="onClick(view)"
        height="30px"
        [attr.data-id]="view.id"
    >
    </app-list-item>
    <app-list-item
        *ngFor="let view of views$ | async"
        [id]="view.id!"
        [selected]="selectedId == view.id"
        [title]="view.title"
        [subtitle]="view.description"
        height="30px"
        (click)="onClick(view)"
        [attr.data-id]="view.id"
    >
    </app-list-item>
</div>
<div class="footer" *ngIf="(userPermission$ | async)?.isAbleToEditReports">
    <app-custom-button
        color="primary"
        type="stroked"
        size="auto"
        text="CREATE NEW VIEW"
        icon="add"
        (buttonClick)="createViewDraft()"
    ></app-custom-button>
</div>
