import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-version-history',
    templateUrl: './version-history.component.html',
    styleUrls: ['./version-history.component.scss']
})
export class VersionHistoryComponent {
    @Input() title = '';
    @Input() subtitle = '';
    @Input() genericInfo = 0;
    @Input() showDeleteAll = false;
    @Input() showVersions = true; // Used when no item is selected
    @Output() close = new EventEmitter();
    @Output() deleteAllVersions = new EventEmitter();

    onClose() {
        this.close.emit();
    }

    clickOnComponent($clickInsideComponent: boolean) {
        if (!$clickInsideComponent) this.onClose();
    }

    onDeleteAllVersions() {
        this.deleteAllVersions.emit();
    }
}
