import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-custom-icon-menu-confirm-button',
    templateUrl: './custom-icon-menu-confirm-button.component.html',
    styleUrls: ['./custom-icon-menu-confirm-button.component.scss']
})
export class CustomIconMenuConfirmButtonComponent {
    @Output() action = new EventEmitter();

    isConfirmationVisible = false;

    hideConfirmation() {
        this.isConfirmationVisible = false;
    }

    showConfirmation() {
        this.isConfirmationVisible = true;
    }

    onAction() {
        this.action.emit();
    }
}
