import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_AUTH_SERVICE } from '../../constants/connection.apiservice.contant';
import { IUnique } from '../../definitions/interfaces';
import {
    UserActivation,
    UserEmail,
    UserResetPassword
} from '../../interfaces/email-password.interfaces';
import { UserInfoInterface } from '../../interfaces/user-info.interface';
import { BaseModel } from '../../models/base.model';
import { ApiService } from '../../services/api.service';
import { CRUDResource } from '../crud.resource';
import { ACCOUNT } from '../resources';

export class AccountModel extends BaseModel {
    constructor(obj?: IUnique) {
        super(obj);
    }

    protected initialize(obj: any): void {
        return;
    }
}

export interface AccountResponseInterface {
    success: boolean;
    successMessage: string;
    errorMessage: string;
    info: UserInfoInterface;
}
@Injectable({
    providedIn: 'root'
})
export class AccountResourceService extends CRUDResource<AccountModel> {
    constructor(@Inject(API_AUTH_SERVICE) private api: ApiService) {
        super(api, ACCOUNT, '', (obj: IUnique) => new AccountModel(obj));
    }

    inviteUser(newUser: any): Observable<AccountResponseInterface> {
        return this.customPostDirect('add', newUser, (obj: AccountResponseInterface) => ({
            success: obj.success,
            successMessage: obj.successMessage,
            info: obj.info,
            errorMessage: obj.errorMessage
        }));
    }

    forgotPassword(email: UserEmail): Observable<AccountResponseInterface> {
        return this.customPostDirect(
            'forgot-password',
            email,
            (obj: AccountResponseInterface) => ({
                success: obj.success,
                successMessage: obj.successMessage,
                info: obj.info,
                errorMessage: obj.errorMessage
            })
        );
    }

    resetPassword(userConfirm: UserResetPassword): Observable<AccountResponseInterface> {
        return this.customPostDirect(
            'reset-password',
            userConfirm,
            (obj: AccountResponseInterface) => ({
                success: obj.success,
                successMessage: obj.successMessage,
                info: obj.info,
                errorMessage: obj.errorMessage
            })
        );
    }

    activateUser(
        user: UserActivation
    ): Observable<{ succeeded: boolean; messsage: string }> {
        return this.api.post(`${ACCOUNT}/activate`, user) as any;
    }

    deleteAccount(id: string): Observable<any> {
        return this.api.delete(`${ACCOUNT}/` + id) as any;
    }

    varifyToken(userId: string, code: string): Observable<any> {
        return this.customPost('verify-token', { userId, code });
    }
}
