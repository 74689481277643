<app-account-result-page>
    <div id="result-subtitle">Your password has been successfully reset</div>
    <div id="result-text">You can now login with your new password.</div>
    <div id="result-image">
        <img
            src="/assets/reset-password-succesfylly.svg"
            alt="reset password success logo"
        />
    </div>
    <div>
        <app-custom-button
            text="Login"
            color="primary"
            type="raised"
            size="large"
            (buttonClick)="onButtonClick()"
        >
        </app-custom-button>
    </div>
</app-account-result-page>
