import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { mismatchValidator } from 'src/app/shared/helpers/mismatch-validator';
import { changePassword } from 'src/app/store/actions/root.actions';
import { PASSWORD_VALIDATION_REGEX } from '../../../core/constants/app-messages';

@Component({
    selector: 'app-personal-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class PersonalChangePasswordComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren('input') inputs: QueryList<ElementRef<HTMLInputElement>> | undefined;
    @ViewChild('current') currentInputRef: ElementRef<HTMLInputElement> | undefined;
    @ViewChild('passwordField') passwordField: ElementRef<HTMLInputElement> | undefined;

    currentPassword = '';
    newPassword = '';
    repeatPassword = '';

    passwordRegex = PASSWORD_VALIDATION_REGEX;

    passwordFieldForValidation = {} as HTMLInputElement;

    onDestory$ = new EventEmitter();

    constructor(private store: Store) {}

    ngOnInit(): void {
        fromEvent(window, 'resize').pipe(takeUntil(this.onDestory$)).subscribe();
    }

    ngOnDestroy(): void {
        this.onDestory$.emit();
    }

    ngAfterViewInit(): void {
        if (this.passwordField?.nativeElement)
            this.passwordFieldForValidation = this.passwordField?.nativeElement;
    }

    get formIsValid(): boolean {
        return (this.inputs ?? [])
            .map((input) => input.nativeElement)
            .every((input) => input.checkValidity());
    }

    get mismatch(): boolean {
        return mismatchValidator(this.newPassword, this.repeatPassword);
    }

    onSubmit(): void {
        if (!this.formIsValid) {
            return;
        }

        const currentPassword = this.currentPassword ?? '';
        const newPassword = this.newPassword ?? '';
        const repeatPassword = this.repeatPassword ?? '';

        this.store.dispatch(
            changePassword({ currentPassword, newPassword, repeatPassword })
        );
    }
}
