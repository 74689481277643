import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordEmailSuccessComponent } from './component/forgot-password-email-success/forgot-password-email-success.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { ResetPasswordSuccessComponent } from './component/reset-password-success/reset-password-success.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { SettingUpUserSuccessComponent } from './component/setting-up-user-success/setting-up-user-success.component';
import { SettingUpUserComponent } from './component/setting-up-user/setting-up-user.component';

const routes: Routes = [
    {
        path: 'forgot-password',
        children: [
            {
                path: '',
                component: ForgotPasswordComponent
            },
            {
                path: 'success/:userEmail',
                component: ForgotPasswordEmailSuccessComponent
            }
        ]
    },
    {
        path: 'reset-password',
        children: [
            {
                path: 'success',
                component: ResetPasswordSuccessComponent
            },
            {
                path: ':code/:userid',
                component: ResetPasswordComponent
            }
        ]
    },
    {
        path: 'activate',
        children: [
            {
                path: 'success',
                component: SettingUpUserSuccessComponent
            },
            {
                path: ':code/:userid',
                component: SettingUpUserComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UsersRoutingModule {}
