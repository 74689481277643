import { Component, Input } from '@angular/core';
import { ID } from 'src/app/core/definitions/types';
import { UserMenuSectionComponent } from '../user-menu-section/user-menu-section.component';

@Component({
    selector: 'app-user-menu-organization[name]',
    templateUrl: './user-menu-organization.component.html',
    styleUrls: ['./user-menu-organization.component.scss']
})
export class UserMenuOrganizationComponent {
    @Input() name!: string;
    @Input() id!: ID;
    constructor(private parent: UserMenuSectionComponent) {}
}
