import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    EMAIL_EMPTY_VALIDATION_MESSAGE,
    EMAIL_INVALID_VALIDATION_MESSAGE,
    VALIDATOR_EMAIL_REGEX
} from 'src/app/core/constants/feature';
import { UserInfoInterface } from 'src/app/core/interfaces/user-info.interface';
import { UserInfoState } from 'src/app/core/models/user-info.model';
import { InvalidWithErrors } from '../../interfaces/invalid-with-errors.interface';
import { isSupportUser, checkForValidationErrors } from '../../organization.functions';

@Component({
    selector: 'app-email-column',
    templateUrl: './email-column.component.html',
    styleUrls: ['./email-column.component.scss']
})
export class EmailColumnComponent implements OnInit {
    @Input() element!: UserInfoInterface;

    userInfoState = UserInfoState.EXISTING;
    userEmail = '';

    isEmailInvalid = false;
    createInfoState = UserInfoState.CREATE;
    inviteEmail = '';

    emailValidator = new RegExp(VALIDATOR_EMAIL_REGEX);
    invalidAndErrors: InvalidWithErrors = { invalid: false, errorMessages: [] };

    @Output() isSupportedUser = new EventEmitter<boolean>();
    @Output() invalidWithErrors = new EventEmitter<InvalidWithErrors>();
    @Output() emailForUser = new EventEmitter<string>();

    ngOnInit(): void {
        if (this.element) {
            this.userInfoState = this.element.state;
            this.userEmail = this.element.email;
        }
    }

    onValueChange(val: any): void {
        this.isSupportedUser.emit(isSupportUser(val));
    }

    onFocusOut(): void {
        this.checkEmailValidation();
    }

    checkEmailValidation() {
        const emailIncorrect = !checkForValidationErrors(
            this.emailValidator.test(this.inviteEmail),
            EMAIL_INVALID_VALIDATION_MESSAGE,
            this.invalidAndErrors.errorMessages
        );
        const emailEmpty = !checkForValidationErrors(
            !!this.inviteEmail,
            EMAIL_EMPTY_VALIDATION_MESSAGE,
            this.invalidAndErrors.errorMessages
        );

        this.invalidAndErrors.invalid = emailIncorrect || emailEmpty;

        this.invalidWithErrors.emit(this.invalidAndErrors);

        if (!this.invalidAndErrors.invalid) this.emailForUser.emit(this.inviteEmail);
    }
}
