<!--TODO Refactor this view, it's almost the same that the login page -->
<div class="container">
    <div class="login-container">
        <div class="title">
            <img src="/assets/workroads-logo-login.svg" alt="workroads logo" />
            <div>You are authenticated in the hub</div>
            <p>This tab can be closed</p>
        </div>
    </div>
</div>
