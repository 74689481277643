import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { ID } from '../../../../core/definitions/types';
import { ElementDefinitionSummary } from '../../../../core/interfaces/element-definition.summary';
import { Store } from '@ngrx/store';
import { IUnique, UserPermission } from '../../../../core/definitions/interfaces';
import { ElementDefinitionDetail } from '../../../../core/interfaces/element-definition-detail.interface';
import { fromElementDefinitions } from '../../../elements/store/element-definitions.selectors';
import { getElementDefinitions } from '../../../elements/store/actions/element-definitions-api.action';
import { delay, map, takeUntil } from 'rxjs/operators';
import { fromRoot } from 'src/app/store';
import { ElementDefinitionQueryParams } from 'src/app/modules/elements/store/types';
import { DraftRoutingService } from '../../services/draft-routing.service';
import { navigateToElementDefinition } from '../../../elements/store/actions/element-definitions.action';

@Component({
    selector: 'app-element-definition-list',
    templateUrl: './element-definition-list.component.html',
    styleUrls: ['./element-definition-list.component.scss']
})
export class ElementDefinitionListComponent implements OnInit, OnDestroy {
    elementDefinitionDrafts$: Observable<ElementDefinitionDetail[]>;
    elementDefinitions$: Observable<ElementDefinitionSummary[]>;
    combined$: Observable<IUnique[]>;
    onDestroy$ = new Subject<void>();
    selectedQueryParam$: Observable<ElementDefinitionQueryParams | null>;
    selectedId: ID | null = null;
    historyCollectionId: ID | null = null;

    userPermission$!: Observable<UserPermission>;

    @ViewChild('elementContainer') elementContainer!: ElementRef;

    constructor(private store: Store, private draftRoutingService: DraftRoutingService) {
        this.elementDefinitionDrafts$ = this.store.select(
            fromElementDefinitions.selectElementDefinitionDrafts
        );
        this.elementDefinitions$ = this.store.select(
            fromElementDefinitions.selectElementDefinitions
        );
        this.selectedQueryParam$ = this.store.select(
            fromElementDefinitions.selectSelectedQueryParam
        );
        this.userPermission$ = this.store.select(fromRoot.selectUserPermissions);
        this.combined$ = combineLatest([
            this.elementDefinitionDrafts$,
            this.elementDefinitions$
        ]).pipe(
            map(([drafts, items]) => {
                return [...drafts, ...items];
            })
        );

        this.draftRoutingService.createElementDraftOnNavigation(this.onDestroy$);
    }

    ngOnInit(): void {
        this.store.dispatch(getElementDefinitions());
        this.selectedQueryParam$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((params: ElementDefinitionQueryParams | null) => {
                this.selectedId = params?.selected ?? null;
                this.historyCollectionId = params?.historyCollection ?? null;
            });

        this.elementDefinitionDrafts$
            .pipe(takeUntil(this.onDestroy$), delay(5))
            .subscribe(() => {
                this.elementContainer.nativeElement.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    createElementDraft(): void {
        this.draftRoutingService.createElementDefinitionDraft();
    }

    onClick({ id, historyCollection }: { id: ID; historyCollection?: ID }): void {
        if (this.selectedId !== id) {
            this.store.dispatch(navigateToElementDefinition({ id, historyCollection }));
        }
    }
}
