import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { CategoryComponent } from './components/category/category.component';
import { ElementDefinitionListComponent } from './components/element-definition-list/element-definition-list.component';
import { HelpItemComponent } from './components/help-list/help-item/help-item.component';
import { HelpListComponent } from './components/help-list/help-list.component';
import { MenuTabComponent } from './components/menu-tab/menu-tab.component';
import { OrganizationComponent } from './components/organization/organization.component';
import { PatternListComponent } from './components/pattern-list/pattern-list.component';
import { PersonalComponent } from './components/personal/personal.component';
import { SideMenuContentComponent } from './components/side-menu-content/side-menu-content.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { UserSettingComponent } from './components/user-setting/user-setting.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ToolbarHostDirective } from './directives/toolbar-host.directive';
import { ViewsListComponent } from './components/views-list/views-list.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
    declarations: [
        CategoryComponent,
        ElementDefinitionListComponent,
        HelpItemComponent,
        HelpListComponent,
        MenuTabComponent,
        OrganizationComponent,
        PatternListComponent,
        PersonalComponent,
        SideMenuComponent,
        SideMenuContentComponent,
        ToolbarComponent,
        UserSettingComponent,
        ToolbarHostDirective,
        ViewsListComponent
    ],
    exports: [SideMenuComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatExpansionModule,
        MatIconModule,
        MatSidenavModule,
        MatToolbarModule,
        RouterModule,
        SharedModule,
        MatInputModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatSlideToggleModule
    ],
    providers: [DatePipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutModule {}
