import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ID } from 'src/app/core/definitions/types';
import { WARNING_2, WarningType } from 'src/app/shared/types/warnings';
import * as patternsActions from '../../store/actions/patterns.action';
import { LinkSummaryWithUser, fromPatterns } from '../../store/patterns.selectors';

export type LinkNameId = {
    id: ID;
    name: string;
    selected: boolean;
};
@Component({
    selector: 'app-delete-pattern-confirmation',
    templateUrl: './delete-pattern-confirmation.component.html',
    styleUrls: ['./delete-pattern-confirmation.component.scss']
})
export class DeletePatternConfirmationComponent implements OnInit, OnDestroy {
    warningType: WarningType = WARNING_2;
    usedIn: (LinkSummaryWithUser & { selected: boolean })[] = [];

    data$: Observable<LinkSummaryWithUser[] | null> = this.store.select(
        fromPatterns.selectAssociatedLinksAndItsUsers
    );
    isLoading$: Observable<boolean> = this.store.select(
        fromPatterns.selectLoadingAssociatedLinks
    );

    private onDestroy = new Subject<void>();

    allSelected = false;

    constructor(
        public dialogRef: MatDialogRef<DeletePatternConfirmationComponent>,
        @Inject(Store) private store: Store,
        @Inject(MAT_DIALOG_DATA)
        public dialogContext: {
            onAccept: (links: ID[]) => void;
        }
    ) {}

    ngOnInit(): void {
        this.store.dispatch(patternsActions.getAssociatedLinks());
        this.data$.pipe(takeUntil(this.onDestroy)).subscribe((result) => {
            if (!result) {
                return;
            }

            this.usedIn = result.map((link) => ({
                ...link,
                selected: false
            }));
        });
    }

    get extraLabelButton(): string {
        return this.someLinksSelected ? 'Archive and Delete Links' : 'Archive';
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    onDiscard(): void {
        this.dialogRef.close();
    }

    onAccept(): void {
        this.dialogContext.onAccept(this.selectedLinks);
        this.dialogRef.close();
    }

    checkAction(data: { id: ID }): void {
        this.usedIn = this.usedIn.map(
            (value: LinkSummaryWithUser & { selected: boolean }) =>
                value.id == data.id ? { ...value, selected: !value.selected } : value
        );
        this.allSelected = this.allLinksSelected;
    }

    get someLinksSelected(): boolean {
        return this.usedIn.some(
            (value: LinkSummaryWithUser & { selected: boolean }) => value.selected
        );
    }

    get allLinksSelected(): boolean {
        return this.usedIn.every(
            (value: LinkSummaryWithUser & { selected: boolean }) => value.selected
        );
    }

    get selectedLinks(): ID[] {
        return this.usedIn.filter((item) => item.selected).map((item) => item.id);
    }

    checkAll(selected: boolean): void {
        this.allSelected = selected;
        this.usedIn = this.usedIn.map(
            (value: LinkSummaryWithUser & { selected: boolean }) => ({
                ...value,
                selected
            })
        );
    }
}
