<div
    class="body"
    *ngIf="{ id: selectedQueryParam$ | async } as selected"
    appAutoScrollToSelected
    appScrollShadows
    [selectedId]="selected.id"
    [items$]="combined$"
>
    <app-menu-tab
        [menuExpanded]="true"
        [menuTab]="route"
        [lightMode]="true"
        (click)="onNavigation()"
    >
        <div class="mat-menu-options">
            <button
                mat-icon-button
                [matMenuTriggerFor]="optionsMenu"
                (click)="$event.stopPropagation()"
            >
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
    </app-menu-tab>

    <ng-container *ngIf="!(isInArchivedPattern$ | async); else archived">
        <app-list-item
            *ngFor="let pattern of draftPatterns$ | async"
            [id]="pattern.id!"
            [selected]="selected.id === pattern.id"
            [isDraft]="true"
            [title]="pattern.title"
            [subtitle]="pattern.description"
            (click)="onClick(pattern)"
            height="30px"
            [attr.data-id]="pattern.id"
        >
        </app-list-item>
        <app-list-item
            *ngFor="let pattern of patterns$ | async"
            [id]="pattern.id!"
            [selected]="selected.id === pattern.id"
            [title]="pattern.title"
            [subtitle]="pattern.description"
            height="30px"
            (click)="onClick(pattern)"
            [attr.data-id]="pattern.id"
        >
            <mat-icon *ngIf="pattern.hasErrors">info</mat-icon>
            <img
                *ngIf="pattern.targetFramework"
                class="software-icon"
                [src]="pattern.targetFramework | frameworkImage"
                alt="source-software-logo"
            />
        </app-list-item>
    </ng-container>
    <ng-template #archived>
        <ng-container *ngIf="!(loadingArchived$ | async); else loading">
            <app-list-item
                *ngFor="let pattern of archived$ | async"
                [id]="pattern.id!"
                [selected]="selected.id === pattern.id"
                [title]="pattern.title"
                [subtitle]="pattern.description"
                height="30px"
                (click)="onClickArchived(pattern)"
                [attr.data-id]="pattern.id"
                [mainIcon]="'inventory_2'"
            >
                <mat-icon class="error" *ngIf="pattern.hasErrors">info</mat-icon>
                <img
                    *ngIf="pattern.targetFramework"
                    class="software-icon"
                    [src]="pattern.targetFramework | frameworkImage"
                    alt="source-software-logo"
                />
            </app-list-item>
        </ng-container>
    </ng-template>
</div>
<div class="footer" *ngIf="!(readonlyMode$ | async)">
    <app-custom-button
        color="primary"
        type="stroked"
        size="auto"
        text="CREATE NEW PATTERN"
        icon="add"
        (buttonClick)="createPatternDraft()"
    ></app-custom-button>
</div>
<ng-template #loading>
    <app-loader></app-loader>
</ng-template>
<mat-menu class="archived-menu" #optionsMenu="matMenu">
    <div class="content" *ngIf="{ archived: isInArchivedPattern$ | async } as data">
        <a class="menu-element" *ngIf="!data.archived" (click)="toggleArchived(true)">
            <span class="archive-label"
                ><mat-icon fontSet="material-icons-outlined">inventory_2</mat-icon
                >Archived Patterns</span
            >
        </a>
        <a class="menu-element" *ngIf="data.archived" (click)="toggleArchived(false)">
            <span class="archive-label"
                ><mat-icon fontSet="material-icons-outlined"
                    >assignment_turned_in</mat-icon
                >Active Patterns</span
            >
        </a>
    </div>
</mat-menu>
