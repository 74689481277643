<div class="header-section" *ngIf="headerItem">
    <div class="form" [formGroup]="headerForm">
        <app-custom-autosize-input
            class="code-name-input"
            formControlName="codeName"
            id="codename-input"
            label="CodeName"
            inputStyle="bold"
            topIconSvg="vector"
            placeholder="CodeName"
            [initWithFocus]="true"
            [required]="true"
        >
        </app-custom-autosize-input>
        <app-custom-autosize-input
            class="name-input"
            formControlName="name"
            id="title-input"
            label="Label"
            topIcon="label_outline"
            inputStyle="bold"
            placeholder="Label"
            [required]="true"
            [parenthesis]="true"
        >
        </app-custom-autosize-input>
        <app-custom-autosize-input
            formControlName="description"
            id="description-input"
            label="Description"
            inputStyle="bold"
            placeholder="Description"
        >
        </app-custom-autosize-input>
        <app-difference-warning [property]="NAME_AND_CODE_NAME"></app-difference-warning>
    </div>
</div>
