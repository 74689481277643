<div id="dialog">
    <div>
        <h1 mat-dialog-title>Attention!</h1>
        <div mat-dialog-content>
            <p class="warning subtitle-1">
                You are about to <b>archive this pattern.</b>
            </p>
            <ng-container *ngIf="!(isLoading$ | async); else loading">
                <div *ngIf="usedIn?.length" class="used-in">
                    <app-warning-message-icon
                        [showWarningWord]="false"
                        [warningType]="warningType"
                    >
                        <p class="used-in-title">Selected links will also be deleted</p>
                    </app-warning-message-icon>
                    <p class="used-in-subtitle">
                        This pattern is used in those links, you will not be able to
                        recover them
                    </p>
                    <mat-checkbox
                        class="select-all"
                        color="warn"
                        [checked]="allSelected"
                        [class.selected]="allSelected"
                        (change)="checkAll($event.checked)"
                        >Select All</mat-checkbox
                    >
                    <div class="used-in-items">
                        <div *ngFor="let link of usedIn">
                            <mat-checkbox
                                [class.selected]="link.selected"
                                color="warn"
                                [checked]="link.selected"
                                (change)="checkAction(link)"
                                >{{ link.drawingName }}, by {{ link.userName }},
                                {{ link.exportedAt | date: 'MMM d' }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="buttons-container" mat-dialog-actions>
            <app-custom-button
                color="primary"
                type="flat"
                text="Cancel"
                (buttonClick)="onDiscard()"
            ></app-custom-button>
            <app-custom-button
                type="stroked"
                color="warn"
                [disabled]="!!(isLoading$ | async)"
                [text]="extraLabelButton"
                (buttonClick)="onAccept()"
            ></app-custom-button>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="loading-container">
        <app-loader></app-loader>
    </div>
</ng-template>
