import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/localstorage.service';
import { StorageKey } from '../definitions/enums';

@Injectable()
export class AuthorizationHandlerInterceptor implements HttpInterceptor {
    /**
     *
     * @param storage
     * Get authorization token from `localstorage` and set it in the header
     * of all the request that doesn't match with `login` or `sign-up`
     */
    constructor(private storage: LocalStorageService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.url.includes('login') || !request.url.includes('sign-up')) {
            const token = this.storage.get<string>(StorageKey.TOKEN);
            const clone = request.clone({
                setHeaders: { Authorization: `Bearer ${token}` }
            });
            return next.handle(clone);
        }
        return next.handle(request);
    }
}
