import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-delete-confirmation',
    templateUrl: './delete-confirmation.component.html',
    styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent {
    constructor(
        public dialogRef: MatDialogRef<DeleteConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            message: string;
            onAccept: () => void;
            data: {
                patternName: string;
                drawingName: string;
                exportedBy: string;
                exportedFrom: string;
            };
        }
    ) {}

    onDiscard(): void {
        this.dialogRef.close();
    }
    onAccept(): void {
        this.data.onAccept();
        this.dialogRef.close();
    }
}
