<div id="dialog">
    <div>
        <h1 mat-dialog-title class="title">Attention!</h1>
        <div mat-dialog-content>
            <p class="text-small">
                {{ data.message }}
            </p>
            <p class="text-regular">{{ data.question }}</p>
        </div>
        <div class="buttons-container" mat-dialog-actions>
            <app-custom-button
                type="stroked"
                color="primary"
                text="Yes"
                (buttonClick)="onConfirm()"
            ></app-custom-button>
            <app-custom-button
                color="primary"
                type="flat"
                text="No"
                cdkFocusInitial
                (buttonClick)="onDiscard()"
            ></app-custom-button>
        </div>
    </div>
</div>
