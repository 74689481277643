import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { HeaderItem } from 'src/app/modules/layout/interfaces/header-item';
import { fromViews } from '../../store/selectors/views.selectors';
import { ViewsState } from '../../store/reducers/views.reducer';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import * as viewsActions from '../../store/actions/views.actions';

@Component({
    selector: 'app-views-toolbar-form',
    templateUrl: './views-toolbar-form.component.html',
    styleUrls: ['./views-toolbar-form.component.scss']
})
export class ViewsToolbarFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() creating = false;
    @Input() ableToEdit = false;

    editing = false;

    headerItem$: Observable<HeaderItem | null>;
    onDestroy$ = new Subject<void>();

    headerItem: HeaderItem | null = null;
    headerForm: FormGroup = this.formBuilder.group({
        name: ['', Validators.required],
        description: ['']
    });

    constructor(private store: Store<ViewsState>, private formBuilder: FormBuilder) {
        this.headerItem$ = this.store.select(fromViews.selectHeader);
    }

    ngOnInit(): void {
        this.headerForm.valueChanges
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((item: HeaderItem) => {
                const changes =
                    item.name != this.headerItem?.name ||
                    item.description != this.headerItem.description;
                if (!changes) return;
                this.store.dispatch(
                    viewsActions.changeDetailHeader({
                        title: item.name,
                        description: item.description
                    })
                );
            });

        this.headerItem$.subscribe((item) => {
            if (item == null) {
                // This handles the cases for save, discard and change current.
                this.editing = false;
            }
            this.headerItem = item;
            this.headerForm.setValue(
                {
                    name: item?.name ?? '',
                    description: item?.description ?? ''
                },
                { emitEvent: false }
            );
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.creating.currentValue) {
            this.editing = true;
        }
    }
    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    onOutsideClick() {
        if (this.headerForm.valid) {
            this.editing = false;
        }
    }

    onEdit() {
        this.editing = true;
    }

    get valid(): boolean {
        return this.headerForm.valid;
    }
}
