<div class="users-table">
    <div class="table-container">
        <div class="table-title">
            {{ isSupportUsers ? 'Support team' : currentOrganization?.name + ' team' }}
        </div>
        <table mat-table [dataSource]="users" class="table-transparent">
            <caption></caption>
            <!-- Name Column -->
            <ng-container matColumnDef="fullname">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="header-border-bottom-line"
                    [class.support-header]="isSupportUsers"
                >
                    <div class="cell-separation header-font">Name</div>
                </th>

                <td mat-cell *matCellDef="let element">
                    <div
                        class="h3-semibold-16 name-container"
                        *ngIf="element.state === createInfoState"
                    >
                        <div class="cell-separation header-font">Invite new user...</div>
                    </div>
                    <div
                        class="h3-semibold-16 name-container"
                        *ngIf="element.state === inviteInfoState"
                    >
                        <app-user-image text=""></app-user-image>
                        <div class="very-small-separation">
                            <div class="cursive user-list-texts">Invitation sent...</div>
                            <div class="invited-since">
                                {{ element.dateLastInvitation | customDate: dateString }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="h3-semibold-16 name-container user-list-texts"
                        *ngIf="element.state === existInfoState"
                    >
                        <app-user-image [text]="element.fullname"></app-user-image>
                        {{ element.fullname }}
                    </div>
                </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="header-border-bottom-line header-font"
                >
                    Email
                </th>

                <td mat-cell *matCellDef="let element">
                    <app-email-column
                        [element]="element"
                        (isSupportedUser)="onSupportedUser($event)"
                        (emailForUser)="onEmailForUserChange($event)"
                        (invalidWithErrors)="onInvalidWithErrorsEmail($event)"
                    >
                    </app-email-column>
                </td>
            </ng-container>

            <!-- Role Column -->
            <ng-container matColumnDef="role">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="header-border-bottom-line header-font"
                >
                    <div *ngIf="!isSupportUsers">Role</div>
                </th>

                <td mat-cell *matCellDef="let element">
                    <app-user-select-column
                        [class.visibility-off]="isSupportUsers"
                        [element]="element"
                        selectType="user"
                        selectionDescription="Roles determines which functionalities can be used by each Users."
                        selectionName="Roles"
                        label="Select Role"
                        [onlyOwner]="onlyOwner"
                        placeholder="Select Role"
                        [options]="(inviteRoles$ | async) ?? []"
                        [selectedOption]="element.roleUser"
                        [selectDisabled]="disableRoleSelection"
                        (newSelectedRoles)="manageRoles($event, element, false)"
                        (invalidWithErrors)="onInvalidWithErrorsRole($event)"
                    ></app-user-select-column>
                </td>
            </ng-container>

            <!-- Role Column -->
            <ng-container matColumnDef="administrative">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="header-border-bottom-line header-font"
                >
                    <div *ngIf="!isSupportUsers">Administrative Rights</div>
                </th>

                <td mat-cell *matCellDef="let element">
                    <app-user-select-column
                        selectType="administrative"
                        selectionDescription="Administrative rights determines who can manage your Organization and its Users. There is no cost associated to administrative rights"
                        selectionName="Administrative Rights"
                        [class.visibility-off]="isSupportUsers"
                        [element]="element"
                        label="Select Rights"
                        placeholder="Select Rights"
                        [onlyOwner]="onlyOwner"
                        [options]="(inviteAdministrativeRoles$ | async) ?? []"
                        [currentPermission]="currentUserPermission"
                        [selectedOption]="element.roleAdmin"
                        [selectDisabled]="disableRoleSelection"
                        (newSelectedRoles)="manageRoles($event, element, true)"
                        (invalidWithErrors)="onInvalidWithErrorAdmin($event)"
                    ></app-user-select-column>
                </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="header-border-bottom-line"
                ></th>

                <td mat-cell *matCellDef="let element; let i = index">
                    <div class="actions-container">
                        <app-custom-menu
                            class="menu"
                            icon="more_horiz"
                            iconColor="primary"
                            iconType="basic"
                            [iconStyle]="iconStyle"
                            [actions]="userActions[i] ? userActions[i].actions : []"
                            (menuClicked)="clickInMenu(element.id)"
                            *ngIf="element.state !== createInfoState && userActions"
                        >
                        </app-custom-menu>
                        <app-custom-button
                            color="primary"
                            text="Invite"
                            type="flat"
                            (buttonClick)="inviteUser()"
                            *ngIf="element.state === createInfoState && !isSupportUsers"
                            [disabled]="
                                !ableToInvite ||
                                invalidEmail.invalid ||
                                (!disableRoleSelection && invalidRole.invalid) ||
                                (!disableRoleSelection &&
                                    invalidAdministrativeRole.invalid)
                            "
                        ></app-custom-button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</div>
