<div class="container-properties">
    <div class="properties">
        <div class="organization-list">
            <ng-container *ngFor="let page of pages">
                <app-list-item
                    *ngIf="
                        !page?.data?.isSecure ||
                        (page.data?.isSecure &&
                            (userPermission$ | async)?.isAbleToUpdateOrganizationInfo)
                    "
                    [title]="page?.data?.title"
                    height="20px"
                    [routerLink]="'/app/organization/' + page.path"
                    [routerLinkActive]="'selected'"
                >
                </app-list-item>
            </ng-container>
        </div>
    </div>
</div>
