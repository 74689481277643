import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import {
    NavigationActionTiming,
    StoreRouterConnectingModule,
    routerReducer
} from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    API_AUTH_SERVICE,
    API_BACKEND_SERVICE,
    API_DOCUMENTATION_SERVICE,
    ConnectionApiService,
    ConnectionAuthService,
    ConnectionDocumentationService,
    DEFAULT_CONNECTION_CONFIG
} from './core/constants/connection.apiservice.contant';
import { AuthorizationHandlerInterceptor } from './core/interceptors/authorization-handler.interceptor';
import { ErrorHandlerInterceptor } from './core/interceptors/error-handler.interceptor';
import { ApiService } from './core/services/api.service';
import { LayoutModule } from './modules/layout/layout.module';
import {
    RoutingService,
    initializeRoutingService
} from './modules/layout/services/routing.service';
import { UsersModule } from './modules/users/users.module';
import { RedirectComponent } from './redirect.component';
import { SharedModule } from './shared/shared.module';
import { SideMenuNavigationComponent } from './side-menu-navigation.component';
import { rootEffects } from './store';
import { rootFeature } from './store/reducer/root.reducer';
import { ResponseLogHandlerInterceptorTsInterceptor } from './core/interceptors/response-log-handler.interceptor.ts.interceptor';
import { Router } from '@angular/router';
import { loggingServiceFactory } from './shared/helpers/logging-service-factory';
import { ApplicationInsightsService } from './shared/services/application-insights.service';

const { reducer } = rootFeature;

@NgModule({
    declarations: [AppComponent, SideMenuNavigationComponent, RedirectComponent],
    imports: [
        SharedModule.forRoot(),
        LayoutModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        UsersModule,
        MatButtonModule,
        MatIconModule,
        HttpClientModule,
        MatToolbarModule,
        MatSlideToggleModule,
        StoreModule.forRoot({ router: routerReducer, root: reducer }),
        EffectsModule.forRoot(rootEffects),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        StoreRouterConnectingModule.forRoot({
            navigationActionTiming: NavigationActionTiming.PostActivation // the selectors provided by @ngrx/router-store will emit values after the guards have been executed
        })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationHandlerInterceptor,
            multi: true
        },
        {
            provide: DEFAULT_CONNECTION_CONFIG,
            useValue: ConnectionApiService
        },
        {
            provide: API_BACKEND_SERVICE,
            useFactory: (http: HttpClient) => new ApiService(http, ConnectionApiService),
            deps: [HttpClient]
        },
        {
            provide: API_AUTH_SERVICE,
            useFactory: (http: HttpClient) => new ApiService(http, ConnectionAuthService),
            deps: [HttpClient]
        },
        {
            provide: API_DOCUMENTATION_SERVICE,
            useFactory: (http: HttpClient) =>
                new ApiService(http, ConnectionDocumentationService),
            deps: [HttpClient]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeRoutingService,
            deps: [RoutingService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseLogHandlerInterceptorTsInterceptor,
            multi: true
        },
        {
            provide: ApplicationInsightsService,
            useFactory: loggingServiceFactory,
            deps: [Store, Router]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
