import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ID } from 'src/app/core/definitions/types';
import { LinkSummary } from 'src/app/core/interfaces/link-summary';
@Component({
    selector: 'app-links-select',
    templateUrl: './links-select.component.html',
    styleUrls: ['./links-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinksSelectComponent implements OnInit, OnDestroy, OnChanges {
    @Input() isMultiSelect = true;
    @Input() links: LinkSummary[] = [];
    @Input() loading = false;

    @Input() selectedLinks: ID[] = [];
    @Input() selectedLink: LinkSummary | null = null;
    @Output() selectionChange: EventEmitter<LinkSummary> =
        new EventEmitter<LinkSummary>();
    @ViewChild(MatMenuTrigger) menuTrigger?: MatMenuTrigger;
    checkedMap: Map<ID, boolean> = new Map<ID, boolean>();
    nameMap: Map<ID, string> = new Map<ID, string>();
    searchControl = new FormControl();
    filteredLinks: LinkSummary[] | null = null;
    isOpen = false;

    updateFiltered$ = new BehaviorSubject<{ value: string; links: LinkSummary[] }>({
        value: '',
        links: []
    });

    ngOnInit() {
        this.searchControl.valueChanges
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((value) => {
                this.updateFiltered$.next({ value, links: this.links });
            });
        this.updateFiltered$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(({ value, links }) => {
                this.onUpdateFilter(value, links);
            });
    }

    onUpdateFilter(value: string, links: LinkSummary[]) {
        this.filteredLinks = this.filterLinks(value, links);
    }

    isEmpty = false;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedLinks || changes.links) {
            this.updateMaps();
            this.isEmpty = this.selectedLinks.length === 0;
        }
        if (changes.links) {
            this.updateFiltered$.next({
                value: this.searchControl.getRawValue(),
                links: this.links
            });
        }
    }

    onDestroy$ = new Subject<void>();
    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    filterLinks(searchText: string, links: LinkSummary[]): LinkSummary[] {
        if (!searchText) return links;
        return links.filter(
            (link) =>
                link.drawingName.toLowerCase().includes(searchText.toLowerCase()) ||
                (link.patternTitle &&
                    link.patternTitle.toLowerCase().includes(searchText.toLowerCase()))
        );
    }

    closeMenu(): void {
        this.menuTrigger?.closeMenu();
    }

    updateMaps() {
        this.checkedMap.clear();
        this.nameMap.clear();
        this.selectedLinks.forEach((linkId) => {
            const link = this.links.find((link) => link.id === linkId);
            if (link) {
                this.checkedMap.set(linkId, true);
                this.nameMap.set(linkId, link.drawingName);
            }
        });
    }

    toggleLink(link: LinkSummary) {
        this.selectionChange.emit(link);
    }

    toggleLinkById(id: ID) {
        const link = this.links.find((link) => link.id === id);
        if (link) {
            this.toggleLink(link);
        }
    }

    checked(linkId: ID): boolean {
        return this.checkedMap.get(linkId) ?? false;
    }

    getName(linkId: ID): string | null {
        return this.nameMap.get(linkId) ?? null;
    }

    onOptionClick(event: Event) {
        event.stopPropagation();
        if (!this.isMultiSelect) {
            this.closeMenu();
        }
    }

    clickedSelector(isClickInside: boolean) {
        if (isClickInside) {
            this.isOpen = !this.isOpen;
        } else {
            this.isOpen = false;
            this.menuTrigger?.closeMenu(); // click outside selector will close the menu.
        }
    }

    focusedItemIndex = -1;

    handleKeypress(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.menuTrigger?.closeMenu();
                this.isOpen = false;
                event.preventDefault();
                break;
        }
    }
}
