<div class="validation-line">
    <mat-icon *ngIf="isValid && !justCreated" class="success">check_circle</mat-icon>
    <mat-icon *ngIf="!isValid && !justCreated" svgIcon="x-error"></mat-icon>
    <div class="empty-circle" *ngIf="justCreated"></div>
    <div
        class="validation-text"
        [class.text-valid]="isValid && !justCreated"
        [class.text-invalid]="!isValid && !justCreated"
        *ngIf="message"
    >
        {{ message.message }}
    </div>
</div>
