import { Component, ViewChild } from '@angular/core';
import { Action } from 'src/app/shared/interfaces/action';
import { MatDialog } from '@angular/material/dialog';
import { SaveElementWithMessageDialogComponent } from 'src/app/modules/elements/components/save-element-with-message-dialog/save-element-with-message-dialog.component';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { ElementToolbarFormComponent } from '../element-toolbar-form/element-toolbar-form.component';
import { DeleteElementConfirmationComponent } from '../delete-element-confirmation/delete-element-confirmation.component';
import { ToolbarElementService } from '../../services/toolbar-element.service';
import { Observable } from 'rxjs';
import { ElementToolbarView } from '../../store/types';

@Component({
    selector: 'app-toolbar-elements',
    templateUrl: './toolbar-elements.component.html',
    styleUrls: ['./toolbar-elements.component.scss']
})
export class ToolbarElementsComponent {
    MENU_ACTIONS = [
        {
            icon: 'file_copy',
            iconFirst: true,
            emitEvent: () => this.duplicateElement(),
            label: 'Duplicate element'
        },
        {
            icon: 'restore',
            iconFirst: true,
            emitEvent: () => this.showVersionHistory(),
            label: 'Version history'
        },
        {
            icon: 'delete_link',
            iconFirst: true,
            emitEvent: () => this.openDeleteDialog(),
            label: 'Delete element',
            style: { color: 'var(--remove)' }
        }
    ] as Action[];

    SAVE_ACTIONS = [
        {
            icon: 'chat_paste_go',
            iconFirst: true,
            emitEvent: () => this.saveDraftWithMessage(),
            label: 'Save with message'
        }
    ] as Action[];

    @ViewChild(ElementToolbarFormComponent) form?: ElementToolbarFormComponent;

    constructor(
        private toolbarElementService: ToolbarElementService,
        private dialog: MatDialog
    ) {}

    get isFormValid(): boolean {
        return !!this.form?.valid;
    }

    get elementToolbarView(): Observable<ElementToolbarView> {
        return this.toolbarElementService.elementToolbarView;
    }

    duplicateElement(): void {
        this.toolbarElementService.duplicateElement();
    }

    discardData(): void {
        this.toolbarElementService.discardData();
    }

    saveDraft(): void {
        this.toolbarElementService.saveDraft();
    }

    publishDraft(): void {
        this.toolbarElementService.currentElement.pipe(take(1)).subscribe((element) => {
            const dialogRef = this.dialog.open(SaveElementWithMessageDialogComponent, {
                disableClose: true,
                data: {
                    title: `You are about to Publish “${element?.name}” element.`,
                    isDraft: false
                }
            });
            dialogRef
                .afterClosed()
                .pipe(
                    take(1),
                    filter((message) => !!message)
                )
                .subscribe((message) =>
                    this.toolbarElementService.savePublished(message)
                );
        });
    }

    saveDraftWithMessage(): void {
        this.toolbarElementService.currentElement.pipe(take(1)).subscribe((element) => {
            const dialogRef = this.dialog.open(SaveElementWithMessageDialogComponent, {
                disableClose: true,
                data: {
                    title: `You are about to save a new draft for “${element?.name}”  element.`,
                    isDraft: true
                }
            });
            dialogRef
                .afterClosed()
                .pipe(
                    take(1),
                    filter((message) => !!message)
                )
                .subscribe((message: string) => {
                    this.toolbarElementService.saveDraft(message);
                });
        });
    }

    showVersionHistory() {
        this.toolbarElementService.showVersionHistory();
    }

    clickOutside() {
        this.form?.onOutsideClick();
    }

    openDeleteDialog(): void {
        this.toolbarElementService.currentElement
            .pipe(
                take(1),
                switchMap((element) =>
                    this.toolbarElementService
                        .getUsedNameOfPatterns(element!.id)
                        .pipe(map((patterns) => ({ element, patterns })))
                )
            )
            .subscribe(({ element, patterns }) => {
                this.dialog.open(DeleteElementConfirmationComponent, {
                    width: '600px',
                    autoFocus: false,
                    height: '500px',
                    data: {
                        onAccept: () =>
                            this.toolbarElementService.deleteElement(
                                element!.id,
                                element!.historyId!
                            ),
                        data: patterns
                    }
                });
            });
    }
}
