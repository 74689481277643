import { Params } from '@angular/router';
import { getSelectors } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import { ALL_PERMISSION_FALSE } from 'src/app/core/constants/permission-default';
import { UserPermission } from 'src/app/core/definitions/interfaces';
import { MenuTab } from 'src/app/shared/models/menu-tab.model';
import { Route, ROUTES } from '../../core/constants/feature';
import { rootFeature } from '../reducer/root.reducer';

const { elements, patterns, views, organization, personal, help, blueprint } = ROUTES;

const { selectQueryParams, selectRouteData, selectUrl } = getSelectors();

const {
    selectOrganizations,
    selectUser,
    selectLoading,
    selectHubUrl,
    selectOrganizationUsersDisplayNames
} = rootFeature;

const selectSelected = createSelector(
    selectQueryParams,
    (queryParams) => queryParams?.selected ?? null
);

const selectDraftCreationParams = createSelector(selectQueryParams, (params) => {
    return {
        isDraft: !!params.new,
        duplicate: !!params.duplicate
    };
});

/**
 * This selector is used to subscribe to the import query
 * parameter.
 *
 * This param is used to select an import in the import selector,
 * in the patterns page. This way, an external app can create
 * a pattern using an import.
 */
const selectImportParam = createSelector(
    selectQueryParams,
    (queryParams) => queryParams.import
);

const selectCurrentFeature = createSelector(selectUrl, (url) => {
    if (!url) return Route.root;
    if (url.includes('app/imports')) return Route.imports;
    if (url.includes('app/patterns')) return Route.patterns;
    if (url.includes('app/elements')) return Route.elements;
    if (url.includes('app/views')) return Route.views;
    if (url.includes('app/help')) return Route.help;
    if (url.includes('app/personal')) return Route.personal;
    if (url.includes('app/organization')) return Route.organization;

    return Route.root;
});

const selectIsDocumentationUrl = createSelector(
    selectCurrentFeature,
    (currentFeature) => currentFeature === Route.help
);

const selectCurrentRouteTitle = createSelector(selectRouteData, (data) => {
    return data?.title;
});

const selectAllOrganizations = createSelector(
    selectOrganizations,
    (organizations) => organizations.all
);

const selectCurrentOrganization = createSelector(
    selectOrganizations,
    (organizations) => organizations.current
);

const selectCurrentOrganizationId = createSelector(
    selectUser,
    (user) => user?.currentOrganizationId
);

const selectOtherOrganizations = createSelector(
    selectOrganizations,
    (organizations) => organizations.others
);

const selectUserPermissions = createSelector(selectUser, (currentUser) => {
    if (currentUser?.rolePermission) {
        const r = currentUser?.rolePermission;
        return {
            isAbleToAddOwners: r.includes('management.organization.owner.add'),
            isAbleToAddUsers: r.includes('management.organization.users.add'),
            isAbleToDeleteOrganization: r.includes('management.organization.delete'),
            isAbleToDeleteUsers: r.includes('management.organization.users.remove'),
            isAbleToEditElements: r.includes('users.elements.edit'),
            isAbleToEditPatterns: r.includes('users.patterns.edit'),
            isAbleToEditReports: r.includes('users.links.reports.edit'),
            isAbleToExportReports: r.includes('users.links.reports.exports'),
            isAbleToDeleteOwners: r.includes('management.organization.owner.remove'),
            isAbleToViewElements: r.includes('users.elements.view'),
            isAbleToViewLinks: r.includes('users.links.view'),
            isAbleToUpdateOrganizationInfo: r.includes(
                'management.organization.information.update'
            ),
            isAbleToViewPatterns: r.includes('users.patterns.view'),
            isAbleToAddAdmin: r.includes('admin.add'),
            isAbleToAddSupport: r.includes('support.add'),
            isAbleToChangePermission: r.includes('admin.roles.permisions.edit'),
            isAbleToEditRoles: r.includes('admin.roles.edit'),
            isAbleToRemoveAdmin: r.includes('admin.remove'),
            isAbleToRemoveSupport: r.includes('support.remove'),
            isAbleToCreateLinkInHub: r.includes('users.hubconnectors.links.create'),
            isAbleToUpdateLinkInHub: r.includes('users.hubconnectors.links.update'),
            isAbleToInstallHub: r.includes('users.hubconnectors.install')
        } as UserPermission;
    }

    return ALL_PERMISSION_FALSE;
});

/**
 * This routes will navigate using actions.
 */
const routesByPermissions = createSelector(
    selectUserPermissions,
    (userPermission: UserPermission): MenuTab[] => {
        const allowedRoutes = [];
        if (userPermission.isAbleToViewLinks) allowedRoutes.push(views);
        if (userPermission.isAbleToViewPatterns) allowedRoutes.push(patterns);
        if (userPermission.isAbleToViewElements) allowedRoutes.push(elements);

        return allowedRoutes;
    }
);

const selectUrlLastValueAndDecode = createSelector(selectUrl, (url) => {
    const splitted = url.split('/');
    const user = splitted[splitted.length - 1];

    return decodeURIComponent(user);
});

const selectCodeAndUserFromUrl = createSelector(selectUrl, (url) => {
    const splitted = url.split('/');
    const userCode = { code: '', userid: '' };
    if (splitted.length > 2) {
        userCode.userid = decodeURIComponent(splitted[splitted.length - 1]);
        userCode.code = decodeURIComponent(splitted[splitted.length - 2]);
    }

    return userCode;
});

const selectHubUrlPermission = createSelector(
    selectHubUrl,
    selectUserPermissions,
    (url, userPermission) => {
        return userPermission.isAbleToInstallHub ? url : '';
    }
);

/**
 * This is used when we want to store the current route,
 * so we can the redirect to it.
 */
export type RouteForReusing = {
    url: string;
    queryParams: Params;
};
const selectCurrentRoute = createSelector(
    selectUrl,
    selectQueryParams,
    (url, queryParams): RouteForReusing => {
        const path = url?.split('?')[0] ?? '';

        return {
            url: path,
            queryParams
        };
    }
);

export const fromRoot = {
    selectAllOrganizations,
    selectCurrentFeature,
    selectCurrentOrganization,
    selectCurrentRouteTitle,
    selectHubUrl,
    selectImportParam,
    selectLoading,
    selectOtherOrganizations,
    selectQueryParams,
    selectSelected,
    selectUser,
    selectIsDocumentationUrl,
    selectUserPermissions,
    routesByPermissions,
    selectOrganizationUsersDisplayNames,
    selectCodeAndUserFromUrl,
    selectCurrentOrganizationId,
    selectHubUrlPermission,
    selectUrlLastValueAndDecode,
    selectDraftCreationParams,
    selectCurrentRoute
};
