import { Pipe, PipeTransform } from '@angular/core';
import { FieldDataType } from '../../core/definitions/enums';
import { getDataTypeName } from '../../modules/shared-nodes/helpers/helper-funtions';

@Pipe({
    name: 'typeIcon'
})
export class TypeIconPipe implements PipeTransform {
    transform(value: FieldDataType, ...args: unknown[]): string {
        return getDataTypeName(value);
    }
}
