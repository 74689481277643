import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
    @Input() title = '';
    @Input() expanded?: boolean;
    @Input() selected?: boolean;
    @Input() patternUsed = '';
    @Input() exportedBy = '';
    @Input() exportedFrom = '';
    @Input() advancedCategory = true;
    @Input() color = '#4baadd';
    @Input() documentationCategory = false;

    @Output() onclick = new EventEmitter();

    userImageSize = 20;
    userFontSize = 10;
    initiallyExpanded = false;
    cardSelectedColor = 'rgba(208, 234, 239, 0.4)';

    ngOnInit() {
        this.initiallyExpanded = this.selected ?? false;
    }

    expandedChanged(value: boolean): void {
        this.expanded = value;
    }

    onClick(event: any) {
        this.onclick.emit();
    }
}
