// Internal Table actions
import { createAction, props } from '@ngrx/store';
import { ReportView } from 'src/app/core/definitions/enums';
import { GroupBy } from 'src/app/modules/list-tree-view/interfaces/group-by.interface';
import { ReportActions } from 'src/app/modules/list-tree-view/interfaces/report-actions';
import { ColumnIdentifier } from 'src/app/modules/list-tree-view/interfaces/report-definition.interface';
import { ReportFilter } from 'src/app/modules/list-tree-view/interfaces/report-filter.interface';

export const sortColumn = createAction(
    '[View Table Internal] Sort column',
    props<{ column: ColumnIdentifier; by: 'asc' | 'desc' }>()
);

export const changeTreeView = createAction(
    '[Links Report Detail] Change the view between Tree and List',
    props<{ reportView: ReportView }>()
);

export const updateFilters = createAction(
    '[Links Report Filters] Update Filters',
    props<{ filters: ReportFilter[] }>()
);

export const updateOrderColumns = createAction(
    '[View Table Internal] Reorder Columns',
    props<{ columns: ColumnIdentifier[] }>()
);

export const updateGroupBy = createAction(
    '[View Table Internal] Update Group By',
    props<{ groupBy: GroupBy }>()
);

export const hideColumns = createAction(
    '[View Table Internal] Hide Column',
    props<{ columns: ColumnIdentifier[]; mode: 'add' | 'update' }>()
);

export const triggerGetCompleteReportForFilters = createAction(
    '[View Table Internal] Get Full Report for Filters'
);
export const viewTableActions: ReportActions = {
    changeTreeView,
    updateFilters,
    updateOrderColumns,
    sortColumn,
    hideColumns,
    triggerGetCompleteReportForFilters
};
