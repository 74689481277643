<div
    id="user-image"
    [class.no-text]="noText"
    [style]="{ height: getSize, width: getSize, fontSize: getFontSize }"
>
    <ng-content *ngIf="initialsWithContent"></ng-content>
    <ng-container *ngIf="!initialsWithContent">
        {{ getInitials }}
    </ng-container>
</div>
