import { Component, Input } from '@angular/core';
import { WARNING_1, WarningType } from '../../types/warnings';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-warning-message-icon',
    templateUrl: './warning-message-icon.component.html',
    styleUrls: ['./warning-message-icon.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state(
                'void',
                style({
                    height: 0,
                    opacity: 0
                })
            ),
            transition('void <=> *', animate(200))
        ])
    ]
})
export class WarningMessageIconComponent {
    @Input() message?: string;
    @Input() warningType: WarningType = WARNING_1;
    @Input() tip?: string;
    @Input() showWarningWord = true;

    showTip = false;

    toggleTip(): void {
        this.showTip = !this.showTip;
    }
}
