import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { fromOrganization } from '../../store/organization.selectors';
import * as organizationActions from '../../store/organization.actions';
import * as rootActions from '../../../../store/actions/root.actions';
import { takeUntil } from 'rxjs/operators';
import { UserRolesEmail } from 'src/app/core/interfaces/user-roles-email.interface';
import { fromRoot } from '../../../../store/selectors/root.selectors';
import { UserPermission } from 'src/app/core/definitions/interfaces';
import { OrganizationSummary } from 'src/app/core/models/organization-summary.model';
import { UserInfoInterface } from 'src/app/core/interfaces/user-info.interface';

@Component({
    selector: 'app-organization-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class OrganizationUsersComponent implements OnInit, OnDestroy {
    normalUsers$!: Observable<UserInfoInterface[]>;
    supportUsers$!: Observable<UserInfoInterface[]>;
    loading$!: Observable<boolean>;
    lastInviteUser$!: Observable<UserRolesEmail | null>;
    currentUserPermission$!: Observable<UserPermission | undefined>;
    currentOrganization$!: Observable<OrganizationSummary | undefined>;

    $onDestroy = new EventEmitter();

    constructor(private store: Store) {}

    ngOnDestroy(): void {
        this.$onDestroy.emit();
    }

    ngOnInit(): void {
        this.store.dispatch(organizationActions.getUsers());
        this.store.dispatch(organizationActions.getRoles());
        this.store.dispatch(rootActions.getOrganizations());

        this.loading$ = this.store.select(fromOrganization.selectLoading);
        this.normalUsers$ = this.store.select(fromOrganization.selectNormalUsers);
        this.supportUsers$ = this.store.select(fromOrganization.selectSupportUsers);
        this.currentUserPermission$ = this.store.select(fromRoot.selectUserPermissions);
        this.currentOrganization$ = this.store.select(fromRoot.selectCurrentOrganization);

        this.store
            .select(fromRoot.selectUser)
            .pipe(takeUntil(this.$onDestroy))
            .subscribe((result) => {
                const organizationId = result?.currentOrganizationId ?? 0;
                this.store.dispatch(
                    rootActions.currentOrganizationChangeFromUserComponent({
                        id: organizationId
                    })
                );
            });
    }
}
