<app-one-section-layout>
    <ng-template #section>
        <section>
            <form class="idable">
                <fieldset>
                    <label for="current">CURRENT PASSWORD</label>
                    <mat-icon
                        *ngIf="current.type === 'text'"
                        (click)="current.type = 'password'"
                    >
                        visibility_off
                    </mat-icon>
                    <mat-icon
                        *ngIf="current.type === 'password'"
                        (click)="current.type = 'text'"
                    >
                        visibility
                    </mat-icon>
                    <input
                        #current
                        #input
                        id="current"
                        type="password"
                        (input)="input.required = true"
                        [(ngModel)]="currentPassword"
                    />
                    <app-form-error-messages
                        [display]="!current.checkValidity()"
                        message="Current password can not be empty."
                    ></app-form-error-messages>
                </fieldset>
                <fieldset #passwordField>
                    <label for="newP">NEW PASSWORD</label>
                    <mat-icon
                        *ngIf="newP.type === 'text'"
                        (click)="newP.type = 'password'"
                    >
                        visibility_off
                    </mat-icon>
                    <mat-icon
                        *ngIf="newP.type === 'password'"
                        (click)="newP.type = 'text'"
                    >
                        visibility
                    </mat-icon>
                    <input
                        #newP
                        #input
                        id="newP"
                        type="password"
                        [pattern]="passwordRegex"
                        (input)="newP.required = true"
                        [(ngModel)]="newPassword"
                    />

                    <app-form-error-messages
                        [display]="!newP.checkValidity()"
                        message="Password doesn't meet the requirements."
                    ></app-form-error-messages>
                </fieldset>
                <fieldset>
                    <label for="repeatP">REPEAT PASSWORD</label>
                    <mat-icon
                        *ngIf="repeatP.type === 'text'"
                        (click)="repeatP.type = 'password'"
                    >
                        visibility_off
                    </mat-icon>
                    <mat-icon
                        *ngIf="repeatP.type === 'password'"
                        (click)="repeatP.type = 'text'"
                    >
                        visibility
                    </mat-icon>
                    <input
                        #repeatP
                        #input
                        id="repeatP"
                        type="password"
                        (input)="repeatP.required = true"
                        [(ngModel)]="repeatPassword"
                    />
                    <app-form-error-messages
                        [display]="mismatch"
                        message="Password mismatch"
                    ></app-form-error-messages>
                </fieldset>
            </form>
            <app-custom-button
                #submit
                color="primary"
                text="Change password"
                type="flat"
                [disabled]="!formIsValid || mismatch"
                (buttonClick)="onSubmit()"
            >
            </app-custom-button>
            <app-password-requirements
                [passwordField]="passwordFieldForValidation"
                [fieldValue]="newPassword"
                [maximumLengthSupported]="800"
            >
            </app-password-requirements>
        </section>
    </ng-template>
</app-one-section-layout>
