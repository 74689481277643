import { Status } from 'src/app/core/definitions/enums';
import { PropertyDifference } from 'src/app/core/interfaces/element-differences.interface';
import { ElementHistoryItem } from 'src/app/core/interfaces/element-history-item.interface';
import {
    ADMITS_CHILDREN,
    CODE_NAME,
    DifferencesResult,
    IS_COUNTABLE,
    NAME,
    NAME_AND_CODE_NAME,
    PropertyId,
    UNICITY,
    UNICITY_KIND
} from './types';
import { WARNING_1, WARNING_2, WarningType } from 'src/app/shared/types/warnings';
import {
    ADMITS_CHILDREN_DISABLE_TIP_MESSAGE,
    ADMITS_CHILDREN_ENABLE_TIP_MESSAGE,
    CODE_NAME_ELEMENT_MESSAGE,
    CODE_NAME_FIELD_TIP_MESSAGE,
    CODE_NAME_INVALID_MESSAGE,
    CODE_NAME_MESSAGE,
    IS_COUNTABLE_DISABLE_TIP_MESSAGE,
    IS_COUNTABLE_ENABLE_TIP_MESSAGE,
    IS_COUNTABLE_MESSAGE,
    NAME_AND_CODE_NAME_MESSAGE,
    NAME_MESSAGE,
    PATTERNS_AFFECT_MESSAGE,
    UNICITY_DISABLE_TIP_MESSAGE,
    UNICITY_ENABLE_TIP_MESSAGE,
    UNICITY_KIND_MESSAGE,
    UNICITY_KIND_TIP_MESSAGE,
    UNICITY_MESSAGE
} from './constants.messages';

function getDifferencesResult(
    message: string,
    warningType: WarningType = WARNING_1,
    tip?: string
): DifferencesResult {
    return { message, warningType, tip };
}

const propertyTransforms: Record<
    PropertyId,
    (value: unknown, isField?: boolean, isValid?: boolean) => DifferencesResult
> = {
    [UNICITY]: (value: unknown) =>
        getDifferencesResult(
            UNICITY_MESSAGE,
            WARNING_1,
            value ? UNICITY_ENABLE_TIP_MESSAGE : UNICITY_DISABLE_TIP_MESSAGE
        ),
    [IS_COUNTABLE]: (value: unknown) =>
        getDifferencesResult(
            IS_COUNTABLE_MESSAGE,
            WARNING_1,
            value ? IS_COUNTABLE_ENABLE_TIP_MESSAGE : IS_COUNTABLE_DISABLE_TIP_MESSAGE
        ),
    [ADMITS_CHILDREN]: (value: unknown) =>
        getDifferencesResult(
            PATTERNS_AFFECT_MESSAGE,
            WARNING_2,
            value
                ? ADMITS_CHILDREN_ENABLE_TIP_MESSAGE
                : ADMITS_CHILDREN_DISABLE_TIP_MESSAGE
        ),
    [UNICITY_KIND]: (value: unknown) =>
        getDifferencesResult(UNICITY_KIND_MESSAGE, WARNING_1, UNICITY_KIND_TIP_MESSAGE),
    [NAME]: () => getDifferencesResult(NAME_MESSAGE, WARNING_1),
    [CODE_NAME]: (value: unknown, isField?: boolean, isValid?: boolean) => {
        if (isValid === false) {
            return getDifferencesResult(CODE_NAME_INVALID_MESSAGE, WARNING_1);
        }
        return isField
            ? getDifferencesResult(
                  CODE_NAME_MESSAGE,
                  WARNING_2,
                  CODE_NAME_FIELD_TIP_MESSAGE
              )
            : getDifferencesResult(CODE_NAME_ELEMENT_MESSAGE, WARNING_1);
    },
    [NAME_AND_CODE_NAME]: () =>
        getDifferencesResult(NAME_AND_CODE_NAME_MESSAGE, WARNING_1)
};

export function groupDrafts(
    input: ElementHistoryItem[]
): (ElementHistoryItem | ElementHistoryItem[])[] {
    const result: (ElementHistoryItem | ElementHistoryItem[])[] = [];
    const currentDrafts: ElementHistoryItem[] = input.filter(
        (item) => item.status === Status.Draft
    );

    if (currentDrafts.length) result.push(currentDrafts);
    result.push(...input.filter((item) => item.status !== Status.Draft));

    return result;
}

export function findDifferenceByProperty(
    differences: PropertyDifference[],
    property: PropertyId,
    isField: boolean,
    transformFn: (
        value: unknown,
        isField?: boolean,
        isValid?: boolean
    ) => DifferencesResult | null
) {
    if (property === NAME_AND_CODE_NAME) {
        const difference = differences?.find(
            (d) => d.property === NAME || d.property === CODE_NAME
        );
        return difference ? transformFn(difference.modifiedValue, isField) : null;
    }
    const difference = differences?.find((d) => d.property === property);
    return difference
        ? transformFn(difference.modifiedValue, isField, difference.valid)
        : null;
}

export function getTransformFunction(
    property: PropertyId
): (value: unknown, isField?: boolean, isValid?: boolean) => DifferencesResult {
    return (value: unknown, isField?: boolean, isValid?: boolean) =>
        propertyTransforms[property](value, isField, isValid);
}
