import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllowPermissionGuard } from 'src/app/core/guards/allow-permission.guard';
import { OrganizationInformationComponent } from './pages/information/information.component';
import { OrganizationUsersComponent } from './pages/users/users.component';

export const routes: Routes = [
    {
        path: 'information',
        canActivate: [AllowPermissionGuard],
        component: OrganizationInformationComponent,
        data: { title: 'Organization Information', isSecure: true }
    },
    {
        path: 'users',
        component: OrganizationUsersComponent,
        data: { title: 'Users', isSecure: false }
    },
    { path: '', redirectTo: 'users', pathMatch: 'full' },
    { path: '**', redirectTo: 'users', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OrganizationRoutingModule {}
