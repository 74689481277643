import { createAction, props } from '@ngrx/store';

export const getDocumentationStructure = createAction(
    '[Documentation] Get DocumentationStructure'
);

export const getDocByPath = createAction(
    '[Documentation] Get Doc by path',
    props<{ path: string }>()
);
