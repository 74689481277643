import { InjectionToken } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConnectionConfig } from '../interfaces/connection.config';

export const ConnectionApiService: ConnectionConfig = {
    protocol: environment.configuration.backend_scheme,
    domain: environment.configuration.backend_url,
    apiVersion: environment.configuration.backend_url_version
};

export const ConnectionAuthService: ConnectionConfig = {
    protocol: environment.configuration.auth_scheme,
    domain: environment.configuration.auth_url,
    apiVersion: environment.configuration.auth_url_version
};

export const ConnectionDocumentationService: ConnectionConfig = {
    protocol: environment.configuration.documentation_scheme,
    domain: environment.configuration.documentation_url,
    apiVersion: environment.configuration.documentation_url_version
};

export const DEFAULT_CONNECTION_CONFIG = new InjectionToken<string>(
    'DEFAULT_CONNECTION_CONFIG'
);

export const API_BACKEND_SERVICE = new InjectionToken<string>('API_BACKEND_SERVICE');

export const API_AUTH_SERVICE = new InjectionToken<string>('API_AUTH_SERVICE');

export const API_DOCUMENTATION_SERVICE = new InjectionToken<string>(
    'API_DOCUMENTATION_SERVICE'
);
