import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserPermission } from 'src/app/core/definitions/interfaces';
import { routes } from 'src/app/modules/organization/organization-routing.module';
import { fromRoot } from 'src/app/store';
@Component({
    selector: 'app-organization',
    templateUrl: './organization.component.html',
    styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent {
    pages = routes.filter((route) => route?.data?.title);

    userPermission$!: Observable<UserPermission>;

    constructor(private store: Store) {
        this.userPermission$ = this.store.select(fromRoot.selectUserPermissions);
    }
}
