import { ID } from 'src/app/core/definitions/types';
import { ElementDefinitionDetail } from 'src/app/core/interfaces/element-definition-detail.interface';
import { ModifiedField } from 'src/app/core/interfaces/element-differences.interface';
import { FieldDefinitionDetail } from 'src/app/core/interfaces/field-definition-detail.interface';
import { WarningType } from 'src/app/shared/types/warnings';

export type ElementToolbarView = {
    ableToEdit: boolean;
    modified: boolean;
    creationMode: boolean;
    currentElement: ElementDefinitionDetail | null;
};

export type ViewElementDifferencesAddedFields = {
    addedFields: FieldDefinitionDetail | null;
    modifiedFields: ModifiedField | null;
};

export type ElementComponentView = {
    isViewLoading: boolean;
    showVersionHistory: boolean;
};

export const CODE_NAME = 'CodeName';
export const NAME = 'Name';
export const NAME_AND_CODE_NAME = 'NameCodeName';
export const ADMITS_CHILDREN = 'AdmitsChildren';
export const IS_COUNTABLE = 'IsCountable';
export const UNICITY = 'Unicity';
export const UNICITY_KIND = 'UnicityKind';

export type PropertyId =
    | typeof CODE_NAME
    | typeof NAME
    | typeof NAME_AND_CODE_NAME
    | typeof ADMITS_CHILDREN
    | typeof IS_COUNTABLE
    | typeof UNICITY
    | typeof UNICITY_KIND;

export type DifferencesResult = {
    message: string;
    warningType: WarningType;
    tip?: string;
};

export type ElementDefinitionQueryParams = {
    selected?: ID;
    historyCollection?: ID;
};
