import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WARNING_2, WarningType } from 'src/app/shared/types/warnings';

@Component({
    selector: 'delete-element-confirmation.component',
    templateUrl: './delete-element-confirmation.component.html',
    styleUrls: ['./delete-element-confirmation.component.scss']
})
export class DeleteElementConfirmationComponent {
    warningType: WarningType = WARNING_2;
    usedIn = '';

    constructor(
        public dialogRef: MatDialogRef<DeleteElementConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA)
        public dialogContext: {
            onAccept: () => void;
            data: string[];
        }
    ) {
        this.usedIn = this.dialogContext.data.join(', ');
    }

    onDiscard(): void {
        this.dialogRef.close();
    }

    onAccept(): void {
        this.dialogContext.onAccept();
        this.dialogRef.close();
    }
}
