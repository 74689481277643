<ng-container *ngIf="elementToolbarView | async as data">
    <app-element-toolbar-form
        [ableToEdit]="data.ableToEdit"
        [creating]="data.creationMode"
    ></app-element-toolbar-form>
    <div class="menu-container" *ngIf="data.ableToEdit && data.currentElement">
        <div class="buttons" *ngIf="data.currentElement.status === 1">
            <ng-container *ngIf="!data.creationMode && !data.modified; else draftBtns">
                <app-custom-button
                    *ngIf="data.currentElement.isMostRecent"
                    color="primary"
                    class="small"
                    type="flat"
                    size="small"
                    text="Publish"
                    (buttonClick)="publishDraft()"
                >
                </app-custom-button>
            </ng-container>
        </div>

        <ng-template #draftBtns>
            <app-custom-button
                color="primary"
                text="Discard"
                class="small"
                type="stroked"
                size="small"
                (buttonClick)="discardData()"
            >
            </app-custom-button>
            <app-custom-button
                color="primary"
                class="small"
                type="flat"
                size="small"
                text="Save Draft"
                [enableSecondButton]="true"
                [disabled]="!data.modified || !isFormValid"
                (buttonClick)="saveDraft()"
                [actions]="SAVE_ACTIONS"
            >
            </app-custom-button>
        </ng-template>

        <app-custom-menu
            *ngIf="!data.creationMode"
            icon="more_vert"
            iconColor="primary"
            iconType="basic"
            [iconStyle]="{
                background: '#F5F5F5',
                color: 'black',
                width: '36px',
                height: '36px'
            }"
            [actions]="MENU_ACTIONS"
        >
        </app-custom-menu>
    </div>
</ng-container>
