import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { CustomSnackBarDataInterface } from 'src/app/core/interfaces/custom-snackbar-data.interface';

@Component({
    selector: 'app-custom-snack-bar',
    templateUrl: './custom-snack-bar.component.html',
    styleUrls: ['./custom-snack-bar.component.scss']
})
export class CustomSnackBarComponent implements OnInit {
    messages: string[] = [];
    success = false;
    constructor(@Inject(MAT_SNACK_BAR_DATA) private data: CustomSnackBarDataInterface) {}

    ngOnInit(): void {
        this.messages = this.data.messages;
        this.success = this.data.success;
    }
}
