import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tip-text',
    templateUrl: './tip-text.component.html',
    styleUrls: ['./tip-text.component.scss']
})
export class TipTextComponent {
    @Input() text?: string;
    @Input() black = false;
}
