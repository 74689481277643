// https://angular.io/guide/dynamic-form#create-a-form-object-model

import { UntypedFormGroup } from '@angular/forms';
import { Option } from '../custom-select/custom-select.component';
import { ID } from '../../../core/definitions/types';
import { Filter } from './filter';
import { uuidWrapper } from '../../helpers/uuid';

/***
 * Form Object Model for dynamic filter creation
 *
 * Future: value as a complex object (currently not supported)
 *
 */
export abstract class FilterBase<T> {
    abstract initialValue: T | undefined;
    abstract key: string;
    abstract label: string;
    required = false;
    abstract controlType: string;
    abstract type: string;
    options: Option[] = [];

    getValueFromForm(form: UntypedFormGroup): T {
        return form.value[this.key];
    }

    getValueFromFilter(filter: Filter<T>): T {
        return filter.value;
    }

    getFilterFromForm(form: UntypedFormGroup, filterId?: ID) {
        return {
            key: this.key,
            value: this.getValueFromForm(form),
            displayName: this.label,
            id: filterId ?? uuidWrapper.uuid(),
            isNew: filterId == undefined,
            query: this.getQuery(form)
        } as Filter<T>;
    }

    getQuery(form: UntypedFormGroup) {
        const value = this.getValueFromForm(form) ?? this.initialValue;
        if (!value) return '';
        return this.getQueryFromValue(value);
    }

    getQueryFromFilter(filter: Filter<T>) {
        return this.getQueryFromValue(filter.value);
    }

    abstract getQueryFromValue(value: T): string;
}
