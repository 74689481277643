import { ImportElementNode } from '../../../core/interfaces/import-element-node.interface';

export const QUANTITY_COLUMN_NAME = 'Quantity';
export const CODE_NAME_COLUMN_NAME = 'Code Name';

export const QUANTITY_COLUMN_CODE_NAME = 'quantity';
export const CODE_NAME_COLUMN_CODE_NAME = 'codeName';

export const getCodeName = (content: ImportElementNode): string => content.codeName;
export const getQuantity = (content: ImportElementNode): number => content.quantity;
