import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-node-show-children-button',
    templateUrl: './node-show-children-button.component.html',
    styleUrls: ['./node-show-children-button.component.scss']
})
export class NodeShowChildrenButtonComponent {
    @Input() expanded = false;
    @Output() toggle = new EventEmitter<void>();

    switch(event: Event) {
        event.stopPropagation();
        this.toggle.emit();
    }

    get icon() {
        return this.expanded ? 'remove' : 'add';
    }
}
