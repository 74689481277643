import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-unsaved-changes-dialog',
    templateUrl: './unsaved-changes-dialog.component.html',
    styleUrls: ['./unsaved-changes-dialog.component.scss']
})
export class UnsavedChangesDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<UnsavedChangesDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            message: string;
            question: string;
        }
    ) {}

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onDiscard(): void {
        this.dialogRef.close(false);
    }
}
