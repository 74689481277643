import { IUnique } from 'src/app/core/definitions/interfaces';
import {
    RoleInfoInterface,
    RolesInfo
} from 'src/app/core/interfaces/roles-info.interface';
import { UserInfoInterface } from 'src/app/core/interfaces/user-info.interface';
import { UserInfoModel, UserInfoState } from 'src/app/core/models/user-info.model';
import { ADMINISTRATIVE_ROLES, SUPPORT_USER_EMAILS } from './contants/role-contants';
import { RoleEnum } from './enums/role-enum';
import { RoleBasic } from './interfaces/role-basic';

export function getCompleteRoles(role: RoleBasic): RolesInfo {
    switch (role.name) {
        case RoleEnum[RoleEnum.Analyst]:
            return {
                ...role,
                subText: 'Paid',
                description: 'Create and manage Elements and Patterns',
                order: 1
            };
        case RoleEnum[RoleEnum.Contributor]:
            return {
                ...role,
                subText: 'Paid',
                description:
                    'Use the Hub to create and update links, but can not edit Elements and Patterns',
                order: 2
            };
        case RoleEnum[RoleEnum.Manager]:
            return {
                ...role,
                subText: '',
                description:
                    "Can manage users but can't delete the Organization or manage Owner's role",
                order: 5
            };
        case RoleEnum[RoleEnum.Viewer]:
            return {
                ...role,
                subText: 'Free',
                description:
                    'View all the data in WorkrRoads but cannot use the Hub, Elements and Patterns',
                order: 3
            };
        case RoleEnum[RoleEnum.Owner]:
            return {
                ...role,
                subText: '',
                description:
                    'Have all administrative rights, including the right to delete the Organization',
                order: 4
            };
        default:
            return {
                ...role,
                subText: '',
                description: 'Do not have any administrative rights',
                order: 6
            };
    }
}

export function isSupportUser(email: string): boolean {
    for (const element of SUPPORT_USER_EMAILS) {
        if (email.includes(element)) return true;
    }
    return false;
}

export function sortByDate(date1: Date, date2: Date) {
    if (date1 < date2) return -1;
    if (date1 > date2) return 1;
    return 0;
}

export function groupUsersByEmailConfirmed(
    users: UserInfoInterface[],
    roles: RoleInfoInterface[]
): UserInfoInterface[] {
    const usersEmailConfirmed: UserInfoInterface[] = [];
    const usersEmailsNoConfirmed: UserInfoInterface[] = [];
    users.forEach((user: UserInfoInterface) => {
        const newUser = new UserInfoModel({
            ...user,
            state: user.emailConfirmed ? UserInfoState.EXISTING : UserInfoState.INVITED
        } as IUnique);

        setRoleByRoleUserName(newUser, roles);

        if (newUser.emailConfirmed) usersEmailConfirmed.push(newUser);
        else usersEmailsNoConfirmed.push(newUser);
    });
    return [
        ...usersEmailsNoConfirmed.sort((a, b) =>
            sortByDate(b.dateLastInvitation, a.dateLastInvitation)
        ),
        ...usersEmailConfirmed
    ];
}

export function addRoleAdminAndUser(newUser: UserInfoInterface, rol: RoleInfoInterface) {
    if (ADMINISTRATIVE_ROLES.includes(rol.name)) {
        newUser.roleAdmin = rol.id.toString();
    } else {
        newUser.roleUser = rol.id.toString();
    }
}

export function setRoleByRoleUserName(
    newUser: UserInfoInterface,
    roles: RoleInfoInterface[]
) {
    newUser.roles.forEach((role) => {
        const rol = roles.find((r) => r.name === role);
        if (rol) {
            addRoleAdminAndUser(newUser, rol);
        }
    });
}

export function getRoleMostSelectedErrorMessage(selectType: string) {
    return `At least one ${selectType} role most be selected`;
}

export function checkForValidationErrors(
    statement: boolean,
    message: string,
    errorMessages: string[]
) {
    if (statement) {
        const index = errorMessages.findIndex((s) => s === message);

        if (index !== -1) {
            errorMessages.splice(index, 1);
        }
    } else {
        const index = errorMessages.findIndex((s) => s === message);

        if (index === -1) {
            errorMessages.push(message);
        }
    }

    return statement;
}
