import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-item-in-group',
    templateUrl: './item-in-group.component.html',
    styleUrls: ['./item-in-group.component.scss']
})
export class ItemInGroupComponent implements OnInit {
    @Input() exportedDate!: Date;
    @Input() message = 'Initial Commit';

    @Output() itemClicked = new EventEmitter<{ message: string; date: string }>();

    subtitle = '';

    constructor(private datePipe: DatePipe) {}

    ngOnInit(): void {
        if (this.exportedDate) {
            this.subtitle = this.getSubtitle(this.exportedDate);
        }
    }

    onClick(): void {
        this.itemClicked.emit({
            message: this.message,
            date: this.getSubtitle(this.exportedDate)
        });
    }

    getSubtitle(date: Date) {
        return `${this.datePipe.transform(date, "dd-MM-yy 'at' HH:mm:ss")}`;
    }
}
