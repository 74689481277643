import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { UserPermission } from 'src/app/core/definitions/interfaces';
import { ID } from 'src/app/core/definitions/types';
import { RolesInfo } from 'src/app/core/interfaces/roles-info.interface';
import { UserInfoInterface } from 'src/app/core/interfaces/user-info.interface';
import { UserInfoModel, UserInfoState } from 'src/app/core/models/user-info.model';
import { CustomMenuContainerComponent } from 'src/app/shared/components/custom-menu-container/custom-menu-container.component';
import {
    CustomMenuOption,
    GroupMenuOption
} from 'src/app/shared/components/custom-menu-content/custom-menu-content.component';
import { RoleEnum } from '../../enums/role-enum';
import { InvalidWithErrors } from '../../interfaces/invalid-with-errors.interface';
import {
    checkForValidationErrors,
    getRoleMostSelectedErrorMessage
} from '../../organization.functions';

@Component({
    selector: 'app-user-select-column',
    templateUrl: './user-select-column.component.html',
    styleUrls: ['./user-select-column.component.scss']
})
export class UserSelectColumnComponent implements OnInit, OnChanges {
    @Input() selectionName = '';
    @Input() selectionDescription = '';
    @Input() options: RolesInfo[] = [];
    @Input() placeholder = '';
    @Input() label = '';

    @Input() selectType = '';
    @Input() element!: UserInfoModel;
    @Input() selectedOption!: ID;
    @Input() selectDisabled = false;
    @Input() icon = '';
    @Input() currentPermission!: UserPermission | null | undefined;
    @Input() onlyOwner: UserInfoInterface | undefined = undefined;

    @ViewChild(CustomMenuContainerComponent) menuTrigger!: CustomMenuContainerComponent;

    @Output() newSelectedRoles = new EventEmitter<ID>();
    @Output() invalidWithErrors = new EventEmitter<InvalidWithErrors>();

    invalidAndErrors: InvalidWithErrors = { invalid: false, errorMessages: [] };

    rolesLoading = false;
    isFirstRoleLoad = false;
    errorValidationMessages: string[] = [];
    invalid = false;

    selectOptions: GroupMenuOption[] = [];

    roleValues = '';
    viewerText = RoleEnum[RoleEnum.Viewer];
    createInfoState = UserInfoState.CREATE;
    userColumnState = UserInfoState.EXISTING;

    supportAutoText = 'Support';

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectDisabled && this.createInfoState === this.userColumnState) {
            if (this.selectDisabled) {
                this.roleValues = this.supportAutoText;
            } else {
                this.roleValues = '';
            }
        }
    }

    ngOnInit(): void {
        if (this.selectedOption) {
            this.loadRolNameIdValues(this.selectedOption);
        }
        if (this.element) {
            this.userColumnState = this.element.state;
        }
        if (this.options) {
            this.setOptions();
        }
    }

    setOptions(): void {
        if (this.options.length) {
            this.selectOptions.push({
                title: this.selectionName,
                description: this.selectionDescription,
                icon: '',
                options: [...this.options]
                    .sort((a, b) => a.order - b.order)
                    .map((option: RolesInfo) => {
                        let disabled = false;
                        if (
                            (this.roleValues === RoleEnum[RoleEnum.Owner] &&
                                this.onlyOwner) ||
                            (this.currentPermission &&
                                ((option.name === RoleEnum[RoleEnum.Owner] &&
                                    !this.currentPermission.isAbleToAddOwners) ||
                                    !this.currentPermission.isAbleToAddUsers))
                        ) {
                            disabled = true;
                        }

                        return {
                            id: option.id,
                            description: option.description,
                            name: option.name,
                            subDescription: option.subText,
                            disabled
                        } as CustomMenuOption;
                    })
            });
        }
    }

    checkPropagation($event: Event): void {
        if (this.element.state === this.createInfoState && this.selectDisabled)
            $event.stopPropagation();
    }

    checkRoleValidation() {
        this.invalidAndErrors.invalid = !checkForValidationErrors(
            !!this.roleValues,
            getRoleMostSelectedErrorMessage(this.selectType),
            this.invalidAndErrors.errorMessages
        );

        this.invalidWithErrors.emit(this.invalidAndErrors);
    }

    manageRoles(rolesSelected: ID) {
        this.newSelectedRoles.emit(rolesSelected);
    }

    onRoleSelect(rolesSelected: ID) {
        this.loadRolNameIdValues(rolesSelected);
        this.checkRoleValidation();

        this.menuTrigger.closeMenu();

        if (!this.invalidAndErrors.invalid) this.newSelectedRoles.emit(rolesSelected);
    }

    onOptionSelected(rolesSelected: ID) {
        if (this.userColumnState === this.createInfoState) {
            this.onRoleSelect(rolesSelected);
        } else {
            this.manageRoles(rolesSelected);
        }
    }

    private loadRolNameIdValues(roleSelected: ID) {
        const roleName = this.options.find((o) => o.id === roleSelected);
        if (roleName) {
            this.roleValues = roleName.name;
        }
    }

    onMenuClosing(): void {
        this.checkRoleValidation();
    }

    onClick(): void {
        if (this.roleValues !== this.supportAutoText) this.menuTrigger.openMenu();
    }
}
