import { createAction, props } from '@ngrx/store';
import { ID } from 'src/app/core/definitions/types';
import { Build } from 'src/app/core/interfaces/build.interface';
import { LinkSummary } from 'src/app/core/interfaces/link-summary';
import { SourceInfo } from 'src/app/core/interfaces/source-info.interface';

export const showVersionHistory = createAction(
    '[Views Toolbar] show the version history list of the selected link'
);

export const hideVersionHistory = createAction(
    '[Views Link Version History] hide the version history list'
);

export const removeVersionHistoryItem = createAction(
    '[Views Link Version History] remove version history item',
    props<{ id: ID }>()
);

export const removeVersionHistoryItemSuccess = createAction(
    '[Views Link Version History] remove the version history item successfully',
    props<{ id: ID; linkId: ID }>()
);

export const removeVersionHistoryItemFail = createAction(
    '[Views Link Version History] remove the version history item fails'
);

export const getVersionHistory = createAction(
    '[Views Toolbar] Get the version history for a linkId'
);

export const getVersionHistorySuccess = createAction(
    '[Views API] Get all the builds for the selected link successfully',
    props<{ builds: Build[] }>()
);

export const versionHistoryError = createAction(
    '[Views API] Get all the builds for the selected link fails',
    props<{ error: string }>()
);

export const selectLink = createAction(
    '[Views Link Version History] Select Link',
    props<{ link: LinkSummary }>()
);

export const selectLinkVersion = createAction(
    '[Views Link Version History] Select Version',
    props<SourceInfo>()
);

export const selectLinkVersionTriggeredByDeletion = createAction(
    '[Views Link Version History] Select Version Triggered by Deletion',
    props<SourceInfo>()
);

export const triggerGetIfNeeded = createAction(
    '[Views Link Version History] Trigger Get Version If Needed'
);
