import { IUnique } from '../definitions/interfaces';
import { PatternDetail } from '../interfaces/pattern-detail.interface';
import { AutocadTree } from '../interfaces/autocad-tree.interface';
import { WorkroadsTree } from '../interfaces/workroads-tree.interface';
import { PatternSummaryModel } from './pattern-summary.model';

export const INITIAL_PATTERN_DETAIL = {
    autocadTree: {
        childrenDefinitions: []
    },
    workRoadsTree: {
        childrenDefinitions: []
    }
};

export class PatternDetailModel extends PatternSummaryModel implements PatternDetail {
    autocadTree!: AutocadTree;
    workRoadsTree!: WorkroadsTree;

    constructor(obj?: IUnique) {
        super(obj);
    }

    protected initialize(obj: any): void {
        super.initialize(obj);
        if (obj) {
            this.autocadTree =
                (obj.autocadTree as AutocadTree) ?? INITIAL_PATTERN_DETAIL.autocadTree;
            this.workRoadsTree =
                (obj.workRoadsTree as WorkroadsTree) ??
                INITIAL_PATTERN_DETAIL.workRoadsTree;
        }
    }
}
