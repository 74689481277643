<div id="container">
    <app-user-image [text]="this.name" [size]="28" [fontSize]="15"></app-user-image>

    <div id="info">
        <div *ngIf="name" id="name" [title]="name">{{ name }}</div>
        <div *ngIf="email" id="email">{{ email }}</div>
        <div id="settings">
            <mat-icon>settings</mat-icon>
            <a [routerLink]="settingsRoute"> {{ text }}</a>
        </div>
    </div>
</div>
