import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LinkSummary } from 'src/app/core/interfaces/link-summary';
import { LinkSelectorService } from '../../services/link-selector.service';
import { ViewsState } from '../../store/reducers/views.reducer';
import { fromViews } from '../../store/selectors/views.selectors';

@Component({
    selector: 'app-links-selector',
    templateUrl: './links-selector.component.html'
})
export class LinksSelectorComponent implements OnInit {
    links$;
    selectedLinks$;
    isLoading$;

    links: LinkSummary[] | null = null;

    constructor(
        private store: Store<ViewsState>,
        private linkSelectorService: LinkSelectorService
    ) {
        this.isLoading$ = this.store.select(fromViews.selectLoadingLinksForSelector);
        this.selectedLinks$ = this.linkSelectorService.selectedLinks();
        this.links$ = this.linkSelectorService.allLinks();
    }

    ngOnInit(): void {
        this.linkSelectorService.loadLinks();
    }

    onToggleLink(link: LinkSummary): void {
        this.linkSelectorService.toggleLink(link.id);
    }
}
