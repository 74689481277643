import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { DocumentationFileStructureModel } from 'src/app/core/models/doc-file-structure.model';
import { fromDocumentation } from '../../../docs/store/docs.selectors';
import { map } from 'rxjs/operators';
import { getDocumentationStructure } from '../../../docs/store/actions/docs.actions';

@Component({
    selector: 'app-help-list',
    templateUrl: './help-list.component.html',
    styleUrls: ['./help-list.component.scss']
})
export class HelpListComponent implements OnInit {
    pages$: Observable<DocumentationFileStructureModel[]>;
    selectedDocPath: string | null = null;
    mouseOver = false;

    path$: Observable<string | null>;

    constructor(private store: Store) {
        this.path$ = this.store.select(fromDocumentation.selectDocQueryParam);

        this.pages$ = this.store
            .select(fromDocumentation.selectDocumentationStructure)
            .pipe(map((structure) => structure?.children ?? []));
    }

    ngOnInit(): void {
        this.store.dispatch(getDocumentationStructure());
    }

    onMouseEnter() {
        this.mouseOver = true;
    }

    onMouseOut() {
        this.mouseOver = false;
    }
}
