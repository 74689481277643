import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { OptionGroup } from '../custom-select/custom-select.component';
import {
    FormControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { FilterBase } from './filter-base';
import { CODE_NAME } from '../../../modules/list-tree-view/components/report-filters/constants';
import { Filter } from './filter';

export type FilterTypeGroup = { label: string; options: string[] };
export type Controls = { [key: string]: FilterBase<any> };

@Component({
    selector: 'app-flow-filter-builder',
    templateUrl: './flow-filter-builder.component.html',
    styleUrls: ['./flow-filter-builder.component.scss']
})
export class FlowFilterBuilderComponent implements OnInit, OnChanges {
    @Input() loading = false;
    @Input() controls: Controls = {};
    @Input() filterTypeGroups: FilterTypeGroup[] = [];
    @Input() filter?: Filter<any>;
    @Input() showDelete?: boolean;

    @Output() submit = new EventEmitter<string>();
    @Output() submitFilter = new EventEmitter<{ filter: Filter<any>; query: string }>();
    @Output() delete = new EventEmitter();

    CODE_NAME = CODE_NAME;

    filterOptions!: OptionGroup[];
    form!: UntypedFormGroup;
    currentFilterType: string | null = null;
    activeKeys: string[] = [];

    isQueryModified = false;

    constructor(private fb: UntypedFormBuilder) {}

    get isFilterSaved() {
        return this.filter?.key;
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            filterType: [null, Validators.required]
        });
        this.updateFilter();
        this.updateFilterOptionsFromTypeGroups();
        this.form.valueChanges.subscribe((value) => {
            if (value.filterType !== this.currentFilterType) {
                this.onFilterTypeChange(value.filterType);
            }
            const newQuery = this.getQueryFromControl();
            if (newQuery != this.filter?.query) {
                this.setIsQueryModified(true);
            }
        });
    }

    /**
     * Listen for changes in case the current source changes.
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges) {
        if (changes.filterTypeGroups && !changes.filterTypeGroups.isFirstChange()) {
            this.updateFilterOptionsFromTypeGroups();
        }
        if (changes.filter && !changes.filter.isFirstChange()) {
            this.updateFilter();
        }
    }

    setIsQueryModified(value: boolean) {
        this.isQueryModified = value;
    }

    /**
     * Adds the control for the filter type to the form.
     *
     * @param value The type for the filter. Note that value is a key
     * used by the controls input, and we need to have a value for it
     * for this component to properly work.
     */
    onFilterTypeChange(value: string) {
        const previousFilterType = this.currentFilterType;
        this.currentFilterType = value;

        if (previousFilterType) {
            this.activeKeys = this.activeKeys.filter(
                (item) => item !== previousFilterType
            );
            this.form.removeControl(previousFilterType);
        }
        const control = this.controls[value];
        this.activeKeys = [...this.activeKeys, value];
        this.form.addControl(
            this.currentFilterType,
            new FormControl(
                control?.initialValue ?? this.filter?.value,
                Validators.required
            )
        );
    }

    updateFilterOptionsFromTypeGroups() {
        this.filterOptions = this.filterTypeGroups
            .map((group) => ({
                ...group,
                options: group.options.map((item) => ({
                    label: this.controls[item]?.label ?? '',
                    value: item
                }))
            }))
            .filter((group) => group.options.length > 0);
    }

    updateFilter() {
        if (this.filter?.key) {
            this.onFilterTypeChange(this.filter.key);
            this.form.setValue({
                filterType: this.filter.key,
                [this.filter.key]: this.filter.value
            });
        }
    }

    onSubmit() {
        if (this.currentFilterType) {
            const query = this.getQueryFromControl();
            this.submit.emit(query);
            const filter = this.controls[this.currentFilterType].getFilterFromForm(
                this.form
            );
            this.submitFilter.emit({ filter: { ...this.filter, ...filter }, query });
        }
    }

    getQueryFromControl() {
        if (!this.currentFilterType) return '';
        const control = this.controls[this.currentFilterType];
        if (!control) return '';
        return control.getQuery(this.form);
    }

    onDelete() {
        this.delete.emit();
    }
}
