<div
    class="selector"
    [class.multiselect]="isMultiSelect"
    [class.empty]="isEmpty"
    [class.simple]="!isMultiSelect"
    [class.open]="isOpen"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    appClickOutside
    (clicked)="clickedSelector($event)"
>
    <div *ngIf="isMultiSelect" class="selector-label">
        <span class="text">Sources</span>
    </div>
    <mat-chip-list *ngIf="isMultiSelect" aria-label="Source Selection">
        <mat-chip
            *ngFor="let id of selectedLinks"
            class="chip"
            (removed)="toggleLinkById(id)"
        >
            {{ getName(id) }}
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>
        <ng-container *ngTemplateOutlet="arrow"></ng-container>
    </mat-chip-list>
    <ng-container *ngIf="!isMultiSelect">
        <span *ngIf="!selectedLink">Select link</span>
        <span
            [title]="selectedLink.drawingName"
            class="simple-select-drawing-name"
            *ngIf="selectedLink"
            >{{ selectedLink.drawingName }}</span
        >
        <ng-container *ngTemplateOutlet="arrow"></ng-container>
    </ng-container>
</div>
<ng-template #spinner>
    <app-loader size="18px"></app-loader>
</ng-template>
<mat-menu
    [class]="isMultiSelect ? 'link-selector-menu multiselect' : 'link-selector-menu'"
    [hasBackdrop]="false"
    (click)="$event.stopPropagation()"
    (keydown)="handleKeypress($event)"
    #menu="matMenu"
>
    <ng-template matMenuContent>
        <div class="search">
            <div class="search-container">
                <input
                    class="search-input"
                    type="text"
                    placeholder="Search links..."
                    matInput
                    [formControl]="searchControl"
                    (click)="$event.stopPropagation()"
                />
                <mat-icon class="search-icon">search</mat-icon>
            </div>
        </div>
        <div class="content">
            <ng-container *ngIf="!loading; else spinner">
                <ng-container *ngIf="links">
                    <div
                        class="option"
                        *ngFor="let link of filteredLinks"
                        (click)="onOptionClick($event)"
                    >
                        <input
                            *ngIf="isMultiSelect"
                            type="checkbox"
                            [checked]="checked(link.id)"
                            (change)="toggleLink(link)"
                        />
                        <app-import-list-item
                            class="item"
                            [disableHoverStyles]="true"
                            [disableHighlight]="isMultiSelect"
                            [drawingName]="link.drawingName"
                            [patternName]="link.patternTitle"
                            [message]="link.latestBuildMessage"
                            [exportedBy]="link.exportedBy"
                            [exportedFrom]="link.exportedFrom"
                            [exportedAt]="link.exportedAt"
                            [targetFramework]="link.targetFramework"
                            [attr.data-id]="link.id"
                            [disableRightBorder]="true"
                            [disableGeneralInfo]="true"
                            [selected]="
                                isMultiSelect
                                    ? checked(link.id)
                                    : selectedLink?.id === link.id
                            "
                            (clicked)="toggleLink(link)"
                        ></app-import-list-item>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-template>
</mat-menu>

<ng-template #arrow>
    <div class="arrow-wrapper">
        <div class="arrow">
            <svg viewBox="0 0 24 24" width="24px" height="20px" focusable="true">
                <path d="M7 10l5 5 5-5z" />
            </svg>
        </div>
    </div>
</ng-template>
