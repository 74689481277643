import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NodeDisableMode } from 'src/app/core/definitions/enums';
import { DEFAULT_DISABLE_INFO, DisableInfo } from 'src/app/core/interfaces/disable-info';
import { CustomIconMenuComponent } from '../custom-icon-menu/custom-icon-menu.component';

@Component({
    selector: 'app-node-actions-menu',
    templateUrl: './node-actions-menu.component.html',
    styleUrls: ['./node-actions-menu.component.scss']
})
export class NodeActionsMenuComponent {
    @Input() disableInfo: DisableInfo = DEFAULT_DISABLE_INFO;
    @Input() showDisabledOptions = false;

    @Output() deleteNode = new EventEmitter();
    @Output() disabledModeChange = new EventEmitter<DisableInfo>();

    @ViewChild(CustomIconMenuComponent) menu?: CustomIconMenuComponent;

    NodeDisableMode = NodeDisableMode;

    onDelete(event?: Event): void {
        event?.stopImmediatePropagation(); // this prevents closing the node
        this.deleteNode.emit();
        this.menu?.close();
    }

    get isNodeHiddenIcon() {
        return this.disableInfo.isNodeHidden ? 'visibility_off' : 'visibility';
    }
    get isNodeDisabledIconSvg(): string {
        switch (this.disableInfo.disableMode) {
            case NodeDisableMode.Disable:
                return 'cancel';
            case NodeDisableMode.EnableIf:
                return 'function_outline';
            case NodeDisableMode.EnableIfQty0:
                return 'null';
            case NodeDisableMode.Enable:
                return 'enable';
            default:
                return 'enable';
        }
    }

    get isNodeHidden() {
        return this.disableInfo.isNodeHidden;
    }

    get isNodeDisabled() {
        return this.disableInfo.disableMode == NodeDisableMode.Disable;
    }

    get selectedMode() {
        return this.disableInfo.disableMode;
    }

    setDisableMode(disableMode: NodeDisableMode) {
        let update: Partial<DisableInfo> = {};
        if (disableMode == NodeDisableMode.EnableIf) {
            update = {
                enabledIfErrors: [],
                enabledIfWarnings: [],
                enabledIfExpression: undefined
            };
        }
        const newDisableInfo = {
            ...this.disableInfo,
            ...update,
            disableMode: disableMode
        };
        this.menu?.close();
        this.disabledModeChange.emit(newDisableInfo);
    }

    setIsNodeHidden(value: boolean) {
        this.disabledModeChange.emit({
            ...this.disableInfo,
            isNodeHidden: value
        });
        this.menu?.close();
    }
}
