import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-one-section-layout',
    templateUrl: './one-section-layout.component.html',
    styleUrls: ['./one-section-layout.component.scss']
})
export class OneSectionLayoutComponent {
    @Input() paddingLeft?: string = undefined;

    @ContentChild('section', { static: false })
    sectionTemplateRef!: TemplateRef<any>;
}
