import {
    AfterViewInit,
    ApplicationRef,
    Directive,
    ElementRef,
    Input
} from '@angular/core';
import { ID } from '../../core/definitions/types';
import { Observable } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { IUnique } from '../../core/definitions/interfaces';

@Directive({
    selector: '[appAutoScrollToSelected]'
})
export class AutoScrollToSelectedDirective implements AfterViewInit {
    @Input('selectedId') selectedId?: ID | null = null;
    @Input('items$') items$: Observable<IUnique[]> | null = null;

    constructor(private el: ElementRef, private appRef: ApplicationRef) {}

    ngAfterViewInit(): void {
        if (this.items$) {
            this.items$
                .pipe(
                    delay(1),
                    tap(() => {
                        this.scrollIfHidden();
                    })
                )
                .subscribe();
        }
    }

    scrollIfHidden(): void {
        if (this.selectedId) {
            const { selectedElement, isElementInView } = this.getSelected();
            if (!isElementInView && selectedElement) {
                selectedElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    getSelected() {
        const selectedElement = this.el.nativeElement.querySelector(
            `[data-id='${this.selectedId}']`
        );
        const container = this.el.nativeElement;
        if (selectedElement && container) {
            const containerRect = container.getBoundingClientRect();
            const elementRect = selectedElement.getBoundingClientRect();

            const isElementInView =
                elementRect.top >= containerRect.top &&
                elementRect.bottom <= containerRect.bottom;

            return { selectedElement, isElementInView };
        }
        return { selectedElement: null, isElementInView: false };
    }
}
