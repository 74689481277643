import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, skip, take, withLatestFrom } from 'rxjs/operators';
import { ID } from 'src/app/core/definitions/types';
import { LinkSummary } from 'src/app/core/interfaces/link-summary';
import * as viewActions from '../store/actions/views.actions';
import { ViewsState } from '../store/reducers/views.reducer';
import { fromViews } from '../store/selectors/views.selectors';

@Injectable({
    providedIn: 'root'
})
export class LinkSelectorService {
    private selectedLinksSubject = new BehaviorSubject<ID[]>([]);

    constructor(private store: Store<ViewsState>) {
        this.selectedLinksSubject
            .pipe(skip(1))
            .subscribe((selected) =>
                this.store.dispatch(viewActions.sourceSelectionChange({ ids: selected }))
            );
    }

    selectedLinks(): Observable<ID[]> {
        return this.store.select(fromViews.selectSelectedSources);
    }

    selectedLinksForVersionSelector(): Observable<LinkSummary[]> {
        return this.allLinks().pipe(
            withLatestFrom(this.selectedLinks()),
            map(([links, selected]) => {
                return links.filter((link) => selected.includes(link.id));
            })
        );
    }

    allLinks(): Observable<LinkSummary[]> {
        return this.store.select(fromViews.selectLinksForSelector);
    }

    loadLinks() {
        this.store.dispatch(viewActions.loadLinks());
    }

    toggleLink(linkId: ID) {
        this.selectedLinks()
            .pipe(take(1))
            .subscribe((selectedLinks) => {
                const updatedSelectedLinks = selectedLinks.includes(linkId)
                    ? selectedLinks.filter((id) => id !== linkId)
                    : [...selectedLinks, linkId];
                this.selectedLinksSubject.next(updatedSelectedLinks);
            });
    }
}
