import { Component, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { fromRoot } from 'src/app/store';
import { snackbarWarning } from 'src/app/store/actions/root.actions';
import { forgotPasswordRequest } from '../../store/users.actions';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
    @ViewChild('emailInput') emailInputRef: ElementRef<HTMLInputElement> | undefined;

    email = '';

    loading$: Observable<boolean>;

    constructor(private store: Store) {
        this.loading$ = this.store.select(fromRoot.selectLoading);
    }

    onSubmit(): void {
        const emailInput = this.emailInputRef?.nativeElement;
        if (!emailInput?.checkValidity()) {
            this.store.dispatch(snackbarWarning({ message: 'Invalid email' }));
            return;
        }

        this.store.dispatch(forgotPasswordRequest({ email: this.email }));
    }
}
