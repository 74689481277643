<ng-container *ngIf="historyItems.length">
    <ng-container *ngIf="showMore; else collapsed">
        <ng-container *ngFor="let item of historyItems; let i = index">
            <app-version-history-timeline
                [mode]="selectedId === item.id ? 'selected' : 'unselected'"
                [position]="detectPosition(i, historyItems.length)"
            >
                <app-version-history-item
                    [versionItemTitle]="item.message"
                    [userId]="item.createdBy"
                    [exportedAt]="item.createdAt"
                    (click)="onClickItem(item.id)"
                    [selected]="selectedId === item.id"
                >
                </app-version-history-item>
            </app-version-history-timeline>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #collapsed>
    <app-version-history-timeline
        mode="show-more"
        [position]="isLastItem ? 'end' : 'start'"
    >
        <span class="show-more">
            {{ historyItems.length }} {{ historyItems.length < 2 ? 'draft' : 'drafts' }}
            <span class="btn" (click)="showMore = true">Show</span>
        </span>
    </app-version-history-timeline>
</ng-template>
