import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { AceEditorComponent } from '../ace-editor/ace-editor.component';
import { ID } from '../../../core/definitions/types';
import { AutocompleteCadData } from '../../../modules/shared-nodes/interfaces/autocomplete-cad-data';

@Component({
    selector: 'app-code-editor',
    templateUrl: './code-editor.component.html',
    styleUrls: ['./code-editor.component.scss']
})
export class CodeEditorComponent implements AfterViewInit {
    @Input() initialValue!: string;
    @Input() editorId!: ID;
    @Input() nodeAutocompleteContent!: AutocompleteCadData;
    @Input() inline?: boolean;
    @Input() error?: boolean;

    @Output() valueChange = new EventEmitter<string>();
    @Output() enter = new EventEmitter();

    @ViewChild(AceEditorComponent) editor?: AceEditorComponent;

    ngAfterViewInit(): void {
        if (this.editor) {
            // This is used by the global editor
            this.setValue(this.initialValue);
            this.resetUndoManager();
        }
    }

    get value() {
        return this.editor?.value;
    }

    setValue(value: string): void {
        this.editor?.setValue(value);
    }

    resetUndoManager(): void {
        this.editor?.resetUndoManager();
    }

    onValueChange(value: string): void {
        this.valueChange.emit(value);
    }

    clickOnReference(value: string): void {
        this.editor?.addTextInCursor(value);
    }
}
