<app-account-result-page>
    <div id="result-subtitle">
        {{ email$ | async }}
    </div>
    <div id="result-text">We just processed your request.</div>
    <div id="result-image">
        <img
            src="/assets/forgot-password-email-sent.svg"
            alt="forgot password email sent success logo"
        />
    </div>
    <div>
        <div class="result-info">
            <span class="bold">If your email is valid</span>, you will receive an email to
            change your password shortly.
        </div>
        <div class="result-info">
            <span class="bold">If you don't receive an email</span>, don't forget to look
            into your junk mail (you know, just in case).
        </div>
        <div class="result-info">
            <span class="bold">If you REALLY don't receive any email from WorkRoads</span
            >, talk to your system administrator.
        </div>
    </div>
</app-account-result-page>
