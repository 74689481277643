<div
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    (menuClosed)="onMenuClose()"
    (menuOpened)="onMenuOpen()"
>
    <ng-container
        *ngTemplateOutlet="template; context: { $implicit: expanded }"
        (click)="menuTrigger.openMenu()"
    ></ng-container>
</div>
<mat-menu #menu="matMenu" class="menu-container">
    <ng-content></ng-content>
</mat-menu>
