import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseObject } from '../definitions/classes';
import { HttpOptions } from '../definitions/types';
import { ConnectionConfig } from '../interfaces/connection.config';
import { DEFAULT_CONNECTION_CONFIG } from '../constants/connection.apiservice.contant';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private PROTOCOL = '';
    private DOMAIN = '';
    private API_VERSION = '';
    private BASE_URL = '';

    constructor(
        private _http: HttpClient,
        @Inject(DEFAULT_CONNECTION_CONFIG) connectionConfig: ConnectionConfig
    ) {
        this.setConnectionConfig(connectionConfig);
    }

    get<T>(url: string, params?: HttpParams): Observable<ResponseObject<T>> {
        return this._http.get<ResponseObject<T>>(
            this.getFullUrl(url),
            this.getOptions(params)
        );
    }

    post<T>(
        url: string,
        body?: any,
        params?: HttpParams,
        responseType = 'json'
    ): Observable<ResponseObject<T>> {
        return this._http.post<ResponseObject<T>>(
            this.getFullUrl(url),
            body,
            this.getOptions(params, responseType)
        );
    }

    patch<T>(
        url: string,
        body?: any,
        params?: HttpParams
    ): Observable<ResponseObject<T>> {
        return this._http.patch<ResponseObject<T>>(
            this.getFullUrl(`${url}`),
            body,
            this.getOptions(params)
        );
    }

    delete<T>(url: string, params?: HttpParams): Observable<ResponseObject<T>> {
        return this._http.delete<ResponseObject<T>>(
            this.getFullUrl(`${url}`),
            this.getOptions(params)
        );
    }

    deleteWithBody<T>(
        url: string,
        params: HttpParams,
        body: any
    ): Observable<ResponseObject<T>> {
        return this._http.delete<ResponseObject<T>>(this.getFullUrl(`${url}`), {
            ...this.getOptions(params),
            body
        });
    }

    head<T>(url: string, params?: HttpParams): Observable<ResponseObject<T>> {
        return this._http.head<ResponseObject<T>>(
            this.getFullUrl(url),
            this.getOptions(params)
        );
    }

    private getFullUrl(url: string): string {
        return `${this.BASE_URL}/${url}`;
    }

    /**
     *
     * @param params
     * @returns
     * Build options given an array of HttpParams.
     *
     * 'Content-Type': 'application/json' is always set
     */
    private getOptions(params?: HttpParams, responseType = 'json'): HttpOptions {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params: params,
            responseType
        };
        return options;
    }

    public setConnectionConfig(config: ConnectionConfig) {
        this.PROTOCOL = config.protocol;
        this.DOMAIN = config.domain;
        this.API_VERSION = config.apiVersion;
        this.BASE_URL = `${config.protocol}${config.domain}${config.apiVersion}`;
    }
}
