export function insertInList<T>(list: T[], item: T, index?: number): T[] {
    if (index == null) {
        index = list.length;
    }
    return [...list.slice(0, index), item, ...list.slice(index)];
}

/**
 * This function updates an item in a list
 * by merging the existing item with a complete or partial update
 *
 * @param list
 * @param updatedItem
 * @param index
 * @param comparer
 */
export function updateInList<T>(
    list: T[],
    updatedItem: Partial<T>,
    { index, comparer }: { index?: number; comparer?: (item: T) => boolean }
): T[] {
    if (comparer) {
        index = list.findIndex((item) => comparer(item));
    }
    if (index == null)
        throw new Error(
            'Trying to update the object but it was not found. Report this bug'
        );

    // IF NEEDED, WE CAN ADD A LAMBDA TO GET THE UPDATED ITEM
    return [
        ...list.slice(0, index),
        { ...list[index], ...updatedItem },
        ...list.slice(index + 1)
    ];
}

export function removeFromList<T>(
    list: T[],
    { index, comparer }: { index?: number; comparer?: (item: T) => boolean }
): T[] {
    if (comparer) {
        return list.filter((item) => !comparer(item));
    }
    if (index == null)
        throw new Error(
            'Trying to remove the object but it was not found. Report this bug'
        );
    return [...list.slice(0, index), ...list.slice(index + 1)];
}
