import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const INPUT_CONTROL_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomAutosizeInputComponent),
    multi: true
};

@Component({
    selector: 'app-custom-autosize-input',
    providers: [INPUT_CONTROL_ACCESSOR],
    templateUrl: './custom-autosize-input.component.html',
    styleUrls: ['./custom-autosize-input.component.scss']
})
export class CustomAutosizeInputComponent implements ControlValueAccessor, OnInit {
    @Input() placeholder = '';
    @Input() value = '';
    @Input() cursorStyle = '';
    @Input() icon?: string;
    @Input() inputStyle: 'bold' | 'normal' = 'normal';
    @Input() required = false;
    @Input() initWithFocus = false;
    @Input() disableNewInstance = false;
    @Input() topIcon?: string;
    @Input() topIconSvg?: string;
    @Input() parenthesis = false;
    @Output() valueChange = new EventEmitter<string>();
    @Output() onFocusOutField = new EventEmitter();
    @ViewChild('smallField', { static: false }) smallField!: ElementRef;

    _placeholder = '';
    isNewInstance = true;

    ngOnInit(): void {
        this._placeholder = this.placeholder;
        if (this.disableNewInstance) {
            this.disableIsNewInstance();
        }
    }

    ngAfterViewInit(): void {
        this.setFocus();
    }

    setFocus(): void {
        if (this.initWithFocus && !this.value) {
            this.smallField?.nativeElement?.focus();
        }
    }

    onClick(event: Event): void {
        this._placeholder = '';
        this.disableIsNewInstance();
        event.stopPropagation();
    }

    private _onChange?: any;
    private _onTouched?: any;

    onChange(value: any): void {
        this.value = value;
        this.valueChange.emit(value);
        this._onChange?.(value);
        this._onTouched?.();
        this.setFocus();
    }

    onFocusOut() {
        this._placeholder = this.placeholder;
        this.onFocusOutField.emit();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    writeValue(obj: any): void {
        this.value = obj;
        this.setFocus();
    }

    onFocus() {
        if (!this.value) {
            this.value = '';
        }
    }

    onBlur() {
        if (!this.value.trim()) {
            this.value = '';
        }
        this.smallField?.nativeElement?.blur();
    }

    private disableIsNewInstance() {
        this.isNewInstance = false;
    }
}
