import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GetInitialsService {
    getInitials(value: string): string {
        if (value === '') return value;
        const v = value.split(' ');
        if (v.length === 1) return value.slice(0, 2).toUpperCase();
        return v
            .map((n) => n[0])
            .slice(0, 2)
            .join('')
            .toUpperCase();
    }
}
