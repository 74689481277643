<app-one-section-layout>
    <ng-template #section>
        <section>
            <form class="idable">
                <fieldset>
                    <label for="name">NAME</label>
                    <input
                        #input
                        id="name"
                        type="text"
                        required
                        minlength="1"
                        [(ngModel)]="name"
                    />
                    <span>Organization's name must contain at least 1 character.</span>
                </fieldset>
            </form>
            <div>
                <app-custom-button
                    color="primary"
                    text="Discard"
                    class="small"
                    type="stroked"
                    size="small"
                    (buttonClick)="onDiscard()"
                >
                </app-custom-button>
                <app-custom-button
                    #submit
                    color="primary"
                    text="Save Changes"
                    class="small"
                    type="flat"
                    size="small"
                    [disabled]="currentOrganization?.name === name || !formIsValid"
                    (buttonClick)="onSubmit()"
                >
                </app-custom-button>
            </div>
        </section>
    </ng-template>
</app-one-section-layout>
