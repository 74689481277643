<div
    *ngIf="{ path: path$ | async } as view"
    class="body"
    [style.--scroll-color]="mouseOver ? '#B8B8B8' : 'transparent'"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseOut()"
    appAutoScrollToSelected
    appScrollShadows
    [selectedId]="view.path"
    [items$]="pages$"
>
    <div class="organization-list">
        <app-help-item
            *ngFor="let page of pages$ | async"
            [item]="page"
            [selectedPath]="view.path"
        ></app-help-item>
    </div>
</div>
