import { Component, Input } from '@angular/core';
import { UserMenuComponent } from '../user-menu.component';

@Component({
    selector: 'app-user-menu-section',
    templateUrl: './user-menu-section.component.html',
    styleUrls: ['./user-menu-section.component.scss']
})
export class UserMenuSectionComponent {
    @Input() header?: string = '';

    constructor(private parent: UserMenuComponent) {}
}
