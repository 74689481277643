import { Pipe, PipeTransform } from '@angular/core';
import { TARGET_SOFTWARE_ICONS } from 'src/app/core/constants/software-info';
import { DrawingTargetFramework } from 'src/app/core/definitions/enums';

@Pipe({
    name: 'frameworkImage'
})
export class FrameworkImagePipe implements PipeTransform {
    transform(value: DrawingTargetFramework, ...args: unknown[]): string {
        return value ? TARGET_SOFTWARE_ICONS[value].image : '';
    }
}
