import { createAction, props } from '@ngrx/store';

export const forgotPasswordRequest = createAction(
    '[Users] Forgot password request',
    props<{ email: string }>()
);
export const forgotPasswordSuccess = createAction(
    '[Users] Forgot password success',
    props<{ message: string; userEmail: string }>()
);
export const forgotPasswordFailure = createAction(
    '[Users] Forgot password failure',
    props<{ message: string }>()
);

export const resetPasswordRequest = createAction(
    '[Users] Reset password request',
    props<{ email: string; password: string; confirmPassword: string }>()
);
export const resetPasswordSuccess = createAction(
    '[Users] Reset password success',
    props<{ message: string }>()
);
export const resetPasswordFailure = createAction(
    '[Users] Reset password failure',
    props<{ message: string }>()
);

export const userActivationRequest = createAction(
    '[Users] User activation request',
    props<{
        confirmPassword: string;
        name: string;
        password: string;
        surnames: string;
    }>()
);
export const userActivationSuccess = createAction(
    '[Users] User activation success',
    props<{ message: string }>()
);
export const userActivationFailure = createAction(
    '[Users] User activation failure',
    props<{ message: string }>()
);
export const validateTokenFromEmail = createAction(
    '[Users] Validate token comming from email'
);

export const validateTokenFromEmailSuccess = createAction(
    '[Users] Validate token success'
);

export const validateTokenFromEmailFailure = createAction(
    '[Users] Validate token failure'
);
