import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { fromRoot } from 'src/app/store';

@Injectable({
    providedIn: 'root'
})
export class AllowPermissionGuard implements CanActivate {
    constructor(private router: Router, private store: Store) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this.store.select(fromRoot.selectUserPermissions).pipe(
            map((userPermission) => {
                if (
                    (state.url.includes('/elements') &&
                        !userPermission.isAbleToViewElements) ||
                    (state.url.includes('/patterns') &&
                        !userPermission.isAbleToViewPatterns) ||
                    (state.url.includes('/links') && !userPermission.isAbleToViewLinks) ||
                    (state.url.includes('/organization/information') &&
                        !userPermission.isAbleToUpdateOrganizationInfo)
                ) {
                    this.router.navigate(['/app']);
                    return false;
                }

                return true;
            })
        );
    }
}
