import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
    selector: 'app-list-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent {
    @Input() selected? = false;
    @Input() title? = '';
    @Input() subtitle? = '';
    @Input() matIcon = '';
    @Input() mainIcon = '';
    @Input() isDraft = false;
    @Input() stretchToBorder = true;
    @Input() height = '40px';
    @Input() loading = false;

    @Output() click = new EventEmitter();

    @HostBinding('class.selected') get isSelected(): boolean | undefined {
        return this.selected;
    }

    onClick(event: Event): void {
        this.click.emit();
        event.stopPropagation();
    }
}
