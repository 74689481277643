import { v4 as uuid } from 'uuid';
import { IUnique } from '../definitions/interfaces';
import { ViewSummary } from '../interfaces/view-summary.interface';
import { BaseModel } from './base.model';

export const INITIAL_VIEW_SUMMARY = {
    id: uuid(),
    title: '',
    description: ''
};

export class ViewSummaryModel extends BaseModel implements ViewSummary {
    title!: string;
    description!: string;
    constructor(obj?: IUnique) {
        super(obj);
    }

    protected initialize(obj: any): void {
        if (obj) {
            this.title = (obj.title as string) ?? INITIAL_VIEW_SUMMARY.title;
            this.description =
                (obj.description as string) ?? INITIAL_VIEW_SUMMARY.description;
        }
    }
}
