import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HelpRoutingService } from '../../../services/help-routing.service';
import { DocumentationFileStructureModel } from 'src/app/core/models/doc-file-structure.model';
import { getFirstDoc } from '../helpers/helper';

@Component({
    selector: 'app-help-item',
    templateUrl: './help-item.component.html',
    styleUrls: ['./help-item.component.scss']
})
export class HelpItemComponent implements OnChanges {
    @Input() item!: DocumentationFileStructureModel;
    @Input() isSelected = false;
    @Input() selectedPath!: string | null;

    isCategorySelected = false;

    constructor(private helpRoutingService: HelpRoutingService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedPath) {
            this.isCategorySelected = this._isCategorySelected(
                this.selectedPath,
                this.item
            );
        }
    }

    _isCategorySelected(path: string | null, category: DocumentationFileStructureModel) {
        if (path == null || category.path == null) return false;
        return path.includes(category.path);
    }

    _isItemSelected(path: string | null, item: DocumentationFileStructureModel) {
        if (path == null) return false;
        return item.path === path;
    }

    onClickItem() {
        if (!this.item.path) return;
        this.helpRoutingService.navigateToDetail(this.item.path);
    }

    onClickCategory() {
        this.helpRoutingService.navigateToDetail(getFirstDoc(this.item.children!));
    }
}
