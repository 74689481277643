import { IUnique } from '../definitions/interfaces';
import { BaseModel } from './base.model';
import { FieldDefinitionModel } from './field-definition.model';
import { ElementDefinitionDetail } from '../interfaces/element-definition-detail.interface';
import { Status } from '../definitions/enums';
import { ID } from '../definitions/types';

const INITIAL = {
    quantity: 0,
    name: '',
    description: '',
    fieldDefinitions: [] as FieldDefinitionModel[],
    elementDefinitions: [] as ElementDefinitionModel[],
    admitChildren: true,
    isCountable: true,
    unicity: false,
    status: Status.Draft,
    createdBy: '',
    message: '',
    isMostRecent: false
};

export class ElementDefinitionModel extends BaseModel implements ElementDefinitionDetail {
    historyId?: ID;
    quantity!: number;
    name!: string;
    description!: string;
    fieldDefinitions!: FieldDefinitionModel[];
    elementDefinitions!: ElementDefinitionModel[];
    admitsChildren!: boolean;
    isCountable!: boolean;
    codeName!: string;
    unicity!: boolean;
    status!: Status;
    createdAt!: Date;
    createdBy!: string;
    message!: string;
    previousVersionId?: ID;
    isMostRecent?: boolean;

    constructor(obj: IUnique) {
        super(obj);
    }

    protected initialize(obj: Partial<ElementDefinitionDetail>): void {
        if (obj) {
            this.historyId = obj.historyId;
            this.name = obj.name ?? INITIAL.name;
            this.quantity = obj.quantity ?? INITIAL.quantity;
            this.description = obj.description ?? INITIAL.description;
            this.admitsChildren = obj.admitsChildren ?? INITIAL.admitChildren;
            this.isCountable = obj.isCountable ?? INITIAL.isCountable;
            this.fieldDefinitions = (
                (obj.fieldDefinitions ?? INITIAL.fieldDefinitions) as []
            ).map((item) => new FieldDefinitionModel(item));
            this.elementDefinitions = (
                (obj.elementDefinitions ?? INITIAL.elementDefinitions) as []
            ).map((item) => new ElementDefinitionModel(item));
            this.codeName = obj.codeName!;
            this.unicity = obj.unicity ?? INITIAL.unicity;
            this.status = obj.status ?? INITIAL.status;
            this.createdAt = obj.createdAt ?? new Date(Date.now());
            this.createdBy = obj.createdBy ?? INITIAL.createdBy;
            this.message = obj.message ?? INITIAL.message;
            this.previousVersionId = obj.previousVersionId;
            this.isMostRecent = obj.isMostRecent ?? INITIAL.isMostRecent;
        }
    }
}
