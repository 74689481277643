<ng-container *ngIf="!initialsOnly">
    <ng-container *ngIf="!loadingOrError; else loading">{{ displayName }}</ng-container>
</ng-container>
<ng-container *ngIf="initialsOnly">
    {{ initials }}
</ng-container>

<ng-template #loading>
    <ngx-skeleton-loader
        id="loader"
        count="1"
        appearance="line"
        [theme]="{
            width: '130px'
        }"
    >
    </ngx-skeleton-loader>
</ng-template>
