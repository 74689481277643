import { Pipe, PipeTransform } from '@angular/core';
import { replaceNewlinesWithInvisibleToken } from '../helpers/string.helpers';

@Pipe({
    name: 'noNewlines'
})
export class NoNewlinesPipe implements PipeTransform {
    transform(value: string, ...args: unknown[]): unknown {
        return value ? replaceNewlinesWithInvisibleToken(value) : '';
    }
}
