import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { Route, ROUTES } from '../../../../core/constants/feature';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { fromRoot, rootActions } from 'src/app/store';
import { UserPermission } from 'src/app/core/definitions/interfaces';
import { MenuTab } from 'src/app/shared/models/menu-tab.model';
import { takeUntil } from 'rxjs/operators';
import { navigateToPatternList } from '../../../patterns/store/actions/patterns.action';
import { navigateToElementDefinitionList } from '../../../elements/store/actions/element-definitions.action';
import { navigateToViewsList } from 'src/app/modules/views/store/actions/views.actions';

const { elements, patterns, organization, views, personal, help } = ROUTES;

@Component({
    selector: 'app-side-menu-content',
    templateUrl: './side-menu-content.component.html',
    styleUrls: ['./side-menu-content.component.scss']
})
export class SideMenuContentComponent implements OnInit, OnDestroy {
    @Input() current: Route | null = null;
    @Input() collapsedMode = false;
    @Output() navigate = new EventEmitter();
    subscriptions: Subscription[] = [];

    userPermission$!: Observable<UserPermission>;

    /**
     * To add a new route we need to:
     *
     * 1. Add a way to check if we are in the current route
     * 2. Update navigateByActions (we need this in order to reset states).
     * 3. Update the selectCurrentFeature selector
     */
    routes$!: Observable<MenuTab[]>;
    onDestroy$ = new Subject<boolean>();

    downloadTab = {
        id: 'download',
        title: 'Download Hub',
        icon: 'download',
        isSvgIcon: false,
        routerLink: '',
        selected: false
    };
    helpTab = help;

    constructor(private router: Router, private store: Store) {
        this.store
            .select(fromRoot.selectHubUrlPermission)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((url) => {
                this.downloadTab = {
                    ...this.downloadTab,
                    routerLink: url ?? ''
                };
            });
        this.userPermission$ = this.store.select(fromRoot.selectUserPermissions);
        this.routes$ = this.store.select(fromRoot.routesByPermissions);
    }

    ngOnInit(): void {
        this.store.dispatch(rootActions.getLatestHubUrl());
    }

    downloadClicked() {
        this.store.dispatch(rootActions.downloadHub());
    }

    onNavigation(route?: MenuTab, navigateByActions?: boolean) {
        if (navigateByActions && route) {
            this.navigateByActions(route);
        }
        this.navigate.emit();
    }

    navigateToRoot() {
        this.router.navigate(['/']);
    }

    get isPatterns() {
        return this.current === Route.patterns;
    }

    get isElements() {
        return this.current === Route.elements;
    }

    get isViews() {
        return this.current === Route.views;
    }

    get isRoot() {
        return this.current === Route.root;
    }

    get isOrganization() {
        return this.current === Route.organization;
    }

    get isPersonal() {
        return this.current === Route.personal;
    }

    get isHelp() {
        return this.current === Route.help;
    }

    // YOU MUST ADD THIS MAPPING IN root.selectors.ts --> selectCurrentFeature
    get currentRoute(): MenuTab | undefined {
        switch (this.current) {
            case Route.patterns:
                return patterns;
            case Route.elements:
                return elements;
            case Route.organization:
                return organization;
            case Route.views:
                return views;
            case Route.personal:
                return personal;
            case Route.help:
                return help;
            default:
                return undefined;
        }
    }

    navigateByActions(route: MenuTab) {
        switch (route.id) {
            case Route.views:
                return this.store.dispatch(navigateToViewsList());
            case Route.patterns:
                return this.store.dispatch(navigateToPatternList());
            case Route.elements:
                return this.store.dispatch(navigateToElementDefinitionList());
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.unsubscribe();
    }
}
