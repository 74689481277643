import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class HelpRoutingService {
    constructor(private router: Router) {}

    navigateToList() {
        this.router.navigate(['app/help']);
    }

    navigateToDetail(path: string) {
        this.router.navigate([`app/help/detail`], {
            queryParams: { doc: path }
        });
    }
}
