import { Component } from '@angular/core';
import { Action } from '../../interfaces/action';

@Component({
    selector: 'app-user-list-invite',
    templateUrl: './user-list-invite.component.html',
    styleUrls: ['./user-list-invite.component.scss']
})
export class UserListInviteComponent {
    MENU_ACTIONS = [
        {
            icon: 'delete',
            emitEvent: () => alert('si'),
            label: 'Delete pattern',
            style: {
                color: 'var(--remove)'
            }
        }
    ] as Action[];
}
