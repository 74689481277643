import { ReportView } from 'src/app/core/definitions/enums';
import { ReportDefinition } from '../../list-tree-view/interfaces/report-definition.interface';
import { NULL_GROUP_BY } from '../interfaces/group-by.interface';

export const DEFAULT_REPORT_DEFINITION_LIST: ReportDefinition = {
    columns: [],
    filters: [],
    order: -1,
    name: '',
    id: '',
    reportView: ReportView.Table,
    groupBy: NULL_GROUP_BY,
    hiddenColumns: []
};

export const DEFAULT_REPORT_DEFINITION_TREE: ReportDefinition = {
    ...DEFAULT_REPORT_DEFINITION_LIST,
    order: 0
};
