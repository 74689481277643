import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { DeviceService } from '../../../../shared/services/device.service';
import { DeviceType } from '../../../../shared/enums/device-type.enum';
import { Route } from '../../../../core/constants/feature';
import { Store } from '@ngrx/store';
import { fromRoot } from '../../../../store';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnDestroy {
    @Output() headerTabClick = new EventEmitter();
    isMenuOpen = false;
    expanded = false;
    prevScrollpos = 0;
    keepShowToolbarState = true;

    isMobile = this.deviceService.isMobile();
    isTablet = this.deviceService.isTablet();

    isComputer = false;

    currentRoute$: Observable<Route>;
    ROOT_ROUTE = Route.root;

    subscriptions: Subscription[] = [];

    constructor(
        private deviceService: DeviceService,
        private router: Router,
        private store: Store
    ) {
        this.currentRoute$ = this.store.select(fromRoot.selectCurrentFeature);
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.deviceService.currentDevice().subscribe((deviceType: DeviceType) => {
                this.isMobile = deviceType == DeviceType.MOBILE;
                this.isTablet = deviceType == DeviceType.TABLET;
                this.isComputer = deviceType == DeviceType.COMPUTER;
            })
        );
    }

    toggleExpanded(value: boolean): void {
        this.expanded = value;
    }

    onScroll(event: Event): boolean {
        // only for mobile version
        if (!this.isMobile) return false;

        const currentScrollPos = (event.target as Element).scrollTop;

        this.prevScrollpos = currentScrollPos;

        return false;
    }

    toggleIsMenuOpened(value: boolean) {
        this.isMenuOpen = value;
    }

    onNavigation() {
        this.toggleExpanded(false);
        this.toggleIsMenuOpened(false);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}
