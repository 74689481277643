import * as ace from 'ace-builds';
import 'ace-builds/src-noconflict/theme-monokai'; // import an existing theme as a base
import css from './flow-theme.css';

ace.define(
    'ace/theme/flow_theme',
    ['require', 'exports', 'module', 'ace/lib/dom'],
    function (require, exports, module) {
        exports.isDark = false; // Set to 'true' for a dark theme, 'false' for a light theme
        exports.cssClass = 'ace-flow-theme';
        exports.cssText = css;
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const dom = require('ace/lib/dom');
        dom.importCssString(exports.cssText, exports.cssClass);
    }
);
