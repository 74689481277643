<article [class.selected]="opened" [class.disabled]="disabled">
    <div id="icons-wrapper">
        <nav>
            <ng-content></ng-content>
        </nav>
    </div>
    <button (click)="onToggle($event)" (mousedown)="$event.stopImmediatePropagation()">
        <mat-icon
            *ngIf="!overrideIcon || opened"
            class="settings-icon"
            svgIcon="settings-outline"
        ></mat-icon>
        <mat-icon *ngIf="overrideIcon && !opened" class="settings-icon-override">
            {{ overrideIcon }}
        </mat-icon>
    </button>
</article>
