<div
    (click)="onClick()"
    class="link-item-container"
    [class.selected]="selected"
    [class.disable-right-border]="disableRightBorder"
    [class.disable-highlight]="disableHighlight"
    [class.hover-styles]="!disableHoverStyles"
>
    <div class="drawing-name" [class.selected]="selected" title="{{ drawingName }}">
        {{ drawingName }}
    </div>
    <div class="pattern">
        <div class="name-and-icon">
            <mat-icon class="pattern-icon" svgIcon="patterns"></mat-icon>
            <div class="pattern-name">{{ patternName }}</div>
        </div>
        <img
            *ngIf="!selected"
            [src]="frameworkConversion.image"
            alt="frameworkConversion.name"
        />
        <img
            *ngIf="selected"
            [src]="frameworkConversion.image_transparent"
            alt="frameworkConversion.name"
        />
    </div>
    <div
        class="link-general-info"
        *ngIf="selected && !disableGeneralInfo; else userImage"
    >
        <mat-icon
            class="separator"
            [class.selected]="selected"
            svgIcon="separator"
        ></mat-icon>
        <div class="build-info">
            <div class="last-build-message">{{ message }}</div>
            <div class="user-info">
                <mat-icon svgIcon="person-outline"></mat-icon>
                <div class="user-info-text">
                    <app-user-name [id]="exportedBy"></app-user-name>,
                    <span class="time-past">{{ exportedAt | customDate }}</span>
                </div>
            </div>
            <div class="user-pc">
                <mat-icon svgIcon="computer"></mat-icon>
                <div class="computer-name">{{ exportedFrom }}</div>
            </div>
        </div>
    </div>
    <ng-template #userImage>
        <div class="user-image-name">
            <app-user-image [initialsWithContent]="true" [size]="20" [fontSize]="10">
                <app-user-name [id]="exportedBy" [initialsOnly]="true"></app-user-name>
            </app-user-image>
        </div>
    </ng-template>
</div>
