import { createSelector } from '@ngrx/store';
import { RoleInfoInterface } from 'src/app/core/interfaces/roles-info.interface';
import { ADMINISTRATIVE_ROLES } from '../contants/role-contants';
import { RoleEnum } from '../enums/role-enum';
import {
    getCompleteRoles,
    groupUsersByEmailConfirmed,
    isSupportUser
} from '../organization.functions';
import { organizationFeature } from './organization.reducers';

const { selectRoles, selectUsers, selectLoading } = organizationFeature;

const selectAdministrativeRole = createSelector(selectRoles, (roles) => {
    return roles.filter(
        (r) =>
            ADMINISTRATIVE_ROLES.includes(r.name) &&
            r.name !== RoleEnum[RoleEnum.User] &&
            r.name !== RoleEnum[RoleEnum.Support]
    );
});

const selectUserRoles = createSelector(selectRoles, (roles) => {
    return roles.filter(
        (r) =>
            !ADMINISTRATIVE_ROLES.includes(r.name) &&
            r.name !== RoleEnum[RoleEnum.User] &&
            r.name !== RoleEnum[RoleEnum.Support]
    );
});

const selectUserRoleOptions = createSelector(selectUserRoles, (roles) => {
    return roles.map((role: RoleInfoInterface) => getCompleteRoles(role));
});

const selectAdministrativeRoleOptions = createSelector(
    selectAdministrativeRole,
    (roles) => {
        return roles.map((role: RoleInfoInterface) => getCompleteRoles(role));
    }
);

const selectNormalUsers = createSelector(selectUsers, selectRoles, (users, roles) => {
    if (!users) return [];
    const normalUsers = users.filter((u) => !isSupportUser(u.email));
    return groupUsersByEmailConfirmed(normalUsers, roles);
});

const selectSupportUsers = createSelector(selectUsers, selectRoles, (users, roles) => {
    if (!users) return [];
    const supportUsers = users.filter((u) => isSupportUser(u.email));
    return groupUsersByEmailConfirmed(supportUsers, roles);
});

export const fromOrganization = {
    selectLoading,
    selectRoles,
    selectUsers,
    selectAdministrativeRole,
    selectUserRoles,
    selectAdministrativeRoleOptions,
    selectUserRoleOptions,
    selectSupportUsers,
    selectNormalUsers
};
