<app-account-result-page>
    <div id="result-text">
        There is a problem with the request :( Please
        <span class="bold">try again later</span>.
    </div>
    <div id="result-image">
        <img src="/assets/error-page-logo.svg" alt="error page logo" />
    </div>
    <div>
        <app-custom-button
            text="Go Back"
            color="primary"
            type="raised"
            size="large"
            (buttonClick)="onButtonClick()"
        >
        </app-custom-button>
    </div>
</app-account-result-page>
