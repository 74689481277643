<div id="dialog">
    <div>
        <h1 mat-dialog-title>Are you sure?</h1>
        <div mat-dialog-content>
            <p>
                Do you want to delete <b>{{ data.message }}</b
                >?
            </p>
        </div>

        <ng-container *ngIf="data.data">
            <div class="drawing-name">{{ data.data.drawingName }}</div>
            <div class="pattern-name-delete">
                <mat-icon svgIcon="patterns"></mat-icon>
                {{ data.data.patternName }}
            </div>
            <div class="user-info-delete">
                <mat-icon svgIcon="person-outline"></mat-icon>

                <app-user-name [id]="data.data.exportedBy"></app-user-name>
                , {{ data.data.exportedFrom }}
            </div>
        </ng-container>
        <div class="buttons-container" mat-dialog-actions>
            <app-custom-button
                type="stroked"
                text="Yes"
                (buttonClick)="onAccept()"
            ></app-custom-button>
            <app-custom-button
                color="primary"
                type="flat"
                text="No"
                (buttonClick)="onDiscard()"
            ></app-custom-button>
        </div>
    </div>
</div>
