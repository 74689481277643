<div *ngIf="isRoot">
    <app-menu-tab
        *ngFor="let route of routes$ | async"
        [menuExpanded]="route.selected"
        [menuTab]="route"
        (click)="onNavigation(route, true)"
    ></app-menu-tab>
    <div class="separator"></div>
    <app-menu-tab
        [menuTab]="helpTab"
        [menuExpanded]="helpTab.selected"
        (click)="onNavigation()"
    ></app-menu-tab>
    <div class="separator"></div>
    <ng-container>
        <app-menu-tab
            [menuTab]="downloadTab"
            [directLink]="true"
            (click)="downloadClicked()"
        ></app-menu-tab>
    </ng-container>
    <app-user-setting></app-user-setting>
</div>

<div class="content" *ngIf="!isRoot">
    <div class="content-grid">
        <div class="header">
            <app-custom-button
                [text]="collapsedMode ? undefined : 'Back'"
                icon="arrow_back"
                color="accent"
                type="link"
                *ngIf="!isRoot"
                (buttonClick)="navigateToRoot()"
                class="back-button"
                [buttonContainer]="true"
                [class.justify-center]="collapsedMode"
            ></app-custom-button>
            <!-- 
                Patterns has a customized menutab, if customizations are not needed,
                the following code can be used so we dont need to repeat the menu-tab for each route
            -->
            <app-menu-tab
                *ngIf="currentRoute && !isPatterns"
                [menuExpanded]="currentRoute.selected"
                [menuTab]="currentRoute"
                [lightMode]="true"
                (click)="onNavigation(currentRoute, true)"
            >
            </app-menu-tab>
        </div>
        <ng-container *ngIf="userPermission$ | async as permissions">
            <app-pattern-list
                *ngIf="permissions.isAbleToViewPatterns && isPatterns"
                (navigate)="onNavigation(currentRoute, true)"
            ></app-pattern-list>
            <app-element-definition-list
                *ngIf="permissions.isAbleToViewElements && isElements"
            ></app-element-definition-list>
            <app-views-list
                *ngIf="permissions.isAbleToViewLinks && isViews"
            ></app-views-list>
        </ng-container>

        <app-personal *ngIf="isPersonal"></app-personal>
        <app-organization *ngIf="isOrganization"></app-organization>
        <app-help-list *ngIf="isHelp"></app-help-list>
    </div>
</div>
