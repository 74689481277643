import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import {
    ACCOUNT_ACTIVATED_SUCCESSFULLY_MESSAGE,
    EMAIL_SUCCESSFULLY_SENT,
    GENERAL_ERROR_MESSAGE,
    PASSWORD_CHANGED_MESSAGE
} from 'src/app/core/constants/feature';
import {
    AccountResourceService,
    AccountResponseInterface
} from 'src/app/core/resources/account-resource-service/account-resource.service';
import { fromRoot, rootActions } from 'src/app/store';
import * as UsersActions from './users.actions';

@Injectable()
export class UsersEffects {
    constructor(
        private accountService: AccountResourceService,
        private actions$: Actions,
        private router: Router,
        private store: Store
    ) {}

    //--------------------------- FORGOT PASSWORD -------------------------------

    forgotPasswordRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UsersActions.forgotPasswordRequest),
            tap(() => this.store.dispatch(rootActions.setLoading({ loading: true }))),
            mergeMap(({ email }) =>
                this.accountService.forgotPassword({ email }).pipe(
                    map((result: AccountResponseInterface) =>
                        UsersActions.forgotPasswordSuccess({
                            message: result.success
                                ? EMAIL_SUCCESSFULLY_SENT
                                : result.errorMessage,
                            userEmail: email
                        })
                    ),
                    catchError(() =>
                        of(
                            UsersActions.forgotPasswordFailure({
                                message: GENERAL_ERROR_MESSAGE
                            })
                        )
                    )
                )
            )
        )
    );

    forgotPasswordSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UsersActions.forgotPasswordSuccess),
                tap(() =>
                    this.store.dispatch(rootActions.setLoading({ loading: false }))
                ),
                tap(({ userEmail }) => {
                    return this.router.navigate([
                        `/users/forgot-password/success/${userEmail}`
                    ]);
                })
            ),
        { dispatch: false }
    );

    forgotPasswordFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UsersActions.forgotPasswordFailure),
                tap(() =>
                    this.store.dispatch(rootActions.setLoading({ loading: false }))
                ),
                tap(() => this.router.navigate(['/error']))
            ),
        { dispatch: false }
    );

    //--------------------------- RESET PASSWORD -------------------------------

    resetPasswordRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UsersActions.resetPasswordRequest),
            concatLatestFrom(() => this.store.select(fromRoot.selectCodeAndUserFromUrl)),
            tap(() => this.store.dispatch(rootActions.setLoading({ loading: true }))),
            mergeMap(([{ email, password, confirmPassword }, userCode]) =>
                this.accountService.resetPassword({
                    email,
                    password,
                    confirmPassword,
                    code: userCode.code
                })
            ),
            map((result) =>
                !result.success
                    ? UsersActions.resetPasswordFailure({ message: result.errorMessage })
                    : UsersActions.resetPasswordSuccess({
                          message: PASSWORD_CHANGED_MESSAGE
                      })
            ),
            catchError(() =>
                of(UsersActions.resetPasswordFailure({ message: GENERAL_ERROR_MESSAGE }))
            )
        )
    );

    resetPasswordSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UsersActions.resetPasswordSuccess),
                tap(() =>
                    this.store.dispatch(rootActions.setLoading({ loading: false }))
                ),
                tap(() => this.router.navigate(['/users/reset-password/success']))
            ),
        { dispatch: false }
    );

    resetPasswordFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UsersActions.resetPasswordFailure),
                tap(() =>
                    this.store.dispatch(rootActions.setLoading({ loading: false }))
                ),
                tap(() => this.router.navigate(['/error']))
            ),
        { dispatch: false }
    );

    //--------------------------- USER ACTIVATION -------------------------------

    userActivationRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UsersActions.userActivationRequest),
            concatLatestFrom(() => this.store.select(fromRoot.selectCodeAndUserFromUrl)),
            tap(() => this.store.dispatch(rootActions.setLoading({ loading: true }))),
            mergeMap(([{ name, confirmPassword, password, surnames }, userCode]) => {
                return this.accountService.activateUser({
                    code: userCode.code,
                    confirmPassword,
                    name,
                    password,
                    userId: userCode.userid,
                    surnames
                });
            }),
            map(({ succeeded }) =>
                !succeeded
                    ? UsersActions.userActivationFailure({
                          message: GENERAL_ERROR_MESSAGE
                      })
                    : UsersActions.userActivationSuccess({
                          message: ACCOUNT_ACTIVATED_SUCCESSFULLY_MESSAGE
                      })
            ),
            catchError(() =>
                of(UsersActions.userActivationFailure({ message: GENERAL_ERROR_MESSAGE }))
            )
        )
    );

    userActivationSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UsersActions.userActivationSuccess),
                tap(() =>
                    this.store.dispatch(rootActions.setLoading({ loading: false }))
                ),
                tap(() => this.router.navigate(['/users/activate/success']))
            ),
        { dispatch: false }
    );

    userActivationFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UsersActions.userActivationFailure),
                tap(() =>
                    this.store.dispatch(rootActions.setLoading({ loading: false }))
                ),
                tap(() => this.router.navigate(['/error']))
            ),
        { dispatch: false }
    );

    validateToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UsersActions.validateTokenFromEmail),
            concatLatestFrom(() => this.store.select(fromRoot.selectCodeAndUserFromUrl)),
            mergeMap(([_, userCode]) =>
                this.accountService.varifyToken(userCode.userid, userCode.code)
            ),
            map((result: any) => {
                if (result.result) return UsersActions.validateTokenFromEmailSuccess();
                else return UsersActions.validateTokenFromEmailFailure();
            })
        )
    );

    validateTokenFromEmailFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UsersActions.validateTokenFromEmailFailure),
                tap(() => {
                    this.store.dispatch(rootActions.setLoading({ loading: false }));
                    this.router.navigate(['/invalid-token-error']);
                })
            ),
        { dispatch: false }
    );

    validateTokenFromEmailSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UsersActions.validateTokenFromEmailSuccess),
                tap(() => this.store.dispatch(rootActions.setLoading({ loading: false })))
            ),
        { dispatch: false }
    );
}
