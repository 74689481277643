import { createAction, props } from '@ngrx/store';
import { UserInfo } from 'src/app/core/definitions/interfaces';
import { OrganizationSummaryModel } from 'src/app/core/models/organization-summary.model';
import { ID } from '../../core/definitions/types';
import { RouteForReusing } from '../selectors/root.selectors';

export const getOrganizations = createAction('[Root] Get All Organizations');

export const getOrganizationsSuccess = createAction(
    '[Root] Get All Organizations Success',
    props<{ organizations: OrganizationSummaryModel[] }>()
);

export const getOrganizationsFail = createAction(
    '[Root] Get All Organizations Fail',
    props<{ error: string }>()
);

/**
 * This action will be handled in the reducer, ant it will set the
 * current organization info from the list of organizations.
 *
 * This will make sure that the name of the new company will show
 * before the request to change organization is completed.
 *
 * This action will not be handled in the getDisplayName effect or other actions
 * that need to be dispatched on companyChange.
 *
 */
export const optimisticUiOrganizationChange = createAction(
    '[Root] Set current organization',
    props<{ id?: ID }>()
);

export const currentOrganizationChangeFromUserComponent = createAction(
    '[Root] Current organization change from user component',
    props<{ id?: ID }>()
);

export const changeOrganization = createAction(
    '[User Setting Component - API] Change Organization',
    props<{ id: ID }>()
);

export const changeOrganizationContextFromUrl = createAction(
    '[User Setting Component - API] Change Organization From Url',
    props<{ id: ID; targetRoute: RouteForReusing }>()
);

export const changeOrganizationSuccess = createAction(
    '[User Setting Component - API] Change Organization Success',
    props<{
        id: ID;
        user: UserInfo;
        targetRoute?: RouteForReusing;
    }>()
);

export const changeOrganizationFail = createAction(
    '[User Setting Component - API] Change Organization Fail'
);

/**
 * This action makes a request to edit the information
 * for the current organization.
 */
export const updateCurrentOrganization = createAction(
    '[Root] Update current organization',
    props<{ name: string }>()
);

export const updateCurrentOrganizationSuccess = createAction(
    '[Root] Update current organization success',
    props<{ name: string }>()
);
export const updateCurrentOrganizationFailure = createAction(
    '[Root] Update current organization failure'
);

export const initUserFromStorage = createAction(
    '[INIT] Init user from storage',
    props<{ user: UserInfo }>()
);
export const initNoUserFromStorage = createAction('[INIT] No user from storage');

export const getLatestHubUrl = createAction('[Packages Hub API] GET LatestHub');

export const getLatestHubUrlSuccess = createAction(
    '[Packages Hub API] GET LatestHub Success',
    props<{ url: string }>()
);

export const getLatestHubUrlFail = createAction(
    '[Packages Hub API] GET LatestHub Fail',
    props<{ error: string | null }>()
);

export const changePassword = createAction(
    '[Personal] Change password',
    props<{ currentPassword: string; newPassword: string; repeatPassword: string }>()
);

export const changePasswordSuccess = createAction('[Personal] Change password success');

export const changePasswordFailure = createAction(
    '[Personal] Change password failure',
    props<{ error: string }>()
);

export const updateUserInformation = createAction(
    '[Personal] Update user information',
    props<{ name: string; surnames: string }>()
);

export const updateUserInformationSuccess = createAction(
    '[Personal] Update user information success'
);

export const updateUserInformationFailure = createAction(
    '[Personal] Update user information failure'
);

export const setLoading = createAction(
    '[Root] Set loading',
    props<{ loading: boolean }>()
);

export const snackbarInfo = createAction(
    '[Root] Show snackbar info',
    props<{ message: string }>()
);

export const snackbarSuccess = createAction(
    '[Root] Show snackbar success',
    props<{ message: string }>()
);

export const snackbarWarning = createAction(
    '[Root] Show snackbar warning',
    props<{ message: string }>()
);

export const snackbarError = createAction(
    '[Root] Show snackbar error',
    props<{ message: string }>()
);

export const getOrganizationUsersDisplayNames = createAction(
    '[Root] GET Organization Users Display Names',
    props<{ currentOrganizationId: ID }>()
);

export const getOrganizationUsersDisplayNamesSuccess = createAction(
    '[Root] GET Organization Users Display Names Success',
    props<{ users: { [nameIdentifier: ID]: string } }>()
);

export const getOrganizationUsersDisplayNamesFail = createAction(
    '[Root] GET Organization Users Display Names Fail',
    props<{ error: Error | string | null }>()
);

export const clearDisplayNames = createAction('[Root] Clear Users Display Names');

export const logout = createAction('[User Settings] Logout from the application');

export const logoutSuccess = createAction('[Root Api] User Login out Success');
export const logoutFail = createAction('[Root Api] User Login out Fail');

export const downloadHub = createAction('[Root Page] Download the Hub app');
export const downloadHubFromPage = createAction(
    '[Welcome Page] Download the Hub app from page'
);

export const discardUnsavedChangesAndNavigate = createAction(
    '[Unsaved Changes Guard] Discard Unsaved Changes and navigate'
);
export const cancelDiscardChangesNavigation = createAction(
    '[Unsaved Changes Guard] Cancel Discard Unsaved Changes and navigation'
);

export const navigatedToRedirect = createAction(
    '[Root] Redirected to redirect component'
);
