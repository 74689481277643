import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAXIMUM_LENGTH_TO_CHANGE_TEXTAREA } from 'src/app/core/constants/feature';

const INPUT_CONTROL_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomInputComponent),
    multi: true
};

@Component({
    selector: 'app-custom-input',
    providers: [INPUT_CONTROL_ACCESSOR],
    templateUrl: './custom-input.component.html',
    styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent implements ControlValueAccessor {
    @Input() placeholder = '';
    @Input() label = '';
    @Input() value = '';
    @Input() type = 'text';
    @Input() allowBigValue = false;
    @Input() disabled = false;
    @Input() readonly = false;
    @Input() cursorStyle = '';
    @Output() valueChange = new EventEmitter<string>();
    @Output() onFocusOutField = new EventEmitter();

    @ViewChild('smallField') smallField!: ElementRef;
    @ViewChild('bigField') bigField!: ElementRef;

    valueForChangeBetweenSmallBigValue = MAXIMUM_LENGTH_TO_CHANGE_TEXTAREA;

    onClick(event: Event): void {
        event.stopPropagation();
    }
    private _onChange?: any;
    private _onTouched?: any;

    onChange(value: string): void {
        this.value = value;
        this.valueChange.emit(value);
        this._onChange?.(value);
        this._onTouched?.();

        if (this.allowBigValue) {
            if (value.length > this.valueForChangeBetweenSmallBigValue) {
                setTimeout(() => {
                    this.bigField.nativeElement.focus();
                }, 100);
            } else {
                setTimeout(() => {
                    this.smallField.nativeElement.focus();
                }, 100);
            }
        }
    }

    onFocusOut() {
        this.onFocusOutField.emit();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    setSmallFieldFocus(): void {
        this.smallField.nativeElement.focus();
    }
}
