<div id="dialog">
    <div>
        <h2 mat-dialog-title class="title">
            {{ data.title }}
        </h2>
        <div mat-dialog-content>
            <p class="text-small">
                Enter a message describing the changes you're about to make.
            </p>
        </div>
        <div class="form-container">
            <mat-form-field appearance="outline">
                <mat-label>Message</mat-label>
                <input matInput placeholder="Message" required [(ngModel)]="message" />
            </mat-form-field>
        </div>
        <div class="buttons-container" mat-dialog-actions>
            <app-custom-button
                type="flat"
                color="primary"
                [text]="data.isDraft ? 'Save Draft' : 'Publish'"
                [disabled]="message.length < 3"
                cdkFocusInitial
                (buttonClick)="onConfirm()"
            ></app-custom-button>
            <app-custom-button
                type="stroked"
                color="primary"
                text="Cancel"
                (buttonClick)="onDiscard()"
            ></app-custom-button>
        </div>
    </div>
</div>
