import { ReportDefinition } from 'src/app/modules/list-tree-view/interfaces/report-definition.interface';
import { IUnique } from '../definitions/interfaces';
import { ViewDetail } from '../interfaces/view-detail.interface';
import { ViewSummaryModel } from './view-summary.model';
import { ID } from '../definitions/types';

export const INITIAL_VIEW_DETAIL = {
    id: null,
    title: '',
    reportDefinitions: [],
    description: '',
    sources: []
};

export class ViewDetailModel extends ViewSummaryModel implements ViewDetail {
    reportDefinitions!: ReportDefinition[];
    description!: string;
    sources!: ID[];

    constructor(obj?: IUnique) {
        super(obj);
    }

    protected initialize(obj: any): void {
        super.initialize(obj);
        if (obj) {
            this.reportDefinitions =
                obj.reportDefinitions ?? INITIAL_VIEW_DETAIL.reportDefinitions;
            this.description = obj.description ?? INITIAL_VIEW_DETAIL.description;
            this.sources = obj.sources ?? INITIAL_VIEW_DETAIL.sources;
        }
    }
}
