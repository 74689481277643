import { Component, ElementRef, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { UserInfo } from 'src/app/core/definitions/interfaces';
import { fromRoot } from 'src/app/store';
import { updateUserInformation } from 'src/app/store/actions/root.actions';

@Component({
    selector: 'app-personal-information',
    templateUrl: './information.component.html',
    styleUrls: ['./information.component.scss']
})
export class PersonalInformationComponent implements OnDestroy {
    @ViewChildren('first, last') inputs!: QueryList<ElementRef<HTMLInputElement>>;

    currentUser$: Observable<UserInfo | undefined>;
    organizations$: Observable<string>;
    loading$: Observable<boolean>;
    onDestroy$ = new Subject<boolean>();

    currentUser?: UserInfo;
    firstName?: string;
    lastName?: string;

    nameWhiteSpace!: boolean;
    surnameWhiteSpace!: boolean;
    formHasChange!: boolean;

    constructor(private store: Store) {
        this.currentUser$ = this.store.select(fromRoot.selectUser);
        this.loading$ = this.store.select(fromRoot.selectLoading);
        this.currentUser$.pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
            this.currentUser = user;
            this.firstName = this.currentUser?.name;
            this.lastName = this.currentUser?.surname;
            this.formHasChange = false;
        });

        this.organizations$ = this.store
            .select(fromRoot.selectAllOrganizations)
            .pipe(map((orgs) => orgs.map((org) => org.name).join(', ')));
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.unsubscribe();
    }

    get formIsValid(): boolean {
        return (this.inputs ?? [])
            .map((input) => input.nativeElement)
            .every((input) => input.checkValidity());
    }

    onChangeFirstName(firstField: HTMLInputElement): void {
        this.firstName = firstField.value;
        this.checkWhiteSpaces();
    }

    onChangeLastName(lastField: HTMLInputElement): void {
        this.lastName = lastField.value;
        this.checkWhiteSpaces();
    }

    checkWhiteSpaces(): void {
        this.nameWhiteSpace =
            this.firstName?.trim().length === 0 && this.firstName?.length > 0;
        this.surnameWhiteSpace =
            this.lastName?.trim().length === 0 && this.lastName?.length > 0;
        this.checkChangesInForm();
    }

    checkChangesInForm(): void {
        this.formHasChange =
            this.firstName !== this.currentUser?.name ||
            this.lastName !== this.currentUser?.surname;
    }

    onDiscard(): void {
        this.firstName = this.currentUser?.name;
        this.lastName = this.currentUser?.surname;
    }

    onSubmit(): void {
        if (!this.formHasChange || !this.formIsValid) {
            return;
        }
        this.store.dispatch(
            updateUserInformation({
                name: this.firstName ?? '',
                surnames: this.lastName ?? ''
            })
        );
    }
}
