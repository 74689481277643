export interface GroupData<T> {
    column?: string;
    groupKey?: string;
    content?: T;
    children: GroupData<T>[];
    depth?: number;
    groupPath?: { column: string; value: string }[];
    isGroup: boolean;
}

export function simpleArrayToGroupData<T>(array: T[]): GroupData<T>[] {
    return array.map((item) => ({
        content: item,
        children: [],
        isGroup: false
    }));
}

export function groupDataToSimpleArray<T>(groupData: GroupData<T>[]): T[] {
    return groupData.map((group) => group.content as T);
}
