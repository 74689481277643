import {
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { mismatchValidator } from 'src/app/shared/helpers/mismatch-validator';
import { fromRoot, rootActions } from 'src/app/store';
import { PASSWORD_VALIDATION_REGEX } from '../../../../core/constants/app-messages';
import { userActivationRequest } from '../../store/users.actions';
import * as userActions from '../../store/users.actions';
@Component({
    selector: 'app-setting-up-user',
    templateUrl: './setting-up-user.component.html',
    styleUrls: ['./setting-up-user.component.scss']
})
export class SettingUpUserComponent implements OnInit, OnDestroy {
    @ViewChildren('input') inputs: QueryList<ElementRef<HTMLInputElement>> | undefined;
    @ViewChild('passwordField') passwordField: ElementRef<HTMLInputElement> | undefined;
    name = '';
    password = '';
    repeatPassword = '';
    surnames = '';
    userCode = '';
    userid = '';

    passwordRegex = PASSWORD_VALIDATION_REGEX;

    passwordFieldForValidation: HTMLInputElement | null = null;

    nameWhiteSpace!: boolean;
    surnameWhiteSpace!: boolean;
    mismatch!: boolean;

    constructor(private store: Store) {
        this.loading$ = this.store.select(fromRoot.selectLoading);
    }

    ngOnDestroy(): void {
        this.onDestroy$.emit();
        this.onDestroy$.unsubscribe();
    }

    loading$!: Observable<boolean>;
    onDestroy$ = new EventEmitter();

    ngOnInit() {
        this.store.dispatch(userActions.validateTokenFromEmail());
        this.store.dispatch(rootActions.setLoading({ loading: true }));
        fromEvent(window, 'resize').pipe(takeUntil(this.onDestroy$)).subscribe();

        this.loading$.pipe(takeUntil(this.onDestroy$)).subscribe((loading) => {
            setTimeout(() => {
                if (!loading && this.passwordField?.nativeElement)
                    this.passwordFieldForValidation = this.passwordField?.nativeElement;
            }, 1);
        });
    }

    get formIsValid(): boolean {
        return (
            !!this.name &&
            !!this.surnames &&
            !!this.password &&
            !!this.repeatPassword &&
            (this.inputs ?? [])
                .map((input) => input.nativeElement)
                .every((input) => input.checkValidity())
        );
    }

    onConfirmPasswordChange(repeat: HTMLInputElement): void {
        repeat.required = true;
        this.repeatPassword = repeat.value;
        this.mismatch = mismatchValidator(this.password, this.repeatPassword);
    }

    onChangeFirstName(firstNameInput: HTMLInputElement): void {
        firstNameInput.required = true;
        this.name = firstNameInput.value;
        this.checkWhiteSpaces();
    }

    onChangeLastName(lastNameInput: HTMLInputElement): void {
        lastNameInput.required = true;
        this.surnames = lastNameInput.value;
        this.checkWhiteSpaces();
    }

    checkWhiteSpaces(): void {
        this.nameWhiteSpace = this.name?.trim().length === 0 && this.name?.length > 0;
        this.surnameWhiteSpace =
            this.surnames?.trim().length === 0 && this.surnames?.length > 0;
    }

    onSubmit(): void {
        this.store.dispatch(
            userActivationRequest({
                name: this.name,
                confirmPassword: this.repeatPassword,
                password: this.password,
                surnames: this.surnames
            })
        );

        this.name = '';
        this.surnames = '';
        this.password = '';
        this.repeatPassword = '';
    }
}
