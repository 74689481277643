import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ButtonType, ThemeColor } from '../custom-button/custom-button.component';
import { Action } from '../../interfaces/action';

@Component({
    selector: 'app-custom-menu',
    templateUrl: './custom-menu.component.html',
    styleUrls: ['./custom-menu.component.scss']
})
export class CustomMenuComponent {
    @Input() actions!: Action[];
    @Input() icon = 'more_horiz';
    @Input() iconType: ButtonType = 'basic';
    @Input() iconColor?: ThemeColor;
    @Input() iconStyle?: any;
    @Output() menuClicked = new EventEmitter();

    @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;

    onMenuTrigger() {
        if (!this.trigger) return;
        this.trigger.toggleMenu();
        this.menuClicked.emit();
    }
}
