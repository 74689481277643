import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-setting-up-user-success',
    templateUrl: './setting-up-user-success.component.html'
})
export class SettingUpUserSuccessComponent {
    constructor(private route: Router) {}

    onButtonClick(): void {
        this.route.navigate(['/login']);
    }
}
