<ng-template #defaultLabelTemplate let-label>
    {{ label }}
</ng-template>

<mat-form-field [appearance]="appearance" color="accent">
    <mat-label>{{ label }}</mat-label>
    <mat-select
        #menu
        [value]="value"
        (valueChange)="onValueChange($event)"
        [multiple]="multiple"
        [required]="false"
        [placeholder]="placeholder"
        (focusout)="focusOut()"
    >
        <ng-container *ngIf="!groups">
            <mat-option
                *ngFor="let option of options"
                [value]="option.value"
                [disabled]="disabled"
            >
                <ng-container
                    *ngTemplateOutlet="
                        labelTemplate ?? defaultLabelTemplate;
                        context: {
                            $implicit: option.label
                        }
                    "
                >
                </ng-container>
            </mat-option>
            <div
                *ngIf="multiple && showAddOnMultiple"
                class="add-multiple-button-wrapper"
            >
                <div class="separator"></div>
                <app-custom-button
                    (buttonClick)="close()"
                    text="Add"
                    type="flat"
                    color="primary"
                    size="small"
                    icon="add"
                ></app-custom-button>
            </div>
        </ng-container>
        <ng-container *ngIf="groups">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let group of groups">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="body-semibold-16"
                            >{{ group.label }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <mat-option
                        *ngFor="let option of group.options"
                        [value]="option.value"
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                labelTemplate ?? defaultLabelTemplate;
                                context: {
                                    $implicit: option.label
                                }
                            "
                        >
                        </ng-container>
                    </mat-option>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-container>

        <mat-option *ngIf="!valueInOptions && !multiple" [hidden]="true" [value]="value">
            {{ currentLabel ? currentLabel : value }}
        </mat-option>
        <mat-option *ngIf="!options && !groups" [disabled]="true" class="loader-option">
            <div class="loader-container">
                <app-loader></app-loader>
            </div>
        </mat-option>
    </mat-select>
</mat-form-field>
