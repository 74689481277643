import { ColumnIdentifier } from 'src/app/modules/list-tree-view/interfaces/report-definition.interface';

export type SortableObject = Record<string, number | boolean | string>;

export const sortBy = (
    A: SortableObject,
    B: SortableObject,
    column: ColumnIdentifier
): number => {
    if (!(column in A)) {
        throw new Error(`Column ${column} not found in A`);
    }
    if (!(column in B)) {
        throw new Error(`Column ${column} not found in B`);
    }

    const { [column]: a } = A;
    const { [column]: b } = B;
    if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
    } else if (typeof a === 'boolean' && typeof b === 'boolean') {
        return Number(a) - Number(b);
    } else if (typeof a === 'string' && typeof b === 'string') {
        if (a === '' && b === '') return 0;
        else if (b === '') return -1;
        else if (a === '') return 1;
        else return a.localeCompare(b);
    }
    return 0;
};
