import { MenuTab } from 'src/app/shared/models/menu-tab.model';

export enum Route {
    elements = 'elements',
    organization = 'organization',
    other = '',
    patterns = 'patterns',
    personal = 'personal',
    help = 'help',
    root = 'root',
    views = 'views',
    imports = 'imports' // BACKWARDS COMPATIBILITY
}

export const ROUTES: {
    [key: string]: MenuTab;
} = {
    patterns: {
        id: 'patterns',
        routerLink: '/app/patterns',
        selected: false,
        title: 'Patterns',
        icon: 'patterns',
        isSvgIcon: true
    },
    elements: {
        id: 'elements',
        routerLink: '/app/elements',
        selected: false,
        title: 'Elements',
        icon: 'elements',
        isSvgIcon: true
    },
    views: {
        id: 'views',
        routerLink: '/app/views',
        selected: false,
        title: 'Views',
        icon: 'views',
        isSvgIcon: true
    },
    personal: {
        id: 'personal',
        routerLink: '/app/personal',
        selected: false,
        title: 'Personal Settings',
        icon: 'settings',
        isSvgIcon: false
    },
    organization: {
        id: 'organization',
        routerLink: '/app/organization',
        selected: false,
        title: 'Organization Settings',
        icon: 'settings',
        isSvgIcon: false
    },
    help: {
        id: 'help',
        routerLink: '/app/help',
        selected: false,
        title: 'Help',
        icon: 'help',
        isSvgIcon: false
    },
    blueprint: {
        id: 'blueprint',
        routerLink: '/app/blueprint',
        selected: false,
        title: 'Blueprint',
        icon: 'blueprint',
        isSvgIcon: false
    }
};

export const SYSTEM_ROLES = {
    USER: 'User',
    OWNER: 'Owner',
    ADMIN: 'Admin',
    NONADMIN: 'Non admin'
};

export const MAXIMUM_LENGTH_TO_CHANGE_TEXTAREA = 20;
export const VALIDATOR_EMAIL_REGEX = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$';

//VALIDATOR ERROR MESSAGES
export const EMAIL_EMPTY_VALIDATION_MESSAGE = 'The email should not be empty';
export const EMAIL_INVALID_VALIDATION_MESSAGE =
    'The email is invalid, please verify it and try again';
export const EMAIL_UNSUCCESSFULLY_SENT =
    'There was a problem sending the invitation to your email, try again later';
export const EMAIL_NOT_VALID_IN_BACKEND_MESSAGE =
    'The email does not exist in our database, you should create an account';

export const ROLES_NO_SELECTED_MESSAGE = 'At least one role has to be selected';

export const ROLES_OR_EMAIL_INVALID =
    'You have to select at least one role and a valid email';

export const PASSWORD_EMPTY_VALIDATION_MESSAGE = 'The password should not be empty';
export const PASSWORD_CONFIRM_PASSWORD_VALIDATION_MESSAGE =
    'The password and confirm password have to be equals';
export const PASSWORD_INVALID_VALIDATION_MESSAGE = 'The password needs to ...';
export const PASSWORD_CHANGED_MESSAGE = 'The password was changed successfully';

export const ACCOUNT_ACTIVATED_SUCCESSFULLY_MESSAGE =
    'Your account has been activated successfully';

export const GENERAL_ERROR_MESSAGE = 'An error occured';

//SUCCESS MESSAGES
export const EMAIL_SUCCESSFULLY_SENT = 'The invitation was sent successfully';
export const EMAIL_CHANGE_PASSWORD =
    'A link to change your password was susccessfully send';
export const ROLES_CHANGED_SUCCESSFULLY = 'The roles were successfully changed';
export const FORM_CONTROL_STATUS_VALID = 'VALID';
