import { IUnique } from '../definitions/interfaces';
import { ID } from '../definitions/types';

export abstract class BaseModel implements IUnique {
    id: ID = '';

    protected constructor(obj?: IUnique) {
        if (obj) {
            this.id = obj.id;
            this.initialize(obj);
        }
    }

    update(obj: any): BaseModel {
        this.initialize(obj);
        return this;
    }

    protected abstract initialize(obj: any): void;
}
