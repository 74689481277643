import { Component, Input, OnInit } from '@angular/core';
import { PASSWORD_VALIDATION_LENGTH } from 'src/app/core/constants/app-messages';

@Component({
    selector: 'app-password-validation-line',
    templateUrl: './password-validation-line.component.html',
    styleUrls: ['./password-validation-line.component.scss']
})
export class PasswordValidationLineComponent implements OnInit {
    @Input() message!: { message: string; regex: string };
    @Input() fieldValue!: string;
    @Input() justCreated = true;

    regexValidtor!: RegExp;

    ngOnInit(): void {
        this.regexValidtor = new RegExp(this.message?.regex);
    }

    get isValid() {
        if (this.message?.regex) return this.regexValidtor.test(this.fieldValue);
        else return this.fieldValue?.length > PASSWORD_VALIDATION_LENGTH;
    }
}
