import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ElementDefinitionDetail } from 'src/app/core/interfaces/element-definition-detail.interface';
import { ElementDefinitionResourceService } from 'src/app/core/resources/element-definition-resource-service/element-definition-resource.service';
import { fromElementDefinitions } from '../store/element-definitions.selectors';
import {
    changeShowVersionHistory,
    elementChangesDiscard,
    saveElementDefinition
} from '../store/actions/element-definitions.action';
import { Status } from 'src/app/core/definitions/enums';
import { ID } from 'src/app/core/definitions/types';
import { map } from 'rxjs/operators';
import { deleteElementDefinition } from '../store/actions/element-definitions-api.action';
import { ElementToolbarView } from '../store/types';
import { DraftRoutingService } from '../../layout/services/draft-routing.service';

@Injectable({
    providedIn: 'root'
})
export class ToolbarElementService {
    private elementToolbarView$: Observable<ElementToolbarView>;
    private currentElement$: Observable<ElementDefinitionDetail | null>;

    constructor(
        private store: Store,
        private elementService: ElementDefinitionResourceService,
        private draftRoutingService: DraftRoutingService
    ) {
        this.currentElement$ = this.store.select(
            fromElementDefinitions.selectCurrentElementDefinition
        );
        this.elementToolbarView$ = this.store.select(
            fromElementDefinitions.selectElementToolbarView
        );
    }

    duplicateElement(): void {
        this.draftRoutingService.duplicateElementDefinition();
    }

    discardData(): void {
        this.store.dispatch(elementChangesDiscard());
    }

    saveDraft(message?: string): void {
        this.store.dispatch(saveElementDefinition({ message, status: Status.Draft }));
    }

    savePublished(message: string): void {
        this.store.dispatch(saveElementDefinition({ message, status: Status.Published }));
    }

    deleteElement(elementId: ID, historyId: ID): void {
        this.store.dispatch(
            deleteElementDefinition({
                id: elementId,
                historyCollection: historyId
            })
        );
    }

    showVersionHistory() {
        this.store.dispatch(changeShowVersionHistory({ enable: true }));
    }

    getUsedNameOfPatterns(elementId: ID): Observable<string[]> {
        return this.elementService
            .getUsedPatterns(elementId)
            .pipe(map((pattern) => pattern.map((p) => p.title)));
    }

    public get currentElement(): Observable<ElementDefinitionDetail | null> {
        return this.currentElement$;
    }

    public get elementToolbarView(): Observable<ElementToolbarView> {
        return this.elementToolbarView$;
    }
}
