export const PASSWORD_VALIDATION_REGEX =
    '^(?=.*[0-9])(?=.*[^a-zA-Z 0-9])(?=.*[a-z])(?=.*[A-Z]){6,50}$';

export const PASSWORD_VALIDATION_REGEX_MESSAGES = [
    { message: 'At least an uppercase character', regex: '.*[A-Z].*' },
    { message: 'At least a lowercase character', regex: '.*[a-z].*' },
    { message: 'At least a digit', regex: '.*[0-9].*' },
    {
        message: 'At least a non-alphanumeric character (!@#$%^&*_|{}():;"\'<>,.?/`-)',
        regex: '.*[^a-zA-Z 0-9].*'
    },
    { message: 'At least 6 characters long', regex: '' }
];

export const PASSWORD_VALIDATION_LENGTH = 6;
