export enum RoleEnum {
    Viewer,
    NonAdmin,
    Manager,
    Analyst,
    Contributor,
    Owner,
    User,
    Support
}
