import { Injectable } from '@angular/core';
import { WorkroadsTreeNode } from '../../../core/interfaces/workroads-tree-node.interface';
import { ID } from '../../../core/definitions/types';
import { AutocadTreeNode } from '../../../core/interfaces/autocad-tree-node.interface';
import { PatternDetail } from '../../../core/interfaces/pattern-detail.interface';
import { uuidWrapper } from 'src/app/shared/helpers/uuid';

@Injectable({
    providedIn: 'root'
})
export class DuplicatePatternService {
    duplicate(pattern: PatternDetail): PatternDetail {
        return this.removeIds(pattern);
    }

    removeIds(pattern: PatternDetail): PatternDetail {
        const ids: { [key: ID]: ID } = {};

        return {
            ...pattern,
            id: null as any,
            tid: pattern.id,
            title: '',
            description: '',
            autocadTree: {
                ...pattern.autocadTree,
                childrenDefinitions: this.removeIdsFromAutocadTree(
                    pattern.autocadTree?.childrenDefinitions ?? [],
                    ids
                )
            },
            workRoadsTree: {
                ...pattern.workRoadsTree,
                childrenDefinitions: this.removeIdsFromWorkroadsTree(
                    pattern.workRoadsTree?.childrenDefinitions ?? [],
                    ids
                )
            }
        };
    }

    /**
     * Removes node ids and filter ids.
     * Filters are updated from this values when navigating
     * @param childrenDefinitions
     * @param ids
     */
    removeIdsFromAutocadTree(
        childrenDefinitions: AutocadTreeNode[],
        ids: { [key: ID]: ID }
    ): AutocadTreeNode[] {
        return childrenDefinitions?.map((node) => {
            if (node.id) {
                ids[node.id] = uuidWrapper.uuid();
            }

            return {
                ...node,
                id: undefined,
                tId: ids[node.id!],
                filters: node.filters.map((filter) => ({
                    ...filter,
                    id: uuidWrapper.uuid(),
                    nodeId: ids[node.id!],
                    isNew: true
                })),
                childrenDefinitions: node.childrenDefinitions
                    ? this.removeIdsFromAutocadTree(node.childrenDefinitions, ids)
                    : []
            } as any;
        });
    }

    /**
     * Removes the ids from nodes and fieldExpressions.
     * @param childrenDefinitions
     * @param ids
     */
    removeIdsFromWorkroadsTree(
        childrenDefinitions: WorkroadsTreeNode[],
        ids: { [key: ID]: ID }
    ): WorkroadsTreeNode[] {
        return childrenDefinitions?.map(
            (node, idx) =>
                ({
                    ...node,
                    id: undefined,
                    tId: uuidWrapper.uuid(),
                    link: node.link ? ids[node.link] : undefined,
                    childrenDefinitions: node.childrenDefinitions
                        ? this.removeIdsFromWorkroadsTree(node.childrenDefinitions, ids)
                        : [],
                    fieldExpressions: node.fieldExpressions.map(
                        (fieldExpression) =>
                            ({
                                ...fieldExpression,
                                id: undefined
                            } as any)
                    )
                } as WorkroadsTreeNode)
        );
    }
}
