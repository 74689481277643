<ng-container [ngSwitch]="mode">
    <div
        class="timeline {{ position }} {{ mode }}"
        [class.selected]="
            mode === 'selected' ||
            mode === 'selected-fat' ||
            mode === 'selected-fat-header'
        "
    >
        <div class="svg-container header" *ngSwitchCase="'header'">
            <svg style="margin-left: -1.01px">
                <path d="M6 0L6 120" stroke="#D7D7D7"></path>
                <circle cx="6" cy="5" r="4.5" fill="white" stroke="#0A68A6"></circle>
            </svg>
        </div>
        <div class="svg-container" *ngSwitchCase="'show-more'">
            <svg style="padding-left: 2px">
                <path d="M3 0L3 120" stroke="#D7D7D7" />
                <circle cx="3" cy="18" r="2.5" fill="white" stroke="#0A68A6" />
            </svg>
        </div>
        <div class="svg-container" *ngSwitchCase="'space'">
            <svg style="padding-left: 2px">
                <path d="M3 0L3 120" stroke="#D7D7D7" />
            </svg>
        </div>
        <div class="svg-container" *ngSwitchCase="'blank'">
            <div style="width: 16px"></div>
        </div>
        <div class="svg-container" *ngSwitchCase="'selected'">
            <svg style="margin-left: -1px">
                <path d="M6 0L6 120" stroke="#D7D7D7" />
                <circle cx="6" cy="26" r="4" fill="#0A68A6" stroke="#0A68A6" />
            </svg>
        </div>
        <div class="svg-container" *ngSwitchCase="'unselected'">
            <svg style="padding-left: 2px">
                <path d="M3 0L3 120" stroke="#D7D7D7" />
                <circle cx="3" cy="26" r="2.5" fill="white" stroke="#0A68A6" />
            </svg>
        </div>

        <div class="svg-container" *ngSwitchCase="'selected-fat-header'">
            <svg style="margin-left: -1px; margin-top: 25px">
                <path d="M6 0L6 120" stroke="#D7D7D7" />
                <circle cx="6" cy="5" r="4" fill="#0A68A6" stroke="#0A68A6" />
            </svg>
        </div>
        <div class="svg-container" *ngSwitchCase="'selected-fat'">
            <svg style="margin-left: -1px">
                <path d="M6 0L6 140" stroke="#D7D7D7" />
                <circle cx="6" cy="30" r="4" fill="#0A68A6" stroke="#0A68A6" />
            </svg>
        </div>
        <div class="svg-container" *ngSwitchCase="'unselected-fat'">
            <svg style="padding-left: 2px">
                <path d="M3 0L3 140" stroke="#D7D7D7" />
                <circle cx="3" cy="30" r="2.5" fill="white" stroke="#0A68A6" />
            </svg>
        </div>
        <div class="svg-container" *ngSwitchCase="'unselected-fat-header'">
            <svg style="padding-left: 2px; margin-top: 25px">
                <path d="M3 0L3 120" stroke="#D7D7D7" />
                <circle cx="3" cy="5" r="2.5" fill="white" stroke="#0A68A6" />
            </svg>
        </div>

        <div class="content-{{ mode }}">
            <ng-content></ng-content>
        </div>
    </div>
</ng-container>
