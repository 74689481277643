export const STRING_RE = '"(.*?)"';
export const IDENTIFIER_RE = '[_a-zA-Z][_a-zA-Z0-9]*';
export const IDENTIFIER_WITH_ACCESS_RE = IDENTIFIER_RE + '(?=[.])';
export const EVERYTHING_ALLOWED_AFTER_FIELD = '(?=[^a-zA-Z0-9._]|$)';
/**
 * In the following regex what I want to match is identifier_re, the rest
 * is to ensure the access to properties has ended.
 */
export const FIELD_DIRECT_RE = `${IDENTIFIER_RE}${EVERYTHING_ALLOWED_AFTER_FIELD}`;
export const FIELD_QUOTED_RE = `${STRING_RE}${EVERYTHING_ALLOWED_AFTER_FIELD}`;
export const NUMERIC_RE = /\d+(\.\d+)?/;
