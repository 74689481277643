import { Component, Input, OnInit } from '@angular/core';
import { DifferencesResult, PropertyId } from '../../store/types';
import { ID } from '../../../../core/definitions/types';
import { Observable } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { fromElementDefinitions } from '../../store/element-definitions.selectors';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-difference-warning',
    templateUrl: './difference-warning.component.html',
    styleUrls: ['./difference-warning.component.scss'],
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ height: 0, opacity: 0 }),
                animate('.3s ease-out', style({ height: 20, opacity: 1 }))
            ]),
            transition(':leave', [
                style({ height: 20, opacity: 1 }),
                animate('.3s ease-in', style({ height: 0, opacity: 0 }))
            ])
        ])
    ]
})
export class DifferenceWarningComponent implements OnInit {
    @Input() property!: PropertyId;
    // undefined in case of warning for an element
    @Input() fieldId?: ID;
    differencesResult$?: Observable<DifferencesResult | null>;
    constructor(private store: Store) {}

    ngOnInit() {
        if (!this.fieldId) {
            this.differencesResult$ = this.store.select(
                fromElementDefinitions.selectDifferences(this.property)
            );
        } else {
            this.differencesResult$ = this.store.select(
                fromElementDefinitions.selectFieldDifference(this.property, this.fieldId)
            );
        }
    }
}
