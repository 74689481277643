import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
    /**
     * The event is triggered each time the user clicks,
     * returns true if the click was over the element otherwise false
     */
    @Output() clicked = new EventEmitter<boolean>();
    private clickedIn = false;

    /**
     * Listen to the component to check if the user clicked over the component
     *
     * This Directive should be used as less as posible
     * @param element
     */
    constructor(private element?: ElementRef) {}

    @HostListener('click')
    clickIn() {
        this.clickedIn = true;
        this.clicked.emit(this.clickedIn);
    }

    @HostListener('document:click')
    /**
     * Handle click outside of the component
     */
    clickOut() {
        if (!this.clickedIn) this.clicked.emit(false);
        this.clickedIn = false;
    }
}
