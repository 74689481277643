import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-version-history-timeline',
    templateUrl: './version-history-timeline.component.html',
    styleUrls: ['./version-history-timeline.component.scss']
})
export class VersionHistoryTimelineComponent {
    @Input() mode:
        | 'show-more'
        | 'selected'
        | 'selected-fat'
        | 'selected-fat-header'
        | 'unselected'
        | 'unselected-fat'
        | 'unselected-fat-header'
        | 'header'
        | 'space'
        | 'blank' = 'unselected';
    @Input() position: 'start' | 'middle' | 'end' = 'start';
}
