//dev.azure.com/ingeniu/Workroads/_git/workroads.backend?version=GBdevelops/dev1&path=/Src/Prodify.Backend.Domain/Enums/ElementType.cshttps:
export enum ElementType {
    Unknown,
    Point,
    Polyline,
    Line,
    Arc,
    Circle,
    Ellipse,
    Spline,
    Parabola,
    Dimension,
    Hatch,
    Text,
    Block,
    BlockDefinition,
    Drawing,
    Document,
    Assembly,
    Part,
    Component,
    InventorDrawing,
    Sheet,
    Sketch,
    Typology
}

/**
 * Autocad exclusive element types. This elements are used to determine
 * the pattern target framework.
 *
 * If any of the pattern node types have the following types, then
 * a target framework for the pattern is Autocad.
 */
export const AUTOCAD_ELEMENT_TYPES = [
    ElementType.Block,
    ElementType.Line,
    ElementType.BlockDefinition
];

/**
 * Inventor exclusive element types. This elements are used to determine
 * the pattern target framework.
 *
 * If any of the pattern node types have the following types, then
 * a target framework for the pattern is Inventor.
 */
export const INVENTOR_ELEMENT_TYPES = [
    ElementType.Point,
    ElementType.Polyline,
    ElementType.Arc,
    ElementType.Circle,
    ElementType.Ellipse,
    ElementType.Spline,
    ElementType.Parabola,
    ElementType.Dimension,
    ElementType.Hatch,
    ElementType.Text,
    ElementType.Drawing,
    ElementType.Document,
    ElementType.Assembly,
    ElementType.Part,
    ElementType.Component,
    ElementType.InventorDrawing,
    ElementType.Sheet,
    ElementType.Sketch
];

export const SOLIDWORKS_ELEMENT_TYPES = [
    ElementType.Assembly,
    ElementType.Part,
    ElementType.Typology,
    ...INVENTOR_ELEMENT_TYPES
];
