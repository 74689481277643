import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    Actions,
    concatLatestFrom,
    createEffect,
    ofType,
    ROOT_EFFECTS_INIT
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { GENERAL_ERROR_MESSAGE } from 'src/app/core/constants/feature';
import { OrganizationSummaryModel } from 'src/app/core/models/organization-summary.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { PackageService } from 'src/app/core/services/package.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { fromRoot } from 'src/app/store';
import { rootActions } from '..';
import { UsersResourceService } from '../../core/resources/users-resource-service/users-resource.service';
import { getUserDisplayNamesMap } from '../reducer/root.reducer.helpers';
import { loginSuccess } from '../../modules/authentication/store/authentication.actions';
import { createAppInsight } from 'src/app/shared/helpers/logging-service-factory';
import { ApplicationInsightsService } from 'src/app/shared/services/application-insights.service';

@Injectable()
export class RootEffects {
    constructor(
        private actions$: Actions,
        private packageService: PackageService,
        private authService: AuthService,
        private store: Store,
        private snackbarService: SnackBarService,
        private usersResourceService: UsersResourceService,
        private router: Router,
        private appInsightService: ApplicationInsightsService
    ) {}

    getUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROOT_EFFECTS_INIT, rootActions.updateUserInformationSuccess),
            map(() => {
                const user = this.authService.getDecodedUserFromStorage(); // this takes into account if the token already expired

                if (user) {
                    const appInsight = createAppInsight(user.instrumentationKey ?? '');
                    this.appInsightService.setApplicationInsight(appInsight);
                    return rootActions.initUserFromStorage({ user });
                }
                return rootActions.initNoUserFromStorage();
            })
        );
    });

    changeOrganization$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(rootActions.changeOrganization),
            switchMap(({ id }) => {
                return this.authService.changeOrganization(id).pipe(
                    map((success) => {
                        const user = this.authService.getDecodedUserFromStorage();
                        if (success && user) {
                            return rootActions.changeOrganizationSuccess({
                                id,
                                user
                            });
                        }
                        return rootActions.changeOrganizationFail();
                    })
                );
            })
        );
    });

    changeOrganizationFromUrl$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(rootActions.changeOrganizationContextFromUrl),
            switchMap(({ id, targetRoute }) => {
                this.router.navigate(['/loading']);
                return this.authService.changeOrganization(id).pipe(
                    map((success) => {
                        const user = this.authService.getDecodedUserFromStorage();
                        if (success && user) {
                            return rootActions.changeOrganizationSuccess({
                                id,
                                user,
                                targetRoute
                            });
                        }
                        return rootActions.changeOrganizationFail();
                    })
                );
            })
        );
    });

    getOrganizations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(rootActions.getOrganizations),
            switchMap(() => {
                return this.authService.getUserOrganizations().pipe(
                    map((organizations: OrganizationSummaryModel[]) =>
                        rootActions.getOrganizationsSuccess({ organizations })
                    ),
                    catchError((error) => of(rootActions.getOrganizationsFail(error)))
                );
            })
        );
    });

    getLatestHubUrl$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(rootActions.getLatestHubUrl),
            mergeMap(() => {
                return this.packageService.getHubUrl().pipe(
                    map((url) => {
                        return rootActions.getLatestHubUrlSuccess({
                            url
                        });
                    }),
                    catchError((error) => of(rootActions.getLatestHubUrlFail(error)))
                );
            })
        );
    });

    updateCurrentOrganizationRequest$ = createEffect(() => {
        let name: string;
        return this.actions$.pipe(
            ofType(rootActions.updateCurrentOrganization),
            tap(({ name: n }) => (name = n)),
            withLatestFrom(this.store.select(fromRoot.selectCurrentOrganization)),
            mergeMap(([{ name }, currentOrganization]) =>
                this.authService.updateOrganization({ ...currentOrganization, name })
            ),
            map(({ succeeded }) => {
                return succeeded
                    ? rootActions.updateCurrentOrganizationSuccess({ name })
                    : rootActions.updateCurrentOrganizationFailure();
            }),
            catchError(() => of(rootActions.updateCurrentOrganizationFailure()))
        );
    });

    updateCurrentOrganizationSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(rootActions.updateCurrentOrganizationSuccess),
                tap(() => this.snackbarService.success('Change succesfully saved'))
            );
        },
        { dispatch: false }
    );

    updateCurrentOrganizationFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(rootActions.updateCurrentOrganizationFailure),
                tap(() => this.snackbarService.error(GENERAL_ERROR_MESSAGE))
            );
        },
        { dispatch: false }
    );

    changePasswordRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(rootActions.changePassword),
            mergeMap(({ currentPassword, newPassword, repeatPassword }) =>
                this.authService.updateUserPassword({
                    currentPassword,
                    newPassword,
                    repeatPassword
                })
            ),
            map(({ success, errors, warnings }) => {
                const messages = [];
                if (warnings) {
                    messages.push(
                        ...Object.values(warnings).map((e) => (e as any).message)
                    );
                }
                if (errors) {
                    messages.push(
                        ...Object.values(errors).map((e) => (e as any).message)
                    );
                }
                return success
                    ? rootActions.changePasswordSuccess()
                    : rootActions.changePasswordFailure({ error: messages.join(', ') });
            }),
            catchError(() =>
                of(rootActions.changePasswordFailure({ error: GENERAL_ERROR_MESSAGE }))
            )
        );
    });

    changePasswordSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(rootActions.changePasswordSuccess),
                map(() => this.snackbarService.success('Changes succesfully saved'))
            );
        },
        { dispatch: false }
    );

    changePasswordFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(rootActions.changePasswordFailure),
                map(({ error }) => this.snackbarService.error(error))
            );
        },
        { dispatch: false }
    );

    updateUserInformationRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(rootActions.updateUserInformation),
            tap(() => this.store.dispatch(rootActions.setLoading({ loading: true }))),
            mergeMap(({ name, surnames }) =>
                this.authService.updateUserInformation({ name, surnames })
            ),
            map((success) =>
                success
                    ? rootActions.updateUserInformationSuccess()
                    : rootActions.updateUserInformationFailure()
            ),
            catchError(() => of(rootActions.updateUserInformationFailure()))
        );
    });

    updateUserInformationSucess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(rootActions.updateUserInformationSuccess),
                tap(() => this.snackbarService.success('Changes succesfully saved')),
                tap(() => this.store.dispatch(rootActions.setLoading({ loading: false })))
            );
        },
        { dispatch: false }
    );

    updateUserInformationFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(rootActions.updateUserInformationFailure),
                tap(() => this.snackbarService.error(GENERAL_ERROR_MESSAGE)),
                tap(() => this.store.dispatch(rootActions.setLoading({ loading: false })))
            );
        },
        { dispatch: false }
    );

    snackbarInfo$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(rootActions.snackbarInfo),
                tap(({ message }) => this.snackbarService.info(message))
            );
        },
        { dispatch: false }
    );
    snackbarSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(rootActions.snackbarSuccess),
                tap(({ message }) => this.snackbarService.success(message))
            );
        },
        { dispatch: false }
    );
    snackbarWarning$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(rootActions.snackbarWarning),
                tap(({ message }) => this.snackbarService.warning(message))
            );
        },
        { dispatch: false }
    );
    snackbarError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(rootActions.snackbarError),
                tap(({ message }) => this.snackbarService.error(message))
            );
        },
        { dispatch: false }
    );

    updateDisplayNamesInCurrentUserChange$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                rootActions.initUserFromStorage,
                rootActions.initNoUserFromStorage,
                rootActions.changeOrganizationSuccess,
                loginSuccess
            ),
            withLatestFrom(this.store.select(fromRoot.selectCurrentOrganizationId)),
            map(([, currentOrganizationId]) => {
                if (!currentOrganizationId) {
                    return rootActions.clearDisplayNames();
                }
                return rootActions.getOrganizationUsersDisplayNames({
                    currentOrganizationId
                });
            })
        );
    });

    navigateOnOrganizationChange$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(rootActions.changeOrganizationSuccess),
                tap(({ targetRoute }) => {
                    this.router.navigate(targetRoute ? [targetRoute.url] : [], {
                        queryParams: {
                            ...targetRoute?.queryParams,
                            currentOrganizationId: null
                        }
                    });
                })
            );
        },
        { dispatch: false }
    );

    getOrganizationUsersDisplayNames$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(rootActions.getOrganizationUsersDisplayNames),
            switchMap(({ currentOrganizationId }) => {
                return this.usersResourceService.getUsers().pipe(
                    map((users) =>
                        rootActions.getOrganizationUsersDisplayNamesSuccess({
                            users: getUserDisplayNamesMap(users)
                        })
                    )
                );
            }),
            catchError((error) =>
                of(rootActions.getOrganizationUsersDisplayNamesFail({ error }))
            )
        );
    });

    logoutUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(rootActions.logout),
            tap(() => this.router.navigate(['/login'])), // Logging out here will make the navigation look smoother
            mergeMap(() => this.authService.logout()),
            map(() => rootActions.logoutSuccess()),
            catchError(({ error }) => of(rootActions.logoutFail()))
        )
    );

    noAllowedDownloadUser$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(rootActions.downloadHub),
                concatLatestFrom(() =>
                    this.store.select(fromRoot.selectHubUrlPermission)
                ),
                tap(([_, url]) => {
                    if (!url)
                        this.snackbarService.error(
                            "You don't have the right to use the hub, please contact your system administrator"
                        );
                })
            ),
        { dispatch: false }
    );
}
