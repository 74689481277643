import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const NUMBER_COMPARISON_CONTROL_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NumberComparisonInputComponent),
    multi: true
};

@Component({
    selector: 'app-number-comparison-input',
    providers: [NUMBER_COMPARISON_CONTROL_ACCESSOR],
    templateUrl: './number-comparison-input.component.html',
    styleUrls: ['./number-comparison-input.component.scss']
})
export class NumberComparisonInputComponent implements ControlValueAccessor, OnInit {
    @Input() value!: { number: any; operator: string };
    @Output() valueChange = new EventEmitter<{ number: any; operator: string }>();
    _value!: { number: any; operator: string };
    operatorOptions = ['=', '>', '>=', '<', '<='].map((item) => ({
        label: item,
        value: item
    }));

    private _onChange?: any;
    private _onTouched?: any;

    ngOnInit() {
        this._value = this.value;
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    writeValue({ number, operator }: { number: any; operator: string }): void {
        this.value = {
            number,
            operator
        };
        this._value = {
            number,
            operator
        };
    }

    onNumberChange(number: any) {
        const value = { ...this._value, number };
        this._value = value;
        this._onChange(value);
        this._onTouched?.();
        this.valueChange.emit(value);
    }

    onOperatorChange(operator: string) {
        const value = { ...this._value, operator };
        this._value = value;
        this._onTouched?.();
        this._onChange(value);
        this.valueChange.emit(value);
    }
}
