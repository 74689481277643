import { Injectable } from '@angular/core';
import { ExternalNode } from '../../linked-trees/interfaces/external-node';
import { Element } from '../../../core/interfaces/import-element-content.interface';
import { ImportElementNode } from '../../../core/interfaces/import-element-node.interface';
import { IMPORT_ELEMENT_TREE_ID } from '../constants/import-tree-id';
import { uuidWrapper } from 'src/app/shared/helpers/uuid';

@Injectable({
    providedIn: 'root'
})
export class ImportElementTreeAdapterService {
    public adapt(importElementNodes: ImportElementNode[]): ExternalNode[] {
        return this.adaptImportElementNode(importElementNodes);
    }

    private adaptContent(importElementNode: ImportElementNode) {
        return {
            elementDefinitionName: importElementNode.elementDefinitionName,
            elementDefinitionId: importElementNode.elementDefinitionId,
            fields: importElementNode.fields ?? [],
            quantity: importElementNode.quantity,
            isCountable: importElementNode.isCountable,
            codeName: importElementNode.codeName,
            errors: importElementNode.errors,
            warnings: importElementNode.warnings
        } as Element;
    }

    private adaptImportElementNode(
        importElementNodes: ImportElementNode[]
    ): ExternalNode[] {
        return importElementNodes.map(
            (node) =>
                ({
                    id: uuidWrapper.uuid(),
                    rootId: IMPORT_ELEMENT_TREE_ID,
                    content: this.adaptContent(node),
                    children: this.adaptImportElementNode(node.elements ?? [])
                } as ExternalNode)
        );
    }
}
