import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { PASSWORD_VALIDATION_REGEX_MESSAGES } from 'src/app/core/constants/app-messages';

@Component({
    selector: 'app-password-requirements',
    templateUrl: './password-requirements.component.html',
    styleUrls: ['./password-requirements.component.scss']
})
export class PasswordRequirementsComponent implements AfterViewInit, OnChanges {
    @Input() passwordField?: HTMLInputElement;
    @Input() fieldValue = '';
    @Input() maximumLengthSupported = 1100;

    MARGIN_LEFT_POPUP = 25;
    MARGIN_TOP_POPUP = 25;

    justCreated = true;

    @ViewChild('passwordReqContainer')
    passwordRequirementsContainer?: ElementRef<HTMLInputElement>;
    requirementsWidth?: number;

    errorMessagesToDisplay = PASSWORD_VALIDATION_REGEX_MESSAGES;

    ngAfterViewInit(): void {
        this.requirementsWidth =
            this.passwordRequirementsContainer?.nativeElement?.offsetWidth;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.fieldValue && this.fieldValue) this.justCreated = false;
    }

    get MAXIMIM_LENGTH_SUPPORTED(): string {
        return `${this.maximumLengthSupported}px`;
    }

    get left(): string {
        if (!this.passwordField) return 'auto';

        let left = 0;
        let width = 0;

        if (this.isBigScreen()) {
            left = this.passwordField.offsetLeft;
            width = this.passwordField.offsetWidth + this.MARGIN_LEFT_POPUP;
        } else if (this.requirementsWidth) {
            width = (this.passwordField.offsetWidth - this.requirementsWidth) / 2;
        }

        return `${left + width}px`;
    }

    get top(): string {
        if (!this.passwordField) return 'auto';

        return this.isBigScreen()
            ? `${this.passwordField.offsetTop + this.MARGIN_TOP_POPUP}px`
            : 'auto';
    }

    isBigScreen(): boolean {
        const clientWidth = this.passwordField?.offsetParent?.clientWidth;
        if (!clientWidth) return false;
        return clientWidth > this.maximumLengthSupported;
    }
}
