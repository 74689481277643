export enum StorageKey {
    TOKEN = 'token',
    TOKEN_EXPIRATION = 'tokenExpiration',
    USERID = 'userId',
    NAME = 'userName',
    EMAIL = 'userEmail',
    SOURCE_SELECTION_STORAGE_KEY = 'source-selection',
    VIEW_SELECTION_STORAGE_KEY = 'view-selection'
}

export enum ClaimTypes {
    NameIdentifier = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
    Name = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
    GivenName = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
    Role = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
    Email = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
    Surname = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
    CurrentOrganizationId = 'OrganizationContextId',
    RolePermission = 'RolePermission',
    InstrumentationKey = 'InstrumentationKey'
}

export enum HubEnvironment {
    Staging = 0,
    Development = 1,
    Production = 2
}

export enum UnicityKind {
    OverwriteValue = 0,
    NumberRound = 1,
    StringCaseSensitive = 2,
    StringCaseInsensitive = 3,
    Boolean = 4,
    NumberExactValue = 5
}

export enum ErrorCode {
    Parser = 0,
    Lexer = 1,
    Runtime = 2,
    CompileTime = 3
}

export enum FieldDataType {
    Text = 0,
    Number = 1,
    Boolean = 2,
    Date = 3
}

export enum TrackedChange {
    Unmodified = 0,
    Created = 1,
    Added = 2,
    Modified = 3,
    Deleted = 4,
    Moved = 5
}

export enum Status {
    Published = 0,
    Draft = 1,
    PreviouslyPublished = 2
}

export enum DrawingTargetFramework {
    Unknown = 0,
    AutoCAD = 1,
    Inventor = 2,
    SolidWorks = 3
}

export enum ReportView {
    Table,
    Tree
}

export enum RoundMode {
    Floor = 0,
    Round = 1,
    Ceiling = 2
}

export enum NodeDisableMode {
    Enable = 0,
    EnableIfQty0 = 1,
    EnableIf = 2,
    Disable = 3
}
