import { Injectable } from '@angular/core';

import { DeviceType } from '../enums/device-type.enum';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const TABLET_MEDIA_QUERY = '(max-width: 768px)';
const MOBILE_MEDIA_QUERY = '(max-width: 425px)';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    constructor(private breakpointObserver: BreakpointObserver) {}

    currentDevice(): Observable<DeviceType> {
        return this.breakpointObserver
            .observe([MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY])
            .pipe(
                map((state: BreakpointState) => {
                    if (state.breakpoints[MOBILE_MEDIA_QUERY]) {
                        return DeviceType.MOBILE;
                    }
                    if (state.breakpoints[TABLET_MEDIA_QUERY]) {
                        return DeviceType.TABLET;
                    }
                    return DeviceType.COMPUTER;
                })
            );
    }

    isMobile(): boolean {
        return this.breakpointObserver.isMatched(MOBILE_MEDIA_QUERY);
    }

    isTablet(): boolean {
        return !this.isMobile() && this.breakpointObserver.isMatched(TABLET_MEDIA_QUERY);
    }
}
