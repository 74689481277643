<ng-container *ngIf="view$ | async as data">
    <app-views-toolbar-form
        [ableToEdit]="!!data.userPermission?.isAbleToEditReports"
        [creating]="data.creationMode"
    >
    </app-views-toolbar-form>
    <ng-container>
        <div class="right-container">
            <div class="button-action-container" style="z-index: 2">
                <ng-container *ngIf="data.creationMode || data.modified; else export">
                    <app-custom-button
                        color="primary"
                        text="Discard"
                        class="small"
                        type="stroked"
                        size="small"
                        (buttonClick)="onDiscard()"
                    >
                    </app-custom-button>
                    <app-custom-button
                        size="small"
                        color="primary"
                        type="flat"
                        [disabled]="!data.modified || !isFormValid"
                        [text]="data.creationMode ? 'Create' : 'Save Changes'"
                        (buttonClick)="onSubmit()"
                    ></app-custom-button>
                </ng-container>
                <ng-template #export>
                    <app-custom-button
                        size="small"
                        color="primary"
                        [text]="data.exporting ? 'Exporting...' : 'Export'"
                        type="flat"
                        (buttonClick)="exportToExcel()"
                    ></app-custom-button>
                </ng-template>
            </div>
            <div class="menu-container">
                <app-custom-menu
                    class="menu"
                    icon="more_vert"
                    iconColor="primary"
                    iconType="basic"
                    [iconStyle]="{
                        background: '#F5F5F5',
                        color: 'black',
                        width: '36px',
                        height: '36px'
                    }"
                    [actions]="MENU_ACTIONS"
                >
                </app-custom-menu>
            </div>
        </div>
    </ng-container>
</ng-container>
