import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Crisp } from 'crisp-sdk-web';
import jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ClaimTypes, StorageKey } from '../definitions/enums';
import {
    ChangePasswordResponse,
    LoginOAuthObject,
    LoginObject,
    LoginResponse,
    LogoutResponse,
    UpdateResponse,
    UserInfo
} from '../definitions/interfaces';
import { ID } from '../definitions/types';
import { OrganizationSummaryModel } from '../models/organization-summary.model';
import {
    CHANGE_ORGANIZATION,
    LOGIN,
    LOGINOAUTH,
    LOGOUT,
    ORGANIZATION,
    UPDATE_PERSONAL_INFO,
    UPDATE_PERSONAL_PASSWORD
} from '../resources/resources';
import { LocalStorageService } from './localstorage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private readonly PROTOCOL = environment.configuration.auth_scheme;
    private readonly DOMAIN = environment.configuration.auth_url;
    private readonly VERSION = environment.configuration.auth_url_version;
    private readonly BASE_URL = `${this.PROTOCOL}${this.DOMAIN}${this.VERSION}`;

    constructor(
        private _http: HttpClient,
        private localStorageService: LocalStorageService
    ) {}

    login(loginObject: LoginObject): Observable<boolean> {
        return this._http
            .post<LoginResponse>(`${this.BASE_URL}${LOGIN}`, loginObject)
            .pipe(
                map((result) => {
                    // login successful if there's a jwt token in the response
                    return this.setToken(result);
                })
            );
    }

    loginOAuth(loginOauthObject: LoginOAuthObject): Observable<LoginResponse> {
        return this._http
            .post<LoginResponse>(`${this.BASE_URL}${LOGINOAUTH}`, loginOauthObject)
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    /**
     * Will get the decoded user from the token stored in the local storage
     * or from the token param.
     *
     * It will also check if the token expired and return the user if
     * the user is valid.
     * @param token
     */
    getDecodedUserFromStorage(token?: string): UserInfo | null {
        const isLoggedIn = this.isLoggedIn();
        if (!isLoggedIn) {
            this.clearLocalStorage();
            return null;
        }
        const storageToken =
            token ?? this.localStorageService.get<string>(StorageKey.TOKEN);
        if (storageToken) {
            try {
                const decoded = this.jwtDecode(storageToken);
                if (decoded) {
                    return {
                        name: decoded[ClaimTypes.Name],
                        userId: decoded[ClaimTypes.NameIdentifier],
                        givenname: decoded[ClaimTypes.GivenName],
                        email: decoded[ClaimTypes.Email],
                        role: decoded[ClaimTypes.Role],
                        currentOrganizationId: decoded[ClaimTypes.CurrentOrganizationId],
                        surname: decoded[ClaimTypes.Surname],
                        rolePermission: decoded[ClaimTypes.RolePermission],
                        instrumentationKey: decoded[ClaimTypes.InstrumentationKey]
                    };
                }
            } catch (e) {
                return null;
            }
        }
        return null;
    }

    jwtDecode(storageToken: string) {
        return jwt_decode<any>(storageToken);
    }

    callOAuthRedirect(url: string, body: LoginResponse): Observable<any> {
        return this._http.post(url, body);
    }

    // TODO: fix response from auth api
    private setToken(response: LoginResponse | UpdateResponse): boolean {
        const succeeded =
            (response as UpdateResponse)?.success ??
            (response as LoginResponse)?.result?.succeeded;
        const token =
            (response as LoginResponse)?.token ??
            (response as UpdateResponse)?.result?.token;
        const expiration =
            (response as LoginResponse)?.expiration ??
            (response as UpdateResponse)?.result?.expiration;
        if (succeeded && token) {
            this.localStorageService.set(StorageKey.TOKEN, token);
            this.localStorageService.set(StorageKey.TOKEN_EXPIRATION, expiration);

            const user = this.getDecodedUserFromStorage(token);
            if (user) {
                Crisp.configure('ee6384f9-07da-49d9-a091-c23786fe5ba2');
                Crisp.user.setEmail(user.email);
                if (user.name) Crisp.user.setNickname(user.name);
            }
        }
        return succeeded;
    }

    logout(): Observable<boolean> {
        return this._http.post<LogoutResponse>(`${this.BASE_URL}${LOGOUT}`, {}).pipe(
            map((result) => {
                if (result.succeeded) {
                    this.clearLocalStorage();
                }
                return result.succeeded;
            })
        );
    }

    clearLocalStorage() {
        // remove user from local storage to log user out
        this.localStorageService.clear(StorageKey.TOKEN);
        this.localStorageService.clear(StorageKey.TOKEN_EXPIRATION);
    }

    getUserOrganizations(): Observable<OrganizationSummaryModel[]> {
        const url = `${this.BASE_URL}${ORGANIZATION}`;
        return this._http.get<OrganizationSummaryModel[]>(url);
    }

    changeOrganization(organizationId: ID): Observable<boolean> {
        const url = `${this.BASE_URL}${CHANGE_ORGANIZATION}`;
        return this._http
            .post<LoginResponse>(url, { organizationId })
            .pipe(map((result) => this.setToken(result)));
    }

    updateOrganization(
        organization: Partial<OrganizationSummaryModel>
    ): Observable<{ succeeded: boolean }> {
        const url = `${this.BASE_URL}${ORGANIZATION}`;
        return this._http.put<{ succeeded: boolean }>(url, { ...organization });
    }

    /**
     * Check the expiration token and returns true if it's still valid
     * @returns true if the `token` is still valid, otherwise `false`
     */
    isLoggedIn(): boolean {
        const expiration = this.getExpiration();
        if (expiration) return Date.now() < expiration.getTime();
        return false;
    }

    private getExpiration(): Date | null {
        const expiration = this.localStorageService.get<string>(
            StorageKey.TOKEN_EXPIRATION
        );
        if (expiration) return new Date(expiration);
        return null;
    }

    updateUserInformation(data: { name: string; surnames: string }): Observable<boolean> {
        const url = `${this.BASE_URL}${UPDATE_PERSONAL_INFO}`;
        return this._http
            .post<LoginResponse>(url, data)
            .pipe(map((result) => this.setToken(result)));
    }

    updateUserPassword(data: {
        currentPassword: string;
        newPassword: string;
        repeatPassword: string;
    }): Observable<ChangePasswordResponse> {
        const url = `${this.BASE_URL}${UPDATE_PERSONAL_PASSWORD}`;
        return this._http.post<ChangePasswordResponse>(url, data);
    }
}
