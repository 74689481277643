import { IUnique } from '../definitions/interfaces';
import { BaseModel } from './base.model';
import { UserInfoInterface } from '../interfaces/user-info.interface';

export enum UserInfoState {
    CREATE = 1,
    INVITED = 2,
    EXISTING = 3
}

export class UserInfoModel extends BaseModel implements UserInfoInterface {
    email!: string;
    fullname!: string;
    roles!: string[];
    state!: UserInfoState;
    emailConfirmed!: boolean;
    roleAdmin!: string;
    roleUser!: string;
    dateLastInvitation!: Date;

    constructor(obj?: IUnique) {
        super(obj);
    }

    protected initialize(obj: any): void {
        if (obj) {
            // This way instead of Object.assign because
            // assing can set fields that doesn't belong to the object
            this.email = obj.email as string;
            this.fullname = obj.fullname as string;
            this.roles = obj.roles as string[];
            this.state = (obj.state ?? UserInfoState.CREATE) as UserInfoState;
            this.emailConfirmed = obj.emailConfirmed as boolean;
            this.dateLastInvitation = obj.dateLastInvitation as Date;
        }
    }
}
