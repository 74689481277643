import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-form-error-messages',
    templateUrl: './form-error-messages.component.html'
})
export class FormErrorMessagesComponent {
    @Input() display?: boolean;
    @Input() message = '';
}
