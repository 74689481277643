import { Component, ContentChild, Input } from '@angular/core';
import { GetInitialsService } from '../../services/get-initials.service';
import { UserNameComponent } from '../user-name/user-name.component';

@Component({
    selector: 'app-user-image',
    templateUrl: './user-image.component.html',
    styleUrls: ['./user-image.component.scss']
})
export class UserImageComponent {
    @Input() text?: string;
    @Input() size = 32;
    @Input() fontSize = 20;
    @Input() initialsWithContent?: boolean;

    @ContentChild(UserNameComponent) userName?: UserNameComponent;

    constructor(private getInitialService: GetInitialsService) {}

    get getInitials(): string {
        return this.getInitialService.getInitials(this.text ?? '');
    }

    get getSize() {
        return `${this.size}px`;
    }

    get getFontSize() {
        return `${this.fontSize}px`;
    }

    get noText() {
        if (this.text) {
            return this.text.length === 0;
        }
        if (this.userName) {
            return this.userName.noText;
        }
        return true;
    }
}
