import { Component, Input } from '@angular/core';
import { GetInitialsService } from 'src/app/shared/services/get-initials.service';
import { UserMenuSectionComponent } from '../user-menu-section/user-menu-section.component';

@Component({
    selector: 'app-user-menu-entity[text][name]',
    templateUrl: './user-menu-entity.component.html',
    styleUrls: ['./user-menu-entity.component.scss']
})
export class UserMenuEntityComponent {
    @Input() text!: string;
    @Input() name!: string;
    @Input() email?: string;
    @Input() settingsRoute!: string;

    constructor(
        private parent: UserMenuSectionComponent,
        private getInitialService: GetInitialsService
    ) {}

    getImage(): string {
        return this.getInitialService.getInitials(this.name);
    }
}
