import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Action } from '../../interfaces/action';

export type ButtonType =
    | 'basic'
    | 'raised'
    | 'stroked'
    | 'flat'
    | 'link'
    | 'basic gray'
    | 'squared';
export type ButtonSize = 'auto' | 'small' | 'medium' | 'large';
export type ThemeColor = 'primary' | 'accent' | 'warn';

@Component({
    selector: 'app-custom-button',
    templateUrl: './custom-button.component.html',
    styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent {
    @Input() text?: string;
    @Input() icon?: string;
    @Input() svgIcon?: string;
    @Input() type: ButtonType = 'basic';
    @Input() size: ButtonSize = 'auto';
    @Input() color?: ThemeColor;
    @Input() fontColorMode?: 'light';
    @Input() styles?: any;
    @Input() buttonContainer = false;
    @Input() iconPosition: 'left' | 'right' = 'left';
    @Input() showButton = true;
    @Input() disabled = false;
    @Input() stopPropagation = true;

    /**
     * @description It will anable a second button in the same button instance that allows to show a menu,
     * together with the definition of this input you shoul define the actions that you want to trigger
     * @type {boolean}
     * @example "true/false"
     */
    @Input() enableSecondButton = false;
    @Input() actions: Action[] = [];

    @Input() cursorStyle = '';
    @Output() buttonClick = new EventEmitter();

    get buttonType(): string {
        if (this.type === 'basic') return 'mat-button';
        if (this.type === 'squared') return 'mat-stroked-button';
        return `mat-${this.type}-button`;
    }

    get blueHover(): boolean {
        return !this.disabled && this.type === 'stroked';
    }

    get isRoundIconButton(): boolean {
        return this.type !== 'squared' && !this.text && !!(this.svgIcon || this.icon);
    }

    get fontColor(): string | undefined {
        if (this.fontColorMode === 'light') return '#FFFFFF';
        return undefined;
    }

    onClick(event: Event): void {
        if (this.stopPropagation) event.stopImmediatePropagation();
        if (this.disabled) return;
        this.buttonClick.emit();
    }
}
