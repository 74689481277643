import { FieldDataType } from 'src/app/core/definitions/enums';
import {
    roundModeToString,
    unicityKindToString
} from 'src/app/core/helpers/string-helpers';
import { FieldDefinitionDetail } from 'src/app/core/interfaces/field-definition-detail.interface';

export function getDataTypeName(dataType: FieldDataType): string {
    return FieldDataType[dataType]?.toLocaleLowerCase() || '';
}

const indentityFunc = (value: any) => value;

export const CODE_NAME = 'codeName';
export const NAME = 'name';
export const UNICITY_KIND = 'unicityKind';
export const STEP = 'step';
export const OFFSET = 'offset';
export const ROUND_MODE = 'roundMode';

/**
 * Compare 2 FieldDefinitions and return differences.
 * This function doesn't work with objects that contain objects
 * @return {Map<string, { current: any; detail: any }>} Map<string, { current: string, detail: string }>
 */
export function diff(
    current: FieldDefinitionDetail,
    detail: FieldDefinitionDetail
): Map<string, { current: any; detail: any }> {
    const propertiesToCompare = [
        { key: CODE_NAME, displayName: 'CodeName', adapter: indentityFunc },
        { key: NAME, displayName: 'Label', adapter: indentityFunc },
        { key: UNICITY_KIND, displayName: 'Unicity Kind', adapter: unicityKindToString },
        { key: STEP, displayName: 'Step', adapter: indentityFunc },
        { key: OFFSET, displayName: 'Offset', adapter: indentityFunc },
        { key: ROUND_MODE, displayName: 'Round Mode', adapter: roundModeToString }
    ];

    const differences = new Map<string, { current: any; detail: any }>();

    propertiesToCompare.forEach((property) => {
        if ((current as any)[property.key] !== (detail as any)[property.key]) {
            differences.set(property.displayName, {
                current: property.adapter((current as any)[property.key]),
                detail: property.adapter((detail as any)[property.key])
            });
        }
    });

    return differences;
}

export function detectPosition(
    index: number,
    length: number
): 'start' | 'middle' | 'end' {
    if (index == 0 && index + 1 === length) return 'end';
    if (index == 0) return 'middle';
    if (index + 1 === length) return 'end';
    return 'middle';
}
