import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { ContextMenuComponent } from '../components/context-menu/context-menu.component';

@Directive({
    selector: '[appContextMenu]'
})
/**
 * Directive that enables a context menu on an element.
 */
export class ContextMenuDirective implements OnInit {
    @Input('appContextMenu') contextMenu?: ContextMenuComponent;
    @Input() contextMenuData?: any;

    constructor(private el: ElementRef) {}

    ngOnInit() {
        // Adds a class to the element to mark it as a context menu directive.
        // This class allows for easy identification and styling of elements with the directive applied.
        this.el.nativeElement.classList.add('context-menu-directive');
    }

    /**
     * Handles the right-click event to open the context menu.
     * This will make sure we do not open two context menus:
     * 1. It will check that this is the closest element with
     * the directive applied to use only the inner context menu
     * @param event The right-click event.
     */
    @HostListener('contextmenu', ['$event'])
    onRightClick(event: MouseEvent) {
        event.preventDefault();
        const targetElement = event.target as HTMLElement;
        const closestAncestorWithDirective = targetElement.closest(
            '.context-menu-directive'
        );
        if (this.contextMenu && closestAncestorWithDirective == this.el.nativeElement) {
            this.contextMenu.data = this.contextMenuData;
            this.contextMenu.openMenu({ x: event.clientX, y: event.clientY });
        }
    }
}
