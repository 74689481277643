import { Pipe, PipeTransform } from '@angular/core';
import { ElementType } from '../../modules/patterns/enums/elementType';

@Pipe({
    name: 'elementType'
})
export class ElementTypePipe implements PipeTransform {
    transform(value: ElementType, ...args: unknown[]): string {
        if (!value) {
            return '';
        }
        if (!(value in ElementType)) {
            return value.toString();
        }
        return this.splitCamelCase(ElementType[value]);
    }

    splitCamelCase(text: string) {
        return text.replace(/([a-z])([A-Z])/g, '$1 $2');
    }
}
