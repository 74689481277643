import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html'
})
export class ErrorPageComponent {
    constructor(private location: Location) {}

    onButtonClick(): void {
        this.location.back();
    }
}
