<article>
    <app-custom-icon-menu class="menu-button">
        <button [matMenuTriggerFor]="deleteMenu" (click)="$event.stopPropagation()">
            <mat-icon class="delete">delete</mat-icon>
        </button>
        <button
            [matMenuTriggerFor]="disabledMenu"
            *ngIf="showDisabledOptions"
            (click)="$event.stopPropagation()"
        >
            <mat-icon
                class="blue svgIcon"
                [class.gray]="isNodeDisabled"
                [svgIcon]="isNodeDisabledIconSvg"
            ></mat-icon>
        </button>
        <button
            [matMenuTriggerFor]="hiddenMenu"
            *ngIf="showDisabledOptions"
            (click)="$event.stopPropagation()"
        >
            <mat-icon [class.blue]="!isNodeHidden">{{ isNodeHiddenIcon }}</mat-icon>
        </button>
    </app-custom-icon-menu>
</article>

<mat-menu class="disabled-settings-menu" #disabledMenu="matMenu">
    <div class="content">
        <a
            class="menu-element regular"
            [class.selected]="selectedMode === NodeDisableMode.Enable"
            (click)="setDisableMode(NodeDisableMode.Enable)"
        >
            <span class="menu-label">
                <mat-icon class="blue" svgIcon="enable"></mat-icon> Always Enable
            </span>
        </a>
        <a
            class="menu-element regular"
            [class.selected]="selectedMode === NodeDisableMode.EnableIfQty0"
            (click)="setDisableMode(NodeDisableMode.EnableIfQty0)"
        >
            <span class="menu-label">
                <mat-icon class="blue" svgIcon="null"></mat-icon> Enable if ≠ 0
            </span>
        </a>
        <a
            class="menu-element regular"
            [class.selected]="selectedMode === NodeDisableMode.EnableIf"
            (click)="setDisableMode(NodeDisableMode.EnableIf)"
        >
            <span class="menu-label">
                <mat-icon class="blue" svgIcon="function_outline"></mat-icon> Enable if...
            </span>
        </a>
        <a
            class="menu-element regular"
            [class.selected]="selectedMode === NodeDisableMode.Disable"
            (click)="setDisableMode(NodeDisableMode.Disable)"
        >
            <span class="menu-label">
                <mat-icon class="gray" svgIcon="cancel"></mat-icon> Disable
            </span>
        </a>
        <a
            class="menu-element regular learn-more"
            href="app/help/detail?doc=02_User_guide%2F05_Patterns_advanced.md"
            target="_blank"
            (click)="setDisableMode(NodeDisableMode.Disable)"
        >
            <span class="menu-label">
                <mat-icon class="blue">info</mat-icon> Learn More
            </span>
        </a>
    </div>
</mat-menu>

<mat-menu class="hidden-settings-menu" #hiddenMenu="matMenu">
    <div class="content">
        <a
            class="menu-element regular"
            [class.selected]="!disableInfo.isNodeHidden"
            (click)="setIsNodeHidden(false)"
        >
            <span class="menu-label">
                <mat-icon class="blue">visibility</mat-icon> Make Standard
            </span>
        </a>

        <a
            class="menu-element regular"
            [class.selected]="disableInfo.isNodeHidden"
            (click)="setIsNodeHidden(true)"
        >
            <span class="menu-label">
                <mat-icon class="gray">visibility_off</mat-icon> Make Virtual
            </span>
        </a>
        <a
            class="menu-element regular learn-more"
            href="app/help/detail?doc=02_User_guide%2F05_Patterns_advanced.md"
            target="_blank"
        >
            <span class="menu-label">
                <mat-icon class="blue">info</mat-icon> Learn More
            </span>
        </a>
    </div>
</mat-menu>

<mat-menu class="delete-node-menu" #deleteMenu="matMenu">
    <div class="content">
        <a class="menu-element delete" (click)="onDelete($event)">
            <span class="menu-label">
                <mat-icon class="delete">delete</mat-icon> Confirm
            </span>
        </a>
    </div>
</mat-menu>
