import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FRAMEWORK_TRANSFERS_ICONS } from 'src/app/core/constants/software-info';
import { DrawingTargetFramework } from 'src/app/core/definitions/enums';

@Component({
    selector: 'app-import-list-item',
    templateUrl: './import-list-item.component.html',
    styleUrls: ['./import-list-item.component.scss']
})
export class ImportListItemComponent implements OnInit {
    @Input() drawingName!: string;
    @Input() patternName!: string;
    @Input() message!: string;
    @Input() exportedBy!: string;
    @Input() exportedFrom!: string;
    @Input() exportedAt!: Date;
    @Input() targetFramework!: string;
    @Input() disableHoverStyles = false;
    @Input() disableRightBorder = false;
    @Input() disableHighlight = false;
    @Input() disableGeneralInfo = false;

    @Input() selected = false;

    @Output() clicked = new EventEmitter();

    frameworkConversion: { name: string; image: string; image_transparent: string } =
        FRAMEWORK_TRANSFERS_ICONS[DrawingTargetFramework.AutoCAD];

    ngOnInit(): void {
        if (this.targetFramework)
            this.frameworkConversion = FRAMEWORK_TRANSFERS_ICONS[this.targetFramework];
    }

    onClick() {
        this.clicked.emit();
    }
}
