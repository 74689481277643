import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../store/reducer/root.reducer';
import { fromRoot } from '../../../store';
import { ID } from '../../../core/definitions/types';
import { Observable, Subscription } from 'rxjs';
import { GetInitialsService } from '../../services/get-initials.service';

@Component({
    selector: 'app-user-name',
    templateUrl: './user-name.component.html'
})
export class UserNameComponent implements OnInit, OnDestroy {
    @Input() id!: string;
    @Input() initialsOnly?: boolean;

    userDisplayNames: { [key: ID]: string } | null = null;
    userDisplayNames$: Observable<{ [key: ID]: string } | null>;

    loadingOrError = true;
    displayName: string | null = null;
    initials: string | null = null;
    noText = true;

    subscriptions: Subscription[] = [];

    constructor(
        private store: Store<RootState>,
        private getInitialService: GetInitialsService
    ) {
        this.userDisplayNames$ = this.store.select(
            fromRoot.selectOrganizationUsersDisplayNames
        );
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.userDisplayNames$.subscribe((namesMap) => {
                this.userDisplayNames = namesMap;
                if (namesMap) {
                    this.loadingOrError = false;
                    this.displayName = namesMap[this.id] ?? this.id;
                    this.initials = this.getInitialService.getInitials(this.displayName);
                    this.noText = this.displayName.length === 0;
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
