import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from 'src/app/shared/shared.module';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { SettingUpUserComponent } from './component/setting-up-user/setting-up-user.component';
import { UsersEffects } from './store/users.effects';
import { UsersRoutingModule } from './users-routing.module';
import { ResetPasswordSuccessComponent } from './component/reset-password-success/reset-password-success.component';
import { SettingUpUserSuccessComponent } from './component/setting-up-user-success/setting-up-user-success.component';
import { ForgotPasswordEmailSuccessComponent } from './component/forgot-password-email-success/forgot-password-email-success.component';

@NgModule({
    declarations: [
        ForgotPasswordComponent,
        ResetPasswordComponent,
        SettingUpUserComponent,
        ResetPasswordSuccessComponent,
        SettingUpUserSuccessComponent,
        ForgotPasswordEmailSuccessComponent
    ],
    imports: [
        SharedModule.forRoot(),
        CommonModule,
        FormsModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        ReactiveFormsModule,
        UsersRoutingModule,
        EffectsModule.forFeature([UsersEffects])
    ]
})
export class UsersModule {}
