<div class="body-regular-14 user-email-texts" *ngIf="userInfoState !== createInfoState">
    {{ userEmail }}
</div>
<div
    class="invite-row-container"
    [ngClass]="{ 'field-invalid': invalidAndErrors.invalid }"
>
    <app-custom-input
        *ngIf="userInfoState === createInfoState"
        placeholder="Email"
        label="Email"
        [(value)]="inviteEmail"
        (valueChange)="onValueChange($event)"
        type="email"
        (onFocusOutField)="onFocusOut()"
    ></app-custom-input>
</div>
