import { Component } from '@angular/core';
import { routes } from 'src/app/modules/personal/personal-routing.module';

@Component({
    selector: 'app-personal',
    templateUrl: './personal.component.html',
    styleUrls: ['./personal.component.scss']
})
export class PersonalComponent {
    pages = routes.filter((route) => route?.data?.title);
}
