import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: 'textarea[appAutoGrow]'
})
export class AutoGrowDirective {
    constructor(public element: ElementRef) {}
    @HostListener('input', ['$event.target'])
    onInput(textArea: HTMLTextAreaElement): void {
        this.adjust();
    }

    ngOnInit(): void {
        this.adjust();
    }

    adjust(): void {
        this.element.nativeElement.style.overflow = 'hidden';
        this.element.nativeElement.style.height = 'auto';
        this.element.nativeElement.style.height =
            this.element.nativeElement.scrollHeight + 'px';
    }
}
