<mat-form-field appearance="outline" color="primary">
    <mat-label>{{ label }}</mat-label>
    <input
        [style.display]="
            !allowBigValue || !value || value.length <= valueForChangeBetweenSmallBigValue
                ? 'block'
                : 'none'
        "
        matInput
        #smallField
        [disabled]="disabled"
        [ngModel]="value"
        (ngModelChange)="onChange($event)"
        [placeholder]="placeholder"
        [type]="type"
        (focusout)="onFocusOut()"
        [readonly]="readonly"
        [style.cursor]="cursorStyle"
    />
    <textarea
        [style.display]="
            allowBigValue && value && value.length > valueForChangeBetweenSmallBigValue
                ? 'block'
                : 'none'
        "
        matInput
        #bigField
        [ngModel]="value"
        (ngModelChange)="onChange($event)"
        [placeholder]="placeholder"
    >
    </textarea>
</mat-form-field>
