import { Pipe, PipeTransform } from '@angular/core';
import { UnicityKind } from '../../core/definitions/enums';

@Pipe({
    name: 'unicityDisplay'
})
export class UnicityDisplayPipe implements PipeTransform {
    transform(value?: UnicityKind, tooltip?: boolean, ...args: unknown[]): string {
        if (tooltip) {
            return this.getTooltip(value);
        }
        return this.getSymbol(value);
    }

    getTooltip(value?: UnicityKind): string {
        switch (value) {
            case UnicityKind.NumberExactValue:
                return 'Exact value';
            case UnicityKind.StringCaseInsensitive:
                return 'Case Insensitive';
            case UnicityKind.StringCaseSensitive:
                return 'Case sensitive';
            case UnicityKind.NumberRound:
                return `Round value`;
            case UnicityKind.Boolean:
            case UnicityKind.OverwriteValue:
            default:
                return '';
        }
    }

    getSymbol(value?: UnicityKind): string {
        switch (value) {
            case UnicityKind.NumberExactValue:
                return '=x';
            case UnicityKind.StringCaseInsensitive:
                return 'A=a';
            case UnicityKind.StringCaseSensitive:
                return 'A≠a';
            case UnicityKind.NumberRound:
                return `≈x`;
            case UnicityKind.Boolean:
            case UnicityKind.OverwriteValue:
            default:
                return '';
        }
    }
}
