import { ID } from 'src/app/core/definitions/types';
import { UserInfoInterface } from '../../core/interfaces/user-info.interface';

export function getUserDisplayNamesMap(users: UserInfoInterface[]): {
    [key: ID]: string;
} {
    const displayNames: { [key: ID]: string } = {};
    users.forEach((user) => {
        displayNames[user.id] = user.fullname;
    });
    return displayNames;
}
