export default `
    .ace-flow-theme .ace_function {
        color: #774499;
    }

    .ace-flow-theme .ace_language {
        color: #38978D;
    }

    .ace-flow-theme .ace_identifier {
        color: #1e1e1e;
    }

    .ace-flow-theme .ace_numeric {
        color: #CC871F;
    }

    .ace-flow-theme .ace_string {
        color: #CC871F;
    }

    .ace-flow-theme .ace_field {
        color: #006FAA;
    }

     .ace-flow-theme .ace_selection {
        background: rgba(200, 200, 200, 0.4);
    }
`;
