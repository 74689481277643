<div class="expansion-card" [class.band-body]="!header" [style.border-color]="color">
    <div
        class="info"
        [class.band-header]="header"
        [style.border-color]="color"
        [class.pointer]="!header"
        (click)="header ? null : onClick($event)"
    >
        <div>
            <h1 class="title" [class.big-text]="header">{{ title }}</h1>
            <div class="subtitle">{{ subtitle }}</div>
        </div>
        <div class="icon" *ngIf="!header">
            <mat-icon aria-hidden="false">{{
                _showContent ? 'expand_less' : 'expand_more'
            }}</mat-icon>
        </div>
    </div>
    <div *ngIf="_showContent" [@inOutAnimation] class="content">
        <ng-content></ng-content>
    </div>
</div>
