<ng-template #defaultOption>
    <p>Using section but passing nothing</p>
</ng-template>

<div class="container">
    <div
        class="section"
        [class.section]="sectionTemplateRef"
        [style.padding-left]="paddingLeft"
    >
        <ng-container [ngTemplateOutlet]="sectionTemplateRef"></ng-container>
    </div>
</div>
