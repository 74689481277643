<div class="container">
    <div class="content">
        <mat-icon
            aria-hidden="true"
            aria-label="Warning element"
            svgIcon="element-warning"
            class="icon {{ warningType }}"
        ></mat-icon>
        <span *ngIf="showWarningWord" class="tag {{ warningType }}">Warning:</span>
        <span *ngIf="message" class="message">{{ message }}</span>
        <ng-content></ng-content>
        <button
            class="tip"
            *ngIf="tip"
            mat-icon-button
            aria-label="tip"
            (click)="toggleTip()"
        >
            <mat-icon svgIcon="tip"></mat-icon>
        </button>
    </div>
    <div *ngIf="showTip" class="tip-content" [@fadeInOut]>
        <span class="message">{{ tip }}</span>
    </div>
</div>
