import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { getSelectors, routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { filter, map, tap } from 'rxjs/operators';
import { encodeSourcesQueryParam } from 'src/app/core/helpers/string-helpers';

const CODE = 'code';
const BUILD_READY = 'build-ready';
const { selectQueryParams, selectUrl, selectRouteParam } = getSelectors();

@Injectable()
export class RedirectEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private router: Router
    ) {}

    redirectFromDesktop$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(routerNavigatedAction),
                concatLatestFrom(() => [
                    this.store.select(selectRouteParam(CODE)),
                    this.store.select(selectQueryParams),
                    this.store.select(selectUrl)
                ]),
                filter(([{ payload }, code, queryParams, url]) => {
                    return !!code && url.startsWith('/redirect');
                }),
                map(([{ payload }, code, queryParams]) => ({
                    code,
                    queryParams
                })),
                tap(({ code, queryParams }) => {
                    if (code === BUILD_READY) {
                        this.handleRedirectBuildReady(queryParams);
                    }
                })
            ),
        { dispatch: false }
    );

    handleRedirectBuildReady(queryParams: Params) {
        const linkId = queryParams['linkId'];
        const buildId = queryParams['buildId'];
        const params = { sources: encodeSourcesQueryParam([{ linkId, buildId }]) };
        this.router
            .navigate([`app/views`], {
                queryParams: {
                    ...params
                }
            })
            // eslint-disable-next-line no-console
            .catch(console.warn);
    }
}
