import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { NodeInfo } from '../../../modules/linked-trees/interfaces/node-info';

const OPEN = 'open';
const CLOSED = 'closed';

@Component({
    selector: 'app-context-menu',
    templateUrl: './context-menu.component.html',
    styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit, OnDestroy {
    @Input() allowedActions = {
        copy: true,
        paste: true,
        delete: true
    };
    @Output() copy = new EventEmitter<NodeInfo>();
    @Output() paste = new EventEmitter();
    @Output() delete = new EventEmitter<NodeInfo>();

    /// Generic code
    @ViewChild('menu') menuTemplate!: TemplateRef<any>;
    private overlayRef: OverlayRef | null = null;
    private unlistenOutsideClick: (() => void) | null = null;
    contextMenuState: string = CLOSED;
    data: any;

    /// End of generic code

    constructor(
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        private renderer: Renderer2
    ) {}

    tooltipContent?: string = undefined;
    showClipboardPermissionTooltip = false;
    pasteContentAvailable = false;

    ngOnInit() {
        this.checkClipboardPermission();
    }

    private checkClipboardPermission() {
        if (!navigator.permissions) {
            //eslint-disable-next-line no-console
            console.warn('Permissions API is not supported in this browser.');
            return;
        }

        navigator.permissions
            .query({ name: 'clipboard-read' as PermissionName })
            .then((permissionStatus) => {
                this.updateTooltipVisibility(permissionStatus.state);
                permissionStatus.onchange = () => {
                    this.updateTooltipVisibility(permissionStatus.state);
                };
            });
    }

    private updateTooltipVisibility(state: PermissionState) {
        this.showClipboardPermissionTooltip = state !== 'granted';
    }

    onCopy() {
        const node: NodeInfo = this.data.node;
        if (node) {
            this.copy.emit(this.data.node);
        }
        this.closeMenu();
    }

    onPaste() {
        this.paste.emit();
        this.closeMenu();
    }

    onDelete() {
        this.delete.emit(this.data.node);
        this.closeMenu();
    }

    /// The following code will probably need to be refactored
    /// when we add another context menu to the app.

    openMenu({ x, y }: { x: number; y: number }) {
        this.closeMenu(false);
        this.contextMenuState = OPEN;
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo({ x, y })
            .withPositions([
                {
                    originX: 'start',
                    originY: 'top',
                    overlayX: 'start',
                    overlayY: 'top'
                }
            ]);

        const overlayConfig = new OverlayConfig({ positionStrategy });
        this.overlayRef = this.overlay.create(overlayConfig);

        const portal = new TemplatePortal(this.menuTemplate, this.viewContainerRef);
        this.overlayRef.attach(portal);

        this.unlistenOutsideClick = this.renderer.listen('document', 'click', (event) => {
            if (
                this.overlayRef &&
                !this.overlayRef.overlayElement.contains(event.target as HTMLElement)
            ) {
                this.closeMenu();
            }
        });
    }

    closeMenu(statusUpdate = true) {
        if (statusUpdate) {
            this.contextMenuState = CLOSED;
        }
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
        if (this.unlistenOutsideClick) {
            this.unlistenOutsideClick();
            this.unlistenOutsideClick = null;
        }
    }

    ngOnDestroy(): void {
        this.closeMenu(true);
    }

    /// End of code that will probably need to be refactored
}
