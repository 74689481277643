<app-account-result-page>
    <div id="result-text">The link has expired.</div>
    <div id="result-image">
        <img src="/assets/invalid-token-page-logo.svg" alt="error page logo" />
    </div>
    <div>
        <div class="result-info">
            <span class="bold">The link you are trying to use has expired.</span> It can
            be because it was created more than 90 days ago, has already been used, or has
            been replaced by a new link.
        </div>
        <div class="result-info">
            <span class="bold">Request a new link on this page: </span>
            <a routerLink="/users/forgot-password">Forgot your password?</a>
        </div>
        <div class="result-info">
            If the problem persists, please contact your system administrator.
        </div>

        <div class="result-info button-container">
            <app-custom-button
                text="Go Home"
                color="primary"
                type="raised"
                size="large"
                (buttonClick)="navigateHome()"
            >
            </app-custom-button>
        </div>
    </div>
</app-account-result-page>
