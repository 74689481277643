import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[appCustomProperties]'
})
export class CustomPropertiesDirective implements OnInit {
    @Input() appCustomProperties: any;

    constructor(private el?: ElementRef) {}

    ngOnInit() {
        if (!this.appCustomProperties) {
            return;
        }

        Object.entries(this.appCustomProperties)
            .filter(([_, value]) => {
                return ['string', 'number'].includes(typeof value);
            })
            .forEach(([key, value]) => {
                const propertyName = `--appCustomProperty-${key}`;
                const number = `${value}`.replace(/[px%rem]*/g, '');
                this.el?.nativeElement.style.setProperty(propertyName, number);
            });
    }
}
