<div class="container" *ngIf="!(loading$ | async)">
    <div class="setting-up-container">
        <img src="/assets/workroads-logo-login.svg" alt="workroads logo" />
        <div class="subtitle">
            Set a password to complete the creation of your account
        </div>

        <section>
            <form class="idable">
                <fieldset>
                    <label for="first-name">FIRST NAME</label>
                    <input
                        #input
                        #firstNameInput
                        id="first-name"
                        type="text"
                        (input)="onChangeFirstName(firstNameInput)"
                        [value]="name"
                    />
                    <app-form-error-messages
                        [display]="!firstNameInput.checkValidity() || nameWhiteSpace"
                        message="First name is required."
                    ></app-form-error-messages>
                </fieldset>
                <fieldset>
                    <label for="last-name">LAST NAME</label>
                    <input
                        #input
                        #lastNameInput
                        id="last-name"
                        type="text"
                        (input)="onChangeLastName(lastNameInput)"
                        [value]="surnames"
                    />
                    <app-form-error-messages
                        [display]="!lastNameInput.checkValidity() || surnameWhiteSpace"
                        message="Last name is required."
                    ></app-form-error-messages>
                </fieldset>
                <fieldset #passwordField>
                    <label for="password">PASSWORD</label>
                    <mat-icon *ngIf="pwd.type === 'text'" (click)="pwd.type = 'password'">
                        visibility_off
                    </mat-icon>
                    <mat-icon *ngIf="pwd.type === 'password'" (click)="pwd.type = 'text'">
                        visibility
                    </mat-icon>
                    <input
                        #pwd
                        #input
                        id="password"
                        type="password"
                        [pattern]="passwordRegex"
                        [(ngModel)]="password"
                        (input)="pwd.required = true"
                    />
                    <app-form-error-messages
                        [display]="!pwd.checkValidity()"
                        message="Password doesn't meet the requirements."
                    ></app-form-error-messages>
                </fieldset>
                <fieldset>
                    <label for="repeat">CONFIRM PASSWORD</label>
                    <mat-icon
                        *ngIf="repeat.type === 'text'"
                        (click)="repeat.type = 'password'"
                    >
                        visibility_off
                    </mat-icon>
                    <mat-icon
                        *ngIf="repeat.type === 'password'"
                        (click)="repeat.type = 'text'"
                    >
                        visibility
                    </mat-icon>
                    <input
                        #repeat
                        #input
                        id="repeat"
                        type="password"
                        (input)="onConfirmPasswordChange(repeat)"
                        [value]="repeatPassword"
                    />
                    <app-form-error-messages
                        [display]="mismatch"
                        message="Password mismatch"
                    ></app-form-error-messages>
                </fieldset>
            </form>
            <app-custom-button
                #submit
                size="large"
                color="primary"
                text="CREATE ACCOUNT"
                type="flat"
                [disabled]="
                    !formIsValid || mismatch || surnameWhiteSpace || nameWhiteSpace
                "
                (buttonClick)="onSubmit()"
            >
            </app-custom-button>
            <app-password-requirements
                *ngIf="passwordFieldForValidation"
                [passwordField]="passwordFieldForValidation"
                [fieldValue]="password"
            >
            </app-password-requirements>
        </section>
    </div>
</div>
<div *ngIf="loading$ | async" class="loader-container">
    <app-loader></app-loader>
</div>
