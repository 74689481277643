import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-not-selected-item[text]',
    templateUrl: './not-selected-item.component.html',
    styleUrls: ['./not-selected-item.component.scss']
})
export class NotSelectedItemComponent {
    @Input() text!: string;
}
