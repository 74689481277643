import { Ace } from 'ace-builds';
import { FlowTokenIterator } from './flow-token-iterator';
import Token = Ace.Token;
import EditSession = Ace.EditSession;
import Editor = Ace.Editor;
import { AutocompleteCadData } from '../../../../modules/shared-nodes/interfaces/autocomplete-cad-data';
import { IDENTIFIER_RE } from '../../../../core/constants/regular-expressions';
import { isFlowMode } from './utils';

export interface Completion {
    caption: string;
    meta: string;
    value: string;
    score: number;
    //docHTML?: string;
    className?: string;
}

export class FlowCompletions implements Ace.Completer {
    context!: AutocompleteCadData;

    constructor(private flowTokenIterator: FlowTokenIterator) {}

    updateContext(newContext: AutocompleteCadData): void {
        this.context = newContext;
    }

    getCompletions(
        editor: Editor,
        session: EditSession,
        pos: { row: number; column: number },
        prefix: string,
        callback: Ace.CompleterCallback
    ) {
        if (!isFlowMode(session.getValue())) {
            return [];
        }
        const state = session.getState(pos.row);
        const completions = this.completions(state, session, pos, prefix);
        const filtered = this.filterCompletions(completions, prefix);
        callback(null, filtered);
        return filtered;
    }

    private completions(
        state: string,
        session: EditSession,
        pos: { row: number; column: number },
        prefix: string
    ) {
        const token: Token | null = session.getTokenAt(pos.row, pos.column);

        // State property has a special case (when we are not finished writing the field)
        // token is field
        const currentlyWritingField = token?.type === 'field';
        if (state === 'property' || currentlyWritingField) {
            return this.getPropertiesCompletions(session, pos, token, prefix);
        }
        return this.getExpressionCompletions();
    }

    private filterCompletions(completions: Completion[], prefix: string): Completion[] {
        if (prefix.length === 0) {
            return completions;
        }
        const lowerPrefix = prefix.toLowerCase();
        return completions.filter(
            (completion) => completion.caption.toLowerCase().startsWith(lowerPrefix) // why caption? to have special syntax completions like .""
        );
    }

    /**
     * Returns the completions for the "." token in the "property" state
     *
     * The user tries to access a property for a node or for another property.
     *
     * Ex1 : parent.link.____  Returns [parent, link, DynamicProperties, ... ]
     * Ex2 : parent.DynamicProperties.____
     *
     * @param session
     * @param pos
     * @param token
     * @param prefix
     */
    getPropertiesCompletions(
        session: EditSession,
        pos: { row: number; column: number },
        token: Token | null,
        prefix: string
    ): Completion[] {
        let lastToken = token;
        if (prefix) {
            lastToken = this.getPreviousToken(session, pos, prefix);
        }
        if (!lastToken) {
            return this.getNodeReferences();
        }
        // token at position is "." -> see token at col - 1
        if (lastToken.value === '.') {
            lastToken = this.getPreviousToken(session, pos, '');
        }
        // last token is parent reference -> suggest groups, flat properties and node references
        if (lastToken && lastToken.type === 'variable.language') {
            return [
                ...this.getGroupCompletions(),
                ...this.getFlatProperties(),
                ...this.getNodeReferences()
            ];
        }
        // last token is group key -> suggest group key items
        const propertyNameAlternatives = [
            lastToken?.value ?? '',
            `"${lastToken?.value}"`
        ];
        const groupSet = this.getGroupsSet();
        if (
            lastToken &&
            lastToken.type === 'identifier' &&
            propertyNameAlternatives.some((item) => groupSet.has(item))
        ) {
            return [...this.getPropertiesForGroup(lastToken.value)];
        }
        return [];
    }

    getPreviousToken(
        session: Ace.EditSession,
        pos: { row: number; column: number },
        prefix: string
    ) {
        const adjustedColumn = pos.column - prefix.length - 1;
        return this.flowTokenIterator.getCurrentToken(session, pos.row, adjustedColumn);
    }

    getGroupsSet() {
        return new Set(Object.keys(this.context.groups));
    }

    getNodeReferences() {
        return treeMovement.map((nodeReference) => ({
            caption: nodeReference,
            meta: 'node',
            value: nodeReference,
            score: 10000,
            //docHTML: `<p> Here goes the description for <br> ${nodeReference}</br> This is html, very customizable</p>`,
            className: 'iconable link'
        }));
    }

    getGroupCompletions() {
        return Object.keys(this.context.groups).map((groupName) => ({
            caption: groupName,
            meta: 'property group',
            value: groupName,
            score: 10000,
            //docHTML: `<p> Here goes the description for <br> ${groupName}</br> This is html, very customizable</p>`,
            className: 'iconable tree'
        }));
    }

    getFlatProperties() {
        return this.context.flatProperties.map((flatPropertyName) => ({
            caption: flatPropertyName,
            meta: 'property',
            value: flatPropertyName,
            score: 10000,
            //docHTML: `<p> Here goes the description for <br> ${flatPropertyName}</br> This is html, very customizable</p>`,
            className: 'iconable list'
        }));
    }

    transformPropertyNameCompletion(propertyName: string) {
        const regex = new RegExp('^' + IDENTIFIER_RE + '$');
        if (regex.test(propertyName)) {
            return propertyName;
        } else {
            return `"${propertyName}"`;
        }
    }

    getPropertiesForGroup(groupKey: string) {
        return this.context.groups[groupKey].map((propertyName) => ({
            caption: propertyName,
            meta: 'property',
            value: this.transformPropertyNameCompletion(propertyName),
            score: 10000,
            //docHTML: `<p> Here goes the description for <br> ${propertyName}</br> This is html, very customizable</p>`,
            className: 'iconable list'
        }));
    }

    getFunctions() {
        return functions.map((functionName) => ({
            caption: functionName,
            meta: 'function',
            value: functionName,
            score: 20000,
            //docHTML: `<p> Here goes the description for <br> ${functionName}</br> This is html, very customizable</p>`,
            className: 'iconable function'
        }));
    }

    /**
     *
     * Get completions for the "expressions" state of the rules
     */
    getExpressionCompletions(): Completion[] {
        const functionsCompletions = this.getFunctions();
        const nodeReferences = this.getNodeReferences();
        return [...nodeReferences, ...functionsCompletions];
    }
}

const treeMovement = ['parent', 'link'];
const functions = [
    'MitterLength',
    'Sum',
    'Product',
    'Div',
    'Pow',
    'Round',
    'Floor',
    'Ceiling',
    'If',
    'Pi',
    'E',
    'Radian',
    'Degree',
    'Average',
    'Max',
    'Min',
    'Count',
    'CountA',
    'Concatenate',
    'Left',
    'Right',
    'Mid',
    'ReplaceNb',
    'ReplaceTxt',
    'Sin',
    'Cos',
    'Tan',
    'Sec',
    'Csc',
    'Cot',
    'Sinh',
    'Cosh',
    'Tanh',
    'Sech',
    'Coth',
    'Asin',
    'Acos',
    'Atan',
    'Asinh',
    'Acosh',
    'Atanh',
    'ChangeCase',
    'ChangeCulture',
    'Find',
    'Len',
    'RegexCount',
    'RegexGroup',
    'RegexIsMatch',
    'RegexIsMatchPosition',
    'RegexIsMatchValue',
    'RegexReplace',
    'RegexSplit',
    'IsSourceChild',
    'And',
    'In',
    'Not',
    'Or',
    'Has',
    'IfError',
    'Error',
    'Warning'
];
