import { Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IUnique } from '../definitions/interfaces';
import { ID } from '../definitions/types';
import { BaseModel } from '../models/base.model';
import { ApiService } from '../services/api.service';
import { HttpParams } from '@angular/common/http';

export abstract class GetResource<T extends BaseModel> {
    protected constructor(
        protected readonly _api: ApiService,
        protected readonly _url: string,
        protected readonly _ctor: (obj: IUnique) => T
    ) {
        // The constructor of T (_ctor param) is necessary due
        // to limitations of the generic system ,
        // Basically, you cannot call new T().
    }

    get(id: ID, params?: HttpParams): Observable<T> {
        return this._api
            .get<T>(`${this._url}/${id}`, params)
            .pipe(map((item) => this.getInstance(item.result)));
    }

    getAll(params?: HttpParams): Observable<T[]> {
        return this._api
            .get<T[]>(`${this._url}/`, params)
            .pipe(map((items) => items.result.map((item) => this.getInstance(item))));
    }

    protected customGet<I>(
        customCtor: (obj: any) => I,
        path: string,
        params: Params = {}
    ): Observable<I> {
        const httpParams = Object.entries(params).reduce(
            (acc: HttpParams, [key, value]: [string, any]) => acc.set(key, value),
            new HttpParams()
        );
        return this._api.get<I>(`${this._url}/${path}`, httpParams).pipe(
            switchMap((value) => {
                if (!value.result) {
                    return throwError(value.errors);
                }
                return of(customCtor({ id: 0, ...value.result } as IUnique));
            })
        );
    }

    protected getInstance(obj: IUnique): T {
        return this._ctor(obj);
    }
}

//TODO: move to a better place
export type Params = { [key: string]: number | string | boolean };
