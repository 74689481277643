import { ErrorHandler, Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpStatusCode
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApplicationInsightsService } from 'src/app/shared/services/application-insights.service';
import { IExceptionTelemetry } from '@microsoft/applicationinsights-web';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    /**
     * @param router
     * @param errorHandler
     * Handle error in requests, if an Unauthorized error status is caught the
     * app will automatically redirect to authentication page
     */
    constructor(
        private router: Router,
        private errorHandler: ErrorHandler,
        private appInsights: ApplicationInsightsService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            // we catch the error
            catchError((errorResponse: HttpErrorResponse) => {
                // log in console
                console.log({ errorResponse }); // eslint-disable-line no-console
                // log in Application Insights
                if (this.appInsights) {
                    const insightError: IExceptionTelemetry = {
                        exception: new Error(errorResponse.message),
                        properties: {
                            name: errorResponse.name,
                            message: errorResponse.message,
                            status: errorResponse.status
                        }
                    };
                    this.appInsights.trackException(insightError);
                }

                // if the status is 401 Unauthorized
                if (errorResponse.status === HttpStatusCode.Unauthorized) {
                    // we redirect to authentication
                    this.router.navigateByUrl('/login');
                } else {
                    // else we notify the user
                    this.errorHandler.handleError(errorResponse);
                }
                return throwError(errorResponse);
            })
        );
    }
}
