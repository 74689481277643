<mat-expansion-panel
    [class]="cardStyle"
    [hideToggle]="true"
    [disabled]="disableExpansion"
    [expanded]="_expanded"
    [class.remove-right-padding]="hideButtonExpansion"
    (expandedChange)="onExpandedChange($event)"
>
    <mat-expansion-panel-header
        [style.background-color]="cardColor"
        (click)="!clickPropagationMode.titleShouldPropagate && $event.stopPropagation()"
    >
        <mat-panel-title>
            <ng-container
                *ngIf="!hideButtonExpansion && buttonExpansionPosition === 'left'"
            >
                <app-custom-button
                    [color]="toggleThemeColor"
                    class="expand-btn"
                    type="link"
                    [icon]="_expanded ? 'expand_less' : 'expand_more'"
                    (buttonClick)="onButtonClick()"
                    [style.color]="toggleStyleColor"
                    [disabled]="disableExpansion"
                >
                </app-custom-button>
            </ng-container>
            <ng-content select=".title"></ng-content
        ></mat-panel-title>
        <ng-container *ngIf="!hideButtonExpansion && buttonExpansionPosition === 'right'">
            <app-custom-button
                [color]="toggleThemeColor"
                class="expand-btn"
                type="link"
                [icon]="_expanded ? 'expand_less' : 'expand_more'"
                (buttonClick)="onButtonClick()"
                [style.color]="toggleStyleColor"
                [disabled]="disableExpansion"
            >
            </app-custom-button>
        </ng-container>
    </mat-expansion-panel-header>
    <div *ngIf="_expanded && cardStyle === 'solid'" class="divider"></div>
    <div class="body" (click)="onClick($event)">
        <ng-content select=".content"></ng-content>
    </div>
</mat-expansion-panel>
