import { FilterBase } from '../../../../shared/components/flow-filter-builder/filter-base';
import { CUSTOM_FUNCTION, labels } from './constants';

export class CustomFunctionFilter extends FilterBase<string> {
    initialValue: string | undefined = '';
    type = 'input';
    key = CUSTOM_FUNCTION;
    controlType = 'input';
    label = labels[this.key];

    getQueryFromValue(value: string): string {
        return `(${value})`;
    }
}
