import { createAction, props } from '@ngrx/store';
import { EditableReportActions } from 'src/app/modules/list-tree-view/interfaces/editable-reports-actions';
import { UpdateReportTab } from 'src/app/modules/list-tree-view/services/report-detail.service';

export const addReport = createAction(
    '[VIEWS Editable Tabs] Add new Report with defined name',
    props<{ newReportDefinitionName: string }>()
);

export const editReportName = createAction(
    '[Views] Edit Report Name',
    props<{ updateReport: UpdateReportTab }>()
);

export const removeReport = createAction(
    '[Views] Remove Report',
    props<{ position: number }>()
);

export const selectReport = createAction(
    '[Views] Select Report Definition',
    props<{ position: number }>()
);

export const editableReportActions: EditableReportActions = {
    addReport,
    editReportName,
    removeReport,
    selectReport
};
