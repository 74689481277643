import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ROUTES } from 'src/app/core/constants/feature';
import { IUnique, UserPermission } from 'src/app/core/definitions/interfaces';
import * as PatternsApiActions from 'src/app/modules/patterns/store/actions/patterns-api.action';
import * as PatternsActions from 'src/app/modules/patterns/store/actions/patterns.action';
import { fromPatterns } from 'src/app/modules/patterns/store/patterns.selectors';
import { ID } from '../../../../core/definitions/types';
import { PatternDetail } from '../../../../core/interfaces/pattern-detail.interface';
import { PatternSummary } from '../../../../core/interfaces/pattern-summary.interface';
import { DraftRoutingService } from '../../services/draft-routing.service';

@Component({
    selector: 'app-pattern-list',
    templateUrl: './pattern-list.component.html',
    styleUrls: ['./pattern-list.component.scss']
})
export class PatternListComponent implements OnInit, OnDestroy {
    @Output() navigate = new EventEmitter();

    draftPatterns$: Observable<PatternDetail[]>;
    patterns$: Observable<PatternSummary[]>;

    selectedQueryParam$: Observable<ID | undefined>;
    combined$: Observable<IUnique[]>;
    archived$: Observable<PatternSummary[]>;

    onDestroy$ = new Subject<void>();

    userPermission$!: Observable<UserPermission>;

    isInArchivedPattern$: Observable<boolean>;
    loadingArchived$: Observable<boolean>;

    route = ROUTES.patterns;
    readonlyMode$: Observable<boolean>;

    constructor(private store: Store, private draftRoutingService: DraftRoutingService) {
        this.draftPatterns$ = this.store.select(fromPatterns.selectDraftPatterns);
        this.patterns$ = this.store.select(fromPatterns.selectPatterns);
        this.archived$ = this.store.select(fromPatterns.selectArchivedPatterns);
        this.isInArchivedPattern$ = this.store.select(
            fromPatterns.selectIsArchivedQueryParam
        );
        this.loadingArchived$ = this.store.select(fromPatterns.selectLoadingArchivedList);
        this.selectedQueryParam$ = this.store.select(
            fromPatterns.selectSelectedQueryParam
        );
        this.combined$ = combineLatest([this.draftPatterns$, this.patterns$]).pipe(
            map(([drafts, patterns]) => [...drafts, ...patterns])
        );

        this.draftRoutingService.createPatternDraftOnNavigation(this.onDestroy$);

        this.readonlyMode$ = this.store.select(fromPatterns.selectIsReadonlyMode);
    }

    ngOnInit(): void {
        this.store.dispatch(PatternsActions.getPatterns());

        this.triggerRequestIfPathIsArchived();
    }

    private triggerRequestIfPathIsArchived() {
        this.isInArchivedPattern$.pipe(take(1)).subscribe((inArchive) => {
            if (inArchive) {
                this.store.dispatch(PatternsApiActions.getArchivedPatterns());
            }
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    createPatternDraft(): void {
        this.draftRoutingService.createPatternDraft();
    }

    onClick(pattern: PatternSummary): void {
        this.selectedQueryParam$
            .pipe(
                take(1),
                filter((selectedId) => selectedId !== pattern.id)
            )
            .subscribe({
                next: () => {
                    this.store.dispatch(
                        PatternsActions.navigateToPattern({
                            id: pattern.id
                        })
                    );
                }
            });
    }

    onClickArchived(pattern: PatternSummary): void {
        this.selectedQueryParam$
            .pipe(
                take(1),
                filter((selectedId) => selectedId !== pattern.id)
            )
            .subscribe({
                next: () => {
                    this.store.dispatch(
                        PatternsActions.navigateToArchivedPattern({
                            id: pattern.id
                        })
                    );
                }
            });
    }

    toggleArchived(value: boolean) {
        if (value) {
            this.store.dispatch(PatternsActions.navigateToArchivedPatternList());
            this.store.dispatch(PatternsApiActions.getArchivedPatterns());
            return;
        }
        this.store.dispatch(PatternsActions.navigateToPatternList());
    }
    onNavigation() {
        this.navigate.emit();
    }
}
