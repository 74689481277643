import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { fromRoot } from 'src/app/store';

@Component({
    selector: 'app-forgot-password-email-success',
    templateUrl: './forgot-password-email-success.component.html'
})
export class ForgotPasswordEmailSuccessComponent {
    email$: Observable<string>;

    constructor(private store: Store) {
        this.email$ = this.store.select(fromRoot.selectUrlLastValueAndDecode);
    }
}
