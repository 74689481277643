import {
    IEventTelemetry,
    ICustomProperties,
    IPageViewTelemetry,
    IMetricTelemetry,
    IExceptionTelemetry,
    ITraceTelemetry,
    IDependencyTelemetry,
    ApplicationInsights
} from '@microsoft/applicationinsights-web';
import { IApplicationInsightsService } from '../interfaces/application-insights.interface';

export class ApplicationInsightsServiceMock implements IApplicationInsightsService {
    setApplicationInsight(appInsights: ApplicationInsights | null): void {
        return;
    }
    trackEvent(
        event: IEventTelemetry,
        customProperties?: ICustomProperties | undefined
    ): void {
        return;
    }
    startTrackEvent(name?: string | undefined): void {
        return;
    }
    stopTrackEvent(
        name: string,
        properties?: { [p: string]: string } | undefined,
        measurements?: { [p: string]: number } | undefined
    ): void {
        return;
    }
    trackPageView(pageView?: IPageViewTelemetry | undefined): void {
        return;
    }
    startTrackPage(name?: string | undefined): void {
        return;
    }
    stopTrackPage(
        name?: string | undefined,
        url?: string | undefined,
        properties?: { [name: string]: string } | undefined,
        measurements?: { [name: string]: number } | undefined
    ): void {
        return;
    }
    trackMetric(
        metric: IMetricTelemetry,
        properties?: ICustomProperties | undefined
    ): void {
        return;
    }
    trackException(
        exception: IExceptionTelemetry,
        properties?: ICustomProperties | undefined
    ): void {
        return;
    }
    trackTrace(
        message: ITraceTelemetry,
        properties?: ICustomProperties | undefined
    ): void {
        return;
    }
    trackDependencyData(dependency: IDependencyTelemetry): void {
        return;
    }
    flush(): void {
        return;
    }
}
