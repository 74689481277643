import { ID } from '../definitions/types';

export interface GeneralValidationResult {
    errors: PatternValidationError[];
}

export interface CycleErrors {
    errors: string[];
    warnings: string[];
}

export interface PatternValidationError extends CycleErrors {
    nodeId: ID;
    fieldId: ID;
}

export type PatternValidationState = {
    [nodeId: string]: NodeCycleErrors;
};

export type NodeCycleErrors = {
    [fieldId: string]: CycleErrors;
};

export function adaptToState(result: GeneralValidationResult): PatternValidationState {
    const state: PatternValidationState = {};

    result.errors.forEach((error) => {
        const { nodeId, fieldId, errors, warnings } = error;

        if (!state[nodeId]) {
            state[nodeId] = {};
        }

        state[nodeId][fieldId] = {
            errors,
            warnings
        };
    });

    return state;
}
