import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'app-custom-menu-container',
    templateUrl: './custom-menu-container.component.html',
    styleUrls: ['./custom-menu-container.component.scss']
})
export class CustomMenuContainerComponent {
    expanded = false;
    @Input() template!: TemplateRef<any>;

    @Output() menuClosing = new EventEmitter();
    @Output() menuOpening = new EventEmitter();

    @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;

    onMenuClose(): void {
        this.expanded = false;
        this.menuClosing.emit();
    }

    onMenuOpen(): void {
        this.expanded = true;
        this.menuOpening.emit();
    }

    openMenu() {
        this.menuTrigger.openMenu();
    }

    closeMenu() {
        this.menuTrigger.closeMenu();
    }
}
