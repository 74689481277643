import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllowPermissionRolesGuard } from './core/guards/allow-permission-roles.guard';
import { AllowPermissionGuard } from './core/guards/allow-permission.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { CloseTabComponent } from './shared/components/close-tab/close-tab.component';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component';
import { InvalidTokenErrorPageComponent } from './shared/components/invalid-token-error-page/invalid-token-error-page.component';
import { SideMenuNavigationComponent } from './side-menu-navigation.component';
import { LoadingPageComponent } from './shared/components/loading-page/loading-page.component';
import { RedirectComponent } from './redirect.component';

const routes: Routes = [
    {
        path: 'app',
        component: SideMenuNavigationComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/welcome/welcome.module').then(
                        (m) => m.WelcomeModule
                    )
            },
            {
                path: 'patterns',
                canActivate: [AllowPermissionGuard],
                loadChildren: () =>
                    import('./modules/patterns/patterns.module').then(
                        (m) => m.PatternsModule
                    )
            },
            {
                path: 'elements',
                canActivate: [AllowPermissionGuard],
                loadChildren: () =>
                    import('./modules/elements/elements.module').then(
                        (m) => m.ElementsModule
                    )
            },
            {
                path: 'views',
                canActivate: [AllowPermissionGuard],
                loadChildren: () =>
                    import('./modules/views/views.module').then((m) => m.ViewsModule)
            },
            {
                path: 'help',
                loadChildren: () =>
                    import('./modules/docs/docs.module').then((m) => m.DocsModule)
            },
            {
                path: 'personal',
                canActivate: [AllowPermissionRolesGuard],
                loadChildren: () =>
                    import('./modules/personal/personal.module').then(
                        (m) => m.PersonalModule
                    )
            },
            {
                path: 'organization',
                canActivate: [AllowPermissionRolesGuard],
                loadChildren: () =>
                    import('./modules/organization/organization.module').then(
                        (m) => m.OrganizationSettingsModule
                    )
            }
        ]
    },
    {
        path: 'users',
        loadChildren: () =>
            import('./modules/users/users.module').then((m) => m.UsersModule)
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./modules/authentication/authentication.module').then(
                (m) => m.AuthenticationModule
            )
    },
    {
        path: 'oauth/third-party-app-authenticated',
        children: [
            {
                path: '',
                component: CloseTabComponent
            }
        ]
    },
    {
        path: 'error',
        component: ErrorPageComponent
    },
    {
        path: 'loading',
        component: LoadingPageComponent
    },
    {
        path: 'invalid-token-error',
        component: InvalidTokenErrorPageComponent
    },
    {
        path: 'redirect/:code',
        component: RedirectComponent
    },
    {
        path: '',
        redirectTo: 'app',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: 'app'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
