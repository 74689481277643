<ng-container *ngIf="showButton">
    <ng-template #notButtonContainer>
        <div [class.button-container-2]="enableSecondButton">
            <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
        </div>
    </ng-template>
    <ng-container *ngIf="buttonContainer; else notButtonContainer">
        <div class="button-container-1">
            <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
        </div>
    </ng-container>
</ng-container>

<ng-template #buttonTemplate>
    <button
        class="icon-small"
        [class.blue-hover]="blueHover"
        [class.mat-icon-button]="isRoundIconButton"
        [class.squared]="type === 'squared'"
        (click)="onClick($event)"
        [color]="color"
        [disabled]="disabled"
        [ngClass]="[size, buttonType, type]"
        mat-button
        [style.color]="fontColor"
        [style]="styles"
        [style.cursor]="cursorStyle"
    >
        <ng-container *ngIf="iconPosition === 'right' || !iconPosition">
            {{ text }}
        </ng-container>
        <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
        <mat-icon *ngIf="svgIcon" [svgIcon]="svgIcon"></mat-icon>
        <ng-container *ngIf="iconPosition === 'left'">
            {{ text }}
        </ng-container>
    </button>
    <ng-container *ngIf="enableSecondButton">
        <button
            class="sencond-btn"
            [class.blue-hover]="blueHover"
            [class.mat-icon-button]="isRoundIconButton"
            [class.squared]="type === 'squared'"
            [color]="color"
            [disabled]="disabled"
            [ngClass]="[size, buttonType, type]"
            mat-button
            [style.color]="fontColor"
            [style]="styles"
            [style.cursor]="cursorStyle"
            [matMenuTriggerFor]="menu"
            #menuTrigger="matMenuTrigger"
        >
            <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu class="sencond-btn-menu" #menu="matMenu">
            <button
                *ngFor="let action of actions"
                class="menu-element"
                [class.disabled]="action.disabled"
                mat-menu-item
                [ngStyle]="action.style"
                [style.color]="fontColor"
                (click)="action.emitEvent()"
                [disabled]="!!action.disabled"
            >
                <ng-container *ngIf="!action.iconFirst">
                    {{ action.label }}
                </ng-container>
                <mat-icon *ngIf="!action.isSvgIcon">{{ action.icon }}</mat-icon>
                <mat-icon *ngIf="action.isSvgIcon" [svgIcon]="action.icon"></mat-icon>
                <ng-container *ngIf="action.iconFirst">
                    {{ action.label }}
                </ng-container>
            </button>
        </mat-menu>
    </ng-container>
</ng-template>
