<ng-template #menu>
    <div class="context-menu">
        <ul>
            <li *ngIf="allowedActions.copy" (click)="onCopy()">
                <div class="option-name">
                    <mat-icon>content_copy</mat-icon>
                    Copy
                </div>
            </li>
            <li *ngIf="allowedActions.paste" (click)="onPaste()">
                <div class="option-name">
                    <mat-icon>content_paste</mat-icon>
                    Paste
                </div>
                <mat-icon
                    title="Clipboard access permission was not granted. To use the paste functionality, please enable clipboard access in your browser settings. Alternatively, you can use 'Ctrl + V' to manually paste your content."
                    *ngIf="showClipboardPermissionTooltip"
                    >info
                </mat-icon>
            </li>
            <li *ngIf="allowedActions.delete" (click)="onDelete()" class="delete-item">
                <div class="option-name">
                    <mat-icon>delete</mat-icon>
                    Delete element
                </div>
            </li>
        </ul>
    </div>
</ng-template>
