import { ApiService } from '../services/api.service';
import { IUnique } from '../definitions/interfaces';
import { GetResource, Params } from './get.resource';
import { BaseModel } from '../models/base.model';
import { Observable, of, throwError } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ResponseObject } from '../definitions/classes';
import { HttpParams } from '@angular/common/http';

export abstract class CRUDResource<T extends BaseModel> extends GetResource<T> {
    protected constructor(
        api: ApiService,
        modulename: string,
        servicename: string,
        ctor: (obj: IUnique) => T
    ) {
        super(api, `${modulename}${servicename}`, ctor);
    }

    create(item: T): Observable<T> {
        return this._api
            .post<T>(`${this._url}/`, item)
            .pipe(
                mergeMap((value) =>
                    value.success
                        ? of(this.getInstance(value.result))
                        : throwError(value.errors)
                )
            );
    }

    update(item: T): Observable<T> {
        return this._api
            .patch<T>(`${this._url}/${item.id}`, item)
            .pipe(
                mergeMap((value) =>
                    value.success
                        ? of(this.getInstance(value.result))
                        : throwError(value.errors)
                )
            );
    }

    delete(item: IUnique, params?: HttpParams): Observable<boolean> {
        return this._api
            .delete<boolean>(`${this._url}/${item.id}`, params)
            .pipe(
                mergeMap((value) =>
                    value.success ? of(value.result) : throwError(value.errors)
                )
            );
    }

    protected customPost<I>(
        path: string,
        item: unknown,
        params: Params = {},
        responseType = 'json'
    ): Observable<ResponseObject<I>> {
        const httpParams = Object.entries(params).reduce(
            (acc: HttpParams, [key, value]: [string, any]) => acc.set(key, value),
            new HttpParams()
        );
        return this._api.post<I>(`${this._url}/${path}`, item, httpParams, responseType);
    }

    protected customPostDirect<I, J>(
        path: string,
        item: J,
        customCtor: (obj: I) => I
    ): Observable<I> {
        return this._api.post<I>(`${this._url}/${path}`, item).pipe(
            map((value) => {
                return customCtor({ ...value.result });
            })
        );
    }

    protected customDelete(path: string): Observable<ResponseObject<boolean>> {
        return this._api.delete<boolean>(`${this._url}/${path}`);
    }
}
