<div class="container" *ngIf="!(loading$ | async)">
    <div class="reset-password-container">
        <img src="/assets/workroads-logo-login.svg" alt="workroads logo" />
        <div class="subtitle">Set a new password</div>

        <section>
            <form class="idable">
                <fieldset>
                    <label for="email">CONFIRM EMAIL</label>
                    <input
                        #input
                        #emailInput
                        id="email"
                        type="email"
                        (input)="onChange(emailInput)"
                        [value]="email"
                    />
                    <app-form-error-messages
                        [display]="!emailInput.checkValidity() || emailWhiteSpace"
                        message="Email is not well formed."
                    ></app-form-error-messages>
                </fieldset>
                <fieldset #newFieldSet>
                    <label for="new">NEW PASSWORD</label>
                    <mat-icon *ngIf="new.type === 'text'" (click)="new.type = 'password'">
                        visibility_off
                    </mat-icon>
                    <mat-icon *ngIf="new.type === 'password'" (click)="new.type = 'text'">
                        visibility
                    </mat-icon>
                    <input
                        #new
                        #input
                        id="new"
                        type="password"
                        [pattern]="passwordRegex"
                        [(ngModel)]="newPassword"
                        (input)="new.required = true"
                    />
                    <app-form-error-messages
                        [display]="!new.checkValidity()"
                        message="Password doesn't meet the requirements."
                    ></app-form-error-messages>
                </fieldset>
                <fieldset>
                    <label for="repeat">REPEAT PASSWORD</label>
                    <mat-icon
                        *ngIf="repeat.type === 'text'"
                        (click)="repeat.type = 'password'"
                    >
                        visibility_off
                    </mat-icon>
                    <mat-icon
                        *ngIf="repeat.type === 'password'"
                        (click)="repeat.type = 'text'"
                    >
                        visibility
                    </mat-icon>
                    <input
                        #repeat
                        #input
                        id="repeat"
                        type="password"
                        (input)="onRepeatPasswordChange(repeat)"
                        [value]="repeatPassword"
                    />
                    <app-form-error-messages
                        [display]="mismatch"
                        message="Password mismatch"
                    ></app-form-error-messages>
                </fieldset>
            </form>
            <app-custom-button
                #submit
                size="large"
                color="primary"
                text="Change password"
                type="flat"
                [disabled]="!formIsValid || mismatch || emailWhiteSpace"
                (buttonClick)="onSubmit()"
            >
            </app-custom-button>
            <app-password-requirements
                *ngIf="passwordFieldForValidation"
                [passwordField]="passwordFieldForValidation"
                [fieldValue]="newPassword"
            >
            </app-password-requirements>
        </section>
    </div>
</div>
<div *ngIf="loading$ | async" class="loader-container">
    <app-loader></app-loader>
</div>
