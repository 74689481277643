import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import { ApplicationInsightsService } from 'src/app/shared/services/application-insights.service';

@Injectable()
export class ResponseLogHandlerInterceptorTsInterceptor implements HttpInterceptor {
    constructor(private appInsights: ApplicationInsightsService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const reqStartDate = Date.now();
        let msg = '';
        return next.handle(req).pipe(
            tap(
                (event) => (msg = this.handleResponse(event)),
                (error) => (msg = this.handleError(error))
            ),
            finalize(() => this.logRequest(req, reqStartDate, msg))
        );
    }

    private handleResponse(event: any) {
        if (event instanceof HttpResponse) {
            const msg = `succeeded with status: ${event.status}: ${event.statusText}`;
            return msg;
        }
        return '';
    }

    private handleError(error: HttpErrorResponse) {
        const errorMsg = `${error.error} with status: ${error.status} and status text: ${error.statusText}`;
        return errorMsg;
    }

    private logRequest(req: HttpRequest<any>, reqStartDate: number, msg: string) {
        if (!this.appInsights || !req.url.includes('api')) return;

        const requestTime = Date.now() - reqStartDate;
        const message = `${req.url}: ${msg}!. Request completed in ${requestTime}ms`;

        this.appInsights.trackTrace({
            message,
            properties: {
                requestTime,
                url: req.url,
                method: req.method,
                body: req.body
            }
        });
        this.appInsights.trackMetric({
            average: requestTime,
            name: 'Server Request'
        });
    }
}
