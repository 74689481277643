<div class="errors">
    <span *ngIf="errors" class="error"
        ><mat-icon
            aria-hidden="true"
            aria-label="Error"
            fontIcon="error"
            class="error-icon"
        ></mat-icon>
        <span>{{ errors }} {{ errors === 1 ? 'error' : 'errors' }}</span></span
    >
    <span *ngIf="warnings" class="warning">
        <mat-icon
            aria-hidden="true"
            aria-label="Warning"
            fontIcon="warning"
            class="warning-icon"
        ></mat-icon>
        <span>
            {{ warnings }}
            {{ warnings === 1 ? 'warning' : 'warnings' }}
        </span>
    </span>
</div>
