import { Pipe, PipeTransform } from '@angular/core';
import locale from 'date-fns/locale/en-US';
import { isBefore, formatDistanceToNowStrict, subDays, format, isValid } from 'date-fns';
import { formatDistance } from '../helpers/custom-format-distance';

export const DATE_HOUR = 'date-hour';
export const MEDIUM = 'medium';
@Pipe({
    name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
    transform(value: Date, ...args: any[]) {
        const formatDate = this.validFormat(args?.length ? args[0] : '');
        return this.calculateDateDistance(new Date(value), formatDate);
    }

    calculateDateDistance(datems: Date, formatDate: string) {
        if (isBefore(datems, subDays(new Date(), 3))) {
            return format(datems, formatDate);
        } else {
            datems = isValid(datems) ? datems : new Date();
            return formatDistanceToNowStrict(datems, {
                addSuffix: true,
                locale: {
                    ...locale,
                    formatDistance
                }
            });
        }
    }

    validFormat(formatName: string): string {
        switch (formatName) {
            case DATE_HOUR:
                return 'dd-MM-yy, HH:mm';
            case MEDIUM:
                return 'MMMM do, yyyy';
            default:
                return 'dd MMM';
        }
    }
}
