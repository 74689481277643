<div (click)="onClick($event)" class="input-wrapper">
    <span
        class="input"
        [class.parenthesis]="parenthesis"
        [ngClass]="inputStyle"
        [class.required]="required && !value && !isNewInstance"
        [class.new-instance]="isNewInstance && !value"
        [class.top-icon-space]="topIconSvg || topIcon"
        #smallField
        contenteditable="true"
        (input)="onChange(smallField.textContent)"
        (focusout)="onFocusOut()"
        [style.cursor]="cursorStyle"
        [class.icon-space]="!!icon"
        role="textbox"
        [textContent]="value"
        [attr.placeholder]="_placeholder"
        (keydown.enter)="onBlur()"
        >{{ value }}</span
    >
    <mat-icon *ngIf="icon" class="input-icon" [svgIcon]="icon"></mat-icon>
    <mat-icon *ngIf="topIcon" class="top-icon">{{ topIcon }}</mat-icon>
    <mat-icon *ngIf="topIconSvg" class="top-icon" [svgIcon]="topIconSvg"></mat-icon>
</div>
