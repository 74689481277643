<div
    class="selectable-card"
    [class.draft]="isDraft"
    [class.stretchToBorder]="stretchToBorder"
    [class.iconPadding]="mainIcon"
    [style.height]="height"
    (click)="onClick($event)"
>
    <ng-template #content>
        <mat-icon class="item-icon" fontSet="material-icons-outlined" *ngIf="mainIcon">{{
            mainIcon
        }}</mat-icon>
        <div class="content">
            <div class="title" [title]="title">{{ title }}</div>
            <div class="subtitle body-regular-14">
                <mat-icon *ngIf="matIcon" [svgIcon]="matIcon"></mat-icon>
                <div class="subtitle-text" [title]="subtitle">
                    {{ subtitle }}
                </div>
            </div>
        </div>
        <ng-content></ng-content>
    </ng-template>

    <div *ngIf="isDraft; else content" class="container">
        <div class="draft-container">Unsaved draft</div>
    </div>
</div>
