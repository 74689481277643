<app-one-section-layout>
    <ng-template #section>
        <div class="users-table-container" *ngIf="!(loading$ | async)">
            <app-user-list
                [allUsers]="normalUsers$ | async"
                [currentUserPermission]="currentUserPermission$ | async"
                [currentOrganization]="currentOrganization$ | async"
            ></app-user-list>
            <app-user-list
                [allUsers]="supportUsers$ | async"
                [isSupportUsers]="true"
                [currentUserPermission]="currentUserPermission$ | async"
                [currentOrganization]="currentOrganization$ | async"
            ></app-user-list>
        </div>
        <div class="loader-container">
            <app-loader *ngIf="loading$ | async"></app-loader>
        </div>
    </ng-template>
</app-one-section-layout>
