<div
    id="user-menu-container"
    appClickOutside
    (clicked)="clickOnComponent($event)"
    [class.shadow-when-close]="!show"
    [class.shadow-when-open]="show"
>
    <div id="user-options" *ngIf="show">
        <ng-content></ng-content>
    </div>
    <div style="height: 70px">
        <div id="button" [class.show]="show">
            <app-user-image
                [text]="userName"
                [size]="28"
                [fontSize]="15"
            ></app-user-image>
            <span id="organization">{{ organization }}</span>
        </div>
    </div>
</div>
