import { IUnique } from '../definitions/interfaces';
import { BaseModel } from './base.model';
import { PatternSummary } from '../interfaces/pattern-summary.interface';
import { ID } from '../definitions/types';
import { v4 as uuid } from 'uuid';
import { DrawingTargetFramework } from '../definitions/enums';

export const INITIAL_PATTERN_SUMMARY = {
    id: uuid(),
    title: '',
    description: '',
    autoVersion: false,
    selectedPush: '',
    selectedDrawing: '',
    createdAt: new Date(Date.now()),
    hasErrors: false,
    hasWarnings: false
};

export class PatternSummaryModel extends BaseModel implements PatternSummary {
    title!: string;
    description!: string;
    autoVersion!: boolean;
    selectedDrawing!: ID;
    selectedPush!: ID;
    createdAt!: Date;
    hasErrors!: boolean;
    hasWarnings!: boolean;
    targetFramework!: DrawingTargetFramework;

    constructor(obj?: IUnique) {
        super(obj);
    }

    protected initialize(obj: any): void {
        if (obj) {
            this.title = (obj.title as string) ?? INITIAL_PATTERN_SUMMARY.title;
            this.description = obj.description ?? INITIAL_PATTERN_SUMMARY.description;
            this.autoVersion =
                obj.autoVersion != null
                    ? obj.autoVersion
                    : INITIAL_PATTERN_SUMMARY.autoVersion;
            this.selectedDrawing =
                obj.selectedDrawing != null
                    ? obj.selectedDrawing
                    : INITIAL_PATTERN_SUMMARY.selectedDrawing;
            this.selectedPush =
                obj.selectedPush != null
                    ? obj.selectedPush
                    : INITIAL_PATTERN_SUMMARY.selectedPush;
            this.createdAt = obj.createdAt ?? INITIAL_PATTERN_SUMMARY.createdAt;
            this.hasErrors = obj.hasErrors ?? INITIAL_PATTERN_SUMMARY.hasErrors;
            this.hasWarnings = obj.hasWarnings ?? INITIAL_PATTERN_SUMMARY.hasWarnings;
            this.targetFramework = obj.targetFramework;
        }
    }
}
