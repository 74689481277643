<div
    #passwordReqContainer
    class="password-validation-container"
    [style.left]="left"
    [style.top]="top"
    [class.password-validation-container-bellow]="!isBigScreen()"
>
    <div *ngIf="isBigScreen()" class="peak"></div>
    <div class="validations">
        <div class="validation-title">Password requirements</div>
        <app-password-validation-line
            *ngFor="let message of errorMessagesToDisplay"
            [message]="message"
            [fieldValue]="fieldValue"
            [justCreated]="justCreated"
        ></app-password-validation-line>
    </div>
</div>
