<div class="header-section" *ngIf="headerItem">
    <div class="input-container" [formGroup]="headerForm">
        <div>
            <app-custom-autosize-input
                formControlName="name"
                id="title-input"
                label="Name"
                inputStyle="bold"
                placeholder="Name"
                [required]="true"
                [initWithFocus]="true"
            >
            </app-custom-autosize-input>
            <app-custom-autosize-input
                formControlName="description"
                id="description-input"
                label="Description"
                placeholder="Description"
            >
            </app-custom-autosize-input>
        </div>
    </div>
</div>
