<mat-button-toggle-group class="button-toogle-container" aria-label="Font Style">
    <mat-button-toggle
        [class]="reportView === listView ? 'selected' : 'unselected'"
        (click)="onClick(listView)"
    >
        <mat-icon svgIcon="list"></mat-icon>
        List
    </mat-button-toggle>
    <mat-button-toggle
        [class]="reportView === treeView ? 'selected' : 'unselected'"
        (click)="onClick(treeView)"
    >
        <mat-icon svgIcon="tree"></mat-icon>
        Tree
    </mat-button-toggle>
</mat-button-toggle-group>
