<app-one-section-layout>
    <ng-template #section>
        <app-one-section-layout>
            <ng-template #section>
                <section>
                    <form class="idable">
                        <fieldset>
                            <label for="email">EMAIL</label>
                            <input
                                id="email"
                                type="email"
                                disabled
                                readonly
                                [value]="(currentUser$ | async)?.email"
                            />
                        </fieldset>
                        <fieldset>
                            <label for="organizations">ORGANIZATIONS</label>
                            <input
                                id="organizations"
                                type="text"
                                disabled
                                readonly
                                [value]="organizations$ | async"
                            />
                        </fieldset>
                        <fieldset>
                            <label for="first-name">FIRST NAME</label>
                            <input
                                #first
                                id="first-name"
                                type="text"
                                required
                                [value]="firstName"
                                (input)="onChangeFirstName(first)"
                            />
                            <app-form-error-messages
                                [display]="!first.checkValidity() || nameWhiteSpace"
                                message="First name must contain at least 1 character."
                            ></app-form-error-messages>
                        </fieldset>
                        <fieldset>
                            <label for="last-name">LAST NAME</label>
                            <input
                                #last
                                id="last-name"
                                type="text"
                                required
                                [value]="lastName"
                                (input)="onChangeLastName(last)"
                            />
                            <app-form-error-messages
                                [display]="!last.checkValidity() || surnameWhiteSpace"
                                message="Last name must contain at least 1 character."
                            ></app-form-error-messages>
                        </fieldset>
                    </form>
                    <div class="information-buttons-container">
                        <app-custom-button
                            color="primary"
                            text="Discard"
                            class="small"
                            type="stroked"
                            size="small"
                            (buttonClick)="onDiscard()"
                        >
                        </app-custom-button>
                        <app-custom-button
                            color="primary"
                            text="Save Changes"
                            class="small"
                            type="flat"
                            size="small"
                            (buttonClick)="onSubmit()"
                            [disabled]="
                                !formHasChange ||
                                !formIsValid ||
                                nameWhiteSpace ||
                                surnameWhiteSpace
                            "
                        >
                        </app-custom-button>
                        <div
                            *ngIf="loading$ | async"
                            class="information-loader-container"
                        >
                            <app-loader></app-loader>
                        </div>
                    </div>
                </section>
            </ng-template>
        </app-one-section-layout>
    </ng-template>
</app-one-section-layout>
