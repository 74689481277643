<div class="container">
    <div class="forgot-password-container">
        <img src="/assets/workroads-logo-login.svg" alt="workroads logo" />
        <div class="forgot-question">Forgot your password?</div>
        <div class="reset-msg">To reset your password, please enter your email</div>
        <div class="email-valid-info">
            If your email is valid, you will receive an email with a link to reset your
            password<br />
            Don't forget to look into your junk mail... you know, just in case...
        </div>
        <div class="form-wrapper">
            <form class="idable hide-errors">
                <fieldset>
                    <label for="email">EMAIL</label>
                    <input
                        #emailInput
                        id="email"
                        type="email"
                        required
                        [(ngModel)]="email"
                    />
                </fieldset>
            </form>
            <app-custom-button
                size="large"
                color="primary"
                text="SEND"
                type="flat"
                (buttonClick)="onSubmit()"
            ></app-custom-button>

            <div *ngIf="loading$ | async" class="loader">
                <app-loader></app-loader>
            </div>
        </div>
    </div>
</div>
