import { createAction, props } from '@ngrx/store';
import { PatternSummary } from 'src/app/core/interfaces/pattern-summary.interface';
import { ID } from '../../../../core/definitions/types';
import { PatternDetail } from '../../../../core/interfaces/pattern-detail.interface';
import { GeneralValidationResult } from '../../../../core/interfaces/general-validation-result.interface';
import { ElementDefinitionDetail } from '../../../../core/interfaces/element-definition-detail.interface';
import { LinkSummary } from 'src/app/core/interfaces/link-summary';

export const getPatterns = createAction('[Patterns API] GET Patterns');

export const getPatternsSuccess = createAction(
    '[Patterns API] GET Patterns Success',
    props<{ patterns: PatternSummary[] }>()
);
export const getPatternsFail = createAction(
    '[Patterns API] GET Patterns Fail',
    props<{ error: string | null }>()
);

export const getArchivedPatterns = createAction('[Patterns API] GET Archived Patterns');

export const getArchivedPatternsSuccess = createAction(
    '[Patterns API] GET Archived Patterns Success',
    props<{ archivedPatterns: PatternSummary[] }>()
);
export const getArchivedPatternsFail = createAction(
    '[Patterns API] GET Archived Patterns Fail',
    props<{ error: string | null }>()
);

export const createPatternSuccess = createAction(
    '[Patterns API] CREATE Pattern Success',
    props<{ draftId: ID; pattern: PatternDetail }>()
);

export const getPatternById = createAction(
    '[Patterns API] GET Pattern by Id',
    props<{ id: ID }>()
);

export const getArchivedPatternById = createAction(
    '[Patterns API] GET Archived Pattern by Id',
    props<{ id: ID }>()
);

export const getPatternByIdSuccess = createAction(
    '[Patterns API] GET Pattern by Id Success',
    props<{ pattern: PatternDetail }>()
);
export const getPatternByIdFail = createAction(
    '[Patterns API] GET Pattern by Id Fail',
    props<{ error: string | null }>()
);

export const restorePatternById = createAction(
    '[Patterns API] Restore Archived Pattern by Id',
    props<{ id: ID }>()
);

export const restorePatternByIdSuccess = createAction(
    '[Patterns API] Restore Pattern by Id Success',
    props<{ id: ID }>()
);
export const restorePatternByIdFail = createAction(
    '[Patterns API] Restore Pattern by Id Fail',
    props<{ error: string | null }>()
);

export const createPattern = createAction(
    '[Patterns API] CREATE Pattern',
    props<{ pattern: PatternDetail }>()
);

export const createPatternFail = createAction(
    '[Patterns API] CREATE Pattern Fail',
    props<{ error: string | null }>()
);

export const updatePattern = createAction(
    '[Patterns API] UPDATE Pattern',
    props<{ pattern: PatternDetail }>()
);
export const updatePatternSuccess = createAction(
    '[Patterns API] UPDATE Pattern Success',
    props<{ pattern: PatternDetail }>()
);
export const updatePatternFail = createAction(
    '[Patterns API] UPDATE Pattern Fail',
    props<{ error: string | null }>()
);

export const deletePattern = createAction(
    '[Patterns API] DELETE Pattern',
    props<{ id: ID }>()
);

export const deletePatternFail = createAction(
    '[Patterns API] DELETE Pattern Fail',
    props<{ error: string | null }>()
);
export const deletePatternSuccess = createAction(
    '[Patterns API] DELETE Pattern Success',
    props<{ id: ID }>()
);

export const fieldChangeResetGeneralErrors = createAction(
    '[Pattern] field Change Reset General Errors'
);
export const validatePatternSuccess = createAction(
    '[Pattern] Validate pattern Success',
    props<{ result: GeneralValidationResult }>()
);
export const validatePatternFail = createAction(
    '[Pattern] Validate pattern fail',
    props<{ error: string }>()
);

export const getElementDefinitionForPatterns = createAction(
    '[Patterns API] GET Element Definitions'
);
export const getElementDefinitionForPatternsSuccess = createAction(
    '[Patterns API] GET Element Definitions Success',
    props<{ elementDefinitions: ElementDefinitionDetail[] }>()
);
export const getElementDefinitionForPatternsFail = createAction(
    '[Patterns API] GETElement Definitions Fail',
    props<{ error: string | null }>()
);
export const getAssociatedLinksSuccess = createAction(
    '[Patterns API] Get All associated links sucessfylly',
    props<{ links: LinkSummary[] }>()
);
export const getAssociatedLinksFail = createAction(
    '[Patterns API] Get All associated links fail',
    props<{ error: string }>()
);
