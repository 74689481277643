<ng-template #menuTriggerTemplate let-expanded>
    <div class="menu-toogle" (click)="checkPropagation($event)">
        <div
            class="menu-toogle-container"
            [class.field-invalid]="invalidAndErrors.invalid"
            [class.select-disabled]="
                userColumnState === createInfoState && selectDisabled
            "
        >
            <div
                class="existing-roles"
                *ngIf="userColumnState !== createInfoState; else customInput"
            >
                {{ roleValues }}
                <div class="free-label" *ngIf="roleValues?.includes(viewerText)">
                    Free
                </div>
            </div>
            <ng-template #customInput>
                <div [class.user-support]="roleValues === supportAutoText">
                    <app-custom-input
                        [value]="roleValues"
                        [readonly]="true"
                        [placeholder]="placeholder"
                        [label]="label"
                        [cursorStyle]="
                            roleValues === supportAutoText ? 'default' : 'pointer'
                        "
                    ></app-custom-input>
                </div>
            </ng-template>
        </div>
        <app-custom-button
            class="expand-btn custom-menu-toogle"
            [class.custom-menu-toogle-input]="userColumnState === createInfoState"
            [class.custom-menu-toogle-invalid]="
                userColumnState === createInfoState && invalidAndErrors.invalid
            "
            [class.custom-menu-toogle-disabled]="
                userColumnState === createInfoState && selectDisabled
            "
            type="link"
            [icon]="expanded ? 'expand_less' : 'expand_more'"
            [cursorStyle]="roleValues === supportAutoText ? 'default' : ''"
            (buttonClick)="onClick()"
        >
        </app-custom-button>
    </div>
</ng-template>

<app-custom-menu-container
    [template]="menuTriggerTemplate"
    (menuClosing)="onMenuClosing()"
>
    <app-custom-menu-content
        [existingSelectedOption]="selectedOption"
        [options]="selectOptions"
        (optionSelected)="onOptionSelected($event)"
    >
    </app-custom-menu-content>
</app-custom-menu-container>
