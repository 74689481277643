import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ID } from 'src/app/core/definitions/types';
import { ElementHistoryItem } from 'src/app/core/interfaces/element-history-item.interface';
import { detectPosition } from 'src/app/modules/shared-nodes/helpers/helper-funtions';

@Component({
    selector: 'app-version-history-item-collapsed',
    templateUrl: './version-history-item-collapsed.component.html',
    styleUrls: ['./version-history-item-collapsed.component.scss']
})
export class VersionHistoryItemCollapsedComponent implements OnInit {
    @Input() selectedId?: ID;
    @Input() isLastItem = false;
    @Input() isFirsItem = false;
    @Input() historyItems: ElementHistoryItem[] = [];
    @Output() clickItem: EventEmitter<ID> = new EventEmitter<ID>();

    showMore = false;

    ngOnInit(): void {
        // show the list in case that the selected id is contained on it
        this.showMore = this.historyItems.some((e) => e.id === this.selectedId);
    }

    onClickItem(id: ID) {
        this.clickItem.emit(id);
    }

    detectPosition(index: number, length: number) {
        const position = detectPosition(index, length);
        if (!this.isLastItem && position === 'end') return 'middle';
        if (!this.isFirsItem && position === 'start') return 'middle';
        return position;
    }
}
