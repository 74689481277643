import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-expansion-card-container',
    templateUrl: './expansion-card-container.component.html',
    styleUrls: ['./expansion-card-container.component.scss'],
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ height: 0, opacity: 0 }),
                animate('.2s linear', style({ height: 100, opacity: 1 }))
            ]),
            transition(':leave', [
                style({ height: 100, opacity: 1 }),
                animate('.2s linear', style({ height: 0, opacity: 0 }))
            ])
        ])
    ]
})
export class ExpansionCardContainerComponent {
    @Input() title = '';
    @Input() subtitle = '';
    @Input() header = false;
    @Input() set showContent(value: boolean | undefined) {
        // This two values of show content are used to prevent
        // unwanted hide action on change of active category
        if (value != null) {
            this._showContent = value;
        }
    }
    @Input() color = '#4baadd';

    _showContent = false;

    onClick(event: Event): void {
        this._showContent = !this._showContent;
        event.stopPropagation();
    }
}
