import { createAction, props } from '@ngrx/store';
import { DocumentationFileStructureModel } from 'src/app/core/models/doc-file-structure.model';
import { DocumentationFileModel } from 'src/app/core/models/doc-file.model';

export const getDocumentationStructure = createAction(
    '[Documentation API] GET DocumentationStructure'
);

export const getDocumentationStructureSuccess = createAction(
    '[Documentation API] GET DocumentationStructure Success',
    props<{ documentationStructure: DocumentationFileStructureModel }>()
);

export const getDocumentationStructureFail = createAction(
    '[Documentation API] GET DocumentationStructure Fail',
    props<{ error: string | null }>()
);

export const getDocByPath = createAction(
    '[Documentation API] GET Doc by path',
    props<{ path: string }>()
);

export const getDocByPathSuccess = createAction(
    '[Documentation API] GET Doc by path success',
    props<{ documentationFile: DocumentationFileModel }>()
);

export const getDocByPathFail = createAction(
    '[Documentation API] GET Doc by path fail',
    props<{ error: string | null }>()
);
