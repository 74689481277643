import { CODE_NAME, labels } from './constants';
import { FilterBase } from '../../../../shared/components/flow-filter-builder/filter-base';
import { Option } from '../../../../shared/components/custom-select/custom-select.component';

// https://angular.io/guide/dynamic-form#define-control-classes
export class CodeNameFilter extends FilterBase<string[]> {
    initialValue: string[] | undefined = [];
    type = 'multiple';
    key = CODE_NAME;
    controlType = 'select';
    label = labels[this.key];
    options: Option[] = [];

    constructor(options: Option[]) {
        super();
        this.options = options;
    }

    getQueryFromValue(value: string[]): string {
        return value.map((value) => `( CodeName = "${value}" )`).join(' || ');
    }
}
