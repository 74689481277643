<div
    class="body"
    #elementContainer
    appAutoScrollToSelected
    appScrollShadows
    [selectedId]="selectedId"
    [items$]="combined$"
>
    <app-list-item
        *ngFor="let element of elementDefinitionDrafts$ | async"
        [selected]="selectedId === element.id"
        [isDraft]="true"
        [title]="element.name"
        (click)="onClick({ id: element.id })"
        height="20px"
        [attr.data-id]="element.id"
    >
    </app-list-item>
    <app-list-item
        *ngFor="let element of elementDefinitions$ | async"
        [selected]="historyCollectionId === element.historyId"
        matIcon="vector"
        [title]="element.name"
        [subtitle]="element.codeName"
        height="30px"
        [attr.data-id]="element.id"
        (click)="onClick({ id: element.id, historyCollection: element.historyId })"
    >
    </app-list-item>
</div>
<div class="footer" *ngIf="(userPermission$ | async)?.isAbleToEditElements">
    <app-custom-button
        color="primary"
        type="stroked"
        size="auto"
        text="CREATE NEW ELEMENT"
        icon="add"
        (buttonClick)="createElementDraft()"
    ></app-custom-button>
</div>
