import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PersonalChangePasswordComponent } from './change-password/change-password.component';
import { PersonalInformationComponent } from './information/information.component';

export const routes: Routes = [
    {
        path: 'information',
        component: PersonalInformationComponent,
        data: { title: 'Personal information' }
    },
    {
        path: 'password',
        component: PersonalChangePasswordComponent,
        data: { title: 'Change password' }
    },
    { path: '', redirectTo: 'information', pathMatch: 'full' },
    { path: '**', redirectTo: 'information', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PersonalRoutingModule {}
