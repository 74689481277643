<div class="container">
    <div class="operator-input">
        <app-custom-select
            [value]="value.operator"
            [options]="operatorOptions"
            (valueChange)="onOperatorChange($event)"
        >
        </app-custom-select>
    </div>
    <div class="number-input">
        <app-custom-input
            type="number"
            [value]="value.number"
            (valueChange)="onNumberChange($event)"
        >
        </app-custom-input>
    </div>
</div>
