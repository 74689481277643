import { Component, Input } from '@angular/core';
import { MenuTab } from '../../../../shared/models/menu-tab.model';

@Component({
    selector: 'app-menu-tab',
    templateUrl: './menu-tab.component.html',
    styleUrls: ['./menu-tab.component.scss']
})
export class MenuTabComponent {
    @Input() menuTab!: MenuTab;
    @Input() lightMode = false;
    @Input() menuExpanded = false;
    @Input() directLink = false;

    navigateTo(): void {
        if (this.menuTab.routerLink) window.location.href = this.menuTab.routerLink;
    }
}
