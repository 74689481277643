import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IUnique, UserPermission } from 'src/app/core/definitions/interfaces';
import { ID } from 'src/app/core/definitions/types';
import {
    RoleInfoInterface,
    RolesInfo
} from 'src/app/core/interfaces/roles-info.interface';
import { UserRolesEmail } from 'src/app/core/interfaces/user-roles-email.interface';
import { OrganizationSummary } from 'src/app/core/models/organization-summary.model';
import { UserInfoModel, UserInfoState } from 'src/app/core/models/user-info.model';
import { CustomSnackBarComponent } from 'src/app/shared/components/custom-snack-bar/custom-snack-bar.component';
import { DeleteConfirmationComponent } from 'src/app/shared/components/delete-confirmation/delete-confirmation.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { RoleEnum } from '../../enums/role-enum';
import * as organizationActions from '../../store/organization.actions';
import { isSupportUser } from '../../organization.functions';
import { fromOrganization } from '../../store/organization.selectors';
import { UserInfoInterface } from 'src/app/core/interfaces/user-info.interface';
import { InvalidWithErrors } from '../../interfaces/invalid-with-errors.interface';
import { Action } from 'src/app/shared/interfaces/action';
import { MEDIUM } from 'src/app/shared/pipes/custom-date.pipe';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserListComponent implements OnInit, OnDestroy {
    iconStyle = {
        background: '#DEDBDB',
        color: 'black',
        'border-radius': '50%',
        transparent: '0.8',
        height: '25px',
        width: '25px'
    };

    displayedColumns: string[] = [
        'fullname',
        'email',
        'role',
        'administrative',
        'action'
    ];

    dateString = MEDIUM;

    users: UserInfoInterface[] = [];

    inviteRoles$!: Observable<RolesInfo[]>;
    inviteAdministrativeRoles$!: Observable<RolesInfo[]>;

    selectedRoles: ID[] = [];
    selectedAdminRole: ID = '';
    selectedUserRole: ID = '';

    errorValidationMessages: string[] = [];

    inviteEmail = '';

    @Input() allUsers!: UserInfoInterface[] | null;
    @Input() isSupportUsers = false;
    @Input() currentUserPermission: UserPermission | null | undefined;
    @Input() currentOrganization: OrganizationSummary | null | undefined;

    onDestroy$ = new EventEmitter();

    disableRoleSelection = false;

    createInfoState = UserInfoState.CREATE;
    existInfoState = UserInfoState.EXISTING;
    inviteInfoState = UserInfoState.INVITED;

    invalidRole: InvalidWithErrors = { invalid: true, errorMessages: [] };
    invalidAdministrativeRole: InvalidWithErrors = { invalid: true, errorMessages: [] };
    invalidEmail: InvalidWithErrors = { invalid: true, errorMessages: [] };

    actionSelectedId = '';

    allRoles: RoleInfoInterface[] = [];

    userToInvite!: UserRolesEmail;

    userActions: { actions: Action[] }[] = [];

    onlyOwner: UserInfoInterface | undefined = undefined;

    constructor(
        private store: Store,
        private snackBarService: SnackBarService,
        public dialog: MatDialog
    ) {}

    ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    ngOnInit(): void {
        this.inviteRoles$ = this.store.select(fromOrganization.selectUserRoleOptions);
        this.inviteAdministrativeRoles$ = this.store.select(
            fromOrganization.selectAdministrativeRoleOptions
        );

        this.store
            .select(fromOrganization.selectRoles)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((roles) => {
                this.allRoles = roles;
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.allUsers) this.addUsersToTable();
    }

    addUsersToTable(): void {
        this.users = this.allUsers ?? [];

        if (
            !this.isSupportUsers &&
            !this.users.find((u) => u.state === UserInfoState.CREATE)
        ) {
            this.users.unshift(
                new UserInfoModel({
                    fullname: '',
                    email: '',
                    roles: [],
                    state: UserInfoState.CREATE,
                    id: '1'
                } as IUnique)
            );
        }

        const owners = this.users.filter(
            (u) => u.emailConfirmed && u.roles.includes(RoleEnum[RoleEnum.Owner])
        );

        if (owners.length === 1) this.onlyOwner = owners[0];

        this.users.forEach((user: UserInfoInterface) => {
            this.userActions.push({
                actions: this.getUserPermission(user, this.onlyOwner)
            });
        });
    }

    deleteAction(): void {
        this.store.dispatch(
            organizationActions.deleteAccount({
                id: this.actionSelectedId,
                organizationName: this.currentOrganization?.name ?? ''
            })
        );
    }

    clickInMenu(id: any) {
        this.actionSelectedId = id;
    }

    openDeleteDialog(): void {
        const selectedUser = this.users.find((u) => u.id === this.actionSelectedId);
        this.dialog.open(DeleteConfirmationComponent, {
            width: '500px',
            autoFocus: false,
            height: '400px',
            data: { message: selectedUser?.email, onAccept: () => this.deleteAction() }
        });
    }

    resendInvitation(): void {
        const selectedUser = this.users.find((u) => u.id === this.actionSelectedId);
        this.createInvitation(selectedUser?.email ?? '', [
            selectedUser?.roleAdmin ?? '',
            selectedUser?.roleUser ?? ''
        ]);
        this.store.dispatch(
            organizationActions.inviteUser({ invite: this.userToInvite })
        );
    }

    onEmailForUserChange(userEmail: string): void {
        this.inviteEmail = userEmail;
    }

    manageRoles(selectedRole: ID, element: UserInfoInterface, isAdmin = true): void {
        if (!selectedRole) return;

        if (element.state !== UserInfoState.CREATE) {
            const rolesToAdd = isAdmin
                ? [element.roleUser, selectedRole]
                : [element.roleAdmin, selectedRole];
            this.userToInvite = {
                email: element.email,
                roles: rolesToAdd
            };
            this.store.dispatch(
                organizationActions.modifyUserRoles({ invite: this.userToInvite })
            );
            this.setDefaults();
        } else {
            if (isAdmin) {
                this.selectedAdminRole = selectedRole;
            } else {
                this.selectedUserRole = selectedRole;
            }
            this.selectedRoles = [this.selectedAdminRole, this.selectedUserRole];
        }
    }

    inviteUser(): void {
        this.createInvitation(this.inviteEmail, this.selectedRoles);
        this.store.dispatch(
            organizationActions.inviteUser({ invite: this.userToInvite })
        );
        this.setDefaults();
    }

    get ableToInvite() {
        return (
            (this.currentUserPermission?.isAbleToAddOwners ?? false) ||
            (this.currentUserPermission?.isAbleToAddUsers ?? false)
        );
    }

    getUserPermission(user: UserInfoInterface, onlyOwner: UserInfoInterface | undefined) {
        const actions: Action[] = [];
        const userExist = user.state === UserInfoState.EXISTING;
        const isOnlyOwner = onlyOwner && onlyOwner.email == user.email;

        if (user.roles.includes(RoleEnum[RoleEnum.Owner])) {
            this.addActionsToUser(
                actions,
                isOnlyOwner ?? false,
                this.currentUserPermission?.isAbleToDeleteOwners ?? false,
                this.currentUserPermission?.isAbleToAddOwners ?? false,
                userExist
            );
        } else if (user.roles.includes(RoleEnum[RoleEnum.Support])) {
            this.addActionsToUser(
                actions,
                isOnlyOwner ?? false,
                this.currentUserPermission?.isAbleToRemoveSupport ?? false,
                this.currentUserPermission?.isAbleToAddSupport ?? false,
                userExist
            );
        } else {
            this.addActionsToUser(
                actions,
                isOnlyOwner ?? false,
                this.currentUserPermission?.isAbleToDeleteUsers ?? false,
                this.currentUserPermission?.isAbleToAddUsers ?? false,
                userExist
            );
        }

        return [...actions];
    }

    addActionsToUser(
        actions: Action[],
        isOnlyOwner: boolean,
        userAllowDelete: boolean,
        userAllowResend: boolean,
        userExist: boolean
    ) {
        if (userAllowDelete) actions.push(this.addRemoveAction(isOnlyOwner));
        if (!userExist && userAllowResend) actions.push(this.addResendAction());
    }

    onInvalidWithErrorsRole(invalid: InvalidWithErrors): void {
        this.invalidRole = invalid;
        this.onCheckForErrors();
    }

    onInvalidWithErrorAdmin(invalid: InvalidWithErrors): void {
        this.invalidAdministrativeRole = invalid;
        this.onCheckForErrors();
    }

    onInvalidWithErrorsEmail(invalid: InvalidWithErrors): void {
        this.invalidEmail = invalid;
        this.onCheckForErrors();
    }

    onSupportedUser(isSupportUser: boolean): void {
        this.disableRoleSelection = isSupportUser;
    }

    onCheckForErrors(): void {
        this.errorValidationMessages = [];
        this.invalidEmail.errorMessages.forEach((message: string) =>
            this.errorValidationMessages.push(message)
        );
        if (!this.disableRoleSelection) {
            this.invalidAdministrativeRole.errorMessages.forEach((message: string) =>
                this.errorValidationMessages.push(message)
            );
            this.invalidRole.errorMessages.forEach((message: string) =>
                this.errorValidationMessages.push(message)
            );
        }

        this.openSnackBarForErrors();
    }

    private createInvitation(userEmail: string, roles: ID[]) {
        this.userToInvite = {
            email: userEmail.toLowerCase(),
            roles: [...roles]
        };

        this.checkForSupportRoles(this.userToInvite);
    }

    private checkForSupportRoles(inviteUser: UserRolesEmail) {
        if (isSupportUser(inviteUser.email.toLowerCase())) {
            inviteUser.roles = this.allRoles
                .filter((r) => r.name === RoleEnum[RoleEnum.Support])
                .map((role: RoleInfoInterface) => {
                    return role.id;
                });
        }
    }

    private addRemoveAction(disabled: boolean) {
        return {
            icon: 'delete',
            iconFirst: true,
            emitEvent: () => this.openDeleteDialog(),
            label: 'Remove User',
            style: {
                color: 'var(--remove)',
                'justify-content': 'flex-start'
            },
            disabled
        };
    }

    private addResendAction() {
        return {
            isSvgIcon: true,
            iconFirst: true,
            icon: 'resend_invitation',
            emitEvent: () => this.resendInvitation(),
            label: 'Resend Invitation',
            style: {
                color: '#4E4E4E',
                'justify-content': 'flex-start'
            }
        } as Action;
    }

    private openSnackBarForErrors() {
        if (this.errorValidationMessages.length) {
            this.snackBarService.openFromComponent(CustomSnackBarComponent, {
                messages: this.errorValidationMessages,
                success: false
            });
        } else {
            this.snackBarService.dismissSnackBar();
        }
    }

    private setDefaults() {
        this.inviteEmail = '';
        this.selectedRoles = [];
    }
}
