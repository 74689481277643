import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserMenuSectionComponent } from '../user-menu-section/user-menu-section.component';

@Component({
    selector: 'app-user-menu-option[text][click]',
    templateUrl: './user-menu-option.component.html',
    styleUrls: ['./user-menu-option.component.scss']
})
export class UserMenuOptionComponent {
    @Input() text?: string;
    @Input() icon = '';
    @Output() click = new EventEmitter();

    constructor(private parent: UserMenuSectionComponent) {}

    onClick() {
        this.click!.emit();
    }
}
function animate(arg0: string): any {
    throw new Error('Function not implemented.');
}
