// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { HubEnvironment } from 'src/app/core/definitions/enums';

export const environment = {
    production: true,
    configuration: {
        backend_scheme: 'https://',
        backend_url: 'wrback.dev1.w0rkr0ads.com',
        backend_url_version: '/api',
        auth_scheme: 'https://',
        auth_url: 'wrauth.dev1.w0rkr0ads.com',
        auth_url_version: '',
        documentation_scheme: 'https://',
        documentation_url: 'wrapidoc.dev1.w0rkr0ads.com',
        documentation_url_version: '/api',
        package_scheme: 'https://',
        package_url: 'wrapipackages.dev1.w0rkr0ads.com',
        package_version: 'api',
        hub_env: HubEnvironment.Development,
        saveInsights: false
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
