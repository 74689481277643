import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReportView } from 'src/app/core/definitions/enums';

@Component({
    selector: 'app-custom-button-toggle',
    templateUrl: './custom-button-toggle.component.html',
    styleUrls: ['./custom-button-toggle.component.scss']
})
export class CustomButtonToggleComponent {
    @Input() reportView: ReportView | null = null;
    @Output() buttonClicked = new EventEmitter<ReportView>();

    treeView = ReportView.Tree;
    listView = ReportView.Table;

    onClick(reportView: ReportView | null) {
        this.buttonClicked.emit(reportView ?? ReportView.Table);
    }
}
