import {
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { mismatchValidator } from 'src/app/shared/helpers/mismatch-validator';
import { fromRoot, rootActions } from 'src/app/store';
import { PASSWORD_VALIDATION_REGEX } from '../../../../core/constants/app-messages';
import { resetPasswordRequest } from '../../store/users.actions';
import * as userActions from '../../store/users.actions';
@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    @ViewChildren('input') inputs: QueryList<ElementRef<HTMLInputElement>> | undefined;
    @ViewChild('newFieldSet') newFieldSet: ElementRef<HTMLInputElement> | undefined;

    email = '';
    newPassword = '';
    repeatPassword = '';
    userCode = '';

    passwordFieldForValidation: HTMLInputElement | null = null;
    passwordRegex = PASSWORD_VALIDATION_REGEX;

    emailWhiteSpace!: boolean;
    mismatch!: boolean;

    loading$: Observable<boolean>;
    onDestroy$ = new EventEmitter();

    constructor(private store: Store) {
        this.loading$ = this.store.select(fromRoot.selectLoading);
    }

    ngOnInit(): void {
        this.store.dispatch(userActions.validateTokenFromEmail());
        this.store.dispatch(rootActions.setLoading({ loading: true }));
        fromEvent(window, 'resize').pipe(takeUntil(this.onDestroy$)).subscribe();
        this.loading$.pipe(takeUntil(this.onDestroy$)).subscribe((loading) => {
            setTimeout(() => {
                if (!loading && this.newFieldSet?.nativeElement)
                    this.passwordFieldForValidation = this.newFieldSet?.nativeElement;
            }, 1);
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.emit();
        this.onDestroy$.unsubscribe();
    }

    onChange(emailInput: HTMLInputElement): void {
        emailInput.required = true;
        this.email = emailInput.value;
        this.emailWhiteSpace = this.email.trim().length === 0 && this.email.length > 0;
    }

    get formIsValid(): boolean {
        return (
            !!this.email &&
            !!this.newPassword &&
            !!this.repeatPassword &&
            (this.inputs ?? [])
                .map((input) => input.nativeElement)
                .every((input) => input.checkValidity())
        );
    }

    onRepeatPasswordChange(repeat: HTMLInputElement): void {
        repeat.required = true;
        this.repeatPassword = repeat.value;
        this.mismatch = mismatchValidator(this.newPassword, this.repeatPassword);
    }

    onSubmit(): void {
        this.store.dispatch(
            resetPasswordRequest({
                confirmPassword: this.repeatPassword,
                email: this.email,
                password: this.newPassword
            })
        );
        this.repeatPassword = '';
        this.email = '';
        this.newPassword = '';
    }
}
