import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import {
    ApplicationInsights,
    ICustomProperties,
    IDependencyTelemetry,
    IEventTelemetry,
    IExceptionTelemetry,
    IMetricTelemetry,
    IPageViewTelemetry,
    ITraceTelemetry
} from '@microsoft/applicationinsights-web';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { fromRoot } from 'src/app/store';
import { IApplicationInsightsService } from '../interfaces/application-insights.interface';

@Injectable()
export class ApplicationInsightsService implements IApplicationInsightsService {
    previousActivatedComponent: any;
    appInsights!: ApplicationInsights;

    constructor(private store: Store, private router: Router) {}

    setApplicationInsight(appInsights: ApplicationInsights | null) {
        if (!appInsights) {
            return;
        }

        this.appInsights = appInsights;

        this.appInsights.loadAppInsights();

        this.store.select(fromRoot.selectUser).subscribe((user) => {
            this.appInsights.setAuthenticatedUserContext(user?.userId ?? 'defaultUserId');
            this.appInsights.addTelemetryInitializer((envelope) => {
                envelope.tags = envelope.tags ?? [];
                envelope.tags['ai.cloud.role'] = 'Workroads App';

                const item = envelope.baseData;
                if (item) {
                    item.properties = item.properties || {};
                    item.properties.organizationId = user?.currentOrganizationId;
                    item.properties.email = user?.email;
                    item.properties.givenname = user?.givenname;
                }
            });
        });

        this.router.events
            .pipe(filter((event): event is ResolveEnd => event instanceof ResolveEnd))
            .subscribe((event) => {
                const activatedComponent = this.getActivatedComponent(event.state.root);
                if (
                    this.previousActivatedComponent &&
                    activatedComponent.name !== this.previousActivatedComponent.name
                ) {
                    this.appInsights.stopTrackPage(
                        this.previousActivatedComponent.name,
                        event.urlAfterRedirects
                    );
                }
                this.appInsights.startTrackPage(activatedComponent.name);
                this.previousActivatedComponent = activatedComponent;
            });
    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }
        return snapshot.component;
    }

    // expose tracking methods
    trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties) {
        this.appInsights.trackEvent(event, customProperties);
    }

    startTrackEvent(name?: string) {
        this.appInsights.startTrackEvent(name);
    }

    stopTrackEvent(
        name: string,
        properties?: { [p: string]: string },
        measurements?: { [p: string]: number }
    ): any {
        this.appInsights.stopTrackEvent(name, properties, measurements);
    }

    trackPageView(pageView?: IPageViewTelemetry) {
        this.appInsights.trackPageView(pageView);
    }

    startTrackPage(name?: string) {
        this.appInsights.startTrackPage(name);
    }

    stopTrackPage(
        name?: string,
        url?: string,
        properties?: { [name: string]: string },
        measurements?: { [name: string]: number }
    ) {
        this.appInsights.stopTrackPage(name, url, properties, measurements);
    }

    trackMetric(metric: IMetricTelemetry, properties?: ICustomProperties) {
        this.appInsights.trackMetric(metric, properties);
    }

    trackException(exception: IExceptionTelemetry, properties?: ICustomProperties) {
        this.appInsights.trackException(exception, properties);
    }

    trackTrace(message: ITraceTelemetry, properties?: ICustomProperties) {
        this.appInsights.trackTrace(message, properties);
    }

    trackDependencyData(dependency: IDependencyTelemetry) {
        this.appInsights.trackDependencyData(dependency);
    }

    flush() {
        this.appInsights.flush();
    }
}
