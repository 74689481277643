<div
    class="version-history-container"
    appClickOutside
    (clicked)="clickOnComponent($event)"
>
    <div class="version-header-container">
        <div class="version-history-header">
            <div class="version-label">Version History</div>
            <app-custom-button
                (buttonClick)="onClose()"
                size="small"
                type="basic"
                icon="cancel"
                styles="color: #707070"
            ></app-custom-button>
        </div>
        <div>
            <div class="version-history-info" *ngIf="title && subtitle">
                <div class="info-text">
                    <div class="info-title">{{ title }}</div>
                    <div class="subtitle-info">{{ subtitle }}</div>
                </div>
            </div>
            <ng-content select="[selector]"></ng-content>
        </div>
        <div class="version-delete" *ngIf="showVersions">
            <div class="version-number">
                {{
                    genericInfo > 1 ? genericInfo + ' versions' : genericInfo + ' version'
                }}
            </div>
            <mat-icon *ngIf="showDeleteAll" class="delete" (click)="onDeleteAllVersions()"
                >delete</mat-icon
            >
        </div>
    </div>

    <div class="version-history-content">
        <ng-content select="[content]"></ng-content>
    </div>
</div>
