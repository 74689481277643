<div class="menu-banner" [class.lightMode]="lightMode">
    <!--
        routerLinkActive applies active class to null routerLink #18347
        https://github.com/angular/angular/issues/18347
     -->
    <a
        *ngIf="!directLink"
        [routerLinkActive]="menuTab.routerLink ? 'active' : ''"
        [routerLink]="menuTab.routerLink"
        class="justify-start"
        [color]="lightMode ? 'primary' : 'accent'"
        mat-flat-button
    >
        <mat-icon *ngIf="menuTab.icon" [svgIcon]="menuTab.isSvgIcon ? menuTab.icon : ''">
            {{ !menuTab.isSvgIcon ? menuTab.icon : '' }}
        </mat-icon>
        <div class="label">
            {{ menuTab.title }}
        </div>
    </a>

    <a
        *ngIf="directLink"
        [routerLinkActive]="menuTab.routerLink ? 'active' : ''"
        (click)="navigateTo()"
        class="justify-start"
        [color]="lightMode ? 'primary' : 'accent'"
        mat-flat-button
    >
        <mat-icon *ngIf="menuTab.icon" [svgIcon]="menuTab.isSvgIcon ? menuTab.icon : ''">
            {{ !menuTab.isSvgIcon ? menuTab.icon : '' }}
        </mat-icon>
        <div class="label">
            {{ menuTab.title }}
        </div>
    </a>
    <div class="extra-content">
        <ng-content></ng-content>
    </div>
</div>
