import { createFeature, createReducer, on } from '@ngrx/store';
import { UserInfo } from 'src/app/core/definitions/interfaces';
import {
    OrganizationSummary,
    OrganizationSummaryModel
} from 'src/app/core/models/organization-summary.model';
import * as rootActions from '../actions/root.actions';
import { ID } from 'src/app/core/definitions/types';
import * as AuthenticationActions from 'src/app/modules/authentication/store/authentication.actions';

export interface RootState {
    hubUrl: any;
    loading: boolean;
    loadingDisplayNames: boolean;
    organizations: {
        all: OrganizationSummaryModel[];
        current: OrganizationSummary | undefined;
        others: OrganizationSummaryModel[];
    };
    user: UserInfo | undefined;
    organizationUsersDisplayNames: { [nameIdentifier: ID]: string } | null;
}

export const initialState: RootState = {
    loading: false,
    user: undefined,
    organizations: { all: [], others: [], current: undefined },
    hubUrl: null,
    organizationUsersDisplayNames: null,
    loadingDisplayNames: false
};

const rootReducer = createReducer(
    initialState,
    on(rootActions.getOrganizationsSuccess, (state, { organizations }) => {
        const currentOrganization = state.user?.currentOrganizationId;
        const current = organizations.find((o) => o.id === currentOrganization);
        const others = organizations.filter((o) => o.id !== currentOrganization);

        return {
            ...state,
            loading: false,
            organizations: { ...state.organizations, all: organizations, current, others }
        };
    }),
    on(
        rootActions.optimisticUiOrganizationChange,
        rootActions.currentOrganizationChangeFromUserComponent,
        rootActions.changeOrganizationContextFromUrl,
        (state, action) => {
            const id = action.id || state.user?.currentOrganizationId;
            const current = state.organizations.all.find((o) => o.id === id);
            const others = state.organizations.all.filter((o) => o.id !== id);
            return {
                ...state,
                organizations: { ...state.organizations, current, others },
                loading: false
            };
        }
    ),

    on(rootActions.updateCurrentOrganizationSuccess, (state, action) => {
        const id = state.user?.currentOrganizationId;
        const current = state.organizations.all.find((o) => o.id === id);
        if (!current) {
            return { ...state };
        }
        current.name = action.name;
        return { ...state, organizations: { ...state.organizations, current } };
    }),
    on(rootActions.getLatestHubUrlSuccess, (state, { url }) => ({
        ...state,
        hubUrl: url
    })),
    on(rootActions.getLatestHubUrl, (state) => ({ ...state, hubUrl: null })),
    on(rootActions.setLoading, (state, { loading }) => ({ ...state, loading })),
    on(rootActions.getOrganizations, (state) => ({ ...state, loading: true })),
    on(rootActions.clearDisplayNames, (state) => ({
        ...state,
        organizationUsersDisplayNames: null
    })),
    on(rootActions.getOrganizationUsersDisplayNames, (state) => ({
        ...state,
        loadingDisplayNames: true,
        organizationUsersDisplayNames: null
    })),
    on(rootActions.getOrganizationUsersDisplayNamesSuccess, (state, { users }) => ({
        ...state,
        loadingDisplayNames: false,
        organizationUsersDisplayNames: users
    })),
    on(rootActions.getOrganizationUsersDisplayNamesFail, (state) => ({
        ...state,
        loadingDisplayNames: false
    })),
    on(rootActions.logout, (state) => ({
        ...state,
        user: undefined,
        organizations: initialState.organizations,
        organizationUsersDisplayNames: initialState.organizationUsersDisplayNames
    })),
    on(rootActions.initUserFromStorage, (state, { user }) => ({ ...state, user })),
    on(rootActions.initNoUserFromStorage, (state) => ({ ...state, user: undefined })),
    on(AuthenticationActions.loginRequest, (state) => ({ ...state, user: undefined })),
    on(AuthenticationActions.loginSuccess, (state, { user }) => ({ ...state, user })),
    on(rootActions.changeOrganizationSuccess, (state, { user }) => {
        const id = user?.currentOrganizationId;
        const current = state.organizations.all.find((o) => o.id === id);
        const others = state.organizations.all.filter((o) => o.id !== id);

        return {
            ...state,
            user,
            organizations: { ...state.organizations, current, others }
        };
    })
);

export const rootFeature = createFeature({ name: 'root', reducer: rootReducer });

export const { reducer } = rootFeature;
