<div
    class="snack-message error"
    [ngClass]="{ error: !success, 'success-notification': success }"
>
    <ul>
        <li *ngFor="let message of messages">
            {{ message }}
        </li>
    </ul>
</div>
