import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { API_BACKEND_SERVICE } from '../../constants/connection.apiservice.contant';
import { IUnique } from '../../definitions/interfaces';
import { ID } from '../../definitions/types';
import { ElementDefinitionModel } from '../../models/element-definition.model';
import { ApiService } from '../../services/api.service';
import { CRUDResource } from '../crud.resource';
import mockedElement from '../mocks/workroadElements.json';
import { ELEMENT_DEFINITIONS } from '../resources';
import { HttpParams } from '@angular/common/http';
import { ElementHistory } from '../../interfaces/element-history.interface';
import { ElementHistoryItem } from '../../interfaces/element-history-item.interface';
import { map } from 'rxjs/operators';
import { ElementDifferences } from '../../interfaces/element-differences.interface';
import { ElementDefinitionDetail } from '../../interfaces/element-definition-detail.interface';
import { PatternSummaryModel } from '../../models/pattern-summary.model';

@Injectable({
    providedIn: 'root'
})
export class ElementDefinitionResourceService extends CRUDResource<ElementDefinitionModel> {
    public isMock = false;

    getElementVersionCtor = (obj: Partial<ElementHistory>) => {
        const history: ElementHistoryItem[] = obj.history ?? [];
        const mostRecentVersion: ID = history.length > 0 ? history[0].id : '';
        const element = obj.elementDefinition ?? { id: 0 };
        const elementDefinitionModel = new ElementDefinitionModel({
            ...element,
            isMostRecent: element.id === mostRecentVersion
        } as any);
        return {
            elementDefinition: elementDefinitionModel,
            history: history
        };
    };

    constructor(@Inject(API_BACKEND_SERVICE) api: ApiService) {
        super(
            api,
            '',
            ELEMENT_DEFINITIONS,
            (obj: IUnique) => new ElementDefinitionModel(obj)
        );
    }

    /**
     * This method returns mocked data if `isMock` field is setted in true otherwise
     * execute the api call
     * @param id
     * @param params
     * @returns
     */
    get(id: ID, params?: HttpParams): Observable<ElementDefinitionModel> {
        if (this.isMock) {
            return of(new ElementDefinitionModel(mockedElement[0]));
        }
        const ctor = (obj: Partial<ElementHistory>) =>
            new ElementDefinitionModel(obj.elementDefinition ?? { id: 0 });
        return super.customGet<ElementDefinitionModel>(ctor, `${id}`);
    }

    getUsedPatterns(id: ID, params?: HttpParams): Observable<PatternSummaryModel[]> {
        const ctor = (obj: any) =>
            Object.entries(obj)
                .filter(([key]) => !isNaN(Number(key)))
                .map(([_, value]) => value as PatternSummaryModel);

        return super.customGet<PatternSummaryModel[]>(ctor, `get-used-patterns/${id}`);
    }

    /**
     * This method returns mocked data if `isMock` field is setted in true otherwise
     * execute the api call
     * @param params
     * @returns
     */
    getAll(params?: HttpParams): Observable<ElementDefinitionModel[]> {
        if (this.isMock) {
            return of(mockedElement.map((item) => new ElementDefinitionModel(item)));
        }

        return super.getAll(params);
    }

    getPublished(params?: HttpParams): Observable<ElementDefinitionModel[]> {
        return this._api
            .get<ElementDefinitionModel[]>(`${this._url}/published`, params)
            .pipe(map((items) => items.result.map((item) => this.getInstance(item))));
    }

    getElementVersion(id: ID, historyCollection: ID): Observable<ElementHistory> {
        return super.customGet<ElementHistory>(
            this.getElementVersionCtor,
            `history/${historyCollection}/${id}`
        );
    }

    getElementDifferences(
        original: ID,
        unsavedDraft: ElementDefinitionDetail
    ): Observable<ElementDifferences> {
        return super
            .customPost<ElementDifferences>('diff-view', unsavedDraft, {
                original
            })
            .pipe(map((value) => value.result));
    }

    publishElement(
        id: ID,
        message: string,
        historyCollection: ID
    ): Observable<ElementDefinitionDetail> {
        return super
            .customPost<ElementDefinitionDetail>(`history/${historyCollection}/publish`, {
                id,
                message
            })
            .pipe(map((value) => value.result));
    }

    deleteElement(id: ID, historyCollection: ID): Observable<boolean> {
        return super
            .customDelete(`history/${historyCollection}/${id}`)
            .pipe(map((value) => value.success));
    }
}
