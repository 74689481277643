import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ResponseObject } from '../definitions/classes';
import { HubUrl } from '../interfaces/hub-url.interface';
import { GET_URL_PACKAGE } from '../resources/resources';

@Injectable({
    providedIn: 'root'
})
export class PackageService {
    private readonly PROTOCOL = environment.configuration.package_scheme;
    private readonly DOMAIN = environment.configuration.package_url;
    private readonly VERSION = environment.configuration.package_version;
    private readonly BASE_URL = `${this.PROTOCOL}${this.DOMAIN}/${this.VERSION}`;

    constructor(private _http: HttpClient) {}

    getHubUrl(): Observable<string> {
        const url = `${this.BASE_URL}${GET_URL_PACKAGE}${environment.configuration.hub_env}`;
        return this._http
            .get<ResponseObject<HubUrl>>(url)
            .pipe(map((result) => result.result.url));
    }
}
