<ng-container *ngIf="currentUser$ | async as currentUser">
    <app-user-menu
        [organization]="(currentOrganization$ | async)?.name ?? ''"
        [userName]="currentUser ? currentUser.name + ' ' + currentUser.surname : ''"
    >
        <app-user-menu-section>
            <app-user-menu-option
                text="Log out"
                (click)="logout()"
                icon="logout"
            ></app-user-menu-option>
        </app-user-menu-section>
        <app-user-menu-section
            header="Change Organization"
            *ngIf="(otherOrganizations$ | async)?.length"
        >
            <app-user-menu-organization
                *ngFor="let organization of otherOrganizations$ | async"
                [name]="organization.name"
                [id]="organization.id"
                (click)="changeOrganization(organization.id)"
            ></app-user-menu-organization>
        </app-user-menu-section>
        <app-user-menu-section
            *ngIf="
                isOrganizationChangeAllow(currentUser) &&
                (currentOrganization$ | async) as currentOrganization
            "
        >
            <app-user-menu-entity
                text="Organization settings"
                [name]="currentOrganization?.name ?? ''"
                [settingsRoute]="'/app/organization'"
            ></app-user-menu-entity>
        </app-user-menu-section>
        <app-user-menu-section>
            <app-user-menu-entity
                text="Personal settings"
                [name]="currentUser ? currentUser.name + ' ' + currentUser.surname : ''"
                [email]="currentUser?.email ?? ''"
                settingsRoute="/app/personal"
            ></app-user-menu-entity>
        </app-user-menu-section>
    </app-user-menu>
</ng-container>
