import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appScrollShadows]'
})
export class ScrollShadowDirective {
    shadowTop!: HTMLElement;
    shadowBottom!: HTMLElement;

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.initShadows();
    }

    initShadows() {
        this.shadowTop = this.renderer.createElement('div');
        this.shadowBottom = this.renderer.createElement('div');

        this.renderer.addClass(this.shadowTop, 'shadow-top-appScrollShadows');
        this.renderer.addClass(this.shadowBottom, 'shadow-bottom-appScrollShadows');
        this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');

        this.renderer.appendChild(this.el.nativeElement, this.shadowTop);
        this.renderer.appendChild(this.el.nativeElement, this.shadowBottom);
        this.onScroll();
    }

    updatePositions() {
        const elem = this.el.nativeElement;

        const topShadowPosition = elem.scrollTop;
        const bottomShadowPosition =
            elem.scrollTop + elem.clientHeight - this.shadowBottom.clientHeight;

        this.renderer.setStyle(this.shadowTop, 'top', topShadowPosition + 'px');
        this.renderer.setStyle(this.shadowBottom, 'top', bottomShadowPosition + 'px');

        if (elem.scrollTop > 0) {
            this.renderer.setStyle(this.shadowTop, 'opacity', '1');
        } else {
            this.renderer.setStyle(this.shadowTop, 'opacity', '0');
        }

        if (
            elem.scrollTop + elem.offsetHeight >=
            elem.scrollHeight - this.shadowBottom.clientHeight
        ) {
            this.renderer.setStyle(this.shadowBottom, 'opacity', '0');
        } else {
            this.renderer.setStyle(this.shadowBottom, 'opacity', '1');
        }
    }

    @HostListener('scroll', ['$event'])
    onScroll(): void {
        this.updatePositions();
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.updatePositions();
    }

    @HostListener('mouseenter', ['$event'])
    onMouseEnter(): void {
        this.updatePositions();
    }

    @HostListener('mouseleave', ['$event'])
    onMouseLeave(): void {
        this.renderer.setStyle(this.shadowTop, 'opacity', '0');
        this.renderer.setStyle(this.shadowBottom, 'opacity', '0');
    }
}
