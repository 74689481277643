import { Ace } from 'ace-builds';
import Token = Ace.Token;
import ace from 'ace-builds/src-noconflict/ace';

const TokenIterator = ace.require('ace/token_iterator').TokenIterator;

export class FlowTokenIterator {
    getCurrentToken(session: Ace.EditSession, row: number, col: number) {
        const iterator = new TokenIterator(session, row, col);
        const last: Token | null = iterator.getCurrentToken();
        return last;
    }
}
