import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-reset-password-success',
    templateUrl: './reset-password-success.component.html'
})
export class ResetPasswordSuccessComponent {
    constructor(private route: Router) {}

    onButtonClick(): void {
        this.route.navigate(['/login']);
    }
}
