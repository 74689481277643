import { getSelectors } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import { DocumentationFileStructureModel } from 'src/app/core/models/doc-file-structure.model';
import { documentationFeature } from './docs.reducer';
import { DocFilePrevNext } from '../../../core/interfaces/documentation-file-prev-next.interface';

const { selectQueryParams } = getSelectors();

const { selectDocumentationFile, selectDocumentationStructure } = documentationFeature;

// Selector for documentation path
const selectDocQueryParam = createSelector(
    selectQueryParams,
    (queryParams) => queryParams?.doc ?? null
);

const selectSelectedDocument = createSelector(
    selectDocumentationStructure,
    selectDocQueryParam,
    (structure, path) => {
        if (structure == null || path == null) {
            return undefined;
        }
        return getFile(structure, path);
    }
);

function getFile(
    structure: DocumentationFileStructureModel,
    id: string
): DocumentationFileStructureModel | null {
    if (structure.type === 1) {
        return structure.path === id ? structure : null;
    } else {
        for (const struct of structure.children ?? []) {
            const result = getFile(struct, id);
            if (result != null) return result;
        }
    }
    return null;
}

const selectPrevAndNextDocument = createSelector(
    selectDocumentationStructure,
    selectSelectedDocument,
    (structure, current) => {
        if (!current) return [null, null];
        const listFiles = [...documentationFileStructureModelFlattener(structure)];
        const docIndex = listFiles.findIndex((e) => e.path === current.path);
        if (docIndex === -1) return [null, null];
        if (docIndex === 0) return [null, listFiles[1]];
        if (docIndex === listFiles.length - 1)
            return [listFiles[listFiles.length - 2], null];
        return [listFiles[docIndex - 1], listFiles[docIndex + 1]];
    }
);

function* documentationFileStructureModelFlattener(
    structure: DocumentationFileStructureModel | null,
    newFolder = true
): Iterable<DocFilePrevNext> {
    if (structure != null) {
        if (structure.type === 1) {
            yield {
                name: structure.name!,
                path: structure.path!,
                newFolder
            };
        } else {
            newFolder = true;
            for (const item of structure.children!) {
                yield* documentationFileStructureModelFlattener(item, newFolder);
                newFolder = false;
            }
        }
    }
    return null;
}

export const fromDocumentation = {
    selectDocQueryParam,
    selectDocumentationFile,
    selectDocumentationStructure,
    selectSelectedDocument,
    selectPrevAndNextDocument
};
