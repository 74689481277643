<div class="content-container" (click)="$event.stopPropagation()">
    <div *ngFor="let item of options">
        <div class="title-container">
            <div class="title">{{ item.title }}</div>
            <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
        </div>
        <div class="description">
            {{ item.description }}
            <a
                *ngIf="item.documentationLink"
                [href]="item.documentationLink"
                target="_blank"
                >Learn more...</a
            >
        </div>
        <mat-radio-group
            class="radio-group-container"
            (change)="onSelect($event.value)"
            [value]="existingSelectedOption"
            [disabled]="disabled"
        >
            <ng-container *ngIf="item.options.length">
                <mat-radio-button
                    *ngFor="let option of item.options"
                    [value]="option.id"
                    class="radio-button-container"
                    [class.selected]="option.id === selectedOption"
                    [disabled]="option.disabled"
                >
                    <div class="title-container">
                        <div class="title">{{ option.name }}</div>
                        <span>{{ option.subDescription }}</span>
                    </div>
                    <div class="description">{{ option.description }}</div>
                </mat-radio-button>
            </ng-container>
        </mat-radio-group>
    </div>
</div>
