import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { SYSTEM_ROLES } from 'src/app/core/constants/feature';
import { ID } from 'src/app/core/definitions/types';
import {
    OrganizationSummary,
    OrganizationSummaryModel
} from 'src/app/core/models/organization-summary.model';
import {
    getOrganizations,
    optimisticUiOrganizationChange
} from 'src/app/store/actions/root.actions';

import { UserInfo } from 'src/app/core/definitions/interfaces';
import { fromRoot, rootActions } from 'src/app/store';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
    selector: 'app-user-setting',
    templateUrl: './user-setting.component.html',
    styleUrls: ['./user-setting.component.scss']
})
export class UserSettingComponent {
    currentOrganization$: Observable<OrganizationSummary | undefined>;
    otherOrganizations$: Observable<OrganizationSummaryModel[]>;

    currentUser$: Observable<UserInfo | undefined>;
    organizations: OrganizationSummaryModel[] = [];

    SYSTEM_ROLES = SYSTEM_ROLES;
    subscriptions: Subscription[] = [];
    existingOrganization = '';

    constructor(
        private store: Store,
        private router: Router,
        private snackBar: SnackBarService
    ) {
        this.currentOrganization$ = this.store.select(fromRoot.selectCurrentOrganization);
        this.otherOrganizations$ = this.store.select(fromRoot.selectOtherOrganizations);
        this.currentUser$ = this.store.select(fromRoot.selectUser);
    }

    ngOnInit(): void {
        this.store.dispatch(getOrganizations());

        this.subscriptions.push(
            this.currentOrganization$.subscribe((result) => {
                if (this.existingOrganization === '') {
                    this.existingOrganization = result ? result.name : '';
                }
                if (
                    this.existingOrganization != '' &&
                    result &&
                    this.existingOrganization != result.name
                ) {
                    this.snackBar.success(
                        `The organization was changed successfully to ${result.name} `
                    );
                    this.existingOrganization = result.name;
                }
            })
        );
    }

    changeOrganization(id: ID) {
        // 1- "Optimistic UI" update
        // in order to remove the WS lag
        this.store.dispatch(optimisticUiOrganizationChange({ id }));
        this.router.navigate(['/']);

        // 2- WS call
        // update user & adjust if necessary
        this.store.dispatch(rootActions.changeOrganization({ id }));
    }

    logout() {
        this.store.dispatch(rootActions.logout());
    }

    isOrganizationChangeAllow(currentUser: UserInfo): boolean {
        if (!currentUser || !currentUser.role) return false;
        return (
            !currentUser?.role?.includes(SYSTEM_ROLES.USER) &&
            !currentUser?.role?.includes(SYSTEM_ROLES.NONADMIN)
        );
    }
}
