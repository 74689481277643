import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { ViewDetail } from 'src/app/core/interfaces/view-detail.interface';
import { ViewSummary } from 'src/app/core/interfaces/view-summary.interface';
import { fromViews } from 'src/app/modules/views/store/selectors/views.selectors';
import { DraftRoutingService } from '../../services/draft-routing.service';
import { ID } from 'src/app/core/definitions/types';
import { IUnique, UserPermission } from 'src/app/core/definitions/interfaces';
import { map, takeUntil } from 'rxjs/operators';
import {
    getViews,
    navigateToView
} from 'src/app/modules/views/store/actions/views.actions';
import { fromRoot } from 'src/app/store';

@Component({
    selector: 'app-views-list',
    templateUrl: './views-list.component.html',
    styleUrls: ['./views-list.component.scss']
})
export class ViewsListComponent implements OnInit, OnDestroy {
    draftViews$: Observable<ViewDetail[]>;
    views$: Observable<ViewSummary[]>;
    selectedQueryParam$: Observable<ID | undefined>;
    onDestroy$ = new Subject<void>();

    combined$: Observable<IUnique[]>;
    userPermission$!: Observable<UserPermission>;

    isAbleToEditPattern = false;

    selectedId?: ID;

    constructor(private store: Store, private draftRoutingService: DraftRoutingService) {
        this.draftViews$ = this.store.select(fromViews.selectDrafts);
        this.views$ = this.store.select(fromViews.selectViews);
        this.selectedQueryParam$ = this.store.select(fromViews.selectSelectedQueryParam);
        this.combined$ = combineLatest([this.draftViews$, this.views$]).pipe(
            map(([drafts, patterns]) => [...drafts, ...patterns])
        );
        this.draftRoutingService.createViewDraftOnNavigation(this.onDestroy$);
        this.userPermission$ = this.store.select(fromRoot.selectUserPermissions);
    }

    ngOnInit(): void {
        this.store.dispatch(getViews());
        this.selectedQueryParam$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((selected) => {
                this.selectedId = selected;
            });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    createViewDraft(): void {
        this.draftRoutingService.createViewDraft();
    }

    onClick(view: ViewSummary): void {
        if (view.id === this.selectedId) {
            return;
        }
        this.store.dispatch(
            navigateToView({
                id: view.id
            })
        );
    }
}
