import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { MatInput } from '@angular/material/input';

@Directive({
    selector: '[appAutofocus]'
})
export class AutofocusDirective implements OnInit {
    @Input()
    autofocusSelectValue = false;

    constructor(
        private matInput: MatInput,
        private elRef: ElementRef<HTMLInputElement>
    ) {}

    ngOnInit(): void {
        setTimeout(() => {
            this.matInput.focus();

            if (this.autofocusSelectValue) {
                this.elRef.nativeElement.setSelectionRange(
                    0,
                    this.elRef.nativeElement.value.length
                );
            }
        });
    }
}
