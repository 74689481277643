import { RoundMode, UnicityKind } from '../definitions/enums';
import { SourcesQueryParam } from '../interfaces/source-info.interface';

export function removeSpaces(str: string): string {
    return str?.replace(/\s+/g, '');
}

export function containsSpaces(str: string): boolean {
    return /\s/.test(str);
}

export function unicityKindToString(unicityKind: UnicityKind): string {
    switch (unicityKind) {
        case UnicityKind.OverwriteValue:
            return 'Overwrite value';
        case UnicityKind.NumberRound:
            return 'Number round value';
        case UnicityKind.StringCaseSensitive:
            return 'Case sensitive';
        case UnicityKind.StringCaseInsensitive:
            return 'Case insensitive';
        case UnicityKind.Boolean:
            return 'Boolean';
        case UnicityKind.NumberExactValue:
            return 'Number exact value';
        default:
            return 'Unicity kind not found';
    }
}

export function roundModeToString(roundMode: RoundMode): string {
    switch (roundMode) {
        case RoundMode.Ceiling:
            return 'Ceiling';
        case RoundMode.Floor:
            return 'Floor';
        case RoundMode.Round:
            return 'Round';
        default:
            return 'Round mode not found';
    }
}

export function encodeSourcesQueryParam(sources: SourcesQueryParam): string {
    return JSON.stringify(sources);
}

export function decodeSourcesQueryParam(param: string): SourcesQueryParam {
    return JSON.parse(param);
}
