import { Component, Input } from '@angular/core';
import { UnicityKind } from '../../../core/definitions/enums';

@Component({
    selector: 'app-unicity-display',
    templateUrl: './unicity-display.component.html',
    styleUrls: ['./unicity-display.component.scss']
})
export class UnicityDisplayComponent {
    @Input() unicityKind?: UnicityKind;
}
