import { UserPermission } from 'src/app/core/definitions/interfaces';

export const ALL_PERMISSION_FALSE: UserPermission = {
    isAbleToDeleteOrganization: false,
    isAbleToAddOwners: false,
    isAbleToDeleteOwners: false,
    isAbleToUpdateOrganizationInfo: false,
    isAbleToAddUsers: false,
    isAbleToDeleteUsers: false,
    isAbleToViewElements: false,
    isAbleToEditElements: false,
    isAbleToViewPatterns: false,
    isAbleToEditPatterns: false,
    isAbleToViewLinks: false,
    isAbleToEditReports: false,
    isAbleToExportReports: false,
    isAbleToAddAdmin: false,
    isAbleToRemoveAdmin: false,
    isAbleToAddSupport: false,
    isAbleToRemoveSupport: false,
    isAbleToEditRoles: false,
    isAbleToChangePermission: false,
    isAbleToCreateLinkInHub: false,
    isAbleToUpdateLinkInHub: false,
    isAbleToInstallHub: false
};

export const ALL_PERMISSION_TRUE: UserPermission = {
    isAbleToDeleteOrganization: true,
    isAbleToAddOwners: true,
    isAbleToDeleteOwners: true,
    isAbleToUpdateOrganizationInfo: true,
    isAbleToAddUsers: true,
    isAbleToDeleteUsers: true,
    isAbleToViewElements: true,
    isAbleToEditElements: true,
    isAbleToViewPatterns: true,
    isAbleToEditPatterns: true,
    isAbleToViewLinks: true,
    isAbleToEditReports: true,
    isAbleToExportReports: true,
    isAbleToAddAdmin: true,
    isAbleToRemoveAdmin: true,
    isAbleToAddSupport: true,
    isAbleToRemoveSupport: true,
    isAbleToEditRoles: true,
    isAbleToChangePermission: true,
    isAbleToCreateLinkInHub: true,
    isAbleToUpdateLinkInHub: true,
    isAbleToInstallHub: true
};
