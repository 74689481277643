import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { fromElementDefinitions } from '../../store/element-definitions.selectors';
import { ElementDefinitionsState } from '../../store/element-definitions.reducer';
import { elementDefinitionHeaderChange } from '../../store/actions/element-definitions.action';
import { HeaderItem } from '../../../layout/interfaces/header-item';
import {
    CODE_NAME,
    DifferencesResult,
    NAME_AND_CODE_NAME,
    PropertyId
} from '../../store/types';
import { CODE_NAME_REGEX } from 'src/app/shared/consts';

@Component({
    selector: 'app-element-toolbar-form',
    templateUrl: './element-toolbar-form.component.html',
    styleUrls: ['./element-toolbar-form.component.scss']
})
export class ElementToolbarFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() creating = false;
    @Input() ableToEdit = false;

    NAME_AND_CODE_NAME: PropertyId = NAME_AND_CODE_NAME;

    elementHeaderItem$: Observable<HeaderItem | null>;
    onDestroy$ = new Subject<void>();

    headerItem: HeaderItem | null = null;
    headerForm: FormGroup = this.formBuilder.group({
        name: ['', Validators.required],
        description: [''],
        codeName: ['', [Validators.required, Validators.pattern(CODE_NAME_REGEX)]]
    });

    editing = false;
    elementCodeNameOriginalValue$: Observable<DifferencesResult | null>;

    constructor(
        private store: Store<ElementDefinitionsState>,
        private formBuilder: FormBuilder
    ) {
        this.elementHeaderItem$ = this.store.select(fromElementDefinitions.selectHeader);
        this.elementCodeNameOriginalValue$ = this.store.select(
            fromElementDefinitions.selectDifferences(CODE_NAME)
        );
    }

    ngOnInit(): void {
        this.headerForm.valueChanges
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((item: HeaderItem) => {
                const changes =
                    item.name !== this.headerItem?.name ||
                    item.description !== this.headerItem.description ||
                    item.codeName !== this.headerItem.codeName;
                if (!changes) return;
                this.store.dispatch(
                    elementDefinitionHeaderChange({
                        elementDefitionHeader: item
                    })
                );
            });

        this.elementHeaderItem$.pipe(takeUntil(this.onDestroy$)).subscribe((item) => {
            if (item == null) {
                // This handles the cases for saving, discard and change current.
                this.editing = false;
            }
            this.headerItem = item;
            this.headerForm.setValue(
                {
                    name: item?.name ?? '',
                    description: item?.description ?? '',
                    codeName: item?.codeName ?? ''
                },
                { emitEvent: false }
            );
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.creating.currentValue) {
            this.editing = true;
        }
    }

    onEdit() {
        this.editing = true;
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.unsubscribe();
    }

    onOutsideClick() {
        if (this.headerForm.valid) {
            this.editing = false;
        }
    }

    get valid(): boolean {
        return this.headerForm.valid;
    }
}
