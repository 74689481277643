<mat-toolbar
    [@toolBar]="hideToolbar ? 'hide' : 'show'"
    id="toolbar"
    appClickOutside
    (clicked)="onClick($event)"
>
    <button mat-icon-button id="menu-button" (click)="onMenuButtonClick(true)">
        <mat-icon>menu</mat-icon>
    </button>

    <ng-template appToolbarHost></ng-template>
</mat-toolbar>
