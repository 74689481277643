<app-custom-expansion-panel
    [expanded]="initiallyExpanded"
    [class.expanded]="expanded"
    [class.selected]="selected"
    cardStyle="light"
    [cardColor]="expanded && !documentationCategory ? cardSelectedColor : ''"
    toggleStyleColor="#626262"
    [hideButtonExpansion]="!documentationCategory"
    [buttonExpansionPosition]="documentationCategory ? 'left' : 'right'"
    (expandedChange)="expandedChanged($event)"
>
    <div
        class="title body-regular-16 info pointer"
        [class.card-header]="!documentationCategory"
        [class.card-header-standard-category]="!advancedCategory"
        [class.leftLineDecoration]="
            !advancedCategory && expanded && !documentationCategory
        "
        [class.selected-color]="expanded && documentationCategory"
        (click)="onClick($event)"
    >
        <span></span>
        {{ title }}
        <ng-container *ngIf="advancedCategory">
            <ng-container *ngIf="!expanded; else patternDetails">
                <div class="patternUsedContainer">
                    <app-user-image
                        [initialsWithContent]="true"
                        [size]="userImageSize"
                        [fontSize]="userFontSize"
                    >
                        <app-user-name
                            [id]="exportedBy"
                            [initialsOnly]="true"
                        ></app-user-name>
                    </app-user-image>
                    <div class="pattern-name">{{ patternUsed }}</div>
                </div>
            </ng-container>
            <ng-template #patternDetails>
                <div class="patternUsedDetails">
                    <mat-icon [svgIcon]="'patterns'"></mat-icon>
                    <div class="pattern-name">{{ patternUsed }}</div>
                </div>
                <div class="patternUsedDetails">
                    <mat-icon svgIcon="person-outline"></mat-icon>
                    <div class="pattern-name pattern-changed-by">
                        <app-user-name [id]="exportedBy"></app-user-name>
                        , {{ exportedFrom }}
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </div>
    <div
        class="content card-content"
        [class.card-content-standard-category]="!advancedCategory"
    >
        <ng-content></ng-content>
    </div>
</app-custom-expansion-panel>
