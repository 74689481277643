import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-save-element-with-message-dialog',
    templateUrl: './save-element-with-message-dialog.component.html',
    styleUrls: ['./save-element-with-message-dialog.component.scss']
})
export class SaveElementWithMessageDialogComponent {
    message = '';
    constructor(
        public dialogRef: MatDialogRef<SaveElementWithMessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string; isDraft: boolean }
    ) {}

    onConfirm(): void {
        if (this.message) this.dialogRef.close(this.message);
    }

    onDiscard(): void {
        this.dialogRef.close(null);
    }
}
