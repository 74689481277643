import { createAction, props } from '@ngrx/store';
import { LinkSummary } from 'src/app/core/interfaces/link-summary';
import { ViewDetail } from 'src/app/core/interfaces/view-detail.interface';
import { ViewSummary } from 'src/app/core/interfaces/view-summary.interface';
import { BuildReport } from 'src/app/core/resources/build-resource-service/build.resource.service';
import { ID } from '../../../../core/definitions/types';
import { SourcesQueryParam } from 'src/app/core/interfaces/source-info.interface';

////// UI

export const saveView = createAction('[Views] Save View');

export const navigateToViewsList = createAction('[View] Navigate to view list');

export const navigateToView = createAction(
    '[Views] Navigate to view',
    props<{ id?: ID; queryParams?: any }>()
);

export const selectedSourcesUpdated = createAction(
    '[Views] Selected Sources updated in query params'
);

export const createViewDraft = createAction(
    '[Views] Create View Draft from URL',
    props<{
        duplicate: boolean;
        draft: ViewDetail;
        queryParams?: any;
    }>()
);

export const selectView = createAction(
    '[Views] Select View',
    props<{ id?: ID | null }>()
);

export const viewSelectedParamUpdated = createAction(
    '[Views] Select Param Updated',
    props<{ reset: boolean }>()
);

export const updatedParamsAfterLoad = createAction(
    '[Views] Updated Params After Load',
    props<{ selected?: ID | null; sources?: SourcesQueryParam; reset?: boolean }>()
);

export const clearSelectedView = createAction('[Views API] Clear Selected View');

export const selectDraftView = createAction(
    '[Views API] Select Draft View',
    props<{ view: ViewDetail }>()
);

export const deleteViewOrDraft = createAction(
    '[Views] Delete View or Draft',
    props<{ id: ID }>()
);

export const deleteViewDraft = createAction(
    '[Views] Delete View Draft',
    props<{ id: ID }>()
);

export const viewDetailDiscard = createAction('[Views] Discard new changes');

export const changeDetailHeader = createAction(
    '[Views] Update view detail header',
    props<{ title: string; description: string }>()
);

///// API

export const getViews = createAction('[Views API] GET All Views');
export const getViewsSuccess = createAction(
    '[Views API] GET All Views Success',
    props<{ views: ViewSummary[] }>()
);
export const getViewsFail = createAction(
    '[Views API] GET All Views Fail',
    props<{ error: string | null }>()
);

export const createViewSuccess = createAction(
    '[View API] CREATE View Success',
    props<{ draftId: ID; view: ViewDetail }>()
);

export const getViewById = createAction(
    '[Views API] GET View by Id',
    props<{ id: ID }>()
);

export const getViewByIdSuccess = createAction(
    '[Views API] GET View by Id Success',
    props<{ view: ViewDetail }>()
);

export const getViewByIdFail = createAction(
    '[Views API] GET View by Id Fail',
    props<{ error: string | null }>()
);

export const createView = createAction(
    '[Views API] CREATE View',
    props<{ view: ViewDetail }>()
);

export const createViewFail = createAction(
    '[Views API] CREATE View Fail',
    props<{ error: string | null }>()
);

export const updateView = createAction(
    '[Views API] UPDATE View',
    props<{ view: ViewDetail }>()
);

export const updateViewSuccess = createAction(
    '[Views API] UPDATE View Success',
    props<{ view: ViewDetail }>()
);

export const updateViewFail = createAction(
    '[Views API] UPDATE View Fail',
    props<{ error: string | null }>()
);

export const deleteView = createAction('[Views API] DELETE View', props<{ id: ID }>());

export const deleteViewFail = createAction(
    '[Views API] DELETE View Fail',
    props<{ error: string | null }>()
);

export const deleteViewSuccess = createAction(
    '[Views API] DELETE View Success',
    props<{ id: ID }>()
);

// Link selector

export const loadLinks = createAction('[Views API] Load Links');
export const loadLinksSuccess = createAction(
    '[Views API] Load Links Success',
    props<{ links: LinkSummary[] }>()
);
export const loadLinksFail = createAction(
    '[Views API] Load Links Fail',
    props<{ error: string | null }>()
);

export const sourceSelectionChange = createAction(
    '[VIEWS] Source Selection Change',
    props<{ ids: ID[] }>()
);

export const sourceSelectionChangeFromVersionDelete = createAction(
    '[VIEWS] Source Selection Change from Version Delete',
    props<{ ids: ID[] }>()
);

export const getReport = createAction('[Views] Get Report (just to set loading)');
export const getReportAfterSourcesParamChange = createAction(
    '[Views] Get Report after sources param change'
);

export const getReportAfterVersionDelete = createAction(
    '[Views] Get Report after version delete'
);
export const clearReport = createAction('[Views] Clear Report');

export const getReportSuccess = createAction(
    '[Views] Get Report From Sources Success',
    props<{ report: BuildReport }>()
);

export const getReportFail = createAction(
    '[Views] Get Report From Sources Fail',
    props<{ error: string | null }>()
);

export const exportToExcel = createAction('[EXPORT VIEW] Export table info to excel');

export const exportToExcelSuccess = createAction(
    '[EXPORT VIEW] Export build to excel successfully'
);
export const exportToExcelFail = createAction(
    '[EXPORT VIEW] Export build to excel fails',
    props<{ error: string }>()
);

export const resetModuleState = createAction('[Views] Reset Module State');

export const removeLink = createAction('[Links] Remove Link');
export const removeLinkSuccess = createAction(
    '[Links API] Remove Link Success',
    props<{ id: ID }>()
);
export const removeLinkFailure = createAction('[Links API] Remove Link Failure');

/// Filters

export const getCompleteReportForFilters = createAction(
    '[LINKS] Get Full Report for Filters'
);
export const getCompleteReportForFiltersSuccess = createAction(
    '[LINKS] Get Full Report for Filters Success',
    props<{ report: BuildReport }>()
);
export const getCompleteReportForFiltersFail = createAction(
    '[LINKS] Get Full Report for Filters Fail',
    props<{ error: string }>()
);
