<ng-template #defaultOption>
    <p>Using section but passing nothing</p>
</ng-template>

<div class="container">
    <div class="section" [class.first-section]="thirdSectionTemplateRef">
        <ng-container [ngTemplateOutlet]="firstSectionTemplateRef"> </ng-container>
    </div>

    <div class="second-section" *ngIf="secondSectionTemplateRef">
        <ng-container [ngTemplateOutlet]="secondSectionTemplateRef"> </ng-container>
    </div>

    <div *ngIf="thirdSectionTemplateRef" class="divider"></div>

    <div class="third-section" *ngIf="thirdSectionTemplateRef">
        <ng-container [ngTemplateOutlet]="thirdSectionTemplateRef || defaultOption">
        </ng-container>
    </div>
</div>
