export function formatDistance(token: string, count: string, options: any) {
    options = options || {};

    const result = formatDistanceLocale[token].replace('{{count}}', count);

    if (options.addSuffix) {
        if (options.comparison > 0) {
            return 'in ' + result;
        } else {
            return result + ' ago';
        }
    }

    return result;
}

const formatDistanceLocale: { [key: string]: string } = {
    xSeconds: '{{count}}s',
    xMinutes: '{{count}}m',
    xHours: '{{count}}h',
    xDays: '{{count}}d'
};
