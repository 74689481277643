import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-invalid-token-error-page',
    templateUrl: './invalid-token-error-page.component.html',
    styleUrls: ['./invalid-token-error-page.component.scss']
})
export class InvalidTokenErrorPageComponent {
    constructor(private router: Router) {}

    navigateHome(): void {
        this.router.navigate(['/']);
    }
}
