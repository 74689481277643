<ng-container *ngIf="!loading; else spinner">
    <div [formGroup]="form">
        <app-custom-select
            formControlName="filterType"
            [groups]="filterOptions"
            label="Filter Type"
        ></app-custom-select>

        <div class="separator"></div>

        <span *ngIf="currentFilterType === null" class="select-filter-type">
            Select a filter type first
        </span>

        <ng-container *ngFor="let key of activeKeys">
            <ng-container
                *ngIf="controls[key] as control"
                [ngSwitch]="control.controlType"
            >
                <app-number-comparison-input
                    *ngSwitchCase="'number-comparison'"
                    [formControlName]="control.key"
                    [id]="control.key"
                ></app-number-comparison-input>
                <app-custom-input
                    *ngSwitchCase="'input'"
                    [label]="control.label"
                    [formControlName]="control.key"
                    [id]="control.key"
                    [type]="control.type"
                ></app-custom-input>
                <app-custom-select
                    *ngSwitchCase="'select'"
                    [multiple]="control.type === 'multiple'"
                    [label]="control.label"
                    [formControlName]="control.key"
                    [options]="control.options"
                    [labelTemplate]="
                        control.key === CODE_NAME ? codeNameLabelTemplate : undefined
                    "
                    [showAddOnMultiple]="true"
                >
                </app-custom-select>
            </ng-container>
        </ng-container>
        <div class="button-container">
            <app-custom-button
                *ngIf="!isFilterSaved"
                data-testid="add-filter-button"
                text="Add Filter"
                size="small"
                color="primary"
                type="flat"
                [disabled]="!form.valid"
                (buttonClick)="onSubmit()"
            ></app-custom-button>
            <app-custom-button
                *ngIf="isFilterSaved"
                data-testid="update-filter-button"
                text="Update Filter"
                size="small"
                color="primary"
                type="flat"
                [disabled]="!form.valid || !isQueryModified"
                (buttonClick)="onSubmit()"
            ></app-custom-button>
            <div class="delete-button-container" *ngIf="showDelete && isFilterSaved">
                <app-custom-button
                    data-testid="delete-filter-button"
                    class="deleteButton"
                    text="Delete Filter"
                    icon="delete"
                    type="link"
                    (buttonClick)="onDelete()"
                >
                </app-custom-button>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #codeNameLabelTemplate let-label>
    {{ label }}
</ng-template>

<ng-template #spinner>
    <div class="spinner-container">
        <app-loader class="spinner"></app-loader>
    </div>
</ng-template>
