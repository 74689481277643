<div id="dialog">
    <div>
        <h1 mat-dialog-title>Attention!</h1>
        <div mat-dialog-content>
            <app-warning-message-icon
                [showWarningWord]="false"
                [warningType]="warningType"
            >
                <p class="warning">
                    Your are about to <b>delete this element and its versions.</b>
                </p>
            </app-warning-message-icon>
            <ng-container *ngIf="usedIn">
                <p>This action might affect <b>patterns behavior.</b></p>
                <p class="used-in">This element is being used in: {{ usedIn }}</p>
            </ng-container>
        </div>
        <p class="revert">This action can not be <b>reverted.</b></p>
        <h3>Do you want to proceed?</h3>

        <div class="buttons-container" mat-dialog-actions>
            <app-custom-button
                color="primary"
                type="flat"
                text="No"
                (buttonClick)="onDiscard()"
            ></app-custom-button>
            <app-custom-button
                type="stroked"
                text="Yes"
                (buttonClick)="onAccept()"
            ></app-custom-button>
        </div>
    </div>
</div>
