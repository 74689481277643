import { Node } from '../interfaces/node';
import { ExternalNode } from '../interfaces/external-node';
import { ID } from '../../../core/definitions/types';
import { LinkBox } from '../interfaces/link-box';
import { IMPORT_ELEMENT_TREE_ID } from '../../list-tree-view/constants/import-tree-id';

/* DFS to store on 'list' the tree as a list of nodes (this way we can display the tree as a list) (use on trees with nodes already formatted)
a
/ \   -> [a, b, d, c]
b   c
\
d
*/
export function formattedTreeToList(nodes: Node[], list: Node[]): void {
    nodes.forEach((n) => {
        list.push(n);
        if (n.children) {
            n.children.forEach((c: Node) => formattedTreeToList([c], list));
        }
    });
}

// Formats tree from the ExternalNode to InternalNode
export function formatTree(
    nodes: ExternalNode[],
    fallbackTreeId: ID,
    showChildren?: (depth: number) => boolean
): Node[] {
    return nodes.map((node) =>
        formatNode(
            node,
            null,
            node.id.toString(),
            0,
            node.rootId ?? fallbackTreeId,
            true,
            showChildren
        )
    );
}

// Sets the special properties of the nodes
export function formatNode(
    node: ExternalNode,
    parent: Node | null,
    rootPath: string,
    depth: number,
    rootId: ID,
    show = true,
    showChildren?: (depth: number) => boolean
): Node {
    const nodeShowChildren = showChildren?.(depth) ?? true;
    const formattedNode: Node = {
        id: node.id,
        content: node.content,
        created: node.created,
        isGoToNode: node.isGoToNode,
        goTo: node.goTo,
        rootId,
        depth,
        parent,
        rootPath,
        showContent: false,
        showChildren: nodeShowChildren,
        show: show
    };

    formattedNode.children = node.children
        ? node.children.map((c: any) =>
              formatNode(
                  c,
                  formattedNode,
                  rootPath + `$${c.id}`,
                  depth + 1,
                  rootId,
                  nodeShowChildren, // if show children is false, then we need to hide all children
                  showChildren
              )
          )
        : [];

    let subtreeSize = 1;
    if (formattedNode.children?.length && formattedNode.children?.length > 0) {
        formattedNode.children?.forEach((c: Node) => {
            subtreeSize += c.subTreeSize ?? 1;
        });
    }
    formattedNode.subTreeSize = subtreeSize;
    return formattedNode;
}

export function getInternalTree(
    tree: ExternalNode[],
    treeId: ID,
    showChildren?: (depth: number) => boolean
) {
    const formattedTree = formatTree(tree, treeId, showChildren);
    const treeAsList: Node[] = [];
    formattedTreeToList(formattedTree, treeAsList);
    return { formattedTree, treeAsList };
}

export function addCustomRootToTree(tree: ExternalNode[]) {
    if (!tree.length) return [];
    const customRoot: ExternalNode[] = [
        {
            children: tree ?? undefined,
            id: 'root',
            isGoToNode: false,
            content: {
                elementDefinitionName: '',
                fields: [],
                isCountable: true,
                quantity: 1,
                errors: [],
                warnings: []
            },
            rootId: IMPORT_ELEMENT_TREE_ID
        }
    ];
    return customRoot;
}

/**
 * Sets the show property of a node and its first children
 * @param node
 * @param value
 */
export function setShowNoRecursive(node: Node, value: boolean): void {
    if (node.children) {
        node.children.forEach((n) => {
            n.show = value;
        });
    }
}

/**
 * Sets the show property of a node and all its children
 * @param node
 * @param value
 */
export function setShow(node: Node, value: boolean): void {
    if (node.children) {
        node.children.forEach((n) => {
            n.showChildren = value;
            n.show = value;
            setShow(n, value);
        });
    }
}

/**
 * Gets the show value of the node parents, if at least one is false everyone should be false
 * @param node
 */
export function getParentsShow(node?: Node): boolean {
    if (node && !node.parent) return node.show ?? false;
    else {
        const prevShow = getParentsShow(node?.parent as Node);
        const nodeShow = node?.show ?? false;
        return prevShow && nodeShow;
    }
}

/***
 * Converts a forest into a tree using a fake root.
 *
 * @param nodes
 * @param rootId
 * @private
 */
export function getFakeRoot(nodes: Node[], rootId: ID): Node {
    return {
        id: 'tempRoot',
        rootId,
        children: nodes
    } as Node;
}

/**
 *  Updates the path to the root after a rearranging has been done
 * @param node
 */
export function updateRootPath(node: Node): void {
    node.rootPath = node.parent
        ? node.parent.rootPath + '$' + node.id
        : node.id.toString();
    if (node.children) {
        node.children.forEach((n) => {
            updateRootPath(n);
        });
    }
}

export function insertByIndex(target: Node[], index: number, toInsert: Node[]) {
    const m1 = target?.slice(0, index) ?? [];
    const m2 = target?.slice(index) ?? [];
    return [...m1, ...toInsert, ...m2];
}

/**
 * Calculates new depth of a node and all its children
 * @param node
 * @param depth
 */
export function updateDepthForSubTree(node: Node, depth: number): void {
    node.depth = depth;
    if (node.children) {
        node.children.forEach((n) => updateDepthForSubTree(n, depth + 1));
    }
}

/**
 * Calculates new sub-size of a node (including it)
 * @param node
 */
export function updateSubtreeSize(node: Node): void {
    if (node.children?.length === 0) {
        node.subTreeSize = 1;
    } else {
        let childrenSubTreeSize = 1;
        node.children?.forEach((n) => {
            updateSubtreeSize(n);
            childrenSubTreeSize += n.subTreeSize as number;
        });
        node.subTreeSize = childrenSubTreeSize;
    }
}

/**
 * Removes a node (draggedId) from its current position.
 *
 * If the node is a root then it is removed from the forest
 * If the node has a parent the children need to be rearranged
 *
 * This is a utility method, and it does not update the depth and treeSize
 *
 * @param draggedNodeParent Can be null if the node is a root
 * @param draggedId
 * @param tree
 * @private
 */
export function removeNodeFromCurrentPosition(
    draggedNodeParent: Node | null,
    draggedId: number | string,
    tree: Node[]
) {
    if (draggedNodeParent) {
        const i = draggedNodeParent.children?.findIndex((n: Node) => n.id === draggedId);
        const m1 = draggedNodeParent.children?.slice(0, i as number) ?? []; // m1 = [x1, x2, x3]
        const m2 = draggedNodeParent.children?.slice((i as number) + 1) ?? []; // m1 = [x4, x5]
        draggedNodeParent.children = [...m1, ...m2];
    } else {
        const i = tree.findIndex((n: Node) => n.id === draggedId);
        tree.splice(i, 1);
    }
}

/**
 * Flatten the list of linkBoxes (prevents 2 non-true-linkBoxes to be consecutive)
 *
 * Returns the flat linkBox
 * @param list
 */
export function flattenLinkBoxes(list: LinkBox[]) {
    const flattenedList: LinkBox[] = [];
    // The pivot linkBox will be used to contain the possible consecutive non-true-linkBoxes
    let pivot = {
        leftList: [],
        rightList: [],
        isLinkBox: false
    } as LinkBox;

    for (let i = 0; i < list.length; i++) {
        // Iterate through the list
        const lb = list[i];
        // If it is a non-true-linkBox
        if (!lb.isLinkBox) {
            // The pivots add the left and right list of the element to its own
            pivot.leftList.push(...lb.leftList);
            pivot.rightList.push(...lb.rightList);
            // If it is the last element of the list, add the pivot to the flattened list
            if (i === list.length - 1) {
                flattenedList.push(pivot);
            }
        } else {
            // Else (this means it is a true linkBox)
            // If the pivot it is not empty
            if (pivot.leftList.length > 0 || pivot.rightList.length > 0) {
                // Add the pivot to the flattened list and reset it
                flattenedList.push(pivot);
                pivot = {
                    leftList: [],
                    rightList: [],
                    isLinkBox: false
                } as LinkBox;
            }
            // Add the linkBox to the flattened list
            flattenedList.push(lb);
        }
    }

    return flattenedList;
}
