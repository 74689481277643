import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';
import { ID } from 'src/app/core/definitions/types';

export interface GroupMenuOption {
    title: string;
    icon: string;
    description: string;
    options: CustomMenuOption[];
    documentationLink?: string;
}
export interface CustomMenuOption {
    id: ID;
    name: string;
    subDescription?: string;
    description: string;
    disabled?: boolean;
}
@Component({
    selector: 'app-custom-menu-content',
    templateUrl: './custom-menu-content.component.html',
    styleUrls: ['./custom-menu-content.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CustomMenuContentComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: CustomMenuContentComponent
        }
    ]
})
export class CustomMenuContentComponent implements ControlValueAccessor, Validator {
    @Input() subName = '';
    @Input() options: GroupMenuOption[] = [];
    @Input() existingSelectedOption!: ID;

    @Output() optionSelected = new EventEmitter<ID>();
    selectedOption!: ID;

    touched = false;

    disabled = false;

    onChange(selected: ID): void {
        console.info({ selected }); // eslint-disable-line no-console
    }

    onTouched(): void {
        console.info(); // eslint-disable-line no-console
    }

    onSelect = (selected: ID) => {
        this.markAsTouched();
        if (this.disabled) return;
        this.selectedOption = selected;
        this.optionSelected.emit(selected);
        this.onChange(selected);
    };

    writeValue(obj: ID): void {
        this.selectedOption = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    validate(control: AbstractControl<any, any>): ValidationErrors | null {
        return null;
    }
    registerOnValidatorChange?(fn: () => void): void {
        fn();
    }
}
