import { Injectable } from '@angular/core';
import { ViewDetail } from 'src/app/core/interfaces/view-detail.interface';
import { ReportDefinition } from '../../list-tree-view/interfaces/report-definition.interface';

@Injectable({
    providedIn: 'root'
})
export class DuplicateViewService {
    duplicate(view: ViewDetail): ViewDetail {
        return this.removeIds(view);
    }

    removeIds(view: ViewDetail): ViewDetail {
        return {
            ...view,
            id: null as any,
            title: '',
            description: '',
            reportDefinitions: view.reportDefinitions.map((reportDefinition) =>
                this.removeIdsFromReportDefinitions(reportDefinition)
            )
        };
    }

    removeIdsFromReportDefinitions(reportDefinition: ReportDefinition): ReportDefinition {
        return {
            ...reportDefinition,
            id: '',
            filters: reportDefinition.filters.map((filter) => ({
                ...filter,
                id: ''
            }))
        };
    }
}
