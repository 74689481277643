import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromRoot } from '../../../store';
import { distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';
import { RootState } from '../../../store/reducer/root.reducer';
import { combineLatest } from 'rxjs';
import { changeOrganizationContextFromUrl } from '../../../store/actions/root.actions';

@Injectable({
    providedIn: 'root'
})
export class RoutingService {
    targetRoute$ = this.store.select(fromRoot.selectCurrentRoute);

    organizationIdFromQueryParam$ = this.store.select(fromRoot.selectQueryParams).pipe(
        map((params: any) => params?.currentOrganizationId),
        distinctUntilChanged()
    );
    currentOrganizationContext$ = this.store.select(fromRoot.selectUser).pipe(
        map((user) => user?.currentOrganizationId),
        distinctUntilChanged()
    );

    constructor(private store: Store<RootState>) {}

    initializeUrlContextChangeHandler() {
        combineLatest([
            this.organizationIdFromQueryParam$,
            this.currentOrganizationContext$
        ])
            .pipe(
                filter(
                    ([organizationIdFromQueryParam, currentOrganizationContext]) =>
                        organizationIdFromQueryParam &&
                        organizationIdFromQueryParam !== currentOrganizationContext
                ),
                withLatestFrom(this.targetRoute$)
            )
            .subscribe(([[newOrganizationIdFromUrl], targetRoute]) => {
                // We used to navigate here but it's better if we navigate once
                // the company is changed
                this.store.dispatch(
                    changeOrganizationContextFromUrl({
                        id: newOrganizationIdFromUrl,
                        targetRoute
                    })
                );
            });
    }
}

export function initializeRoutingService(routingService: RoutingService) {
    return () => routingService.initializeUrlContextChangeHandler();
}
