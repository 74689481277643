// noinspection RegExpRedundantEscape

import * as ace from 'ace-builds';
import { FlowCompletions } from './flow-completions';
import {
    FIELD_DIRECT_RE,
    FIELD_QUOTED_RE,
    IDENTIFIER_WITH_ACCESS_RE,
    NUMERIC_RE,
    STRING_RE
} from '../../../../core/constants/regular-expressions';

export function createFlowMode(completer: FlowCompletions): any {
    return new FlowMode(completer);
}

const TextMode = ace.require('ace/mode/text').Mode;
const TextHighlightRules = ace.require(
    'ace/mode/text_highlight_rules'
).TextHighlightRules;

class FlowMode extends TextMode {
    HighlightRules = class FlowHighlightRules extends TextHighlightRules {
        $rules = rules;
    };

    constructor(completer: FlowCompletions) {
        super();
    }
}

export const FIELD = 'field';
export const IDENTIFIER = 'identifier';

export const rules = {
    start: [
        { token: 'string', regex: STRING_RE },
        {
            token: 'punctuation.operator',
            regex: '[,]'
        },
        {
            token: 'invalid',
            regex: '[.]'
        },
        {
            token: 'support.function',
            regex: 'MitterLength(?=\\()|SUM(?=\\()|PRODUCT(?=\\()|DIV(?=\\()|POW(?=\\()|ROUND(?=\\()|FLOOR(?=\\()|CEILING(?=\\()|IF(?=\\()|PI(?=\\()|E(?=\\()|Radian(?=\\()|Degree(?=\\()|AVERAGE(?=\\()|MAX(?=\\()|MIN(?=\\()|COUNT(?=\\()|COUNTA(?=\\()|CONCATENATE(?=\\()|LEFT(?=\\()|RIGHT(?=\\()|MID(?=\\()|REPLACENB(?=\\()|REPLACETXT(?=\\()|SIN(?=\\()|COS(?=\\()|TAN(?=\\()|SEC(?=\\()|CSC(?=\\()|COT(?=\\()|SINH(?=\\()|COSH(?=\\()|TANH(?=\\()|SECH(?=\\()|COTH(?=\\()|ASIN(?=\\()|ACOS(?=\\()|ATAN(?=\\()|ASINH(?=\\()|ACOSH(?=\\()|ATANH(?=\\()',
            caseInsensitive: true
        },
        {
            token: 'keyword.operator',
            regex: /\^|\*\*|\*|\/|%|\+|-|<|<=|>|>=|&|!|<>|=/
        },
        { token: 'paren.lparen', regex: '[(]' },
        {
            token: 'paren.rparen',
            regex: '[)]'
        },
        { token: 'constant.numeric', regex: NUMERIC_RE },
        {
            token: 'variable.language',
            regex: 'link|parent',
            next: () => 'property'
        }
    ],
    property: [
        {
            token: 'variable.language',
            regex: 'link|parent'
        },
        {
            token: IDENTIFIER,
            regex: IDENTIFIER_WITH_ACCESS_RE
        },
        {
            token: 'punctuation.operator',
            regex: '[.]'
        },
        {
            token: FIELD,
            regex: FIELD_DIRECT_RE + '|' + FIELD_QUOTED_RE,
            next: () => 'start'
        }
    ]
};
