import { Injectable } from '@angular/core';
import { StorageKey } from '../definitions/enums';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    set(key: StorageKey, data: any): void {
        localStorage.setItem(key, JSON.stringify(data));
    }

    /**
     *
     * @param key
     * @returns a T object or null if the item is not found
     */
    get<T>(key: StorageKey): T | null {
        const item = localStorage.getItem(key);
        return item ? (JSON.parse(item) as T) : null;
    }

    clear(key: StorageKey): void {
        localStorage.removeItem(key);
    }
}
