import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_AUTH_SERVICE } from '../../constants/connection.apiservice.contant';
import { IUnique } from '../../definitions/interfaces';
import { UserInfoInterface } from '../../interfaces/user-info.interface';
import { UserInfoModel } from '../../models/user-info.model';
import { ApiService } from '../../services/api.service';
import { GetResource } from '../get.resource';
import { ORGANISATIONS } from '../resources';

@Injectable({
    providedIn: 'root'
})
export class UsersResourceService extends GetResource<UserInfoModel> {
    constructor(@Inject(API_AUTH_SERVICE) private api: ApiService) {
        super(api, ORGANISATIONS, (obj: IUnique) => new UserInfoModel(obj));
    }

    getUsers(params?: HttpParams): Observable<UserInfoInterface[]> {
        return this._api.get<any>(`${ORGANISATIONS}/get-users`, params).pipe(
            map((users: any) => {
                if (users.result) {
                    return users.result as UserInfoModel[];
                } else {
                    return users as UserInfoModel[];
                }
            })
        );
    }
}
