import { createAction, props } from '@ngrx/store';
import { RoleInfoInterface } from 'src/app/core/interfaces/roles-info.interface';
import { UserInfoInterface } from 'src/app/core/interfaces/user-info.interface';
import { UserRolesEmail } from 'src/app/core/interfaces/user-roles-email.interface';

export const getRoles = createAction('[Organization] GET roles');
export const getRolesSuccess = createAction(
    '[Organization] GET Roles Success',
    props<{ roles: RoleInfoInterface[] }>()
);
export const getRolesFail = createAction(
    '[Organization] GET Roles Fail',
    props<{ error: string }>()
);
export const getUsers = createAction('[Organization] GET Users');
export const getUsersSuccess = createAction(
    '[Organization] GET Users Success',
    props<{ users: UserInfoInterface[] }>()
);
export const getUsersFail = createAction(
    '[Organization] GET Users Fail',
    props<{ err: string }>()
);
export const modifyUserRoles = createAction(
    '[Organization] Modify User roles',
    props<{ invite: UserRolesEmail }>()
);
export const modifyUserRolesSuccess = createAction(
    '[Organization] GET Modify user role Success',
    props<{ succeeded: boolean; message: string }>()
);
export const modifyUserRolesFail = createAction(
    '[Organization] Get Modify user role Fail',
    props<{ error: string }>()
);

export const inviteUser = createAction(
    '[Organization] Invite new user',
    props<{ invite: UserRolesEmail }>()
);
export const inviteUserSuccess = createAction(
    '[Organization] GET Invitation Success',
    props<{ succeeded: boolean; message: string; invite: UserRolesEmail }>()
);
export const inviteUserFail = createAction(
    '[Organization] Get Invitation Fail',
    props<{ error: string }>()
);
export const deleteAccount = createAction(
    '[Organization] Delete User',
    props<{ id: string; organizationName: string }>()
);
export const deleteAccountSuccess = createAction(
    '[Organization] Delete User Success',
    props<{ success: boolean; message: string }>()
);
export const deleteAccountFail = createAction(
    '[Organization] Delete User Fail',
    props<{ error: string }>()
);
