import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { fromRoot } from 'src/app/store';
import { SYSTEM_ROLES } from '../constants/feature';

@Injectable({
    providedIn: 'root'
})
export class AllowPermissionRolesGuard implements CanActivate {
    constructor(private store: Store, private router: Router) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this.store.select(fromRoot.selectUser).pipe(
            map((user) => {
                if (
                    (user?.role?.includes(SYSTEM_ROLES.USER) ||
                        user?.role?.includes(SYSTEM_ROLES.NONADMIN)) &&
                    state.url.includes('/organization')
                ) {
                    return this.router.createUrlTree(['/app']);
                }
                return true;
            })
        );
    }
}
