<app-category
    *ngIf="item.type === 0; else file"
    [title]="item.name!"
    [advancedCategory]="false"
    [documentationCategory]="true"
    [selected]="isCategorySelected"
    [expanded]="isCategorySelected"
    (onclick)="onClickCategory()"
>
    <app-help-item
        *ngFor="let page of item.children"
        [item]="page"
        [selectedPath]="selectedPath"
    ></app-help-item>
</app-category>

<ng-template #file>
    <app-list-item
        [title]="item.name"
        height="20px"
        [selected]="_isItemSelected(selectedPath, item)"
        [attr.data-id]="item.path"
        (click)="onClickItem()"
    >
    </app-list-item>
</ng-template>
