// Auth
export const LOGIN = '/Account/login';
export const LOGINOAUTH = '/Account/oauth';
export const LOGOUT = '/Account/logout/';
export const ORGANIZATION = '/Organisations/';
export const CHANGE_ORGANIZATION = '/Organisations/change-context/';
export const UPDATE_PERSONAL_INFO = '/Account/update/';
export const UPDATE_PERSONAL_PASSWORD = '/Account/change-password/';

// Backend
export const ELEMENT_DEFINITIONS = 'elementDefinitions';
export const FIELD_DEFINITIONS = 'fieldDefinitions';
export const FIELD_DEFINITIONS_VALIDATOR = 'validate';
export const PATTERNS = 'patterns';
export const BLOCK_DEFINITIONS = 'pushes/blockDefinitions';
export const ORGANISATIONS = 'Organisations';
export const ROLES = 'Roles';
export const ACCOUNT = 'Account';

export const BUILDS = 'builds';
export const REPORTS = 'reports';
export const PUSHES = 'pushes';
export const LINKS = 'links';
export const VIEWS = 'views';

export const INTERPRETER = 'interpreter';
export const INTERPRETER_VALIDATOR = 'validate';
export const PATTERN_VALIDATOR = 'check';

// Packages
export const GET_URL_PACKAGE = '/hub/latest/';

// Documentation
export const DOCUMENTATION_FILE = '/documentation/file';
