export const UNICITY_MESSAGE = 'This will affect future links results.';
export const IS_COUNTABLE_MESSAGE = 'This will affect future links results.';
export const UNICITY_KIND_MESSAGE = 'This will affect future links results.';
export const PATTERNS_AFFECT_MESSAGE =
    'This will affect the patterns behavior and links results.';
export const NAME_MESSAGE =
    'This will affect visible label of this property in patterns and links.';
export const NAME_AND_CODE_NAME_MESSAGE =
    'This will affect the visible name and codename of this element in patterns and links.';
export const CODE_NAME_ELEMENT_MESSAGE =
    'This will affect the visible codename of this element in patterns and links.';
export const CODE_NAME_MESSAGE = 'This will affect the patterns behavior.';
export const CODE_NAME_INVALID_MESSAGE =
    'This code name is invalid. Sometimes this error is caused by a space at the end of the code name.';
export const UNICITY_ENABLE_TIP_MESSAGE =
    'Please be advised that enabling the "Unicity" feature will result in data reduction when creating objects in WorkRoads.';
export const UNICITY_DISABLE_TIP_MESSAGE =
    'Please be advised that disabling the "Unicity" feature will result in data redundancy when creating objects in WorkRoads.';
export const IS_COUNTABLE_ENABLE_TIP_MESSAGE =
    'Please be advised that enabling the "Is Countable" feature will result in all elements generated in links displaying a quantity. Also a new field for the quantity will be displayed in the patterns.';
export const IS_COUNTABLE_DISABLE_TIP_MESSAGE =
    'Please be advised that disabling the "IsCountable" feature will result in all elements generated in links not displaying a quantity.';
export const ADMITS_CHILDREN_ENABLE_TIP_MESSAGE =
    'Please be advised that enabling "Admits children" will allow adding or managing any child elements for this element in the patterns section.';
export const ADMITS_CHILDREN_DISABLE_TIP_MESSAGE =
    'Please be advised that disabling "Admits children" will prevent adding or managing any child Elements for this Element in the Patterns section.';
export const UNICITY_KIND_TIP_MESSAGE =
    'Please be advised that changing the "Unicity kind” will affect the way unicity compares data when creating objects into WorkRoads.';
export const CODE_NAME_FIELD_TIP_MESSAGE =
    'Please be advised that changing this “property codename” will affect the previous codename written in FLOW expressions.';
