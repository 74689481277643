import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DraftRoutingService } from 'src/app/modules/layout/services/draft-routing.service';
import { DeleteConfirmationComponent } from 'src/app/shared/components/delete-confirmation/delete-confirmation.component';
import { UnsavedChangesDialogComponent } from 'src/app/shared/components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { Action } from 'src/app/shared/interfaces/action';
import { ViewToolbarView } from '../../interfaces/view-toolbar-view.interface';
import * as viewsActions from '../../store/actions/views.actions';
import * as versionHistoryActions from '../../store/actions/links-version-history.action';
import { fromViews } from '../../store/selectors/views.selectors';
import { ViewsToolbarFormComponent } from '../views-toolbar-form/views-toolbar-form.component';

@Component({
    selector: 'app-toolbar-views',
    templateUrl: './toolbar-views.component.html',
    styleUrls: ['./toolbar-views.component.scss']
})
export class ToolbarViewsComponent {
    view$: Observable<ViewToolbarView>;
    onDestroy$ = new Subject<boolean>();

    @ViewChild(ViewsToolbarFormComponent) form?: ViewsToolbarFormComponent;

    MENU_ACTIONS = [
        {
            icon: 'restore',
            iconFirst: true,
            emitEvent: () => this.showVersionHistory(),
            label: 'Version history'
        },
        {
            icon: 'file_copy',
            iconFirst: true,
            emitEvent: () => this.duplicate(),
            label: 'Duplicate View',
            disabled: false
        },
        {
            icon: 'delete_link',
            iconFirst: true,
            emitEvent: () => this.openDeleteDialog(),
            label: 'Delete View',
            style: { color: 'var(--remove)' }
        }
    ] as Action[];

    constructor(
        private store: Store,
        private draftRoutingService: DraftRoutingService,
        private dialog: MatDialog
    ) {
        this.view$ = this.store.select(fromViews.selectToolbarView);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.unsubscribe();
    }

    onSubmit(): void {
        this.store.dispatch(viewsActions.saveView());
    }

    duplicate() {
        this.draftRoutingService.createDuplicateViewDraft();
    }

    get isFormValid(): boolean {
        return !!this.form?.valid;
    }

    exportToExcel(): void {
        this.store.dispatch(viewsActions.exportToExcel());
    }

    showVersionHistory(): void {
        this.store.dispatch(versionHistoryActions.showVersionHistory());
    }

    onDiscard(): void {
        const dialogRef = this.dialog.open(UnsavedChangesDialogComponent, {
            data: {
                message: 'You are about to discard all the changes you have made.',
                question: 'Do you want to proceed?'
            }
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((response) => {
                if (response) this.store.dispatch(viewsActions.viewDetailDiscard());
            });
    }

    openDeleteDialog(): void {
        this.view$.pipe(take(1)).subscribe((view) => {
            if (!view.selected) return;
            return this.dialog.open(DeleteConfirmationComponent, {
                width: '500px',
                autoFocus: false,
                height: '400px',
                data: {
                    message: 'this view',
                    onAccept: () =>
                        this.store.dispatch(
                            viewsActions.deleteView({ id: view.selected!.id })
                        ),
                    view
                }
            });
        });
    }

    clickOutside(): void {
        this.form?.onOutsideClick();
    }
}
