<article class="confirmation-menu" [class.confirmation-mode]="isConfirmationVisible">
    <button
        id="confirm"
        [class.visible]="isConfirmationVisible"
        (click)="onAction(); $event.stopImmediatePropagation()"
        (mousedown)="$event.stopImmediatePropagation()"
    >
        Confirm
    </button>
    <button
        [id]="isConfirmationVisible ? 'close' : 'delete'"
        (click)="
            isConfirmationVisible ? hideConfirmation() : showConfirmation();
            $event.stopImmediatePropagation()
        "
        (mousedown)="$event.stopImmediatePropagation()"
    >
        <mat-icon [ngClass]="isConfirmationVisible ? 'close' : 'delete'">delete</mat-icon>
    </button>
</article>
