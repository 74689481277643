import { createFeature, createReducer, on } from '@ngrx/store';
import { ViewSummary } from 'src/app/core/interfaces/view-summary.interface';
import { ViewDetail } from 'src/app/core/interfaces/view-detail.interface';
import { HeaderItem } from 'src/app/modules/layout/interfaces/header-item';
import * as viewsActions from '../actions/views.actions';
import * as viewsTableActions from '../actions/views-table.actions';
import * as viewsEditableReportActions from '../actions/views-editable-reports.actions';
import * as linksVersionHistoryActions from '../actions/links-version-history.action';
import { discardUnsavedChangesAndNavigate } from 'src/app/store/actions/root.actions';
import { LinkSummary } from 'src/app/core/interfaces/link-summary';
import { updateInList } from 'src/app/shared/helpers/reducer-lists-helpers';
import { ID } from 'src/app/core/definitions/types';
import {
    addReportDefinition,
    removeReport,
    updateReportDefinition
} from 'src/app/modules/list-tree-view/functions.reducer';
import { Build } from 'src/app/core/interfaces/build.interface';

interface VersionHistoryState {
    versionHistoryBuildsLinkId: ID | null;
    versionHistoryBuilds: Build[];

    versionHistoryVisible: boolean;
    versionHistoryLoading: boolean;
    selectedLink: LinkSummary | null;
}

export interface ViewsState extends VersionHistoryState {
    views: ViewSummary[];
    drafts: ViewDetail[];
    loadingDetail: boolean;
    loadingList: boolean;
    selected: ViewDetail | null;
    modified: boolean;
    detailHeader: HeaderItem | null;
    creationMode: boolean;
    linksForSelector: LinkSummary[];
    loadingLinksForSelector: boolean;
    loadingReport: boolean;
    selectedReportPosition: number;

    exporting: boolean;
    isCompleteReportLoading: boolean;
    completeReportLoadedFor: ID | null;
}

const VIEW_NOT_SELECTED = {
    loadingDetail: false,
    selected: null,
    modified: false,
    detailHeader: null,
    creationMode: false,
    selectedReportPosition: 0,
    exporting: false,
    isCompleteReportLoading: false,
    completeReportLoadedFor: null
};

const VIEWS_NOT_LOADED = {
    loadingList: false,
    views: [],
    drafts: []
};

const INITIAL_VERSION_HISTORY_STATE = {
    versionHistoryBuildsLinkId: null,
    versionHistoryBuilds: [],
    versionHistoryVisible: false,
    versionHistoryLoading: false,
    selectedLink: null
};

const INITIAL_SELECTOR_STATE = {
    linksForSelector: [],
    loadingLinksForSelector: false,
    loadingReport: false
};

export const initialState: ViewsState = {
    ...VIEWS_NOT_LOADED,
    ...VIEW_NOT_SELECTED,
    ...INITIAL_SELECTOR_STATE,
    ...INITIAL_VERSION_HISTORY_STATE
};

export const viewsFeature = createFeature({
    name: 'views',
    reducer: createReducer(
        initialState,
        on(viewsActions.getViews, (state) => ({
            ...state,
            ...VIEWS_NOT_LOADED,
            loadingList: true
        })),
        on(viewsActions.getViewsSuccess, (state, { views }) => ({
            ...state,
            views,
            loadingPatternList: false
        })),
        on(viewsActions.getViewsFail, (state) => ({
            ...state,
            loadingPatternList: false
        })),
        on(viewsActions.getViewById, (state) => ({
            ...state,
            ...VIEW_NOT_SELECTED,
            loadingDetail: true
        })),
        on(viewsActions.getViewByIdSuccess, (state, { view }) => ({
            ...state,
            selected: view,
            loadingDetail: false
        })),
        on(viewsActions.selectDraftView, (state, { view }) => ({
            ...state,
            selected: view
        })),
        on(viewsActions.getViewByIdFail, (state) => ({
            ...state,
            loadingDetail: false
        })),
        on(viewsActions.deleteView, (state) => ({
            ...state,
            loadingDetail: true
        })),
        on(viewsActions.deleteViewSuccess, (state) => ({
            ...state,
            loadingDetail: false,
            selected: null
        })),
        on(viewsActions.deleteViewFail, (state) => ({
            ...state,
            loadingDetail: false
        })),
        on(viewsActions.createView, (state) => ({
            ...state,
            loadingDetail: true
        })),
        on(viewsActions.createViewSuccess, (state, { draftId, view }) => ({
            ...state,
            creationMode: false,
            drafts: state.drafts.filter((v) => v.id !== draftId),
            views: [view, ...state.views],
            selected: view,
            modified: false,
            loadingDetail: false
        })),
        on(viewsActions.createViewFail, (state) => ({
            ...state,
            loadingDetail: false
        })),
        on(viewsActions.createViewDraft, (state, { draft }) => ({
            ...state,
            selected: null,
            creationMode: true,
            drafts: [...state.drafts, draft]
        })),
        on(viewsActions.deleteViewDraft, (state, { id }) => ({
            ...state,
            drafts: state.drafts.filter((v) => v.id !== id)
        })),
        on(viewsActions.deleteViewSuccess, (state, { id }) => ({
            ...state,
            views: state.views.filter((v) => v.id !== id), // Assuming 'views' stores all the views
            selected: null
        })),
        on(viewsActions.updateView, (state) => ({
            ...state,
            loadingDetail: true,
            selected: null
        })),
        on(viewsActions.updateViewSuccess, (state, { view }) => ({
            ...state,
            views: state.views.map((v) => (v.id === view.id ? view : v)),
            selected: view,
            modified: false,
            loadingDetail: false
        })),
        on(viewsActions.updateViewFail, (state) => ({
            ...state,
            loadingDetail: false
        })),
        on(viewsActions.changeDetailHeader, (state, { title, description }) => {
            if (
                state.selected?.title === title &&
                state.selected?.description === description
            ) {
                return state;
            }

            return {
                ...state,
                detailHeader: {
                    name: title,
                    description: description
                },
                modified: true
            };
        }),
        on(viewsActions.viewDetailDiscard, (state) => {
            const drafts = state.creationMode
                ? state.drafts.filter((item) => item.id !== state.selected?.id)
                : state.drafts;

            return {
                ...state,
                ...VIEW_NOT_SELECTED,
                drafts
            };
        }),
        on(discardUnsavedChangesAndNavigate, (state) => ({
            ...state,
            drafts: initialState.drafts,
            modified: false
        })),
        on(viewsActions.sourceSelectionChange, (state, { ids }) => {
            const lastId = ids[ids.length - 1];
            const selectedLink = state.linksForSelector.find(
                (link) => link.id === lastId
            );

            return {
                ...state,
                // This is a small UX improvement,
                // when the user selects a source,
                //  the last selected link is also selected
                // so he already has it in the version history
                selectedLink: selectedLink ?? null
            };
        }),
        on(viewsActions.loadLinks, (state) => ({
            ...state,
            loadingLinksForSelector: true
        })),
        on(viewsActions.loadLinksSuccess, (state, { links }) => ({
            ...state,
            linksForSelector: links,
            loadingLinksForSelector: false
        })),
        on(viewsActions.loadLinksFail, (state) => ({
            ...state,
            loadingLinksForSelector: false
        })),
        on(viewsActions.getReport, (state) => ({
            ...state,
            loadingReport: true
        })),
        on(viewsActions.clearReport, (state) => ({
            ...state,
            loadingReport: false
        })),
        on(viewsActions.getReportSuccess, viewsActions.getReportFail, (state) => ({
            ...state,
            loadingReport: false
        })),
        on(viewsTableActions.changeTreeView, (state, { reportView }) => {
            if (!state.selected) return state;
            const newReports =
                state.selected?.reportDefinitions.map((report, index) =>
                    index === state.selectedReportPosition
                        ? { ...report, reportView }
                        : report
                ) ?? [];
            return {
                ...state,
                selected: { ...state.selected, reportDefinitions: newReports },
                modified: true
            };
        }),
        on(viewsTableActions.updateFilters, (state, { filters }) => {
            if (!state.selected) return state;
            return {
                ...state,
                modified: true,
                selected: {
                    ...state.selected,
                    reportDefinitions: updateInList(
                        state.selected.reportDefinitions,
                        {
                            filters
                        },
                        { index: state.selectedReportPosition }
                    )
                }
            };
        }),
        on(viewsTableActions.updateGroupBy, (state, { groupBy }) => {
            if (!state.selected) return state;

            return {
                ...state,
                modified: true,
                reportDefinitions: updateInList(
                    state.selected.reportDefinitions,
                    {
                        groupBy
                    },
                    { index: state.selectedReportPosition }
                )
            };
        }),
        on(viewsTableActions.updateOrderColumns, (state, { columns }) => {
            if (!state.selected) return state;
            return {
                ...state,
                selected: {
                    ...state.selected,
                    reportDefinitions: updateInList(
                        state.selected.reportDefinitions,
                        {
                            columns
                        },
                        { index: state.selectedReportPosition }
                    )
                },
                modified: true
            };
        }),
        on(viewsTableActions.hideColumns, (state, { columns, mode }) => {
            if (!state.selected) return state;

            const currentHiddenColumns =
                state.selected.reportDefinitions[state.selectedReportPosition]
                    .hiddenColumns ?? [];

            const newHiddenColumns =
                mode === 'add' ? [...currentHiddenColumns, ...columns] : columns;

            return {
                ...state,
                selected: {
                    ...state.selected,
                    reportDefinitions: updateInList(
                        state.selected.reportDefinitions,
                        {
                            hiddenColumns: newHiddenColumns
                        },
                        { index: state.selectedReportPosition }
                    )
                },
                modified: true
            };
        }),
        on(viewsTableActions.sortColumn, (state, { column, by }) => {
            // Handle sortColumn action here
            return { ...state };
        }),
        on(viewsEditableReportActions.addReport, (state, { newReportDefinitionName }) => {
            if (state.selected == null) {
                return state;
            }
            const newView = addReportDefinition(state.selected, newReportDefinitionName);
            return {
                ...state,
                selected: newView,
                modified: true,
                selectedReportPosition: newView.reportDefinitions.length
                    ? newView.reportDefinitions.length - 1
                    : 0
            };
        }),
        on(viewsEditableReportActions.removeReport, (state, { position }) => {
            if (state.selected == null) {
                return state;
            }

            const newView = removeReport(state.selected, position);

            return {
                ...state,
                selected: newView,
                modified: true
            };
        }),
        on(viewsEditableReportActions.editReportName, (state, { updateReport }) => {
            if (state.selected == null) {
                return state;
            }
            const { position, name } = updateReport;
            const newView = updateReportDefinition(state.selected, { name }, position);
            return {
                ...state,
                selected: newView,
                modified: true
            };
        }),
        on(viewsEditableReportActions.selectReport, (state, { position }) => {
            return {
                ...state,
                selectedReportPosition: position,
                loadingReport: true
            };
        }),
        on(viewsActions.resetModuleState, () => ({ ...initialState })),
        /// Version History for link
        on(linksVersionHistoryActions.selectLink, (state, { link }) => ({
            ...state,
            selectedLink: link
        })),
        on(linksVersionHistoryActions.getVersionHistory, (state) => ({
            ...state,
            versionHistoryLoading: true
        })),
        on(linksVersionHistoryActions.getVersionHistorySuccess, (state, { builds }) => {
            if (!state.selectedLink) {
                throw new Error('No link selected. This is a bug');
            }

            return {
                ...state,
                versionHistoryBuildsLinkId: state.selectedLink.id,
                versionHistoryBuilds: builds,
                versionHistoryLoading: false
            };
        }),
        on(linksVersionHistoryActions.showVersionHistory, (state) => ({
            ...state,
            versionHistoryVisible: true
        })),
        on(
            linksVersionHistoryActions.hideVersionHistory,
            viewsActions.navigateToView,
            viewsActions.navigateToViewsList,
            (state) => ({
                ...state,
                versionHistoryVisible: false
            })
        ),
        on(linksVersionHistoryActions.removeVersionHistoryItem, (state) => ({
            ...state,
            versionHistoryLoading: true,
            versionHistoryBuildsLinkId: null // This is required. This way we mark the version history as outdated
        })),
        on(linksVersionHistoryActions.removeVersionHistoryItemSuccess, (state) => ({
            ...state,
            versionHistoryLoading: false
        })),
        on(linksVersionHistoryActions.removeVersionHistoryItemFail, (state) => ({
            ...state,
            versionHistoryLoading: false
        })),
        on(viewsActions.exportToExcel, (state) => ({
            ...state,
            exporting: true
        })),
        on(viewsActions.exportToExcelSuccess, (state) => ({
            ...state,
            exporting: false
        })),
        on(viewsActions.exportToExcelFail, (state) => ({
            ...state,
            exporting: false
        })),
        on(viewsActions.getCompleteReportForFilters, (state) => {
            return {
                ...state,
                isCompleteReportLoading: true,
                completeReportLoadedFor: null
            };
        }),
        on(viewsActions.getCompleteReportForFiltersSuccess, (state) => {
            return {
                ...state,
                isCompleteReportLoading: false,
                completeReportLoadedFor: state.selected?.id ?? null
            };
        }),
        on(viewsActions.getCompleteReportForFiltersFail, (state) => {
            return {
                ...state,
                isCompleteReportLoading: false,
                completeReportLoadedFor: null
            };
        })
    )
});
