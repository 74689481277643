import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-sections-layout',
    templateUrl: './sections-layout.component.html',
    styleUrls: ['./sections-layout.component.scss']
})
/*
 * SectionLayout contains 3 sections, either one is identified by its own reference tag
 *   The tags are:
 *   `firstSection` `secondSection` `thirdSection`
 *   `thirdSection` is the only one that it is not required, if this section is not used
 *   the layout is going to show only 2 spaces
 *
 *   For using this component
 *
 *        `<app-sections-layout>
 *            <ng-template #firstSection>
 *                <h1>Hi this is the first section</h1>
 *            </ng-template>
 *            <ng-template #secondSection>
 *                <h1>Hi this is the second section</h1>
 *            </ng-template>
 *         <app-sections-layout>`
 *
 *
 */
export class SectionsLayoutComponent {
    @Input() highlight?: boolean = false;
    @ContentChild('firstSection', { static: false })
    firstSectionTemplateRef!: TemplateRef<any>;

    @ContentChild('secondSection', { static: false })
    secondSectionTemplateRef!: TemplateRef<any>;

    @ContentChild('thirdSection', { static: false })
    thirdSectionTemplateRef: TemplateRef<any> | undefined;
}
