import {
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrganizationSummary } from 'src/app/core/models/organization-summary.model';
import { fromRoot } from 'src/app/store';
import { updateCurrentOrganization } from 'src/app/store/actions/root.actions';

@Component({
    selector: 'app-organization-information',
    templateUrl: './information.component.html',
    styleUrls: ['./information.component.scss']
})
export class OrganizationInformationComponent implements OnInit, OnDestroy {
    @ViewChildren('input') inputs: QueryList<ElementRef<HTMLInputElement>> | undefined;

    currentOrganization$: Observable<OrganizationSummary | undefined>;
    currentOrganization?: OrganizationSummary;
    name?: string;
    onDestroy$ = new EventEmitter();

    constructor(private store: Store) {
        this.currentOrganization$ = this.store.select(fromRoot.selectCurrentOrganization);
    }

    ngOnInit(): void {
        this.currentOrganization$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((organization) => {
                this.currentOrganization = organization;
                this.name = this.currentOrganization?.name;
            });
    }

    ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    get formIsValid(): boolean {
        return (this.inputs ?? [])
            .map((input) => input.nativeElement)
            .every((input) => input.checkValidity());
    }

    onDiscard(): void {
        this.name = this.currentOrganization?.name;
    }

    onSubmit(): void {
        if (!this.formIsValid || !this.name) {
            return;
        }
        this.store.dispatch(updateCurrentOrganization({ name: this.name }));
    }
}
